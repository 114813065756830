import { AssetVersion } from '../../../workflows/types/workflow.types';

export const typeMatcher = (type: string) => (asset: AssetVersion) =>
	asset?.type === type;

export const typePrefixMatcher = (type?: string) => {
	if (!type) type = '';
	const typePrefixRegex = new RegExp(
		`^${(type ?? '').replace(/\/+$/, '')}/[^/]+$`
	);
	return (asset: AssetVersion) =>
		typePrefixRegex.test(asset && asset.type ? asset.type : '');
};

export const extMatcher = (ext: string) => {
	const extRegex = new RegExp(`\\.${ext || ''}$`);
	return (asset: AssetVersion) =>
		extRegex.test(asset && asset.fileName ? asset.fileName : '');
};
