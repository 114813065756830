import {
	faCheckCircle,
	faPencilAlt,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from '@reach/router';
import { useAuthContext } from 'auth';
import { Heading, ScrollCard, ScrollTable } from 'components';
import { RomeSwal } from 'components/alert';
import { SecondaryButton } from 'components/buttons.styled-components';
import { startCase } from 'lodash';
import { featureMapKeys, RoleContext } from 'permissions/PermissionsContext';
import React, { useContext, useState } from 'react';
import { CardBody, CardHeader, Container, FormText, Input } from 'reactstrap';

export const BackOffice = () => {
	const { currentUser } = useAuthContext();
	const permissions = useContext(RoleContext);
	const superRole = React.useMemo(
		() => permissions.roles.find((role) => role.role === 'SuperAdmin'),
		[permissions.roles]
	);
	const [isEditing, setIsEditing] = useState(false);
	const [editingRole, setEditingRole] = useState(superRole);

	const saveRole = async () => {
		if (editingRole) {
			await permissions.updateRole(editingRole, editingRole.role);
			setIsEditing(false);
			RomeSwal.fire({
				icon: 'success',
				title: 'Permissions updated successfully',
				allowEnterKey: true,
				allowEscapeKey: true,
				text: 'ROME Back Office permissions were updated successfully.',
				allowOutsideClick: true,
			});
		}
	};
	if (currentUser.role !== 'RomeDevelopers')
		return <Redirect to="/admin" noThrow />;

	return (
		<Container fluid>
			<ScrollCard>
				<CardHeader>
					<Heading>ROME Feature Management</Heading>
					<small
						onClick={() => setIsEditing(!isEditing)}
						style={{ cursor: 'pointer' }}
					>
						<FormText className={'d-block'}>
							{isEditing ? 'Cancel' : 'Edit Rome Features'}{' '}
							<FontAwesomeIcon icon={isEditing ? faTimesCircle : faPencilAlt} />
						</FormText>
					</small>
				</CardHeader>
				<CardBody>
					<ScrollTable>
						<thead>
							<tr>
								<th>Feature</th>
								<th>Can View</th>
								<th>Enabled</th>
							</tr>
						</thead>
						<tbody>
							{superRole &&
								superRole.featureMap &&
								Object.keys(superRole.featureMap).map((key) =>
									isEditing ? (
										<tr key={key}>
											<td>{startCase(key)}</td>
											<td>
												<FontAwesomeIcon
													className="check-icon"
													icon={faCheckCircle}
													style={{ color: 'green !important' }}
												/>
											</td>
											<td>
												<Input
													type={'checkbox'}
													defaultChecked={
														superRole.featureMap &&
														superRole.featureMap[key as featureMapKeys] ===
															'true'
													}
													onChange={(e) =>
														setEditingRole({
															...superRole!,
															featureMap: {
																...editingRole!.featureMap!,
																[key]: e.target.checked.toString(),
															},
														})
													}
												/>
											</td>
										</tr>
									) : (
										<tr key={key}>
											<td>{startCase(key)}</td>
											<td>
												<FontAwesomeIcon
													className="check-icon"
													icon={faCheckCircle}
													style={{ color: 'green !important' }}
												/>
											</td>
											<td>
												{superRole.featureMap &&
													superRole.featureMap[key as featureMapKeys] &&
													superRole.featureMap[key as featureMapKeys] ===
														'true' && (
														<FontAwesomeIcon
															className="check-icon"
															icon={faCheckCircle}
															style={{ color: 'green !important' }}
														/>
													)}
												{superRole.featureMap &&
													superRole.featureMap[key as featureMapKeys] &&
													superRole.featureMap[key as featureMapKeys] !==
														'true' && (
														<FontAwesomeIcon
															className="error-icon"
															icon={faTimesCircle}
															style={{ color: 'red !important' }}
														/>
													)}
											</td>
										</tr>
									)
								)}
						</tbody>
						{isEditing && (
							<tfoot>
								<tr>
									<td colSpan={3}>
										<SecondaryButton onClick={saveRole}>
											Update Back Office Permissions
										</SecondaryButton>
									</td>
								</tr>
							</tfoot>
						)}
					</ScrollTable>
				</CardBody>
			</ScrollCard>
		</Container>
	);
};
