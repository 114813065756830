import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import { last } from 'lodash';
import React from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';
// styled components
export const Logo = ({
	previewUrl,
	type,
	size = 'sm',
	defaults,
}: {
	previewUrl?: string;
	type?: 'loginCardLogo' | 'desktop' | 'mobile';
	size?: 'lg' | 'sm';
	defaults?: SiteDefaults;
}) => {
	const typeToUse = !!type ? type : 'desktop';
	if (!defaults) return null;
	const getUrl = (type: 'desktop' | 'loginCardLogo' | 'mobile') => {
		if (type === 'loginCardLogo' && defaults?.loginCardLogo)
			return `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
				defaults?.account
			}/${last(defaults?.loginCardLogo.split('/'))}`;
		if (previewUrl) return previewUrl;

		return `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
			defaults?.account
		}/${last(defaults?.defaultLogo.split('/'))}`;
	};

	const LogoElement = styled.figure`
			display: inline-block;
			background: url('${getUrl(typeToUse)}') center center / contain no-repeat;
			width: ${size === 'sm' ? '140px' : '240px'};
			height: ${size === 'sm' ? '70px' : '120px'};
			margin-bottom: 2em;
		`;

	return <LogoElement className={size === 'sm' ? '' : 'img-thumbnail'} />;
};
