import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { useCallback, useContext, useState } from 'react';
import Moment from 'react-moment';
import { Col, Container, Row } from 'reactstrap';
import { isIdentifiableLoaded } from '../common/mobx.utils';
import BackLink from '../components/back-link/back-link.component';
import CircleButton from '../components/circle-button.component';
import CollectionActionDropdown from '../components/collection-action-dropdown';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../components/entity-details.styled-components';
import ConfirmationDialog from '../components/modals/confirmation-dialog.component';
import { Divider, Heading } from '../components/ui';
import EntityMetadataFields from '../metadata/components/entity-metadata-fields.component';
import { NotificationsContext } from '../notifications';
import { useModalCreator } from '../stores/ModalStack';
import themeStore from '../theme/models/ThemeStore';
import WorkflowCard from '../workflows/components/workflow-card.component';
import EditCollectionWorkflowsDialog from './components/edit-workflow-collection-workflows-dialog.component';
import {
	EntityMetadata,
	Workflow,
	WorkflowCollection,
	WorkflowTemplate,
} from '../workflows/types/workflow.types';
import { getWorkflowBoolean } from '../workflows/helpers/workflowBoolean.helpers';
import { useAuthContext, User } from '../auth';
import { useAxios } from '../hooks';
import { useGroupContext } from '../auth';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import Loading from 'components/loading.component';
import { useWorkflowCollectionContext } from 'workflows/hooks/useWorkflowCollectionStore';
import {
	canEditWorkflowCollection,
	isActive,
	isCancelled,
	isCompleted,
	isOverdue,
	isPaused,
	isPipeline,
	isRoadblocked,
} from 'workflows/helpers';
import { PageSubheading, ToggleCollectionView } from 'components';
import { WorkflowListTable } from 'workflows/components';
import { startCase } from 'lodash';

// constants
const workflowTerm = themeStore._.workflow.toLowerCase();
const projectTerm = themeStore._.workflowCollection;

// interface
interface WorkflowCollectionDetailsProps extends RouteComponentProps {
	projectId: string;
}

// component
const WorkflowCollectionDetailsView = (
	props: WorkflowCollectionDetailsProps
) => {
	const { groupsForCurrentUser } = useGroupContext();
	const { projectId } = props;
	const collectionStore = useAxios<WorkflowCollection>('projects');
	const {
		entities: collections,
		updateContext,
	} = useWorkflowCollectionContext();
	const { entities: workflows } = useWorkflowContext();
	const { entities: users, currentUser } = useAuthContext();
	const modalStack = useModalCreator();
	const { info } = useContext(NotificationsContext);

	const [collection, setCollection] = useState<WorkflowCollection>();
	const [collectionFlows, setCollectionFlows] = useState<Workflow[]>();
	const [fetching, setFetching] = useState(false);
	const [createdBy, setCreatedBy] = useState<User>();

	const [currentView, setCurrentView] = useState<'cards' | 'list'>('cards');
	const [filter, setFilter] = useState<
		| 'overdue'
		| 'roadblocked'
		| 'active'
		| 'completed'
		| 'cancelled'
		| 'pipeline'
		| 'paused'
		| 'all'
	>('all');

	const filterMap = {
		overdue: isOverdue,
		roadblocked: isRoadblocked,
		active: isActive,
		completed: isCompleted,
		cancelled: isCancelled,
		paused: isPaused,
		pipeline: isPipeline,
		all: (wf: Workflow) => true,
	};

	React.useEffect(() => {
		if (collection && collection.createdBy && users && users.length) {
			setCreatedBy(
				users.find((m) => m._id === (collection?.createdBy as string))
			);
		}
	}, [users, collection]);

	React.useEffect(() => {
		if (fetching) return;
		if (!collection && !!collections?.length) {
			setFetching(true);
			setCollection(
				collections.find((collection) => collection._id === projectId)
			);
			setFetching(false);
		}
	}, [fetching, collections, collection, collectionStore, projectId]);

	React.useEffect(() => {
		if (collection && !collectionFlows && !!workflows?.length) {
			const flows: Workflow[] = [];
			collection.workflows.forEach((workflow: string | Workflow) => {
				flows.push(
					workflows.find(
						(flow) => flow._id === (workflow as string)
					) as Workflow
				);
			});
			setCollectionFlows(flows);
		}
	}, [collection, collectionFlows, workflows]);

	const showEditWorkflowModal = (collection?: WorkflowCollection) =>
		modalStack.addModal(
			<EditCollectionWorkflowsDialog
				onChange={(updatedCollection) => {
					updateContext(updatedCollection);
					const flows: Workflow[] = [];
					updatedCollection.workflows.forEach((workflow: string | Workflow) => {
						flows.push(
							workflows?.find(
								(flow) => flow._id === (workflow as string)
							) as Workflow
						);
					});
					setCollectionFlows(flows);
					setCollection(updatedCollection);
				}}
				selectedCollection={collection as WorkflowCollection}
			/>
		);

	const deleteCollection = useCallback(
		async (collection: WorkflowCollection) => {
			await collectionStore.deleteOne(collection._id);

			info(`${projectTerm} deleted!`);

			return navigate(`/admin/workflow/projects`);
		},
		[collectionStore, info]
	);

	const showDeleteWorkflowModal = useCallback(
		(collection: WorkflowCollection) =>
			modalStack.addModal(
				<ConfirmationDialog
					header={`Delete "${collection.title}"`}
					onConfirm={() => deleteCollection(collection)}
				>
					<p>
						Are you sure you want to delete this {projectTerm.toLowerCase()}?
						This action cannot be undone.
					</p>
				</ConfirmationDialog>
			),
		[modalStack, deleteCollection]
	);

	const renderActionDropdown = (collection?: WorkflowCollection) => {
		if (!collection) return null;
		if (!canEditWorkflowCollection(collection, currentUser)) {
			return null;
		}

		return (
			<div className="d-flex">
				<CollectionActionDropdown
					collection={collection as WorkflowCollection}
					collectionItems={collectionFlows as Workflow[]}
					collectionTerm={projectTerm}
					editLink={`/admin/workflow/projects/${collection?._id}/edit`}
					showDeleteModal={showDeleteWorkflowModal}
				/>
				<ToggleCollectionView
					cards
					toggle={(view) => setCurrentView(view)}
					currentView={currentView}
				/>
			</div>
		);
	};

	const collectedWorkflows = React.useMemo(() => {
		if (collectionFlows?.length) {
			return (collectionFlows as Workflow[])
				.filter(isIdentifiableLoaded)
				.filter(
					(wf: Workflow) =>
						getWorkflowBoolean(
							'isCurrentUserInvolved',
							wf as Workflow,
							groupsForCurrentUser || [],
							currentUser,
							wf.templateUsed as WorkflowTemplate
						) ||
						getWorkflowBoolean(
							'isCurrentUserFollower',
							wf as Workflow,
							groupsForCurrentUser || [],
							currentUser,
							wf.templateUsed as WorkflowTemplate
						) ||
						wf.createdBy._id === currentUser._id
				);
		}
		return [];
	}, [collectionFlows, currentUser, groupsForCurrentUser]);

	return (
		<Container>
			{fetching && (
				<Loading
					justifyContent="center"
					alignItems="center"
					label="Loading collection.."
				/>
			)}
			{fetching === false && (
				<Row>
					<Col
						xs={12}
						className="d-flex justify-content-between align-items-center"
					>
						<div>
							<BackLink link=".." title={`${projectTerm}s`} />
							<Heading>{collection?.title}</Heading>
						</div>

						{renderActionDropdown(collection)}
					</Col>
					<Divider />

					<Col xs={12}>
						<div className="d-flex flex-column">
							<PageSubheading text="FILTER PROJECT WORKFLOWS BY" />
							<Heading>Status</Heading>
						</div>
						<select
							onChange={(e) => setFilter(e.target.value as any)}
							className="form-control"
						>
							{[
								'all',
								'overdue',
								'paused',
								'cancelled',
								'active',
								'completed',
								'pipeline',
								'roadblocked',
							].map((key) => (
								<option key={key} value={key}>
									{startCase(key)}
								</option>
							))}
						</select>
						<Divider />
					</Col>
					<Col md={2}>
						<EntityPropList className="mt-4">
							<EntityPropListItem>
								<EntityPropLabel>Created on</EntityPropLabel>
								<p>
									<Moment format="MMM DD, YYYY" date={collection?.createdAt} />
								</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>Created by</EntityPropLabel>
								<p>
									{createdBy?.givenName} {createdBy?.familyName}
								</p>
							</EntityPropListItem>
							<EntityMetadataFields
								metadata={collection?.metadata as EntityMetadata}
							/>
						</EntityPropList>
					</Col>
					<Col md={10}>
						{currentView === 'list' && collectedWorkflows.length && (
							<WorkflowListTable
								workflows={collectedWorkflows.filter(filterMap[filter])}
							/>
						)}
						<Row>
							{currentView === 'cards' &&
								!!collectedWorkflows?.length &&
								collectedWorkflows
									.filter(filterMap[filter])
									.map((workflow: Workflow) => (
										<WorkflowCard key={workflow._id} workflow={workflow} />
									))}
						</Row>
						{filter !== 'all' &&
							!collectedWorkflows?.filter(filterMap[filter])?.length && (
								<p>No workflows found.</p>
							)}
					</Col>

					<Col xl={12}>
						<div className="mt-md-4 mt-5 d-flex justify-content-center align-items-center">
							<CircleButton
								className="sm"
								icon={faPlus}
								onClick={() => showEditWorkflowModal(collection)}
								tooltip={`Add/remove ${workflowTerm}s`}
							/>
						</div>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default WorkflowCollectionDetailsView;
