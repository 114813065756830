import React, { useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { useAuthContext } from '../../auth';
import { LabeledInput, SubmitButton } from '../../components/forms';
import RenderWhen from '../../components/render-when.component';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import EntityMetadataManagementForm from '../../metadata/components/entity-metadata-management-form.component';
import {
	EntityMetadata,
	EntityMetadataTemplate,
} from '../../workflows/types/workflow.types';

interface EntityMetadataTemplateEditFormProps {
	selectedMetadataTemplate: EntityMetadataTemplate;
	onSubmit: (e: EntityMetadataTemplate) => Promise<unknown>;
	displayTemplateField?: boolean;
}

const EntityMetadataTemplateEditForm = (
	props: EntityMetadataTemplateEditFormProps
) => {
	const { currentUser } = useAuthContext();
	const { selectedMetadataTemplate, onSubmit, displayTemplateField } = props;
	const [editedMetadata, setEditedMetadata] = useState(
		selectedMetadataTemplate
	);
	const isFormValid = () => {
		return editedMetadata.title !== '';
	};
	const onChangeCb = React.useCallback(
		(updated: EntityMetadata) => {
			const updatedValues: Record<string, string | string[]> = {};
			updated?.fields?.forEach((field) => (updatedValues[field] = ''));
			setEditedMetadata({
				...editedMetadata,
				tags: updated.tags,
				values: updatedValues,
				fieldTypes: updated.fieldTypes,
				fieldOptions: updated.fieldOptions,
				fields: updated.fields,
			} as EntityMetadataTemplate);
		},
		[editedMetadata]
	);

	return (
		<Form>
			<Row form>
				<Col md={12}>
					<LabeledInput
						label="Title*"
						type="text"
						name="title"
						id="title"
						value={editedMetadata.title}
						onChange={(e) =>
							setEditedMetadata({ ...editedMetadata, title: e.target.value })
						}
						required
						inputValid={editedMetadata.title !== ''}
						errorMsg="Title is required"
					/>
				</Col>
			</Row>
			<RenderWhen when={currentUser.isAdmin}>
				<EntityMetadataManagementForm
					onChange={onChangeCb}
					metadata={editedMetadata}
					displayTemplateField={displayTemplateField}
				/>
			</RenderWhen>
			<RenderWhen when={!currentUser.isAdmin}>
				<EntityMetadataForm
					onChange={(updated: EntityMetadataTemplate | undefined) => {
						if (updated) {
							setEditedMetadata({
								...editedMetadata,
								...(updated as EntityMetadata),
							});
						}
						return updated as EntityMetadata;
					}}
					update={(updated) => {
						setEditedMetadata({ ...editedMetadata, ...updated.payload });
					}}
					metadata={editedMetadata as EntityMetadata}
					displayTemplateField={displayTemplateField}
				/>
			</RenderWhen>
			<SubmitButton
				label="Save Metadata Template"
				disabled={!isFormValid()}
				onClick={() => onSubmit(editedMetadata)}
			/>
		</Form>
	);
};

export default EntityMetadataTemplateEditForm;
