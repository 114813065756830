import React from 'react';
import { useForm } from 'react-hook-form';
import { RegisterInput } from './RegisterInput';
import { RegisteredUser } from '../Register';
import { StyledChangeFormButton, StyledSubmitButton } from './RegisterStyles';
import { Col, Row } from 'reactstrap';
import {
	faAddressBook,
	faMapMarker,
	faUserCheck,
	faUserEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';

type Props = {
	onSubmit: (formData: RegisterFormData) => void;
	setLoggingIn: () => void;
	defaults: SiteDefaults;
};

export type RegisterFormData = RegisteredUser & {
	password: string;
	confirmPassword: string;
};

const Divider = styled.hr`
	border: 0.5px solid #afafaf2e;
	margin: 0 0 1em;
	width: 100%;
`;

export const RegisterForm = (props: Props) => {
	const { defaults } = props;
	const { handleSubmit, register, watch, formState } = useForm();

	const passwordMismatchError = () =>
		watch('password') !== watch('confirmPassword') &&
		formState.touched.password &&
		formState.touched.confirmPassword;

	const passwordMatches = (password: string) =>
		password === watch('confirmPassword');

	const passwordMeetsRequirements = () =>
		/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(watch('password'));

	return (
		<form onSubmit={handleSubmit(props.onSubmit)}>
			<Row>
				<Col>
					<h2>
						Rome Registration Details &nbsp;
						<FontAwesomeIcon icon={faUserCheck} />
					</h2>
					<Divider />
					<Row>
						<Col xl={12}>
							<RegisterInput
								name="email"
								placeholder="E-mail"
								type="email"
								onChange={register}
							/>
						</Col>
						<Col xl={6}>
							<RegisterInput
								name="password"
								type="password"
								onChange={register({
									validate: passwordMatches,
									minLength: 8,
									pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/,
								})}
							/>
						</Col>
						<Col xl={6}>
							<RegisterInput
								name="confirmPassword"
								type="password"
								placeholder="Confirm Password"
								onChange={register}
							/>
						</Col>
					</Row>
					{passwordMismatchError() && (
						<p style={{ color: 'red' }}>Passwords Must Match</p>
					)}
					{formState.touched.password && !passwordMeetsRequirements() && (
						<div style={{ color: 'red' }}>
							<p>Must Contain:</p>
							<ul>
								<li>At least 8 Characters</li>
								<li>Number</li>
								<li>Uppercase Letter</li>
								<li>Lowercase Letter</li>
							</ul>
						</div>
					)}

					<h2>
						Personal Information &nbsp; <FontAwesomeIcon icon={faUserEdit} />{' '}
						<Divider />
					</h2>
					<Row>
						<Col xl={6}>
							<RegisterInput
								name="givenName"
								placeholder="First Name"
								onChange={register}
							/>
						</Col>
						<Col xl={6}>
							<RegisterInput
								name="familyName"
								placeholder="Last Name"
								onChange={register}
							/>
						</Col>
						<Col xl={4}>
							<RegisterInput name="phone" type="number" onChange={register} />
						</Col>
						<Col xl={4}>
							<RegisterInput name="company" onChange={register} />
						</Col>
						<Col xl={4}>
							<RegisterInput name="title" onChange={register} />
						</Col>
						<Col xl={12}>
							<RegisterInput name="department" onChange={register} />
						</Col>
					</Row>
					<Row>
						<Col xl={12}>
							<h2>
								Your Company Address &nbsp;{' '}
								<FontAwesomeIcon icon={faMapMarker} />
							</h2>{' '}
							<Divider />
						</Col>

						<Col xl={4}>
							<RegisterInput
								name="addressLine1"
								placeholder="Address Line 1"
								onChange={register}
							/>
						</Col>
						<Col xl={4}>
							<RegisterInput
								name="addressLine2"
								placeholder="Address Line 2"
								onChange={register}
								optional
							/>
						</Col>
						<Col xl={4}>
							<RegisterInput name="city" onChange={register} />
						</Col>
					</Row>
					<Row>
						<Col xl={4}>
							<RegisterInput
								name="state"
								placeholder="State/Province"
								onChange={register}
							/>
						</Col>
						<Col xl={4}>
							<RegisterInput name="zip" onChange={register} />
						</Col>
						<Col xl={4}>
							<RegisterInput name="country" onChange={register} />
						</Col>
					</Row>
					<Row>
						<Col xl={12}>
							{' '}
							<h2>
								Your Rome Reference Contact Information &nbsp;{' '}
								<FontAwesomeIcon icon={faAddressBook} />
							</h2>{' '}
							<Divider />
						</Col>
						<Col xl={4}>
							<RegisterInput
								name="referenceName"
								placeholder="Referred By Name"
								onChange={register}
							/>
						</Col>
						<Col xl={4}>
							<RegisterInput
								name="referencePhone"
								type="number"
								placeholder="Referred By Phone"
								onChange={register}
							/>
						</Col>
						<Col xl={4}>
							<RegisterInput
								name="referenceEmail"
								placeholder="Referred By Email"
								onChange={register}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<div className="text-center">
					<StyledSubmitButton
						type="submit"
						style={{ backgroundColor: defaults?.secondary }}
					>
						Register
					</StyledSubmitButton>

					<StyledChangeFormButton onClick={props.setLoggingIn} type="button">
						Log In
					</StyledChangeFormButton>
				</div>
			</Row>
		</form>
	);
};
