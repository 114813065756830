import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import styled from 'styled-components';

import themeStore from '../theme/models/ThemeStore';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';
const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

// styled components
const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100vh;

	@keyframes run {
		0% {
			color: ${colors.lightGrey};
			left: -90px;
		}
		50% {
			color: ${colors.darkestGrey};
		}
		100% {
			color: ${colors.lightGrey};
			left: 90px;
		}
	}

	@-webkit-keyframes bounce {
		0%,
		80%,
		100% {
			-webkit-transform: scale(0);
		}
		40% {
			-webkit-transform: scale(1);
		}
	}

	@keyframes bounce {
		0%,
		80%,
		100% {
			-webkit-transform: scale(0);
			transform: scale(0);
		}
		40% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
`;

const LoadingDot = styled.div`
	position: relative;
	border-top-color: #3498db;
	border: 3px solid transparent;

	font-size: 25px;
	position: relative;
	width: 150px;
	height: 150px;
	border-top-color: ${defaults?.primaryHighlighted};
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	animation-name: spin;
	animation-duration: 1.5s;
	border-radius: 50%;
	:after {
		content: '';
		position: absolute;
		top: 15px;
		border-radius: 50%;
		left: 15px;
		right: 15px;
		bottom: 15px;
		border: 3px solid transparent;
		border-top-color: ${defaults?.primary};
	}

	:before {
		content: '';
		position: absolute;
		top: 5px;
		left: 5px;
		right: 5px;
		bottom: 5px;
		border: 3px solid transparent;
		border-top-color: ${defaults?.secondary};
		border-radius: 50%;
	}

	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
			-ms-transform: rotate(0deg); /* IE 9 */
			transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
		}
		100% {
			-webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
			-ms-transform: rotate(360deg); /* IE 9 */
			transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
		}
	}
	@keyframes spin {
		0% {
			-webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
			-ms-transform: rotate(0deg); /* IE 9 */
			transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
		}
		100% {
			-webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
			-ms-transform: rotate(360deg); /* IE 9 */
			transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
		}
	}
`;
// interfaces
interface Props {
	label?: string;
	alignItems?: string;
	justifyContent?: string;
}

// components
export const Loading = (props: Props) =>
	props.label ? (
		<LoadingWrapper
			style={{
				alignItems: props?.alignItems ?? 'center',
				justifyContent: props?.justifyContent ?? 'center',
			}}
		>
			<Label>{props.label}</Label>
			<LoadingDot />
		</LoadingWrapper>
	) : (
		<LoadingWrapper>
			<Label>Loading...</Label>
			<LoadingDot />
		</LoadingWrapper>
	);

export const LoadingRow = (props: Props) => {
	const label = props.label ? props.label : 'Loading...';

	return (
		<Row>
			<Col xs={12}>
				<p>{label}</p>
			</Col>
		</Row>
	);
};

export default Loading;
