import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import {
	FileShareInstance,
	useFileShare,
} from 'file-sharing/hooks/useFileShare';
import { NotificationsContext } from 'notifications';
import React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import styled from 'styled-components';
type Props = {
	fileShareInstance: FileShareInstance;
	onClosed: () => void;
};

const StyledDialog = styled(ConfirmationDialog)`
	*,
	> * {
		max-width: 800px !important;
	}
`;

export const FileSharingRecallDialog = (props: Props) => {
	const { fileShareInstance, onClosed } = props;
	const { recallSharedFiles } = useFileShare();
	const { info, error: showError } = React.useContext(NotificationsContext);

	const recallCallback = async () => {
		try {
			await recallSharedFiles(fileShareInstance._id);
			info('Successfully recalled the shared files.');
			onClosed();
		} catch (err) {
			showError(
				'Sorry, an issue occurred when recalling your shared files. Please try again later.'
			);
		}
	};

	return (
		<StyledDialog
			header="Recall Shared Files"
			onConfirm={recallCallback}
			isDisabled={fileShareInstance.attachments.length === 0}
			onCancel={onClosed}
		>
			{fileShareInstance.attachments.length === 0 && (
				<Row>
					<Col xl={11} className="m-auto">
						<Alert color={'danger'}>
							<FontAwesomeIcon icon={faExclamationCircle} />
							There are no files attached to the file share to recall.
						</Alert>
					</Col>
				</Row>
			)}
			{fileShareInstance.attachments.length > 0 && (
				<Row className="mx-2">
					<Col xl={12} className={'p-0  form-horizontal'}>
						<p>
							Recalling these files will make them no longer accessible by any
							of the shared recipients. This action can not be undone.
						</p>
					</Col>
				</Row>
			)}
		</StyledDialog>
	);
};
