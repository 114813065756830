import { RequiresFeatureFlag } from 'core/FeatureFlags';
import React, { FormEvent, useState } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { LabeledInput, SubmitButton } from '../../components/forms';
import themeStore from '../../theme/models/ThemeStore';
import { AssetCollection } from '../../workflows/types/workflow.types';
import CollectionAssetSelect from './collection-asset-select.component';

interface AssetCollectionFormProps {
	selectedCollection: AssetCollection;
	onSubmit: (event: FormEvent) => Promise<unknown>;
	onChange: (collection: AssetCollection) => void;
}

const AssetCollectionForm = (props: AssetCollectionFormProps) => {
	const { selectedCollection, onSubmit, onChange } = props;
	const [editedCollection, setEditedCollection] = useState(selectedCollection);
	const isFormValid = () => {
		return selectedCollection.title !== '';
	};

	return (
		<Form>
			<Row form>
				<Col md={12}>
					<LabeledInput
						label="Title*"
						type="text"
						name="title"
						id="title"
						value={selectedCollection.title}
						onChange={(e) => {
							setEditedCollection({
								...editedCollection,
								title: e.target.value,
							});
							onChange({ ...editedCollection, title: e.target.value });
						}}
						required
						inputValid={selectedCollection.title !== ''}
						errorMsg="Title is required"
					/>
				</Col>
				<Col>
					<RequiresFeatureFlag
						flag="addAssetCollectionButton"
						defaultValue={true}
					>
						<FormGroup>
							<CollectionAssetSelect
								onChange={(updated) => {
									setEditedCollection({
										...editedCollection,
										assets: updated.assets,
									});
									onChange({ ...editedCollection, assets: updated.assets });
								}}
								collection={selectedCollection}
							/>
						</FormGroup>
					</RequiresFeatureFlag>
				</Col>
			</Row>

			<SubmitButton
				onClick={onSubmit}
				label={`Save ${themeStore._.assetCollection.toLowerCase()}`}
				disabled={!isFormValid()}
			/>
		</Form>
	);
};

export default AssetCollectionForm;
