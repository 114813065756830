import React, { useEffect } from 'react';
import { Redirect } from '@reach/router';

import { appRoutes } from './app.routes';
import { AuthContext } from '../core/AuthProvider';
import { ViewWrapper } from '../components/ui';
import { useModalCreator } from '../stores/ModalStack';
import {
	SessionTimeout,
	SessionTimeoutService,
} from '../core/SessionTimeoutService';
import DialogPortal from 'components/modals/dialog-portal.component';
import moment from 'moment';
import { saveNode } from 'common';
import { CreateATicketFab } from 'components/CreateATicketFab';
import { RoleContext } from 'permissions/PermissionsContext';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DisabledMask } from 'workflows/components/workflow-stage-details/workflow-disabled-mask.component';
const ConfirmationDialog = React.lazy(() =>
	import('../components/modals/confirmation-dialog.component')
);
const AdminNavigation = React.lazy(() =>
	import('../components/admin-navigation/admin-navigation.component')
);

const Footer = React.lazy(() => import('../components/footer.component'));

const MainView = () => {
	const permissions = React.useContext(RoleContext);

	return (
		<ViewWrapper>
			{<DialogPortal />}
			<CreateATicketFab />
			<div className="navwrapper">
				<AdminNavigation editThemeView={false} />
			</div>

			{!!permissions.roles.length &&
				permissions.roles.length > 0 &&
				permissions.showDisabledOverlay() === false && (
					<div className="w-25 m-auto d-block py-3 h-100">
						<DisabledMask style={{ top: '100px' }} />
						<Alert style={{ zIndex: 9 }} color={'info'}>
							<div className="align-items-center d-flex justify-content-center">
								<FontAwesomeIcon icon={faInfoCircle} />{' '}
								<p>
									{' '}
									Contact your account representative to enable this feature.
								</p>
							</div>
						</Alert>
					</div>
				)}

			{appRoutes}
			<Footer />
			<SessionTimeout />
		</ViewWrapper>
	);
};

const Admin = () => {
	const auth = React.useContext(AuthContext);

	const storedAuthValue = JSON.parse(
		localStorage.getItem('rome_auth') as string
	);

	const isOverdue =
		!storedAuthValue?.expiresAt ||
		storedAuthValue?.expiresAt === -1 ||
		moment(storedAuthValue?.expiresAt).isBefore(moment(new Date()));

	const isSessionAboutToEnd = React.useMemo(
		() => moment(storedAuthValue?.expiresAt).isBefore(moment(new Date())),
		[storedAuthValue]
	);
	const sessionTimeoutSvc = React.useMemo(
		() => new SessionTimeoutService(auth),
		[auth]
	);
	const modalCreator = useModalCreator();

	useEffect(() => {
		if (sessionTimeoutSvc.isIdle || isSessionAboutToEnd) {
			modalCreator.addModal(
				<ConfirmationDialog
					header="Session is about to expire"
					onConfirm={() => sessionTimeoutSvc.hideModal()}
				>
					<p>Your session is about to expire. Continue?</p>
					<span>{sessionTimeoutSvc.logoutCountdown}</span>
				</ConfirmationDialog>
			);
		}
	}, [sessionTimeoutSvc, isSessionAboutToEnd, modalCreator]);

	if (!storedAuthValue || isOverdue) {
		saveNode('rome_auth', {
			accessToken: null,
			expiresAt: -1,
			idToken: null,
			authUser: null,
		});
		return <Redirect to="/auth" noThrow />;
	}

	const Skeleton = <MainView />;

	return <>{Skeleton}</>;
};

export default Admin;
