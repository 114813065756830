import React from 'react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardBody, Collapse, Popover } from 'reactstrap';

import { Subheading } from '../../components/ui';
import { useToggler } from '../../common/hooks';
import themeStore from '../../theme/models/ThemeStore';

import {
	PhaseIndicator,
	PhaseKeyCard,
	PhaseKeyCardHeader,
} from './template-phase-key.styled-components';
import {
	TemplatePhase,
	WorkflowTemplate,
} from '../../workflows/types/workflow.types';
import { useTemplateContext } from 'workflow-templates/helpers/useTemplateContext';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';

const PhaseKeyWrapper = styled.div`
	> * > .arrow {
		color: transparent;
	}
`;

const PhaseSketchPicker = ({
	color,
	onChange,
	displayColorPicker,
}: {
	color: string;
	onChange: (color: { hex: string; rgb: any }) => void;
	displayColorPicker: boolean;
}) => {
	return (
		<>
			{displayColorPicker && (
				<div
					style={{
						position: 'fixed',
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
					}}
				>
					&nbsp;
					<div style={{ position: 'absolute', zIndex: 2 }}>
						<SketchPicker color={color} onChange={onChange} />
					</div>
				</div>
			)}
		</>
	);
};

const phaseTerm = themeStore._.phase;

interface TemplatePhaseKeyProps {
	template: WorkflowTemplate;
}

const TemplatePhaseKey = (props: TemplatePhaseKeyProps) => {
	const [collapse, toggleCollapse] = useToggler(true);
	const { saveTemplate, template } = useTemplateContext();

	const onChange = async (updatedTemplate: WorkflowTemplate) => {
		await saveTemplate(updatedTemplate);
	};
	const [displayed, setDisplayed] = React.useState('');
	const containerRef = React.useRef<HTMLElement>();
	React.useEffect(() => {
		if (displayed) {
			const displayValue = displayed;

			const eventListener = (evt: any) => {
				console.log(displayValue);
				if (evt.target) {
					const secondaryRef = displayValue
						? document.querySelector(`#popover-phase-${displayValue}`)
						: undefined;
					console.log(secondaryRef?.contains(evt.target));
					if (
						!containerRef?.current?.contains(evt?.target) &&
						!secondaryRef?.contains(evt.target)
					)
						setDisplayed('');
				}
			};
			document.addEventListener('click', eventListener);

			return () => {
				document.removeEventListener('click', eventListener);
			};
		}
	}, [displayed]);
	const render = () => {
		const phases = template?.phases;

		if (!phases || !phases.length) {
			return null;
		}

		return (
			<PhaseKeyCard>
				<PhaseKeyCardHeader onClick={toggleCollapse}>
					<Subheading>{phaseTerm} key</Subheading>
					<FontAwesomeIcon
						icon={collapse ? faMinus : faPlus}
						className="text-muted small"
					/>
				</PhaseKeyCardHeader>
				<Collapse isOpen={collapse}>
					<CardBody>
						<PhaseKeyWrapper
							className="wrapper"
							ref={(ref: any) => (containerRef.current = ref as HTMLElement)}
						>
							{phases.map((phase: TemplatePhase) => {
								const tipId = `tooltip-phase-${phase._id}`;
								return (
									<React.Fragment key={tipId}>
										<Popover
											id={`popover-phase-${phase._id}`}
											isOpen={displayed === phase._id}
											placement="left"
											trigger={'click'}
											target={tipId}
										>
											<PhaseSketchPicker
												displayColorPicker={displayed === phase._id}
												color={phase.color}
												onChange={async (updated) => {
													const updatedPhases = [
														...phases.map((p) => {
															if (phase._id === p._id) {
																return { ...phase, color: updated.hex };
															} else {
																return p;
															}
														}),
													];

													const updatedTemplate = {
														...template,
														phases: updatedPhases,
													} as WorkflowTemplate;
													await onChange(updatedTemplate);
												}}
											/>
										</Popover>
										<div
											id={tipId}
											onClick={() => setDisplayed(phase._id)}
											key={phase._id}
											className="d-flex"
										>
											<PhaseIndicator background={phase.color} />
											<p style={{ marginTop: 5 }}>{phase.title}</p>
										</div>
									</React.Fragment>
								);
							})}
						</PhaseKeyWrapper>
					</CardBody>
				</Collapse>
			</PhaseKeyCard>
		);
	};

	return render();
};

export default TemplatePhaseKey;
