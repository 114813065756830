import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from '@reach/router';
import styled from 'styled-components';

import { ffRegular, fsSmol } from '../theme/ui/typography';
import { secondaryButton } from '../theme/components/secondary-button';

const Heading = styled.h1`
	${ffRegular};
	${fsSmol};
	letter-spacing: 2px;
	text-transform: uppercase;
`;

const Subheading = styled.p`
	${ffRegular};
	font-size: 20px;
	font-weight: 500;
	line-height: 1.2;
	margin-bottom: 0.5em;
	margin-top: 0;
	text-align: center;
`;

const GoHomeButton = styled(Link)`
	${secondaryButton};
`;

const NotFound = () => (
	<Container>
		<Row>
			<Col className="text-center mt-5">
				<Heading>404</Heading>
				<Subheading>We couldn't find the page you were looking for.</Subheading>
				<GoHomeButton className="mt-4" to="/">
					Take me home
				</GoHomeButton>
			</Col>
		</Row>
	</Container>
);

export default NotFound;
