import React from 'react';
import * as R from 'ramda';
import { Table } from 'reactstrap';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { Cell, CellLink, ScrollCard, StageStatusPill } from 'components';
import { flattenStages, getStageDueDate } from '../../helpers';
import { Stage, Workflow } from '../../types';
import { formatDuration } from '../../../common';
import { buildStageUrl } from '../../../common/links';
import { useAuthContext, User } from 'auth';
import { Avatar } from 'components/avatar.component';
interface Props {
	stages: Stage[];
}

export const MyAssignmentsTable = ({ stages }: Props) => {
	const { entities: workflows } = useWorkflowContext();
	if (R.isEmpty(stages))
		return (
			<table>
				<tbody>
					<tr>
						<Cell className="pt-4">No Results</Cell>
					</tr>
				</tbody>
			</table>
		);
	return (
		<ScrollCard>
			<Table>
				<thead>
					<tr>
						<th>Stage</th>
						<td></td>
						<th>Due Date</th>
						<th>Expected duration</th>
						<th>Stage Status</th>
						<th>Workflow</th>
					</tr>
				</thead>
				<tbody>
					{stages &&
						stages.map((stage) => (
							<AssignmentTableRow
								stageWorkflow={workflows?.find((workflow) =>
									flattenStages(workflow, true)?.some(
										(s) => s?._id === stage?._id
									)
								)}
								key={stage._id}
								stage={stage}
							/>
						))}
				</tbody>
			</Table>
		</ScrollCard>
	);
};

type AssignmentTableProps = {
	stage: Stage;
	stageWorkflow?: Workflow;
};
const AssignmentTableRow = ({ stage, stageWorkflow }: AssignmentTableProps) => {
	const { currentUser } = useAuthContext();
	const shouldShowBackground = (stage: Stage) =>
		currentUser?.proxyingFor &&
		currentUser?.proxyingFor?.picture &&
		stage?.owners?.some((owner) => owner._id === currentUser?.proxyingFor?._id);
	return (
		<tr key={stage._id}>
			{shouldShowBackground(stage) && (
				<td
					style={{
						verticalAlign: 'sub',
						background: shouldShowBackground(stage) ? '#ccc' : 'transparent',
					}}
					className="h-100 p-0 m-0"
				>
					<div className="mt-2 h-100 w-100 d-flex align-items-center justify-content-center">
						<small>
							<Avatar user={currentUser?.proxyingFor as User} />
						</small>
					</div>
				</td>
			)}
			<Cell colSpan={!shouldShowBackground(stage) ? 2 : 1} className="mb-0">
				<div className="d-flex align-items-center justify-content-start">
					<CellLink className="ml-2" to={buildStageUrl(stageWorkflow, stage)}>
						{stage.title}
					</CellLink>
				</div>
			</Cell>
			<Cell>{getStageDueDate(stage) || 'TBD'}</Cell>
			<Cell>{formatDuration(stage?.expectedDurationHrs || 0)}</Cell>
			<Cell>
				<StageStatusPill
					// @ts-ignore
					status={stage.status || '-'}
				/>
			</Cell>
			{stageWorkflow?.title && <Cell>{stageWorkflow?.title}</Cell>}
		</tr>
	);
};
