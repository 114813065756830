import React from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from '../components/buttons.styled-components';
import { Row, Col, FormGroup, Input, Container, Label } from 'reactstrap';
import { FilterCheckboxSelections } from './FilterCheckboxSelections';
import { Loading } from 'components';
import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';
type StepFourProps = {
	selectedPrimaryEntity: string;
	title?: string;
	exportOptions?: Array<{ type: string }>;

	onComplete: ({
		reportTitle,
		exportOptions,
	}: {
		reportTitle: string;
		exportOptions: Array<{ type: string }>;
	}) => void;
};

export const ReportBuilderStepFour = (props: StepFourProps) => {
	const { defaults } = useThemeContext();
	const [exportOptions, setExportOptions] = React.useState<
		Array<{ type: string }>
	>(props.exportOptions ? props.exportOptions : []);
	const [reportTitle, setReportTitle] = React.useState(props.title ?? '');
	const [isSaving, setIsSaving] = React.useState(false);

	return (
		<Container fluid>
			{isSaving && <Loading label="Saving report..." />}
			<Row>
				<Col xl={12}>
					<FormGroup>
						<Label>Add a title to your report</Label>
						<Input
							defaultValue={reportTitle}
							onChange={(e) => setReportTitle(e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label>Select export options</Label>
						<div style={{ position: 'relative', left: -20 }}>
							<FilterCheckboxSelections
								defaults={defaults}
								options={['CSV'].map((a) => ({ title: a }))}
								selected={exportOptions.map((a) => ({
									title: a.type.toUpperCase(),
								}))}
								onChange={(updatedSelections) =>
									setExportOptions(
										updatedSelections.map((a) => ({
											type: a.title.toLowerCase(),
										}))
									)
								}
							/>
						</div>
					</FormGroup>
				</Col>
				<Col xl={12}>
					{
						<PrimaryButton
							disabled={!reportTitle || isSaving}
							onClick={() => {
								setIsSaving(true);
								props.onComplete({ reportTitle, exportOptions });
							}}
						>
							<FontAwesomeIcon icon={faCheckCircle} /> Save Report{' '}
						</PrimaryButton>
					}
				</Col>
			</Row>
		</Container>
	);
};
