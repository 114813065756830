import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';
import { Card as CardBase, CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';

import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const DetailCard = styled(CardBase)`
	height: 100%;
	min-height: 500px;
	z-index: 0 !important;
	position: relative;

	@media (max-width: 992px) {
		margin-top: 3em;
	}
`;

export const DetailCardBody = styled(CardBody)`
	// margin-bottom: 4em;
`;

export const CardDetails = styled.div`
	// max-height: 450px;
	overflow: auto;
`;

export const MutedHeader = styled.button`
	background: none;
	border: none;
	color: ${colors.grey};
	font-size: 24px;
	line-height: 1;
	margin: 0;
	padding: 0;

	&:disabled {
		cursor: not-allowed;
	}

	&:hover {
		color: ${colors.success};
	}
`;

export const Timeline = styled.ol`
	list-style-type: none;
	margin-top: 1em;
	padding-inline-start: 0;
`;

export const TimelineItem = styled.li`
	position: relative;
	margin: 0;
	padding-bottom: 1em;

	&:last-child {
		padding-bottom: 0;
	}

	p {
		font-size: 13px;
	}
`;

export const Hr = styled.hr`
	margin-bottom: 0;
`;

export const InputSlotContainer = styled.div`
	display: flex;
	max-width: 490px;
	flex-wrap: wrap;
`;

export const InputSlot = styled.div`
	min-width: 105px;
	height: 60px;
	border-radius: 5px;
	text-align: center;
	margin: 1em 1em 0.25em 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 1em;

	p {
		margin-bottom: 0;
	}

	&.fulfilled {
		color: ${defaults?.primary};
		border: 1px dashed ${defaults?.primary};
	}

	&.missing {
		color: var(--darkGrey);
		border: 1px dashed var(--darkGrey);

		&:hover {
			color: ${defaults?.primary};
			border: 1px dashed ${defaults?.primary};
		}
	}
`;

export const FlexCardHeader = styled(CardHeader)`
	display: flex;
	justify-content: space-between;
`;

export const DisabledOwnerButton = styled.div`
	cursor: no-drop;
	display: flex;
`;
