import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import BackLink from '../components/back-link/back-link.component';
import { Heading } from '../components/ui';
import themeStore from '../theme/models/ThemeStore';
import NewCollectionWithAssetForm from './components/add-asset-to-new-collection-form.component';
interface AssetCollectionCreationWithAssetProps {
	assetIds: string;
}
const AssetCollectionCreationWithAssetView = (
	props: AssetCollectionCreationWithAssetProps
) => (
	<Container>
		<Row className="justify-content-center">
			<Col lg={8}>
				<BackLink link=".." title={`${themeStore._.assetCollection}s`} />
				<div className="d-flex justify-content-between">
					<Heading>
						Create a {themeStore._.assetCollection.toLowerCase()}
					</Heading>
				</div>
			</Col>
			<Col lg={8}>
				<NewCollectionWithAssetForm assetIds={props.assetIds} />
			</Col>
		</Row>
	</Container>
);

export default AssetCollectionCreationWithAssetView;
