import React, { FormEvent, useCallback, useState } from 'react';
import { Col, Form, Row, ModalBody } from 'reactstrap';
import { LabeledSelect } from '../../components/forms';
import DialogModal from '../../components/modals/dialog-modal.component';
import { NotificationsContext } from '../../notifications';

import { useModalCloser } from '../../stores/ModalStack';
import { StyledButton } from '../../components/forms/submit-button.styled-components';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
	AssetVersion,
	AssetCollection,
} from '../../workflows/types/workflow.types';
import { _logError } from 'common/log';
import { useAxios } from 'hooks';
import { useFetching } from 'hooks/useFetching';
import { useAuthContext, User } from 'auth';
import Loading from 'components/loading.component';
interface AddAssetToCollectionProps {
	assets?: AssetVersion[];
	toggleSelectingState?: (isSelecting: boolean) => void;
}

const AddAssetToCollectionDialog = (props: AddAssetToCollectionProps) => {
	const { info, error: showError } = React.useContext(NotificationsContext);
	const { assets, toggleSelectingState } = props,
		id = (assets as AssetVersion[]).map((m) => m._id).join(',');
	const { currentUser } = useAuthContext();
	const collectionStore = useAxios<AssetCollection[]>(
		'collections',
		'collections/all'
	);
	const { updateOne } = useAxios<AssetCollection>('collections');
	const [usersCollections, setUsersCollections] = useState<AssetCollection[]>();
	const { isFetching, beginFetching, finishFetching } = useFetching();

	React.useEffect(() => {
		const filterUserCollections = (assetCollections?: AssetCollection[]) => {
			if (!assetCollections) {
				setUsersCollections([]);
				return;
			}
			setUsersCollections(
				assetCollections?.filter((coll) =>
					typeof coll.createdBy === 'string'
						? coll.createdBy === currentUser._id
						: (coll.createdBy as User)._id === currentUser._id
				)
			);
		};
		if (isFetching) return;
		if (!usersCollections) {
			beginFetching();
			collectionStore
				.getCustom()
				.then(filterUserCollections)
				.finally(finishFetching);
		}
	}, [
		currentUser._id,
		isFetching,
		collectionStore,
		beginFetching,
		finishFetching,
		usersCollections,
	]);

	const modalCloser = useModalCloser();
	const setSelectedCallback = useCallback((selected: AssetCollection) => {
		setSelectedCollection(selected);
	}, []);
	const [selectedCollection, setSelectedCollection] = useState<
		AssetCollection
	>();
	const routeToAddNewCollection = useCallback(() => {
		modalCloser.closeModal();
		return navigate(`/admin/dam/collections/new/${id}`);
	}, [id, modalCloser]);

	const addAssetToCollection = React.useCallback(async () => {
		if (!selectedCollection) return;
		if (
			!selectedCollection.assets.some((m) =>
				id.split(',').find((id) => id === m)
			)
		) {
			id.split(',').forEach((id) => {
				selectedCollection.assets.push(id);
			});
		}
		try {
			await updateOne(selectedCollection?._id, selectedCollection);
			info(
				`Successfully added ${id.split(',').length} asset(s) to collection.`
			);
			if (toggleSelectingState) toggleSelectingState(false);
			modalCloser.closeModal();
		} catch (e) {
			_logError(e);
			showError('Failed to add asset to collection. Please try again later.');
		}
	}, [
		selectedCollection,
		id,
		info,
		showError,
		modalCloser,
		updateOne,
		toggleSelectingState,
	]);

	const isValid = () => {
		return !!(selectedCollection && selectedCollection._id);
	};

	return isFetching ? (
		<Loading
			label="Loading asset collections.."
			alignItems="center"
			justifyContent="center"
		/>
	) : (
		<DialogModal header="Add asset to Collection">
			<ModalBody>
				<Form>
					<Row form>
						<Col md={12}>
							<LabeledSelect
								onChange={(e) => {
									e.preventDefault();
									const selected = usersCollections?.find(
										(m) => m._id === e.target.value
									);
									setSelectedCallback(selected as AssetCollection);
								}}
								label="Title*"
								name="title"
								id="title"
								value={selectedCollection?._id}
								required
							>
								<option>Please select...</option>
								{usersCollections?.map((availableCollection) => (
									<option
										key={availableCollection._id}
										value={availableCollection._id}
									>
										{availableCollection.title}
									</option>
								))}
							</LabeledSelect>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col md={6} className="text-center">
							<StyledButton
								disabled={!isValid()}
								onClick={(e: FormEvent) => {
									e.preventDefault();
									addAssetToCollection();
								}}
								className="btn text-light"
							>
								Add to collection
							</StyledButton>
						</Col>
						<Col md={6} className="text-center">
							<StyledButton
								onClick={() => routeToAddNewCollection()}
								className="btn text-light"
							>
								<FontAwesomeIcon className="pr-1" icon={faPlus} />
								New collection
							</StyledButton>
						</Col>
					</Row>
				</Form>
			</ModalBody>
		</DialogModal>
	);
};

export default AddAssetToCollectionDialog;
