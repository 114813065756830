import React from 'react';
import themeStore from '../../../theme/models/ThemeStore';
import { useWorkflowContext } from '../../models/useWorkflowStore';
import { Stage, Workflow } from '../../types/workflow.types';
import { StatusEnum } from '../../types/workflowStatus.types';

import StageDialog from './stage-dialog.component';

const stageTerm = themeStore._.stage;

const ForceActivateStageDialog = (props: { stage: Stage }) => {
	const { updateStatus, workflow } = useWorkflowContext();

	return (
		<StageDialog
			header={`Force activate stage "${props.stage.title}"`}
			context="Please add some context about why this stage is being forcibly activated."
			onClick={async (message: string) =>
				await updateStatus(
					StatusEnum.active,
					workflow as Workflow,
					message,
					props.stage
				)
			}
			warning="Are you sure you want to force activate this stage?"
			successMessage={`${stageTerm} activated!`}
		/>
	);
};

export default ForceActivateStageDialog;
