import React, { FormEvent, useState } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { LabeledInput, SubmitButton } from '../../components/forms';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import themeStore from '../../theme/models/ThemeStore';
import {
	EntityMetadata,
	WorkflowCollection,
} from '../../workflows/types/workflow.types';
import CollectionWorkflowSelect from './collection-workflow-select.component';

interface WorkflowCollectionFormProps {
	selectedCollection: WorkflowCollection;
	onSubmit: (event: FormEvent) => Promise<unknown>;
	onChange: (wc: WorkflowCollection) => void;
}

const WorkflowCollectionForm = (props: WorkflowCollectionFormProps) => {
	const { selectedCollection, onSubmit } = props;
	const [editedCollection, setEditedCollection] = useState(selectedCollection);

	const isFormValid = selectedCollection.title !== '';

	return (
		<Form>
			<Row form>
				<Col md={12}>
					<LabeledInput
						label="Title*"
						type="text"
						name="title"
						id="title"
						value={selectedCollection.title}
						onChange={(e) => {
							setEditedCollection({
								...editedCollection,
								title: e.target.value,
							});
							props.onChange({
								...editedCollection,
								title: e.target.value,
							});
						}}
						required
						inputValid={selectedCollection.title !== ''}
						errorMsg="Title is required"
					/>
				</Col>
				<Col md={12}>
					<FormGroup>
						<CollectionWorkflowSelect
							onChange={(updated) => {
								setEditedCollection({
									...editedCollection,
									workflows: updated.workflows,
								});
								props.onChange({
									...editedCollection,
									workflows: updated.workflows,
								});
							}}
							collection={selectedCollection}
						/>
					</FormGroup>
				</Col>
			</Row>

			<EntityMetadataForm
				onChange={(updated) => {
					if (updated) {
						setEditedCollection({ ...editedCollection, metadata: updated });
						props.onChange({ ...editedCollection, metadata: updated });
					}
					return updated as EntityMetadata;
				}}
				update={(updated) => {
					if (updated.payload) {
						setEditedCollection({
							...editedCollection,
							metadata: updated.payload,
						});
						props.onChange({ ...editedCollection, metadata: updated.payload });
					}
				}}
				metadata={selectedCollection.metadata as EntityMetadata}
			/>

			<SubmitButton
				onClick={onSubmit}
				label={`Save ${themeStore._.workflowCollection.toLowerCase()}`}
				disabled={!isFormValid}
			/>
		</Form>
	);
};

export default WorkflowCollectionForm;
