import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback } from 'react';
import SubmitButton from '../../../components/forms/submit-button.component';
import { AssetVersion } from '../../../workflows/types/workflow.types';
import { useViewer } from '../../services/viewer.service';
import { ViewableMimetypes } from '../asset-card-thumbnail.component';

const DetailedViewButton = ({ asset }: { asset: AssetVersion }) => {
	const viewer = useViewer();

	const onClick = useCallback(
		() => viewer.viewSingle(asset),
		// eslint-disable-next-line
		[]
	);
	if (!asset) return null;
	return !!ViewableMimetypes.some((type) => asset.type === type) ? (
		<SubmitButton
			type="button"
			className="mt-0 mb-4"
			label="Launch detailed view"
			icon={faExternalLinkAlt}
			onClick={onClick}
		/>
	) : null;
};

export default DetailedViewButton;
