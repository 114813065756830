import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import {
	Alert,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	FormText,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from 'reactstrap';

import { AuthContext } from '../core/AuthProvider';
import { MutedButton } from '../components/buttons.styled-components';
import { StyledInput, SubmitButton } from '../components/forms';
import { useEventHandler } from '../common/hooks';
import googleBrand from '../assets/images/google.svg';
import office365Brand from '../assets/images/office-365.svg';
import RenderWhen from '../components/render-when.component';
import { Register } from './Register/Register';
import { StyledChangeFormButton } from './Register/components';
import { navigate } from '@reach/router';
import * as axios from 'axios';
import { useAuthContext, User } from 'auth';
import { NotificationsContext } from 'notifications';
import { StyledNavLink } from 'components/admin-navigation/admin-navigation.styled-components';
import { Logo } from './login.view.styled';
import { usePageHistory } from 'page-history/PageHistoryContext';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import { RomeSwal } from 'components/alert';

interface MaybeHasError {
	error?: Error;
}

interface LoginViewProps {
	location?: {
		state?: MaybeHasError;
	};
	defaults?: SiteDefaults;
}

const LoginError = ({ error }: MaybeHasError) => {
	if (!error) {
		return null;
	}
	return (
		<Row className="justify-content-center mt-5">
			<Col lg={5} md={7}>
				<Alert color="danger">
					Sorry, there was an error logging you in. Please try again later.
					{error.message}
				</Alert>
			</Col>
		</Row>
	);
};

const Login = React.memo((props: LoginViewProps) => {
	const { defaults } = props;
	const pageHistory = usePageHistory();
	const auth = React.useContext(AuthContext);
	const { error: showError } = React.useContext(NotificationsContext);
	const { getPasswordResetLink } = useAuthContext();
	const [valid, setValid] = useState(true);
	const resetPasswordCallback = async () => {
		const { value: email } = await RomeSwal.fire({
			title: 'Reset your password',
			input: 'email',
			confirmButtonColor: defaults?.secondary,
			html: 'Enter your e-mail address to reset your password',
			confirmButtonText: 'Reset password',
			icon: 'info',
			showCancelButton: true,
			inputPlaceholder: 'Enter your email address',
		});

		if (email) {
			await getPasswordResetLink(email).then((success) => {
				RomeSwal.fire({
					toast: true,
					showConfirmButton: false,
					title: success ? 'Successfully reset password' : `Error!`,
					html: success
						? `An email has been sent to your email address with a link to reset your
					password.`
						: `An issue occurred while trying to reset your password. Please try again.`,
					icon: success ? 'success' : 'warning',
					timer: 5000,
					position: 'top-right',
				});
			});
		}
	};

	const loadApprovals = async () => {
		const res = await axios.default.get<User[]>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/auth/loadApprovals`
		);
		return res.data;
	};
	const signInWithGoogle = useEventHandler(auth.signInWithGoogle);
	const signInWithOffice365 = useEventHandler(auth.signInWithOffice365);

	const shouldShowSingleSignOn = !process.env.REACT_APP_BLOCK_SSO;
	const [loginForm, setForm] = useState({ email: '', password: '' });
	const [isLoggingIn, setIsLoggingIn] = useState(true);
	// eslint-disable-next-line
	const [isResetting, setIsResetting] = useState(false);

	const updateLogin = (event: any) => {
		const { target } = event;
		const { name, value } = target;
		setForm({
			...loginForm,
			[name]: value,
		});
	};
	const signInWithEmailPassword = useCallback(() => {
		if (loginForm.email && loginForm.password) {
			loadApprovals().then((users) => {
				if (users?.some((u) => u.email === loginForm.email)) {
					navigate('/needs-approval');
				} else {
					auth.signInWithEmailPassword(loginForm);
				}
			});
		} else setValid(false);
		// eslint-disable-next-line
	}, [auth, loginForm]);

	useEffect(() => {
		const errorMessage = window.location.hash
			.replace('#', '')
			.replace('%20', ' ')
			.replace('%20', ' ')
			.replace('%20', ' ')
			.replace('%20', ' ');
		if (errorMessage) {
			showError(errorMessage);
			window.location.hash = '';
		}
		// eslint-disable-next-line
	}, [showError, window.location.hash]);

	useEffect(() => {
		pageHistory.clear();
		//eslint-disable-next-line
	}, []);

	return (
		<>
			<RenderWhen when={isLoggingIn && !isResetting}>
				<Container fluid>
					<LoginError error={props.location?.state?.error} />
					<Row className="justify-content-center mt-5">
						<Col lg={5} md={7} className="mt-5">
							<Card className="mt-4 ">
								<CardHeader className="py-5 text-center">
									{defaults && (
										<Logo size="lg" type="loginCardLogo" defaults={defaults} />
									)}

									{shouldShowSingleSignOn && (
										<div className="btn-wrapper text-center">
											<Row>
												<Col>
													<MutedButton
														type="button"
														className="btn-light"
														onClick={signInWithGoogle}
													>
														<span className="d-inline-block">
															<img
																alt="Google"
																src={googleBrand}
																className="w-50"
															/>
														</span>
														<span className="d-inline-block">
															<span className="mb-0">Sign in with Google</span>
														</span>
													</MutedButton>
												</Col>
												<Col>
													<MutedButton
														type="button"
														className="btn-light"
														onClick={signInWithOffice365}
													>
														<span className="d-inline-block">
															<img
																alt="Office 365"
																src={office365Brand}
																className="img-fluid"
																style={{
																	height: 20,
																}}
															/>
														</span>
														<span
															className="d-inline-block"
															style={{ verticalAlign: 'top' }}
														>
															<span className="mb-0">
																Sign in with Office 365
															</span>
														</span>
													</MutedButton>
												</Col>
											</Row>
										</div>
									)}
								</CardHeader>
								<CardBody className="px-lg-5 py-lg-5">
									<div className="text-center text-muted mb-4">
										<small>
											{shouldShowSingleSignOn && <>Or</>} Sign in with
											credentials
										</small>
									</div>
									<Form role="form" onSubmit={signInWithEmailPassword}>
										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<FontAwesomeIcon icon={faEnvelope} />
													</InputGroupText>
												</InputGroupAddon>
												<StyledInput
													name="email"
													placeholder="Email"
													type="email"
													autoComplete="on"
													onChange={updateLogin}
												/>
											</InputGroup>
										</FormGroup>
										<FormGroup>
											<InputGroup className="input-group-alternative">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<FontAwesomeIcon icon={faLock} />
													</InputGroupText>
												</InputGroupAddon>
												<StyledInput
													name="password"
													placeholder="Password"
													type="password"
													autoComplete="on"
													onChange={updateLogin}
												/>
											</InputGroup>
										</FormGroup>
										<FormGroup>
											<FormText
												color="danger"
												className={valid ? 'd-none' : 'd-block'}
											>
												Email and password are both required to login.
											</FormText>
										</FormGroup>
										<div className="d-flex align-items-center justify-content-between">
											<div className="mt-2 custom-control custom-control-alternative custom-checkbox">
												<input
													className="custom-control-input"
													id=" customCheckLogin"
													type="checkbox"
												/>
												<label
													className="custom-control-label"
													htmlFor=" customCheckLogin"
												>
													<p className="text-muted">Remember me</p>
												</label>
											</div>
											<StyledNavLink
												onClick={async () => await resetPasswordCallback()}
											>
												Reset password
											</StyledNavLink>
										</div>
										<div className="text-center">
											<SubmitButton
												style={{
													color: '#fff',
													background: defaults?.secondary,
												}}
												label="Sign in"
												onClick={signInWithEmailPassword}
											/>
											<StyledChangeFormButton
												onClick={() => setIsLoggingIn(false)}
												type="button"
											>
												Register
											</StyledChangeFormButton>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</RenderWhen>
			<RenderWhen when={!isLoggingIn && !isResetting}>
				<Register
					defaults={defaults || ({} as SiteDefaults)}
					setLoggingIn={() => setIsLoggingIn(true)}
				/>
			</RenderWhen>
		</>
	);
});

export default Login;
