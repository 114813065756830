import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { isIdentifiableLoaded } from '../../common';
import themeStore from '../../theme/models/ThemeStore';
import AssetCard from './asset-card.component';
import { _logError } from '../../common/log';
import { uniq } from 'lodash';
import { AssetVersion, Workflow } from '../../workflows/types/workflow.types';
import { useAssetHelper } from './helpers/useAssetHelper';

const RelatedAssetsColumn = ({
	asset,
	workflow,
	onDelete,
	setAsset,
}: {
	onDelete: () => void;
	asset?: AssetVersion;
	workflow?: Workflow;
	setAsset: (asset: AssetVersion) => void;
}) => {
	const onAssetClick = React.useCallback(
		(asset: AssetVersion) => {
			setAsset(asset);
		},
		[setAsset]
	);
	return isIdentifiableLoaded(asset) ? (
		<Col lg={4} sm={6} className="mb-4">
			<AssetCard
				disableDetail
				isLinkEnabled
				onDelete={onDelete}
				onClick={onAssetClick}
				type="uploaded"
				workflowId={workflow?._id}
				assetVersion={(asset as unknown) as AssetVersion}
			/>
		</Col>
	) : null;
};
const RelatedAssetsContent = ({
	setAsset,
	asset,
	workflow,
}: {
	setAsset: (asset: AssetVersion) => void;
	asset?: AssetVersion;
	workflow?: Workflow;
}) => {
	const { relatedAssets, findOne } = useAssetHelper();
	const [relatedIDs, setRelatedIDs] = useState<string[]>();
	const [assets, setAssets] = useState<AssetVersion[]>();

	// todo use an 'observable' way of getting these instead of useEffect
	const setAssetCallBack = (asset: AssetVersion) => {
		setAsset(asset);
		setRelatedIDs(undefined);
		setAssets(undefined);
	};

	useEffect(() => {
		if (!relatedIDs && asset && workflow) {
			relatedAssets(asset, workflow._id).then(setRelatedIDs).catch(_logError);
		}
		//eslint-disable-next-line
	}, [asset, workflow, relatedIDs]);

	useEffect(() => {
		if (relatedIDs?.length && !assets) {
			relatedIDs
				.filter((a) => a !== asset?._id)
				// eslint-disable-next-line
				.map((assetId) => {
					findOne(assetId).then((found) => {
						if (found) {
							setAssets((assets) => [
								...(assets || []).filter((asset) => asset._id !== assetId),
								found,
							]);
						}
					});
					return null;
				});
		}
		//eslint-disable-next-line
	}, [relatedIDs]);

	if (relatedIDs?.length) {
		return (
			<>
				<p>Related {themeStore._.asset.toLowerCase()}s</p>
				<hr />
				<Row>
					{assets &&
						assets?.length > 0 &&
						uniq(assets).map((relatedAsset) => (
							<RelatedAssetsColumn
								setAsset={setAssetCallBack}
								onDelete={() =>
									setAssets((assets) =>
										assets?.filter((a) => a._id !== relatedAsset._id)
									)
								}
								key={relatedAsset._id}
								asset={relatedAsset}
							/>
						))}
				</Row>
			</>
		);
	}
	return null;
};

const RelatedAssetsRow = ({
	setAsset,
	asset,
	workflow,
}: {
	setAsset: (asset: AssetVersion) => void;
	asset?: AssetVersion;
	workflow?: Workflow;
}) => {
	const render = () => (
		<Container className="mt-5 px-0">
			{isIdentifiableLoaded(asset) ? (
				<RelatedAssetsContent
					setAsset={setAsset}
					workflow={workflow}
					asset={asset}
				/>
			) : (
				<p>Loading...</p>
			)}
		</Container>
	);

	return render();
};

export default RelatedAssetsRow;
