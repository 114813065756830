import React, { useCallback } from 'react';
import { faFastForward } from '@fortawesome/free-solid-svg-icons';

import { buildClassList } from '../../../common';
import { StageStatus } from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';

import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from '../action-button.styled-components';

import { StageActionButtonProps } from '.';
import BypassStageDialog from './bypass-stage-dialog.component';
import { getNextStage } from '../../helpers/workflowStage.helpers';
import { Stage } from '../../types/workflow.types';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { useAuthContext } from 'auth';

export const BypassStageButton = (props: StageActionButtonProps) => {
	const { className, stage } = props;
	const { workflow } = useWorkflowContext();
	const modalStack = useModalCreator();
	const { currentUser } = useAuthContext();
	const canBypassStage = (stage: Stage) =>
		currentUser.isAdmin ||
		stage.owners?.some((owner) => owner._id === currentUser._id);
	const openBypassModal = useCallback(() => {
		modalStack.addModal(<BypassStageDialog stage={stage} />);
	}, [stage, modalStack]);

	if (
		!canBypassStage(stage) ||
		getNextStage(stage, workflow?.stages || []) ||
		stage.status === StageStatus.completed
	) {
		return null;
	}

	const buttonId = `bypassStage${stage._id}`;
	return (
		<ActionButtonContainer
			className={buildClassList('stage-action bypass-stage', className)}
		>
			<StyledTooltip target={buttonId}>Bypass "{stage.title}"</StyledTooltip>
			<ActionButton
				id={buttonId}
				color=""
				size="sm"
				className="action-button bypass-stage-button danger"
				onClick={openBypassModal}
			>
				<IconWithRightMargin icon={faFastForward} />
				Bypass
			</ActionButton>
		</ActionButtonContainer>
	);
};
