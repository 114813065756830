import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import React from 'react';
import { Input } from 'reactstrap';
import { pure } from 'recompose';
import styled from 'styled-components';
import themeStore from 'theme/models/ThemeStore';
const { colors } = themeStore.selectedTheme;
export type FilterOption = {
	title: string;
};

export type SelectionProps = {
	options: Array<FilterOption>;
	selected: Array<FilterOption>;
	onChange: (updated: Array<FilterOption>) => void;
	defaults: SiteDefaults;
	multiple?: boolean;
};

export const FilterCheckboxSelections = pure((props: SelectionProps) => {
	const { options, onChange, selected, defaults } = props;
	const BoxTags = styled.ul`
		list-style: none;
		padding-left: 0;
		li {
			display: inline;
		}
		li label {
			display: inline-block;
			background-color: ${defaults?.primary};
			border: 2px solid ${defaults?.primary};
			color: #fff;
			border-radius: 25px;
			white-space: nowrap;
			margin: 3px 0px;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-webkit-tap-highlight-color: transparent;
			transition: all 0.2s;
		}

		li label {
			padding: 8px 12px;
			cursor: pointer;
		}

		li label::before {
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			content: '';
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			font-size: 12px;
			padding: 2px 6px 2px 2px;
			transition: transform 0.3s ease-in-out;
		}

		li input[type='checkbox']:checked + label::before {
			content: '';
			transform: rotate(-360deg);
			transition: transform 0.3s ease-in-out;
		}

		li input[type='checkbox']:checked + label {
			border: 2px solid ${colors?.primary};
			background-color: ${defaults?.secondary};
			color: #fff;
			transition: all 0.2s;
		}

		li input[type='checkbox'] {
			display: flex;
		}
		li input[type='checkbox'] {
			position: absolute;
			opacity: 0;
		}
		li input[type='checkbox']:focus + label {
			border: 2px solid ${defaults?.secondaryHighlighted};
		}
	`;
	const isSelected = (option: FilterOption) =>
		selected.some((a) => a.title === option.title);
	return (
		<div style={{ maxWidth: 640 }}>
			<BoxTags className="ks-cboxtags">
				{options.map((opt, idx) => (
					<li key={idx}>
						<span style={{ position: 'relative', left: 20 }}>
							<FontAwesomeIcon
								style={{ color: '#fff' }}
								icon={isSelected(opt) ? faCheck : faPlus}
							/>
						</span>
						<Input
							style={{ maxWidth: 40 }}
							className="form-control"
							onLoad={(e: any) => {
								e.target.classList.remove('form-control');
							}}
							onClick={(e: any) => {
								if (e.target.checked) {
									if (props.multiple) onChange([...selected, opt]);
									else onChange([opt]);
								} else {
									if (props.multiple)
										onChange([
											...selected.filter((a) => a.title !== opt.title),
										]);
									else onChange([]);
								}
							}}
							defaultChecked={selected.some((a) => a.title === opt.title)}
							type={'checkbox'}
							id={opt.title}
						/>
						<label htmlFor={opt.title}>{opt.title}</label>
					</li>
				))}
			</BoxTags>
		</div>
	);
});
