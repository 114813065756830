import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { _logError } from '../../../common/log';
import { RemoveButton } from '../../../components/buttons.styled-components';
import ConfirmationDialog from '../../../components/modals/confirmation-dialog.component';

import { NotificationsContext } from '../../../notifications';
import { useModalCreator } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';
import { Workflow, WorkflowCollection } from '../../types/workflow.types';
import { useWorkflowContext } from '../../models/useWorkflowStore';

interface Props {
	workflow: Workflow;
	setUpdated: (workflow: Workflow) => void;
}

const RemoveFromWorkflowCollectionButton = (props: Props) => {
	const { info, error: showError } = React.useContext(NotificationsContext);
	const { workflow } = props;
	const { removeFromCollection } = useWorkflowContext();
	const modalStack = useModalCreator();

	const workflowTerm = themeStore._.workflow.toLowerCase();
	const collectionTerm = themeStore._.workflowCollection.toLowerCase();

	let workflowCollection: Maybe<WorkflowCollection>;
	if (workflow.workflowCollection) {
		workflowCollection = workflow.workflowCollection as WorkflowCollection;
	}

	const onSubmit = async () => {
		try {
			const updated = await removeFromCollection(workflow?._id as string);
			props.setUpdated(updated as Workflow);
			info(`Removed from ${collectionTerm}!`);
		} catch (error) {
			_logError(error);
			showError(
				`An error occurred while removing this ${workflowTerm} from "${workflowCollection?.title}". Please try again later.`
			);
		}
	};

	const showModal = () =>
		modalStack.addModal(
			<ConfirmationDialog
				header={`Remove from "${workflowCollection?.title}"`}
				onConfirm={onSubmit}
			>
				<p>
					Are you sure you want to remove this {workflowTerm} from the "
					{workflowCollection?.title}" {collectionTerm}?
				</p>
			</ConfirmationDialog>
		);

	return (
		<RemoveButton id={`workflowDelete${workflow._id}`} onClick={showModal}>
			<UncontrolledTooltip target={`workflowDelete${workflow._id}`}>
				Remove from {collectionTerm}
			</UncontrolledTooltip>
			<FontAwesomeIcon icon={faTimes} />
		</RemoveButton>
	);
};

export default RemoveFromWorkflowCollectionButton;
