import React, { Profiler, Suspense } from 'react';
import { Redirect, Router } from '@reach/router';
import { ThemeProvider } from 'styled-components';

import { authProvider, AuthContext, NewAuthStore } from './core';
import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';
import GlobalStyle from './theme/GlobalStyle';
import LoginCallback from './views/login-callback';
import { themeStore } from './theme/models/ThemeStore';

import './styles/App.scss';
import { UploadContextProvider } from './components/admin-navigation/file-upload-provider.component';
import { RoleContextProvider } from './permissions/PermissionsContext';
import { NotificationsContextProvider } from './notifications/Notification.context';
import { UserContextProvider } from './auth/AuthContext';
import { GroupContextProvider } from './auth/GroupContext';
import { WorkflowStoreContextProvider } from 'workflows/models/useWorkflowStore';
import { MetadataContextProvider } from 'workflows/hooks/useMetadataContext';
import { Loading } from 'components';
import { QueryStoreContextProvider } from 'queries/hooks/useQueryStore';
import { AwaitingApproval } from './accounts/AwaitingApproval';
import { AccountsContextProvider } from 'admin-dashboard/components/accounts/useAccounts';
import { ThemeContextProvider } from 'admin-dashboard/components/theme/useThemeContext';
import { FileShareContextProvider } from 'file-sharing/hooks/useFileShare';
import FileSharingPoint from 'file-sharing/components/file-sharing-point';
import { PageHistoryProvider } from 'page-history/PageHistoryContext';
import { SidebarContextProvider } from 'workflow-templates/helpers/HideSidebarContext';
import { Layout } from 'Layout';
import { SiteSettingsContextProvider } from 'hooks/useSiteSettings';
import { ActiveUsersContextProvider } from 'auth/ActiveUserContext';

export class AppProfiler extends React.Component {
	onRenderCallback = (
		id, // the "id" prop of the Profiler tree that has just committed
		phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
		actualDuration, // time spent rendering the committed update
		baseDuration, // estimated time to render the entire subtree without memoization
		startTime, // when React began rendering this update
		commitTime, // when React committed this update
		interactions // the Set of interactions belonging to this update
	) => {
		console.table(
			'id',
			'phase',
			'actualDuration',
			'baseDuration',
			'startTime',
			'commitTime',
			'interactions'
		);
		console.table(
			id,
			phase,
			actualDuration,
			baseDuration,
			startTime,
			commitTime,
			interactions
		);
	};

	render() {
		return (
			<Profiler id="test" onRender={this.onRenderCallback}>
				{this.props.children}
			</Profiler>
		);
	}
}
const App = () => {
	const authContext = NewAuthStore();
	const { handleAuthentication } = authContext;

	const authenticate = React.useCallback(
		async () => handleAuthentication(),
		// eslint-disable-next-line
		[]
	);
	return (
		<Suspense
			fallback={
				<Loading
					alignItems="center"
					justifyContent="center"
					label="Loading.."
				/>
			}
		>
			<Layout>
				<ThemeProvider theme={themeStore.selectedTheme}>
					<GlobalStyle />
					<AuthContext.Provider value={authProvider}>
						<ActiveUsersContextProvider>
							<SiteSettingsContextProvider>
								<SidebarContextProvider>
									<ThemeContextProvider>
										<QueryStoreContextProvider>
											<UserContextProvider>
												<RoleContextProvider>
													<GroupContextProvider>
														<UploadContextProvider>
															<NotificationsContextProvider>
																<WorkflowStoreContextProvider>
																	<MetadataContextProvider>
																		<AccountsContextProvider>
																			<FileShareContextProvider>
																				<PageHistoryProvider>
																					<></>
																					<Router>
																						<FileSharingPoint path="/filesharing/:fileShareId/:recipient_id" />
																						<Redirect
																							noThrow
																							from="/"
																							to="/auth"
																						/>
																						<AwaitingApproval path="/needs-approval" />
																						<AdminLayout path="admin/*" />
																						<React.Fragment>
																							<AuthLayout default path="/" />
																							<AuthLayout path="/auth/*" />
																						</React.Fragment>
																						<LoginCallback
																							handleAuthentication={
																								authenticate
																							}
																							path="/callback"
																						/>
																					</Router>
																				</PageHistoryProvider>
																			</FileShareContextProvider>
																		</AccountsContextProvider>
																	</MetadataContextProvider>
																</WorkflowStoreContextProvider>
															</NotificationsContextProvider>
														</UploadContextProvider>
													</GroupContextProvider>
												</RoleContextProvider>
											</UserContextProvider>
										</QueryStoreContextProvider>
									</ThemeContextProvider>
								</SidebarContextProvider>
							</SiteSettingsContextProvider>
						</ActiveUsersContextProvider>
					</AuthContext.Provider>
				</ThemeProvider>
			</Layout>
		</Suspense>
	);
};
export default App;
