import { useNavigate } from '@reach/router';
import React, { useCallback } from 'react';
import { DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { useTemplateContext } from 'workflow-templates/helpers/useTemplateContext';
import { WorkflowTemplate } from 'workflows/types/workflow.types';
import { useAuthContext } from '../../auth';
import { _logError } from '../../common/log';
import ActionsDropdownToggle from '../../components/action-dropdown-toggle.component';
import { DropDownMenuItem } from '../../components/drop-down-menu.component';
import ConfirmationDialog from '../../components/modals/confirmation-dialog.component';
import WarningModal from '../../components/modals/warning-modal.component';
import RenderWhen from '../../components/render-when.component';
import { NotificationsContext } from '../../notifications';
import { useModalCreator } from '../../stores/ModalStack';
import themeStore from '../../theme/models/ThemeStore';
import { WarningListItem } from './template-stage-edit-form.styled-components';

interface TemplateOwnersListProps {
	template: any;
}

const canFinalizeTemplate = (template: any) => {
	// TODO: add more conditions as needed
	return !(
		template.isUntitled ||
		template.hasUntitledStages ||
		template.hasUnlabeledInputSlots
	);
};

const TemplateActions = (props: TemplateOwnersListProps) => {
	const { info, error } = React.useContext(NotificationsContext);
	const modalStack = useModalCreator();
	const navigate = useNavigate();
	const { currentUser } = useAuthContext();
	const templateStore = useTemplateContext();
	const cloneTemplate = useCallback(async () => {
		const cloned = (await templateStore.cloneTemplate(
			props.template
		)) as WorkflowTemplate;
		info('Successfully cloned template.');
		//TODO: Replace this with a navigate route, currently the component isnt listening to the :templateId when it changes
		return window.location.assign(`/admin/workflow/templates/${cloned._id}`);
	}, [templateStore, props.template, info]);

	const saveAsDraftCallback = useCallback(async () => {
		const finalizeTemplate = () =>
			templateStore.updateTemplateStatus(props.template, 'draft');
		try {
			await finalizeTemplate();
			info('Template saved as Draft');
		} catch (e) {
			_logError(e);
			error(`An issue occurred while saving as Draft. Please try again later.`);
		}
	}, [props.template, error, templateStore, info]);

	const goToEditWorkflowProcess = useCallback(() => {
		return navigate(`/admin/workflow/templates/${props.template._id}/true`);
	}, [props.template._id, navigate]);

	const showFinalizeModal = useCallback(async () => {
		if (canFinalizeTemplate(props.template)) {
			const finalizeTemplate = () =>
				templateStore.updateTemplateStatus(props.template, 'final');

			modalStack.addModal(
				<ConfirmationDialog
					header="Finalize Template"
					onConfirm={async () => {
						await finalizeTemplate();
						info('Successfully finalized template.');
					}}
				>
					<p>
						Are you certain you want to finalize this template? You won't be
						able to edit it in the future.
					</p>
				</ConfirmationDialog>
			);
		} else {
			modalStack.addModal(
				<WarningModal header="Warning" buttonLabel="OK">
					<p>It is not currently possible to finalize this template.</p>
					<p>Please address the issues below and try again.</p>
					<ul>
						{props.template.isUntitled ? (
							<WarningListItem>
								Template has not been given a name.
							</WarningListItem>
						) : null}
						{props.template.hasUntitledStages ? (
							<WarningListItem>
								Not all {themeStore._.stage.toLowerCase()}s have been given a
								title.
							</WarningListItem>
						) : null}
						{props.template.hasUnlabeledInputSlots ? (
							<WarningListItem>
								Not all input labels have been labeled.
							</WarningListItem>
						) : null}
					</ul>
				</WarningModal>
			);
		}
	}, [props.template, modalStack, info, templateStore]);

	const renderComponent = () => (
		<UncontrolledDropdown size="sm" className="mt-2">
			<ActionsDropdownToggle caret color="light">
				Actions
			</ActionsDropdownToggle>
			<DropdownMenu right>
				<RenderWhen when={props.template.isFinalized}>
					<DropDownMenuItem onClick={goToEditWorkflowProcess}>
						Edit workflow process
					</DropDownMenuItem>
				</RenderWhen>
				<DropDownMenuItem className="secondary" onClick={showFinalizeModal}>
					Finalize
				</DropDownMenuItem>
				<DropDownMenuItem onClick={saveAsDraftCallback}>
					Save As Draft
				</DropDownMenuItem>
				<RenderWhen when={currentUser.isAdmin}>
					<DropDownMenuItem onClick={cloneTemplate}>Clone</DropDownMenuItem>
				</RenderWhen>
			</DropdownMenu>
		</UncontrolledDropdown>
	);

	return renderComponent();
};

export default TemplateActions;
