import { Link } from '@reach/router';
import styled from 'styled-components';

import { fsNormal, fsMedium } from '../../../theme/ui/typography';
import anchor from '../../../theme/components/anchor';

export const TimelineList = styled.ol`
	list-style-type: none;
	margin-top: 1em;
	padding-inline-start: 0;
`;

export const TimelineItem = styled.li`
	position: relative;
	margin: 0;
	padding-bottom: 1em;

	&:last-child {
		padding-bottom: 0;
	}
`;

export const StageTitle = styled.h1`
	${fsNormal}
`;

export const EventDetails = styled.div`
	display: -webkit-inline-box;
`;

export const MessageContainer = styled.p`
	font-size: ${fsMedium};
	margin-left: 5px;
`;

export const AssetVersionLink = styled(Link)`
	${anchor};
`;
