import { Form, ModalBody } from 'reactstrap';
import * as R from 'ramda';
import React, { useContext, useState } from 'react';

import { _logError } from '../../../common/log';
import {
	LabeledSelect,
	StyledInput,
	StyledLabel,
	SubmitButton,
} from '../../../components/forms';
import { useModalCloser } from '../../../stores/ModalStack';
import { unwrapEvent, useStateFromEvent } from '../../../common';
import DialogModal from '../../../components/modals/dialog-modal.component';

import { Stage, Workflow } from '../../types/workflow.types';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { NotificationsContext } from 'notifications';
import { flattenStages } from 'workflows/helpers';
import { uniqBy } from 'lodash';

const RejectStageDialog = (props: { stage: Stage; workflow: Workflow }) => {
	const { stage, workflow } = props;
	const { info } = useContext(NotificationsContext);
	const modalStack = useModalCloser();
	const { rejectStage } = useWorkflowContext();
	const { error } = useContext(NotificationsContext);

	const [message, setMessage] = useStateFromEvent('');

	const transitions = uniqBy(
		stage.transitions.filter(R.propEq('type', 'backward')),
		(t) => t.targetStage
	);
	const hasMultipleTransitions =
		transitions && transitions.length && transitions.length > 1;

	const [transitionId, setTransitionId] = useState(
		hasMultipleTransitions ? '-1' : R.head(transitions)?._id
	);

	// methods
	const isFormValid = () => {
		const hasMessage = message.length > 0;
		if (hasMultipleTransitions) {
			return transitionId !== undefined && transitionId !== '' && hasMessage;
		} else {
			return hasMessage;
		}
	};

	const onSubmit = async () => {
		try {
			await rejectStage(transitions[0]._id, message, workflow, stage);
			info('Successfully rejected ' + stage.title);
			modalStack.closeModal();
		} catch (e) {
			_logError(e);
			error('Unable to apply the rejection, an issue occurred while saving.');
			modalStack.closeModal();
		}
	};

	return (
		<DialogModal header={`Rejecting stage "${stage.title}"`}>
			<ModalBody>
				<Form>
					{hasMultipleTransitions ? (
						// If there are multiple backward transitions, allow the user to select which stage to change to
						<fieldset className="mt-2">
							<legend className="sr-only">Rejection Message</legend>
							<LabeledSelect
								id="transitionSelect"
								label="Please select to which stage the rejection leads."
								name="targetTransition"
								required
								value={transitionId}
								onChange={unwrapEvent(setTransitionId)}
							>
								<option disabled value="-1">
									Select a target stage
								</option>
								{transitions.map((t) => (
									<option key={t._id} value={t._id}>
										{
											flattenStages(workflow)?.find(
												(s) => s._id === t.targetStage
											)?.title
										}
									</option>
								))}
							</LabeledSelect>
						</fieldset>
					) : (
						// if there is only one backward transition, show which transition it will lead to
						<p className="mt-2">
							Rejecting this stage will lead to "
							{
								flattenStages(workflow)?.find(
									(s) => s._id === transitions[0].targetStage
								)?.title
							}
							".
						</p>
					)}

					<fieldset>
						<legend className="sr-only">Rejection Message</legend>
						<StyledLabel className="mb-2" for="rejectionMessage">
							Please provide some context about the rejection.*
						</StyledLabel>
						<StyledInput
							id="rejectionMessage"
							type="textarea"
							name="message"
							value={message}
							placeholder="Write a message..."
							onChange={setMessage}
							required={true}
						/>
					</fieldset>

					<SubmitButton
						onClick={onSubmit}
						disabled={!isFormValid()}
						label="Submit"
					/>
				</Form>
			</ModalBody>
		</DialogModal>
	);
};

export default RejectStageDialog;
