import React from 'react';
import { ModalBody } from 'reactstrap';
import { _logError } from '../../../common/log';
import themeStore from '../../../theme/models/ThemeStore';
import { useWorkflowContext } from '../../models/useWorkflowStore';
import { useForm } from 'react-hook-form';
import { FormModal } from '../../../components/Modal';
import { Stage, Workflow, BaseWorkflowOwner } from '../../types/workflow.types';
import { NotificationsContext } from 'notifications';
import { BaseOwnerSelect } from 'workflow-templates/components/owner-select.component';

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

const AssignStageOwnerDialog = ({ isOpen, onClose }: Props) => {
	const form = useForm();
	const {
		stage: updatedStage,
		setStage: setUpdatedStage,
		workflow,
	} = useWorkflowContext();

	const [owners, setOwners] = React.useState(updatedStage?.owners);
	const { info, warn } = React.useContext(NotificationsContext);
	const isModalOpen = React.useMemo(() => {
		if (isOpen) {
			return true;
		} else {
			return false;
		}
	}, [isOpen]);

	const stageTerm = themeStore._.stage;
	const ownerTerm = themeStore._.owner;

	const { setOwner } = useWorkflowContext();
	const updateStageSubmit = async () => {
		try {
			const updated = (await setOwner(
				'setOwner',
				owners as BaseWorkflowOwner[],
				updatedStage as Stage,
				workflow as Workflow
			)) as Workflow;
			onClose();
			setUpdatedStage({
				...(updated?.stages?.find((m) => m._id === updatedStage?._id) as Stage),
				owners: owners as BaseWorkflowOwner[],
			});
			info(`${stageTerm} saved!`);
		} catch (error) {
			onClose();
			_logError(error);
			warn(
				`An issue occurred while updating ${stageTerm.toLowerCase()}. Please try again later.`
			);
		}
	};
	return (
		<FormModal
			isOpen={isModalOpen}
			onClose={onClose}
			cancelText={'Cancel'}
			title={`Edit ${ownerTerm}s`}
			submitText={'Update'}
			form={form}
			onSubmit={updateStageSubmit}
		>
			<ModalBody>
				<BaseOwnerSelect
					onChange={(ownable) => setOwners(ownable.payload)}
					owners={updatedStage?.owners as BaseWorkflowOwner[]}
				/>
			</ModalBody>
		</FormModal>
	);
};

export default AssignStageOwnerDialog;
