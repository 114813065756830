import React from 'react';
import { Table } from 'reactstrap';
import { Link } from '@reach/router';
import Moment from 'react-moment';
import styled from 'styled-components';

import {
	buildUserUrl,
	buildVersionUrl,
	buildWorkflowUrl,
} from '../../common/links';
import { Cell } from '../../components/tables.styled-components';
import themeStore from '../../theme/models/ThemeStore';

import LinkToAssetVersionStage from './link-to-asset-version-stage.component';
import { User } from '../../auth';
import { AssetVersion, Workflow } from 'workflows/types';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';

const { colors } = themeStore.selectedTheme;
const stageTerm = themeStore._.stage;
const workflowTerm = themeStore._.workflow;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
// styled components
const StyledLink = styled(Link)`
	color: ${defaults?.primary};
	text-decoration: none;

	&:hover {
		color: ${defaults?.primaryHighlighted};
		text-decoration: none;
	}
`;

const StyledTable = styled(Table)`
	background-color: var(--white);
	margin-top: 32px;
`;

const HeadingCell = styled.th`
	color: ${colors.darkerGrey};
`;

interface Props {
	workflowA: Workflow;
	versionA: AssetVersion;
	workflowB: Workflow;
	versionB: AssetVersion;
}

export const AssetComparisonTable = (props: Props) => {
	const { workflowA, versionA, workflowB, versionB } = props;

	const render = () => (
		<StyledTable>
			<thead>
				<tr>
					<HeadingCell>Property</HeadingCell>
					<HeadingCell>Source</HeadingCell>
					<HeadingCell>Other</HeadingCell>
				</tr>
			</thead>

			<tbody>
				<tr>
					<Cell>ID</Cell>
					<Cell>{versionA._id}</Cell>
					<Cell>{versionB._id}</Cell>
				</tr>

				<tr>
					<Cell>File name</Cell>
					<Cell>
						<StyledLink to={buildVersionUrl(versionA as any)}>
							{versionA.fileName}
						</StyledLink>
					</Cell>
					<Cell>
						<StyledLink to={buildVersionUrl(versionB as any)}>
							{versionB.fileName}
						</StyledLink>
					</Cell>
				</tr>

				<tr>
					<Cell>File size</Cell>
					<Cell>{versionA.size}</Cell>
					<Cell>{versionB.size}</Cell>
				</tr>

				<tr>
					<Cell>File type</Cell>
					<Cell>{versionA.type}</Cell>
					<Cell>{versionB.type}</Cell>
				</tr>

				<tr>
					<Cell>Created on</Cell>
					<Cell>
						<Moment format="MM/DD/YYYY, h:MM A" date={versionA.createdAt} />
					</Cell>
					<Cell>
						<Moment format="MM/DD/YYYY, h:MM A" date={versionB.createdAt} />
					</Cell>
				</tr>

				<tr>
					<Cell>Created by</Cell>
					<Cell>
						<StyledLink to={buildUserUrl((versionA.createdBy as any) as User)}>
							{versionA.createdBy?.name}
						</StyledLink>
					</Cell>
					<Cell>
						<StyledLink to={buildUserUrl((versionB.createdBy as any) as User)}>
							{versionB.createdBy?.name}
						</StyledLink>
					</Cell>
				</tr>

				<tr>
					<Cell>{workflowTerm}</Cell>
					<Cell>
						<StyledLink to={buildWorkflowUrl(workflowA as any)}>
							{workflowA.title}
						</StyledLink>
					</Cell>
					<Cell>
						<StyledLink to={buildWorkflowUrl(workflowB as any)}>
							{workflowB.title}
						</StyledLink>
					</Cell>
				</tr>

				<tr>
					<Cell>{stageTerm}</Cell>
					<Cell>
						<LinkToAssetVersionStage workflow={workflowA} version={versionA} />
					</Cell>
					<Cell>
						<LinkToAssetVersionStage workflow={workflowB} version={versionB} />
					</Cell>
				</tr>

				<tr>
					<Cell>Number of views</Cell>
					<Cell>{versionA?.views || 1}</Cell>
					<Cell>{versionB?.views || 1}</Cell>
				</tr>
			</tbody>
		</StyledTable>
	);

	return render();
};
