import React, { FormEvent } from 'react';
// import * as R from 'ramda';
import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';

import {
	LabeledInput,
	// Select,
	SubmitButton,
} from '../../components/forms';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import PhaseInput from '../../workflows/components/phase-input.component';
import styled from 'styled-components';
import {
	BaseWorkflowOwner,
	EntityMetadata,
} from '../../workflows/types/workflow.types';
import { WorkflowTemplate } from '../../workflows/types/workflow.types';
import { buildEmptyAssetMetadata } from 'dam-assets/components/helpers/useAssetHelper';
import { isEqual, map } from 'lodash';
import { BaseOwnerSelect } from './owner-select.component';
import { useMetadataContext } from 'workflows/hooks/useMetadataContext';
import { SecondaryButton } from 'components/buttons.styled-components';
import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import RenderWhen from 'components/render-when.component';
import { NotificationsContext } from 'notifications';
import { Subtitle } from 'components';
// import { Option } from 'components/forms/Select/Option/Option';
// import { useGroupContext } from 'auth';

const FollowerSection = styled(FormGroup)`
	border-bottom: 1px solid #ccc;
	padding-bottom: 2rem;
	margin-top: 2rem;
`;

const EditTemplateForm = (props: {
	selectedTemplate: WorkflowTemplate;
	buttonLabel: string;
	onSubmit: (event: FormEvent) => Promise<unknown>;
	onChange: (template: WorkflowTemplate) => void;
}) => {
	const { selectedTemplate, buttonLabel, onSubmit, onChange } = props;
	const { info } = React.useContext(NotificationsContext);
	const template = React.useMemo(
		() => ({
			...selectedTemplate,
			metadata: !selectedTemplate?.metadata
				? buildEmptyAssetMetadata
				: selectedTemplate?.metadata,
		}),
		[selectedTemplate]
	);
	const [edited, setEdited] = React.useState<WorkflowTemplate>(template);
	const [skuMetadata, setSkuMetadata] = React.useState(template?.skuMetadata);
	const [useSKUs, setUseSKUs] = React.useState<boolean>(
		template?.useSKUs ?? false
	);
	const { templates } = useMetadataContext();
	const [showEditSkuMeta, setShowEditSkuMeta] = React.useState(false);

	const renderMetadataTemplateSelect = () => {
		return (
			<FormGroup>
				<Label>SKU Metadata Template</Label>
				<select
					className="form-control"
					defaultValue={skuMetadata?.templateUsed ?? ''}
					onChange={(e: any) => {
						let selectedOpt: string = e.target.value;
						if (selectedOpt) {
							const selected = templates?.find(
								(temp: any) => temp._id === selectedOpt
							);
							setSkuMetadata({
								...(selected as EntityMetadata),
								templateUsed: selected?._id,
							});
							setEdited({
								...edited,
								skuMetadata: { ...selected, templateUsed: selected?._id },
							});
						} else {
							setSkuMetadata(undefined);
							setEdited({ ...edited, skuMetadata: undefined });
						}
					}}
				>
					<option>Please select..</option>
					{templates?.length ? (
						map(templates, (template: any) => (
							<option value={template._id} key={template._id}>
								{template.title}
							</option>
						))
					) : (
						<></>
					)}
				</select>
			</FormGroup>
		);
	};
	const render = () => {
		const isFormValid = () => {
			return edited?.title !== '';
		};

		return (
			<Form>
				<LabeledInput
					label="Title*"
					type="text"
					name="title"
					id="templateTitle"
					value={edited?.title}
					onChange={(e) => {
						setEdited({ ...edited, title: e.target.value });
						onChange({ ...edited, title: e.target.value });
					}}
					required
					inputValid={edited?.title !== ''}
					errorMsg="Title is required"
				/>
				<PhaseInput
					onChange={(updated) => {
						setEdited({ ...edited, phases: updated.phases });
						onChange({ ...edited, phases: updated.phases });
					}}
					className="mt-3"
					template={edited}
				/>

				<EntityMetadataForm
					onChange={(updated) => {
						if (updated && !isEqual(updated, edited.followers)) {
							setEdited({ ...edited, metadata: updated });
							onChange({ ...edited, metadata: updated });
						}
						return updated as EntityMetadata;
					}}
					update={(updated) => {
						setEdited({ ...edited, metadata: updated.payload });
						onChange({ ...edited, metadata: updated.payload });
					}}
					metadata={edited?.metadata as EntityMetadata}
				/>

				<FollowerSection className="mb-5 position-relative">
					<BaseOwnerSelect
						label="Followers"
						onChange={(updated) => {
							if (!isEqual(updated.payload, edited.followers)) {
								setEdited({ ...edited, followers: updated.payload });
								onChange({ ...edited, followers: updated.payload });
							}
						}}
						owners={edited.followers as BaseWorkflowOwner[]}
					/>
				</FollowerSection>

				<FormGroup className="border-light my-3">
					<Label>Enable SKU's</Label>

					<select
						className="form-control"
						onChange={(e) => {
							setUseSKUs(e.target.value === 'true');
							setEdited({ ...edited, useSKUs: e.target.value === 'true' });
							onChange({ ...edited, useSKUs: e.target.value === 'true' });
						}}
						defaultValue={useSKUs.toString()}
					>
						<option>Please select...</option>
						<option value={'false'}>No</option>
						<option value={'true'}>Yes</option>
					</select>
					<RenderWhen when={useSKUs}>
						<SecondaryButton
							type={'button'}
							onClick={() => setShowEditSkuMeta(true)}
						>
							Update SKU Metadata
						</SecondaryButton>

						{showEditSkuMeta && (
							<ConfirmationDialog
								header="Update SKU Metadata Template"
								onConfirm={() => {
									setShowEditSkuMeta(false);
									onChange(edited);
									info('Updated Template Successfully');
								}}
								onCancel={() => setShowEditSkuMeta(false)}
								confirmText={'Update'}
							>
								<Container>
									<Row>
										<Col xl={12}>{renderMetadataTemplateSelect()}</Col>
									</Row>
								</Container>
							</ConfirmationDialog>
						)}
					</RenderWhen>
				</FormGroup>

				<hr />
				{!!buttonLabel && (
					<SubmitButton
						label={buttonLabel}
						onClick={onSubmit}
						disabled={!isFormValid()}
					/>
				)}
			</Form>
		);
	};

	return render();
};

export default EditTemplateForm;
