import { Link } from '@reach/router';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { SubmitButton } from '../../../components/forms';
import WorkflowStatusPill from '../../../components/workflow-status-pill.component';
import { underlinedAnchor } from '../../../theme/components/anchor';
import inputField from '../../../theme/components/input-field';
import { secondaryButton } from '../../../theme/components/secondary-button';

import { fsLarge, fsMedium } from '../../../theme/ui/typography';

export const StyledPropLink = styled(Link)`
	${underlinedAnchor};
	${fsLarge};
`;

export const CustomDatePicker = styled(DatePicker)`
	${inputField};
	padding: 0.375rem 0.75rem;
`;

export const UpdateWorkflowButton = styled(SubmitButton)`
	${secondaryButton};
	padding: 6px 10px;
	margin-left: 5px;
`;
export const UpdateAssetsButton = styled(SubmitButton)`
	${secondaryButton};
	padding: 6px 10px;
	margin-left: 5px;
`;
export const WorkflowStatusPillLg = styled(WorkflowStatusPill)`
	&& {
		${fsMedium};
		margin-top: 5px;
	}
`;

export const StaticPropText = styled.p`
	${fsLarge};
`;
