import { useAuthContext } from 'auth';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormFeedback } from 'reactstrap';
import DownshiftMultiFreeform from '../downshift-select/downshift-multi-freeform.component';
import { SelectionActions } from '../downshift-select/downshift.interfaces';

// interface
interface TagInputProps {
	emails: string[];
	onChange?: (emails: string[]) => void;
}

// component
const EmailInput = ({ emails, onChange }: TagInputProps) => {
	const { entities } = useAuthContext();
	useEffect(() => {
		if (!isEqual(emails, editingTags)) {
			setEditingTags(emails);
		}
		//eslint-disable-next-line
	}, [emails]);

	const [editingTags, setEditingTags] = React.useState(emails);
	const [valid, setValid] = useState(true);

	const removeTag = (tag: string) => {
		const updated = editingTags?.filter((tagEdited) => tagEdited !== tag);
		setEditingTags(updated);
		if (onChange) onChange(updated);
	};

	const validateEmail = (email: string) => {
		//eslint-disable-next-line
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	const [emailValid, setEmailValid] = useState(true);

	const addTag = (tag: string) => {
		if (!validateEmail(tag)) {
			setEmailValid(false);
		} else {
			setEmailValid(true);
			if (tag && editingTags?.indexOf(tag.toString()) <= -1) {
				setValid(true);
				setEditingTags([...editingTags, tag]);
				if (onChange) onChange([...editingTags, tag]);
			} else {
				setValid(false);
			}
		}
	};

	const selectionActions: SelectionActions<string, string> = {
		select: addTag,
		unselect: removeTag,
	};

	const selectionState = {
		selection: editingTags,
		options: [...(entities || [])?.map((e) => e.email)],
		searchPredicate: (tag: Nullable<string>, email: string) => {
			if (tag && email) {
				return email.toLocaleLowerCase().indexOf(tag.toLocaleLowerCase()) > -1;
			} else return false;
		},
	};

	return (
		<>
			<DownshiftMultiFreeform
				label="To (ROME users or EMAIL address)"
				placeholder="Add a email address..."
				selectionState={selectionState}
				selectionActions={selectionActions}
			/>
			<FormFeedback
				invalid="true"
				className={valid && emailValid ? 'd-none' : 'd-block'}
			>
				{!valid && <>That email-address was already added</>}
				{!emailValid && <>Email must be valid to add to recipients</>}
			</FormFeedback>
		</>
	);
};

export default EmailInput;
