import {
	faCalendar,
	faCommentDots,
	faEdit,
	faFilter,
	faSearch,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Heading, LabeledInput, LabeledSelect } from 'components';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Col, Container, FormText, Label, Row, Table } from 'reactstrap';
import * as axios from 'axios';
import { useHeaders, User } from 'auth';
import { AssetVersion, WorkflowTemplate } from 'workflows/types';
import moment from 'moment';
import { map, startCase } from 'lodash';
import { PaddedBadge } from 'dashboard/components/workflow-health-flag.component';
import { buildUserProfileUrl } from 'common/links';
import { StyledLink } from 'dam-assets/components/asset-card.styled-components';
export type TemplateAudit = {
	createdBy: User;
	template: WorkflowTemplate;
	action: 'deleted' | 'updated';
	createdAt: Date;
	_id: string;
};
export type AssetAudit = {
	createdBy: User;
	asset: AssetVersion;
	action: 'deleted' | 'updated' | 'deleted-version';
	createdAt: Date;
	_id: string;
};

export const AdminLogs = () => {
	const [logSearch, setLogSearch] = useState('');
	const [logDate, setLogDate] = useState<Date | null>();
	const [logType, setLogType] = useState<'templates' | 'assets' | ''>('');
	const [audits, setAudits] = useState<{
		templates: Array<TemplateAudit>;
		assets: Array<AssetAudit>;
	}>({
		templates: [],
		assets: [],
	});

	const { getHeaders } = useHeaders();
	const templateEndpoint = `${process.env.REACT_APP_ROME_API_ENDPOINT}/templates/audits`;
	const assetEndpoint = templateEndpoint
		.replace('templates', 'assets')
		.replace('audits', 'asset/audits');

	useEffect(() => {
		axios.default
			.get(templateEndpoint, getHeaders())
			.then((response) =>
				setAudits((audits) => ({ ...audits, templates: response.data }))
			);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		axios.default
			.get(assetEndpoint, getHeaders())
			.then((response) =>
				setAudits((audits) => ({ ...audits, assets: response.data }))
			);
		//eslint-disable-next-line
	}, []);

	const CustomDateInput = () => {
		return (
			<div className="position-relative my-1">
				<FontAwesomeIcon
					style={{ zIndex: 1, right: '70%', top: 10 }}
					icon={faCalendar}
					className="position-absolute"
				/>
				<ReactDatePicker
					onChange={setLogDate}
					value={logDate ? logDate.toLocaleDateString() : '    '}
				/>
			</div>
		);
	};

	const renderTemplateLogRow = (audit: TemplateAudit) => (
		<tr key={audit._id}>
			<td>
				<PaddedBadge
					color={audit.action.includes('deleted') ? 'danger' : 'info'}
				>
					{startCase(audit.action)}{' '}
					<FontAwesomeIcon
						icon={audit.action === 'updated' ? faEdit : faTrash}
					/>
				</PaddedBadge>
			</td>
			<td>
				Template "{audit.template.title}" was {audit.action}
			</td>
			<td>
				<FormText className="text-muted">
					{moment(audit.createdAt).fromNow()}
				</FormText>
			</td>
			<td>
				{audit.createdBy.givenName} {audit.createdBy.familyName}
			</td>
		</tr>
	);

	const renderAssetLogRow = (audit: AssetAudit) => (
		<tr key={audit._id}>
			<td>
				<PaddedBadge
					color={audit.action.includes('deleted') ? 'danger' : 'info'}
				>
					{startCase(audit.action)}{' '}
					<FontAwesomeIcon
						icon={audit.action === 'updated' ? faEdit : faTrash}
					/>
				</PaddedBadge>
			</td>
			<td>
				Asset "{audit.asset.fileName}" was {audit.action}
			</td>
			<td>
				<FormText className="text-muted">
					{moment(audit.createdAt).fromNow()}
				</FormText>
			</td>
			<td>
				<StyledLink to={buildUserProfileUrl(audit.createdBy)}>
					{audit.createdBy.givenName} {audit.createdBy.familyName}
				</StyledLink>
			</td>
		</tr>
	);

	const renderAuditLogs = React.useMemo(() => {
		return () => {
			switch (logType) {
				case 'templates':
					const initial = audits.templates;
					const filter = (audit: any) => {
						let retVal: boolean = true;
						if (logDate) {
							retVal = moment(audit.createdAt).isAfter(logDate);
						}

						if (logSearch) {
							retVal =
								audit.createdBy.givenName
									.toLowerCase()
									.includes(logSearch.toLowerCase()) ||
								audit.createdBy.familyName
									.toLowerCase()
									.includes(logSearch.toLowerCase()) ||
								audit.template.title
									.toLowerCase()
									.includes(logSearch.toLowerCase());
						}
						return retVal;
					};
					return initial.filter(filter);
				case 'assets':
					const initialAssets = audits.assets;
					const assetFilter = (audit: any) => {
						let retVal: boolean = true;
						if (logDate) {
							retVal = moment(audit.createdAt).isAfter(logDate);
						}
						if (logSearch) {
							retVal =
								audit.createdBy.givenName
									.toLowerCase()
									.includes(logSearch.toLowerCase()) ||
								audit.createdBy.familyName
									.toLowerCase()
									.includes(logSearch.toLowerCase()) ||
								audit.asset.title
									.toLowerCase()
									.includes(logSearch.toLowerCase());
						}
						return retVal;
					};
					return initialAssets.filter(assetFilter);
				default:
					return [];
			}
		};
	}, [logDate, logSearch, logType, audits.templates, audits.assets]);

	const renderAuditRow = (entity: any) => {
		switch (logType) {
			case 'templates':
				return renderTemplateLogRow(entity);
			case 'assets':
				return renderAssetLogRow(entity);
			default:
				return null;
		}
	};

	return (
		<Container>
			<div className="d-flex">
				<Heading>
					Logs <FontAwesomeIcon icon={faCommentDots} />{' '}
				</Heading>
			</div>
			<Row>
				<Col sm={4}>
					<LabeledInput
						icon={faSearch}
						onChange={(e) => setLogSearch(e.target.value)}
						label="Search for logs..."
						value={logSearch}
						title={'Search for logs by username or ' + logType + ' title'}
						placeholder={
							logType
								? 'Search for logs by username or ' + logType + ' title'
								: 'Search for logs...'
						}
					/>
				</Col>
				<Col sm={4}>
					<FontAwesomeIcon icon={faFilter} />
					<LabeledSelect
						onChange={(e) => setLogType(e.target.value.toLowerCase() as any)}
						label="Filter logs by type"
						id="logFilterSelect"
					>
						<option value={''}>Please select...</option>
						{['Templates', 'Assets'].map((t) => (
							<option key={t} value={t}>
								{t}
							</option>
						))}
					</LabeledSelect>
				</Col>
				<Col sm={4} className="pt-2">
					<Label className="m-0">Filter by log date</Label>
					<CustomDateInput />
				</Col>
			</Row>

			<Row className="px-3">
				<Table striped hover size="sm">
					<thead>
						<tr>
							<th>Action</th>
							<th>Description</th>
							<th>When</th>
							<th>Action taken by</th>
						</tr>
					</thead>
					<tbody>
						{map(renderAuditLogs(), renderAuditRow)}
						{renderAuditLogs().length === 0 && (
							<tr>
								<td colSpan={4}>No audit logs to display.</td>
							</tr>
						)}
					</tbody>
				</Table>
			</Row>
		</Container>
	);
};
