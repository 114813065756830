import { generateID } from 'common';
import React, { createContext, FC, useState } from 'react';
import {
	Notification,
	NotificationType,
	NotificationProps,
} from './Notification';
import { NotificationsContainer } from './Notification.styles';

type Notification = {
	type: NotificationType;
	text: string;
	heading?: string;
	expiryMs?: number;
};

type NotificationContext = {
	warn: any;
	info: any;
	error: any;
};

export const NotificationsContext = createContext<NotificationContext>({
	warn: () => {},
	info: () => {},
	error: () => {},
});

export const NotificationsContextProvider: FC<{}> = ({ children }) => {
	const [notifications, setNotifications] = useState<React.ReactNode>([]);

	type Config = Pick<NotificationProps, 'heading' | 'expirationInMs'>;

	const warn = (text: string, config?: Config) => {
		setNotifications((notifications) => [
			...notifications,
			<Notification {...config} type="warning" text={text} />,
		]);
	};

	const info = (text: string, config?: Config) => {
		setNotifications((notifications) => [
			...notifications,
			<Notification key={generateID()} {...config} type="info" text={text} />,
		]);
	};

	const error = (text: string, config?: Config) => {
		setNotifications((notifications) => [
			...notifications,
			<Notification {...config} type="error" text={text} />,
		]);
	};

	return (
		<NotificationsContext.Provider value={{ warn, info, error }}>
			<>
				<NotificationsContainer>{notifications}</NotificationsContainer>
				{children}
			</>
		</NotificationsContext.Provider>
	);
};
