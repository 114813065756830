import { faStepForward } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { StageStatus } from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';
import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
} from '../action-button.styled-components';

import ActivateFirstStageDialog from './activate-first-stage-dialog.component';
import { Flattenable, Stage } from '../../types/workflow.types';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { useAuthContext, useGroupContext } from 'auth';
import { flattenStages } from 'workflows/helpers';
import { UncontrolledTooltip } from 'reactstrap';

const workflowTerm = themeStore._.workflow.toLowerCase();

const ActivateFirstStageButton = () => {
	const { stage, workflow } = useWorkflowContext();
	const modalStack = useModalCreator();
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();

	const allStagesHaveOwner = React.useMemo(
		() =>
			flattenStages(workflow as Flattenable)
				?.filter((s) => s.type !== 'parallel')
				.every(
					(stage) =>
						!!stage.owners && !!stage.owners.length && stage.owners.length > 0
				),
		[workflow]
	);
	const canForceActivateStage = (stage: Stage) => {
		return (
			workflow?.createdBy?._id === currentUser._id ||
			(groupsForCurrentUser &&
				groupsForCurrentUser?.some((group) =>
					stage.owners.some((owner) => owner._id === group._id)
				)) ||
			(stage.owners && stage.owners.some((m) => m._id === currentUser._id)) ||
			currentUser.isAdmin
		);
	};
	const openActivateFirstStageModal = () => {
		modalStack.addModal(<ActivateFirstStageDialog />);
	};
	const message = React.useMemo(
		() =>
			allStagesHaveOwner
				? `Activate "${stage?.title}" to begin ${workflowTerm}`
				: `All applicable stages must have atleast one stakeholder to activate the ${workflowTerm}`,
		[allStagesHaveOwner, stage]
	);
	if (
		!canForceActivateStage(stage as Stage) ||
		stage?.status === StageStatus.active ||
		stage?.status === StageStatus.roadblocked
	) {
		return null;
	}

	const buttonId = `activateFirstStage${stage?._id}`;

	return (
		<ActionButtonContainer className="stage-action activate-first-stage">
			<UncontrolledTooltip target={buttonId}>{message}</UncontrolledTooltip>
			<div
				id={buttonId}
				style={{ cursor: !allStagesHaveOwner ? 'not-allowed' : 'default' }}
			>
				<ActionButton
					style={{ pointerEvents: !allStagesHaveOwner ? 'none' : 'default' }}
					color="secondary"
					size="sm"
					className="action-button activate-first-stage-button solid"
					onClick={openActivateFirstStageModal}
					disabled={!allStagesHaveOwner}
				>
					<IconWithRightMargin icon={faStepForward} />
					Begin {workflowTerm}
				</ActionButton>
			</div>
		</ActionButtonContainer>
	);
};

export default ActivateFirstStageButton;
