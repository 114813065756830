import { faLifeRing } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from 'auth';
import { NotificationsContext } from 'notifications';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { formatPayload, sendFeedback } from './FormFeedbackApi';
import { InputField, Textarea } from './forms';
import { FormModal } from './Modal';
import ConfirmationDialog from './modals/confirmation-dialog.component';

const RegisterModal = ({ onConfirm }: { onConfirm: () => void }) => {
	return (
		<ConfirmationDialog
			header="Registration Required"
			onConfirm={onConfirm}
			onCancel={onConfirm}
			confirmText="Okay"
		>
			<p>
				In order to submit a support ticket you must first register an account
				on zendesk.{' '}
				<a
					href="https://wearealexander.zendesk.com/"
					rel={'noopener noreferrer'}
					target={'_blank'}
				>
					Register now
				</a>
				.
			</p>
		</ConfirmationDialog>
	);
};

const FabButton = styled(animated.button)`
	position: fixed;
	display: flex;
	bottom: 16px;
	right: -178px;
	padding: 10px 30px 10px 13px;
	font-size: 14px;
	color: white;
	background: #416c65;
	border-radius: 24px;
	border: none;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 8px 16px -4px,
		rgba(9, 30, 66, 0.31) 0px 0px 1px;
	outline: none;
	z-index: 1000000;
	transition: all 0.5s;
	transform: translateX(0);

	:hover {
		transform: translateX(-160px);
	}

	:active,
	:focus {
		outline: none;
	}

	.icon {
		margin-top: 1px;
		margin-right: 0.5em;
	}
`;

export const CreateATicketFab = () => {
	const [isOpen, setIsOpen] = useState(false);
	const form = useForm();
	const { info } = React.useContext(NotificationsContext);
	const token = localStorage.getItem('rome_auth');
	const hasToken = React.useMemo(() => {
		if (token && JSON.parse(token) !== null) {
			return !!JSON.parse(token).accessToken ? true : false;
		}
	}, [token]);
	const { currentUser } = useAuthContext();

	type FormData = {
		subject: string;
		message: string;
	};

	const [showRegisterModal, setShowRegisterModal] = React.useState(false);

	const onSubmit = async (formData: FormData) => {
		const response = await sendFeedback(
			formatPayload(
				formData.subject,
				currentUser.email,
				currentUser.givenName + ' ' + currentUser.familyName,
				formData.message,
				window.location.href
			)
		);
		// If we dont get a success response, set submitError to true to show error message
		if (!response.request) {
			if (response.suspended_ticket) {
				setShowRegisterModal(true);
			}
			return;
		}
		info('Successfully created a ticket on Zendesk!');
		setIsOpen(false);
	};

	const onConfirm = () => setShowRegisterModal(false);

	if (!hasToken) return null;

	return (
		<>
			{showRegisterModal && <RegisterModal onConfirm={onConfirm} />}
			{!isOpen && (
				<FabButton onClick={() => setIsOpen((o) => !o)}>
					<FontAwesomeIcon icon={faLifeRing} size="lg" className="icon" />{' '}
					Create a support ticket.
				</FabButton>
			)}
			<FormModal
				type="right-panel"
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				title="Create A Ticket"
				form={form}
				onSubmit={onSubmit}
				submitText="Submit Ticket"
			>
				<InputField
					autoFocus
					label="Ticket Subject"
					name="subject"
					form={form as any}
					validators={{ required: true }}
				/>

				<Textarea
					label="Describe your Ticket"
					name="message"
					type="textarea"
					form={form as any}
					validators={{ required: true }}
				/>
			</FormModal>
		</>
	);
};
