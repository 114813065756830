import styled from 'styled-components';

import inputField from '../../../theme/components/input-field';
import themeStore from '../../../theme/models/ThemeStore';

const buttonStyles = `
	background: transparent;
	outline: none;
	font-size: 14px;
	display: inline-block;
	-webkit-appearance: none;
	border-radius: 4px;
	border-width: 0px;
	box-sizing: border-box;
	cursor: pointer;
	line-height: inherit;
	margin: 0px;
	padding: 8px 16px;
	text-align: center;
	-webkit-text-decoration: none;
	text-decoration: none;
	margin-top: 1.5em;
	margin-left: 1em;

	:hover {
		background: #eee;
	}
`;

export const StyledLogo = styled.figure`
	display: inline-block;
	background: url(${themeStore.selectedTheme.images.desktopLogo}) center center /
		contain no-repeat;
	width: 140px;
	height: 70px;
	margin-bottom: 2em;
`;

export const StyledInput = styled.input`
	${inputField};
	width: 100%;
`;

export const StyledSubmitButton = styled.button`
	${buttonStyles};
	color: white;

	background: #9e9e9e70;

	:hover {
		background: #62a094;
	}

	:disabled {
		cursor: not-allowed;
		background: #eeeeee;

		:hover {
			background: #eeeeee;
		}
	}
`;

export const StyledChangeFormButton = styled.button`
	${buttonStyles};
	background: lightgrey;
	color: black;

	:hover {
		background: #eee;
	}
`;
