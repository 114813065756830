import React, { useState } from 'react';
import { FormFeedback, FormGroup, InputProps } from 'reactstrap';

import { StyledInput, StyledLabel } from './forms.styled-components';
import { unwrapEvent } from '../../common/hooks';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface LabelInputProps extends InputProps {
	inputValid?: boolean;
	errorMsg?: string;
	icon?: IconProp;
	label: string;
}

export interface ControlledLabelInputProps extends LabelInputProps {
	// Not optional. This is a controlled input.
	value: Maybe<string>;
}

export interface UncontrolledLabelInputProps extends LabelInputProps {
	value?: never;
}

export const LabeledInput = (props: ControlledLabelInputProps) => {
	const {
		id,
		label,
		icon,
		inputValid = true,
		value = '',
		errorMsg,
		...rest
	} = props;

	const feedback = inputValid ? null : (
		<FormFeedback invalid="true">{errorMsg}</FormFeedback>
	);

	return (
		<FormGroup className="position-relative">
			<StyledLabel for={id}>{label}</StyledLabel>
			{icon && (
				<div
					style={{ top: 37, right: 5, zIndex: 1 }}
					className="position-absolute"
				>
					<FontAwesomeIcon icon={icon} />
				</div>
			)}
			<StyledInput
				value={value}
				className={!inputValid && 'is-invalid'}
				{...rest}
			/>
			{feedback}
		</FormGroup>
	);
};

export const UncontrolledLabeledInput = (
	props: UncontrolledLabelInputProps
) => {
	const [value, setValue] = useState('');
	return (
		<LabeledInput {...props} value={value} onChange={unwrapEvent(setValue)} />
	);
};

export default LabeledInput;
