import React from 'react';
import moment from 'moment';
import { Card } from 'reactstrap';
import {
	ListColumn,
	makeColumns,
	ListTable,
	CellLink,
	Loading,
} from '../../../components';
import { Workflow, WorkflowTemplate } from '../../../workflows/types';
import DeleteTemplateButton from '../../../workflow-templates/components/delete-template-button.component';
import { useTemplateContext } from 'workflow-templates/helpers/useTemplateContext';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { useAxios } from 'hooks';
import { useAuthContext, User } from 'auth';
import { isEqual } from 'lodash';

type Props = {
	templates: WorkflowTemplate[];
	onDelete: (wt: WorkflowTemplate) => void;
	onUpdate: (wt: WorkflowTemplate) => void;
};

export const WorkflowTemplatesTable = ({
	templates,
	onDelete,
	onUpdate,
}: Props) => {
	const { entities: workflows } = useWorkflowContext();
	const { currentUser } = useAuthContext();

	const compareCreator = (t: WorkflowTemplate) => {
		const type = typeof t?.createdBy;
		if (type === 'object')
			return isEqual((t?.createdBy as User)?._id, currentUser?._id);
		if (type === 'string') return isEqual(t?.createdBy, currentUser?._id);
		return false;
	};
	const templateListColumns: ListColumn<WorkflowTemplate>[] = makeColumns([
		{
			label: 'Name',
			prop: (t: WorkflowTemplate) => (
				<CellLink to={`./${t._id}`}>{t.title}</CellLink>
			),
		},
		{
			label: 'Template #',
			prop: '_id',
		},
		{
			label: 'Created',
			prop: (t) => moment(t.createdAt).toISOString().substring(0, 10),
		},
		{
			label: 'Editing State',
			prop: 'editingState',
		},
		{
			label: 'Used Count',
			prop: (template: WorkflowTemplate) => (
				<>
					{
						workflows
							?.filter((a) => !!a.templateUsed)
							.filter((a) =>
								typeof a.templateUsed === 'string'
									? a.templateUsed === template._id
									: a.templateUsed._id === template._id
							).length
					}
				</>
			),
		},
		{
			label: '',
			prop: (t: WorkflowTemplate) =>
				compareCreator(t) ? (
					<DeleteTemplateButton
						onUpdate={onUpdate}
						template={t}
						onDelete={(t) => onDelete(t)}
					/>
				) : null,
		},
	]);

	if (!workflows) return <Loading />;

	return (
		<Card>
			<ListTable
				columns={templateListColumns}
				rows={templates || []}
				noResultsLabel="No Results"
			/>
		</Card>
	);
};
