import React from 'react';
import * as R from 'ramda';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { buildClassList } from '../../../common';

import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from '../action-button.styled-components';

import { StageActionButtonProps } from '.';
import { useModalCreator } from 'stores/ModalStack';
import RejectStageDialog from './RejectStageDialog';

export const RejectStageButton = (props: StageActionButtonProps) => {
	const modalStack = useModalCreator();
	const { className, stage } = props;

	const buttonId = `rejectStage${stage._id}`;

	if (R.not(R.any(R.propEq('type', 'backward'), stage.transitions)))
		return null;

	return (
		<ActionButtonContainer
			className={buildClassList('stage-action reject-stage', className)}
		>
			<StyledTooltip target={buttonId}>Reject "{stage.title}"</StyledTooltip>
			<ActionButton
				id={buttonId}
				color=""
				size="sm"
				className="action-button reject-stage-button danger"
				onClick={() =>
					modalStack.addModal(
						<RejectStageDialog stage={stage} workflow={props.workflow} />
					)
				}
				disabled={stage.status === 'roadblocked'}
			>
				<IconWithRightMargin icon={faTimes} />
				Reject
			</ActionButton>
		</ActionButtonContainer>
	);
};
