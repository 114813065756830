import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';
import styled from 'styled-components';
import { primaryButton } from '../theme/components/primary-button';
import { secondaryButton } from '../theme/components/secondary-button';
import themeStore from '../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const PrimaryButton = styled.button`
	${primaryButton};
`;

export const SecondaryButton = styled.button`
	${secondaryButton};

	:disabled {
		background: lightgrey;

		:hover {
			background: lightgrey;
		}
	}
`;

export const WarningButton = styled.button`
	${primaryButton};
	background-color: ${colors.warning};

	&:hover {
		background-color: ${colors.warning};
		color: ${defaults?.secondary};
		filter: brightness(1.375);
	}
`;

export const DangerButton = styled.button`
	${primaryButton};
	background-color: ${defaults?.danger};

	&:hover {
		background-color: ${defaults?.danger};
		filter: brightness(1.375);
	}
`;

export const OutlineButton = styled.button`
	${primaryButton};
	transition: all 0.3s ease-in-out;
	background-color: transparent;
	border: 1px solid ${defaults?.primary};
	color: ${defaults.primary};

	&:hover {
		background-color: ${defaults?.secondary};
	}

	&:disabled {
		background-color: transparent;
		opacity: 0.5;

		&:hover {
			color: ${defaults?.secondary};
			background-color: transparent;
		}
	}
`;

export const MutedButton = styled.button`
	${primaryButton};
	background-color: ${colors.darkerGrey};
	color: var(--white);

	&:hover {
		background-color: ${colors.darkestGrey};
	}
`;

export const RemoveButton = styled.button`
	${secondaryButton};
	background-color: transparent !important;
	color: ${defaults?.secondary} !important;
	height: 24px;
	line-height: 0;
	padding: 0;
	vertical-align: middle;
	width: 24px;
	margin-left: 10px;

	&:hover {
		background-color: ${defaults?.danger} !important;
		color: ${colors.white} !important;
	}
`;
