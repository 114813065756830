import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';
import styled from 'styled-components';
import React from 'react';
export const AnchorSpan = ({
	children,
	...props
}: { children: React.ReactNode | React.ReactNode[] } & React.HTMLProps<
	any
>) => {
	const { defaults } = useThemeContext();

	const StyledAnchorSpanElement = styled.span`
		color: ${defaults?.primary};
		cursor: pointer;
		&:hover {
			color: ${defaults?.primaryHighlighted};
			text-decoration: underline;
			cursor: pointer;
		}
	`;

	return (
		<StyledAnchorSpanElement {...(props as any)}>
			{children}
		</StyledAnchorSpanElement>
	);
};
