import React from 'react';
import { FormText } from 'reactstrap';
import ToggleSwitch from '../../user-preferences/components/user-preference-toggle-switch.component';

type Props = {
	enabled?: boolean;
	onStateChanged: Function;
	preferencesEnabled: boolean;
	label: string;
};

export const EditPreferencesToggleSwitch = ({
	enabled,
	onStateChanged,
	preferencesEnabled,
	label,
}: Props) => {
	return (
		<div className="form-inline">
			<ToggleSwitch
				theme="graphite-small"
				className="d-flex"
				enabled={enabled}
				onStateChanged={onStateChanged}
			/>
			<FormText
				className={`${
					preferencesEnabled ? 'text-dark' : 'text-secondary'
				} form-label ml-2 mb-2 text-dark form-text text-muted `}
			>
				{label}
			</FormText>
		</div>
	);
};
