import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { secondaryButton } from '../../theme/components/secondary-button';
import { spinAnim } from '../../theme/ui/ui';

export const StyledButton = styled.button`
	${secondaryButton};
	margin-top: 1.5em;
`;

export const RotatingIcon = styled(FontAwesomeIcon)`
	${spinAnim};
	animation: spinAnim 1s infinite linear;
`;
