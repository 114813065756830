import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { isIdentifiableLoaded } from '../common';

import BackLink from '../components/back-link/back-link.component';
import { LoadingRow } from '../components/loading.component';
import { Heading } from '../components/ui';
import { useAxios } from '../hooks';
import themeStore from '../theme/models/ThemeStore';
import { AssetCollection } from '../workflows/types/workflow.types';
import EditAssetCollectionForm from './components/edit-asset-collection-form';

interface EditAssetCollectionProps {
	collectionId: string;
}

const AssetCollectionEditView = (props: EditAssetCollectionProps) => {
	const { collectionId } = props;
	const assetCollectionStore = useAxios<AssetCollection>('collections');
	const [fetchingCollection, setFetchingCollection] = useState(false);
	const [collection, setCollection] = useState<AssetCollection>();

	React.useEffect(() => {
		if (fetchingCollection) return;
		if (!collection) {
			assetCollectionStore
				.findOne(collectionId)
				.then(setCollection)
				.finally(() => setFetchingCollection(false));
		}
	}, [fetchingCollection, collection, assetCollectionStore, collectionId]);

	const label = themeStore._.assetCollection;

	return (
		<Container>
			{isIdentifiableLoaded(collection) ? (
				<Row className="justify-content-center">
					<Col lg={8}>
						<BackLink link=".." title={`${label} Details`} />
						<div className="d-flex justify-content-between">
							<Heading>Edit "{collection.title}"</Heading>
						</div>
					</Col>
					<Col lg={8}>
						<EditAssetCollectionForm collection={collection} />
					</Col>
				</Row>
			) : (
				<Container>
					<LoadingRow label="Loading..." />
				</Container>
			)}
		</Container>
	);
};

export default AssetCollectionEditView;
