import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMatch } from '@reach/router';
// prettier-ignore
import { getItemsOnPage, getTotalPages, Heading, Loading, PageSubheading, PaginationContainer, SaveQueryButton, ToggleCollectionView } from 'components';
import { Search, searchObject } from 'components/forms/Search';
import { Select } from 'components/forms/Select';
import { Option } from 'components/forms/Select/Option/Option';
import useViewType from 'core/hooks/useViewType';
import windowModel from 'models/WindowModel';
import { usePageHistory } from 'page-history/PageHistoryContext';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { RoleContext } from '../permissions/PermissionsContext';
// prettier-ignore
import { CreateWorkflowButton, WorkflowPageFilters, WorkflowPageHeader } from './components';

type Selection = {
	label: string;
	value: any;
};

type Props = {
	title: string;
	collection: any[];
	filters?: {
		options: { label: string; value: string }[];
		fn: (value: string | string[]) => void;
	};
	defaultFilter?: string[];
	defaultSort?: string;
	template?: {
		options: Array<{ label: string; value: string }>;
		fn: (value: string | string[]) => void;
	};
	sorting?: {
		options: { label: string; value: string }[];
		fn: (value: string) => void;
	};
	views: {
		table: (collection: any[]) => React.ReactNode;
		cards?: (collection: any[]) => React.ReactNode;
	};
	saveQuery?: boolean;
	create?: boolean;
};

export const WorkflowsIndexView = (props: Props) => {
	const {
		title,
		collection,
		template,
		filters,
		sorting,
		views,
		defaultFilter,
	} = props;
	const match = useMatch('/admin/workflow/workflows/status/:status');
	// Context and Store Hooks
	const { canViewByRole } = useContext(RoleContext);
	// useState Hooks
	const pageHistory = usePageHistory();
	const [currentPage, setCurrentPage] = useState<number>(
		pageHistory.getStateForKey(window.location.href, 'currentPage') ?? 1
	);
	const [searchTerm, setSearchTerm] = useState(
		pageHistory.getStateForKey(window.location.href, 'searchTerm') ?? ''
	);
	const [viewType, setViewType] = useViewType();

	const FilterColSize = React.useMemo(() => (!!template?.options ? 2 : 3), [
		template,
	]);
	// Effects
	useEffect(() => {
		const queryValue = windowModel.getURLSearchParam('q');
		if (queryValue && searchTerm !== queryValue) {
			setSearchTerm(queryValue);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const page = {
			path: window.location.href,
			state: [
				{
					key: 'searchTerm',
					value: searchTerm,
				},
			],
		};

		pageHistory.updateState(page, 'update');
		//eslint-disable-next-line
	}, [searchTerm]);

	// view type toggles
	const containsTitle = (str: string) =>
		title?.toLowerCase()?.indexOf(str?.toLowerCase()) > -1;

	const toggleViewType = (vt: 'list' | 'cards') => {
		if (['Assignments', 'Templates', 'Roadblocks'].some(containsTitle)) return;
		setViewType(vt);
	};

	const getViewType = () => {
		return ['Assignments', 'Templates', 'Roadblocks'].some(containsTitle)
			? 'list'
			: viewType;
	};

	const searchList = (term: string, list: any[]) =>
		list.filter(
			({ title, metadata }) =>
				searchObject(metadata?.values, term, true) ||
				searchObject({ title }, term)
		);

	if (!collection)
		return (
			<Container fluid>
				<Loading
					label={'Loading ' + props.title + '...'}
					justifyContent="start"
					alignItems="center"
				/>
			</Container>
		);
	return (
		<Container fluid style={{ paddingLeft: 30, paddingRight: 30 }}>
			<Row>
				<WorkflowPageHeader>
					<div>
						<PageSubheading text="workflows" />
						<Heading>{`${title}`}</Heading>
					</div>

					{canViewByRole('wfEditable') && props.create && (
						<CreateWorkflowButton
							to={match && match.status ? '../../new' : './new'}
						>
							<FontAwesomeIcon icon={faPlus} className="mr-2" />
							New {title.slice(0, -1)}
						</CreateWorkflowButton>
					)}
				</WorkflowPageHeader>
				<WorkflowPageFilters>
					<Col xl={5} className="my-1 col-xs-12 search-query-grid">
						{/* Search Heading */}
						<Search
							// className='search-query'
							defaultValue={searchTerm}
							onSearch={setSearchTerm}
							placeholder={`Search ${title}`}
						/>
						{props.saveQuery && (
							<SaveQueryButton
								className="mx-1"
								searchTerm={searchTerm}
								type={'workflows'}
							/>
						)}
					</Col>
					{filters && (
						<Col xl={FilterColSize} className="my-1 col-xs-12">
							{/* Filter By Status */}

							<Select
								name="status"
								label={'Filter ' + title}
								defaultValue={defaultFilter as string[]}
								noLabel
								multiple
								onSelect={(selection: Selection | Selection[]) => {
									if (Array.isArray(selection))
										filters.fn(selection?.map((selection) => selection.value));
									if (currentPage > 1) setCurrentPage(1);
									if (typeof Selection === typeof selection)
										filters.fn((selection as any).value);
								}}
							>
								{filters?.options.map((props, idx) => (
									<Option key={idx} {...props} />
								))}
							</Select>
						</Col>
					)}

					{sorting && (
						<Col xl={FilterColSize} className="my-1 col-xs-12">
							{/* Sorting */}

							<Select
								name="sort"
								noLabel
								label="Sort Workflows"
								defaultValue={props.defaultSort}
								onSelect={(selection: Selection | Selection[]) =>
									Array.isArray(selection) ? null : sorting.fn(selection.value)
								}
							>
								{sorting.options.map(({ value, label }) => (
									<Option
										key={label}
										value={value}
										label={'Sort By ' + label}
									/>
								))}
							</Select>
						</Col>
					)}
					{FilterColSize === 2 && (
						<Col xl={FilterColSize} className="col-xs-12">
							<Select
								noLabel
								search
								name="templateFilter"
								label="Filter by Workflow Template"
								defaultValue={props.defaultSort}
								onSelect={(selection: Selection | Selection[]) => {
									const value = Array.isArray(selection)
										? selection.map((a) => a.value)
										: selection.value;
									if (currentPage > 1) setCurrentPage(1);
									template?.fn(value);
								}}
							>
								{template?.options.map(({ value, label }) => (
									<Option key={label} value={value} label={label} />
								))}
							</Select>
						</Col>
					)}
					{views && (
						<Col xl={1} className="col-xs-12">
							{/* Toggle View */}

							<ToggleCollectionView
								cards={!!views.cards}
								className="toggle-view"
								currentView={getViewType()}
								toggle={toggleViewType}
							/>
						</Col>
					)}
				</WorkflowPageFilters>
			</Row>
			<Row>
				{/* Cards List */}
				<>
					{getViewType() === 'cards' &&
						collection &&
						views.cards &&
						views.cards(
							getItemsOnPage(searchList(searchTerm, collection), currentPage)
						)}

					{/* Table List */}
					{getViewType() === 'list' && collection && (
						<div style={{ width: '100%' }}>
							{views.table(
								getItemsOnPage(searchList(searchTerm, collection), currentPage)
							)}
						</div>
					)}
				</>
			</Row>
			{/* Pagination */}
			{collection && getTotalPages(collection) > 1 && (
				<div className="d-flex justify-content-center">
					<PaginationContainer
						totalPages={getTotalPages(searchList(searchTerm, collection))}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</div>
			)}
		</Container>
	);
};
