import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import React from 'react';
import { ModalBody } from 'reactstrap';
import BaseOwnerSelect from 'workflow-templates/components/owner-select.component';
import { BaseWorkflowOwner, Workflow } from './types';
type AddFollowerProps = {
	followers: BaseWorkflowOwner[];
	workflow: Workflow;
	onCancel: () => void;
	onConfirm: (followers: BaseWorkflowOwner[]) => void;
};
export const AddWorkflowFollowerDialog = (props: AddFollowerProps) => {
	const { followers, onCancel, onConfirm, workflow } = props;
	const [owners, setOwners] = React.useState(followers);
	const stateConfirm = () => {
		// confirm saves the api
		onConfirm(owners);
		// cancel closes it
		onCancel();
	};
	return (
		<ConfirmationDialog
			onCancel={onCancel}
			cancelText={'Cancel'}
			onConfirm={stateConfirm}
			header={"Update '" + workflow.title + "' workflow followers"}
			confirmText="Update"
		>
			<ModalBody>
				<BaseOwnerSelect
					label="Workflow Followers"
					onChange={(ownable) => setOwners(ownable.payload)}
					owners={followers as BaseWorkflowOwner[]}
				/>
			</ModalBody>
		</ConfirmationDialog>
	);
};
