import React from 'react';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import styled from 'styled-components';
import { last } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

type previewProps = {
	selectedTheme: SiteDefaults;
};

const MobilePreviewComponent = (props: previewProps) => {
	const { selectedTheme } = props;

	const mobileLogo = selectedTheme?.mobileLogo?.includes('blob')
		? selectedTheme?.mobileLogo
		: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
				selectedTheme?.account
		  }/${last(selectedTheme?.mobileLogo.split('/'))}`;

	const PreviewNavBar = styled.div`
		background-color: ${selectedTheme.navbarBackground};
		width: 100%;
		height: 60px;
		padding: 10px;
		display: flex;
		justify-content: space-between;
	`;

	const PreviewMobileLogo = styled.div`
		background-color: green;
		height: 40px;
		width: 70px;
		background: url(${mobileLogo}) left center / contain no-repeat;
	`;

	const MobilePreviewWrapper = styled.div`
		display: flex;
		justify-content: center;
		background-color: black;
		width: 100%;
		height: 100%;
	`;

	const MobileView = styled.div`
		height: 100%;
		width: 360px;
		background-color: #f5f5f5;
	`;

	return (
		<MobilePreviewWrapper>
			<MobileView>
				<PreviewNavBar>
					<PreviewMobileLogo />
					<FontAwesomeIcon icon={faBars} size="2x" />
				</PreviewNavBar>
			</MobileView>
		</MobilePreviewWrapper>
	);
};

export default MobilePreviewComponent;
