import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import React, { useCallback } from 'react';
import { Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import SubmitButton from '../../components/forms/submit-button.component';
import DialogModal from '../../components/modals/dialog-modal.component';
import { useModalCloser } from '../../stores/ModalStack';
import themeStore from '../../theme/models/ThemeStore';
import { AssetVersion } from '../../workflows/types/workflow.types';

import { useViewer } from '../services/viewer.service';

import AssetCard from './asset-card.component';

const assetTerm = themeStore._.asset.toLowerCase();
const pluralAssetsTerm = `${assetTerm}s`;

interface Props {
	assets: AssetVersion[];
}

const CompareAssetsDialog = (props: Props) => {
	const { assets } = props;
	const modalStack = useModalCloser();
	const assetViewer = useViewer();

	const compareAssets = useCallback(async () => {
		await assetViewer.compareTwo(assets[0], assets[1]);
		modalStack.closeModal();
	}, [assets, assetViewer, modalStack]);

	const render = () => {
		return (
			<DialogModal header={`Compare these ${pluralAssetsTerm}?`}>
				<ModalBody>
					<Row>
						{assets.map((asset, index) => (
							<Col md={6} key={asset?._id}>
								<AssetCard assetVersion={asset} />
							</Col>
						))}
					</Row>
				</ModalBody>
				<ModalFooter>
					<SubmitButton
						onClick={compareAssets}
						label="Proceed"
						icon={faExternalLinkAlt}
					/>
				</ModalFooter>
			</DialogModal>
		);
	};

	return render();
};

export default CompareAssetsDialog;
