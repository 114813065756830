import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AssetVersion } from 'workflows/types';
import { FallbackViewer } from './fallback-viewer.component';
const StyledZoom = styled.figure`
	max-width: 100%;
	width: 100%;
	background-repeat: no-repeat;
	cursor: crosshair;
	img {
		display: block;
		width: 100%;
		pointer-events: none;
	}
	&:hover img {
		opacity: 0;
		cursor: crosshair;
	}
`;
const ZoomComponent: React.FC<{
	src: string;
	alt: string;
	className?: string;
}> = ({
	src,
	alt,
	className,
}: {
	src: string;
	alt: string;
	className?: string;
}) => {
	const [state, setState] = React.useState({
		backgroundImage: `url(${src})`,
		backgroundPosition: '0% 0%',
		border: '1px solid #eee',
	});

	useEffect(() => {
		if (state.backgroundImage.includes(src) === false)
			setState((state) => ({ ...state, backgroundImage: `url(${src})` }));
		//eslint-disable-next-line
	}, [src]);
	const [isValid, setIsValid] = React.useState(true);
	const handleMouseMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const {
			left,
			top,
			width,
			height,
		} = e.currentTarget.getBoundingClientRect();
		const x = ((e.pageX - left) / width) * 100;
		const y = ((e.pageY - top) / height) * 100;
		setState({ ...state, backgroundPosition: `${x}% ${y}%` });
	};

	const render = () => (
		<>
			{isValid && (
				<StyledZoom onMouseMove={handleMouseMove} style={state}>
					<img
						onError={() => setIsValid(false)}
						className={className}
						alt={alt}
						src={src}
					/>
				</StyledZoom>
			)}
			{!isValid && <FallbackViewer asset={{} as AssetVersion} />}
		</>
	);
	return render();
};
export default ZoomComponent;
