import greys from '../../styles/common/_Greys.scss';
import { types } from 'mobx-state-tree';
import { nonEmptyStringWithDefault } from '../../models/common';
import { toRGBA } from '../../common/color.utils';
import { mapValues } from 'lodash';

export const ThemeGreysModel = types.model(
	'ThemeGreys',
	mapValues(greys, (v) => types.optional(types.string, v))
);

// main colors
const primary = '#3c3f42';
const primaryHighlighted = '#767d80';

const navbarBackground = '#ffffff';
const navbarForeground = 'rgba(60,63,66, 0.6)';
const navbarDarkOrLight = 'light';

const secondary = '#416c65';
const secondaryHighlighted = '#62a094';

// messaging colors
const danger = '#dc3545';
const warning = '#dadc35';
const success = '#28a745';
const info = '#17a2b8';

// status colors
const active = info;
const healthy = success;
const overdue = warning;
const blocked = danger;
const pipeline = primary;

// a11y
const focus = toRGBA(primary, 0.6);

export const ThemeColorsModel = types.model('ThemeColors', {
	primary: types.optional(types.string, primary),
	primaryHighlighted: types.optional(types.string, primaryHighlighted),

	secondary: types.optional(types.string, secondary),
	secondaryHighlighted: types.optional(types.string, secondaryHighlighted),

	danger: types.optional(types.string, danger),
	warning: types.optional(types.string, warning),
	success: types.optional(types.string, success),
	active: types.optional(types.string, active),

	healthy: types.optional(types.string, healthy),
	overdue: types.optional(types.string, overdue),
	blocked: types.optional(types.string, blocked),
	pipeline: types.optional(types.string, pipeline),

	focus: types.optional(types.string, focus),
	navbarBackground: types.optional(types.string, navbarBackground),
	navbarForeground: types.optional(types.string, navbarForeground),
	navbarDarkOrLight: types.optional(types.string, navbarDarkOrLight),
});
