import { Link as LinkBase } from '@reach/router';
import { CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';
import { ffBold } from '../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

export const CollectionCardHeader = styled(CardHeader)`
	height: 220px;
	overflow: hidden;
	position: relative;
	z-index: -3;
	display: flex;
	justify-content: center;

	&&& {
		background-color: ${colors.grey};
		border-bottom: none;
		padding: 30px 40px 30px 30px;
	}

	@media only screen and (min-width: 576px) {
		display: table;
	}
`;

export const CollectionCardBody = styled(CardBody)`
	&&& {
		background-color: var(--white);
	}
`;

export const CollectionCardBodyHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-right: 10px;
	margin-bottom: 5px;
`;

export const CollectionTitle = styled.h6`
	${ffBold};
	font-size: 15px;
	margin: 0;
	overflow: hidden;
	padding-right: 1.25rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: auto;
`;

export const Link = styled(LinkBase)`
	color: black;
	text-decoration: none;

	&:hover {
		color: black;
		text-decoration: none;

		.card {
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.125);
			transform: scale(1.0125);
			transition: transform 1ms ease-in-out;
		}
	}
`;
