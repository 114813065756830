import React from 'react';
import { _logWarning } from '../common/log';
import { useNavigate } from '@reach/router';
import { Container } from 'reactstrap';

type Props = {
	handleAuthentication: () => Promise<void>;
};
const LoginCallback = (props: Props) => {
	const navigate = useNavigate();
	const { handleAuthentication } = props;
	React.useEffect(() => {
		if (window.location.hash.includes('access_token=')) {
			handleAuthentication().catch((error) => {
				_logWarning(error);
				return navigate('/auth', { state: error });
			});
		}
	}, [handleAuthentication, navigate]);

	return <Container></Container>;
};

export default LoginCallback;
