import { css } from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';

import { boxShadowFocus } from '../ui/ui';
import { ffTextBody, fsNormal } from '../ui/typography';
import storageModel from 'models/StorageModel';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

const inputField = css`
	${ffTextBody};
	${fsNormal};
	border-radius: 4px;
	line-height: 20px;
	padding: 6px 12px;
	text-rendering: optimizeLegibility;

	&:focus {
		${boxShadowFocus};
		border: 1px solid ${defaults?.primaryHighlighted} !important;
	}

	&:disabled {
		background-color: ${colors.lightGrey} !important;
		cursor: not-allowed;
	}

	&:read-only {
		cursor: not-allowed;
	}
`;
export default inputField;
