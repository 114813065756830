import React from 'react';
import { Router } from '@reach/router';
import styled from 'styled-components';

import { getRGBfromHEX } from '../common';
import { ViewWrapper as ViewWrapperBase } from '../components/ui';
import Footer from '../components/footer.component';
import Login from '../views/login.view';
import { last } from 'lodash';
import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';

export const ViewWrapper = ({ children, defaults }) => {
	const rgb = getRGBfromHEX(defaults?.secondary);
	if (!defaults?.account) return null;

	const getUrl = () =>
		`${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
			defaults?.account
		}/${last(defaults?.loginBackground.split('/'))}`;

	const ViewWrapperElement = styled(ViewWrapperBase)`
		background-size: cover;
		background-image: linear-gradient(
				to top,
				rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.7) 0%,
				rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.1) 100%
			),
			url(${getUrl()});
	`;
	return <ViewWrapperElement>{children}</ViewWrapperElement>;
};
export const Auth = () => {
	const [defaults, setDefaults] = React.useState();
	const { fetchDefaults } = useThemeContext();
	React.useEffect(() => {
		fetchDefaults().then(setDefaults);
		//eslint-disable-next-line
	}, []);
	return (
		<ViewWrapper defaults={defaults}>
			<Router>
				<Login defaults={defaults} path="login" default />
			</Router>
			<Footer />
		</ViewWrapper>
	);
};

export default Auth;
