import pixelMatch from 'pixelmatch';
import { pick } from 'lodash';
import { AssetVersion } from 'workflows/types';

const BYTES_PER_PX = 4;

export interface Versions {
	versionA: AssetVersion;
	versionB: AssetVersion;
}

interface Contexts {
	a: CanvasRenderingContext2D;
	b: CanvasRenderingContext2D;
	diff: CanvasRenderingContext2D;
}

interface Diff {
	similarity: number;
	data: ImageData;
}

const aspect = ({ width, height }: Dimensions) => width / height;
const scaleCanvasToAspect = (
	canvas: HTMLCanvasElement,
	dimensions: Dimensions
) => {
	const imageAspect = aspect(dimensions);
	const canvasWidth = canvas.width;
	const scaledHeight = canvasWidth / imageAspect;

	canvas.setAttribute('height', scaledHeight.toFixed(0) + 'px');

	return {
		width: canvasWidth,
		height: scaledHeight,
	};
};

export async function drawVersionDiff(
	{ versionA, versionB }: Versions,
	{ a, b, diff }: Contexts
) {
	const [imageA, imageB] = await Promise.all([
		getAssetVersionImage(versionA),
		getAssetVersionImage(versionB),
	]);

	const dimensions = pick(imageA, ['width', 'height']);

	const [iDataA, iDataB] = await Promise.all([
		drawImageData(imageA, a, dimensions),
		drawImageData(imageB, b, dimensions),
	]);

	const { similarity, data: diffData } = getDiffData(iDataA, iDataB);

	const diffImageData = drawImageData(
		await createImageBitmap(diffData),
		diff,
		dimensions
	);

	return {
		similarity,
		data: diffImageData,
	};
}

export function getAssetVersionImage(
	version: AssetVersion
): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		if (!version.previewURL) {
			return reject({
				message: `Preview property of version "${version._id}" is undefined`,
			});
		}

		const image = new Image();
		image.onload = () => resolve(image);
		image.onerror = (err) => reject(err);

		image.src = version.previewURL;
	});
}

export async function drawImageData(
	imageSource: CanvasImageSource,
	ctx: CanvasRenderingContext2D,
	dimensions: Dimensions
): Promise<ImageData> {
	const { width, height } = scaleCanvasToAspect(ctx.canvas, dimensions);

	ctx.drawImage(imageSource, 0, 0, width, height);

	return ctx.getImageData(0, 0, width, height);
}

export function getDiffData(
	imageDataA: ImageData,
	imageDataB: ImageData
): Diff {
	const { width, height } = imageDataA;

	const outputBuffer = new Uint8Array(imageDataA.data.length);

	const differentPxCount = pixelMatch(
		new Uint8Array(imageDataA.data),
		new Uint8Array(imageDataB.data),
		outputBuffer,
		width,
		height,
		{ threshold: 0.05, diffColor: [240, 128, 128], alpha: 0.5 }
	);

	return {
		similarity: 1 - differentPxCount / (imageDataA.data.length / BYTES_PER_PX),
		data: new ImageData(new Uint8ClampedArray(outputBuffer), width, height),
	};
}
