import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { StageActionButtonProps } from '.';
import {
	StageStatus,
	statusForRoadblockToggle,
} from '../../../models/StageStatusModel';
import { RoleContext } from '../../../permissions/PermissionsContext';
import { useModalCreator } from '../../../stores/ModalStack';
import {
	OutlinedCircleButton,
	StyledTooltip,
} from '../action-button.styled-components';
import ChangeStageStatusDialog from './change-stage-status-dialog.component';

export const RoadblockStageButton = (props: StageActionButtonProps) => {
	const { stage } = props;
	const { canViewByRole } = React.useContext(RoleContext);
	const modalStack = useModalCreator();
	const openRoadblockModal = useCallback(() => {
		modalStack.addModal(
			<ChangeStageStatusDialog
				stage={stage}
				statusChange={statusForRoadblockToggle[stage.status as string]}
			/>
		);
	}, [stage, modalStack]);

	return canViewByRole('canEditRoadblocks') ? (
		<>
			<StyledTooltip target="roadblockBtn">
				{[StageStatus.active].includes(stage.status as StageStatus)
					? 'Roadblock stage'
					: 'Resolve roadblock'}
			</StyledTooltip>
			<OutlinedCircleButton
				id="roadblockBtn"
				className={
					stage.status === StageStatus.roadblocked
						? 'roadblocked text-warning'
						: ''
				}
				disabled={stage.status === StageStatus.completed}
				color=""
				size="sm"
				onClick={openRoadblockModal}
			>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{stage.status === StageStatus.roadblocked ? ' Resolve' : ''}
			</OutlinedCircleButton>
		</>
	) : null;
};
