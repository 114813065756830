import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';
import { css } from 'styled-components';
import { fsNormal } from '../ui/typography';
import { boxShadowFocus } from '../ui/ui';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const primaryButton = css`
	${fsNormal};
	display: inline-block;
	-webkit-appearance: none;
	background-color: ${defaults?.primary};
	border-color: initial;
	border-image: initial;
	border-radius: 4px;
	border-style: initial;
	border-width: 0px;
	box-sizing: border-box;
	color: var(--white);
	cursor: pointer;
	line-height: inherit;
	margin: 0px;
	padding: 8px 16px;
	text-align: center;
	text-decoration: none;

	&:hover {
		background-color: ${defaults?.primaryHighlighted};
		color: var(--white);
		text-decoration: none;
	}

	&:focus {
		${boxShadowFocus};
		text-decoration: none;
	}

	&:disabled {
		cursor: not-allowed;
		background-color: var(--darkGrey);

		&:hover {
			background-color: var(--darkGrey);
		}
	}
`;

export const secondaryButton = css`
	${primaryButton};
	background-color: ${defaults?.secondary} !important;

	&:hover {
		background-color: ${defaults?.secondaryHighlighted} !important;
	}
`;
