import React, { Suspense, useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import themeStore from '../theme/models/ThemeStore';
import Loading from '../components/loading.component';
import { RoleContext } from 'permissions/PermissionsContext';
const PageSubheading = React.lazy(() =>
	import('../components/page-subheading.component')
);

const ReportsIndexView = () => {
	const { canViewByRole } = useContext(RoleContext);
	return canViewByRole('canViewReporting') ? (
		<Suspense fallback={<Loading />}>
			<Container>
				<Row>
					<Col md={2} className="mb-md-0 mb-4">
						<h1>{themeStore._.report}s</h1>
						<PageSubheading text="Treehouse Status Report"></PageSubheading>
					</Col>
				</Row>
			</Container>
		</Suspense>
	) : null;
};

export default ReportsIndexView;
