import ThemeModel from '../models/ThemeModel';
import { toRGBA } from '../../common/color.utils';

// main colors
const primary = '#00644C';
const primaryHighlighted = '#925041';

const secondary = '#5EA000';
const secondaryHighlighted = '#00644C';
const navbarBackground = '#ffffff';
const navbarDarkOrLight = 'light';
// status colors
const pipeline = primary;

// a11y
const focus = toRGBA(primary, 0.5);

export const pdlTheme = ThemeModel.create({
	_id: 'pdl',
	title: 'PDL',
	images: {
		desktopLogo: '/themes/pdl/logo-desktop.svg',
		mobileLogo: '/themes/pdl/logo-mobile.svg',
		loginBackground: '/themes/pdl/login-bg.jpg',
	},
	colors: {
		primary,
		primaryHighlighted,
		secondary,
		secondaryHighlighted,
		pipeline,
		focus,
		navbarBackground,
		navbarDarkOrLight,
	},
});
