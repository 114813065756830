import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { Container, StyledLink } from './back-link.styled-components';
import { useNavigate } from '@reach/router';

// interface
interface BackLinkProps {
	link: string;
	title: string;
	useHistory?: boolean;
}

// component
const BackLink = (props: BackLinkProps) => {
	const navigateToRoute = () => {
		return window.history.back();
	};
	return (
		<Container>
			<StyledLink to="" onClick={navigateToRoute}>
				<FontAwesomeIcon icon={faArrowLeft} className="button-icon mr-2" />
				<span className="button-label">Back to previous page</span>
			</StyledLink>
		</Container>
	);
};
export default BackLink;
