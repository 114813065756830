import { Link } from '@reach/router';
import { NavItem } from 'reactstrap';
import styled, { StyledComponent } from 'styled-components';
import anchor from '../../theme/components/anchor';
import { secondaryButton } from '../../theme/components/secondary-button';

export const CreateWorkflowButton = styled(Link)`
	${secondaryButton};
	margin: 1.5em 0;
`;

export const FilterNavItem: React.FC = styled(NavItem)`
	margin-top: 1em;

	&:first-of-type {
		margin-top: 0;
	}
`;
export const FilterNavButton: StyledComponent<'button', {}> = styled.button`
	${anchor};
`;

export const WorkflowPageHeader = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	h1 {
		margin-bottom: 0;
		padding-bottom: 0;
	}
`;

export const WorkflowPageFilters = styled.header`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 10px;
	width: 100%;
	padding-bottom: 16px;

	.search-query-grid {
		display: grid;
		grid-template-columns: 75% 25%;
		@media (max-width: 768px) {
			grid-template-columns: 70% 20%;
		}
	}

	.save-query-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 125px;
		position: relative;
		left: 5px;
	}

	.filter-workflows {
		width: 20%;
		margin-left: auto;
	}

	.sort-workflows {
		width: 25%;
	}

	.toggle-view {
		display: flex;
	}
`;
