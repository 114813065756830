import { useState } from 'react';
import { isString, isFunction, isBoolean } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const StyledToggleSwitch = styled.div`
	/* DEFAULT COLOR VARIABLES */
	/* DEFAULT SIZING VARIABLES */
	/* DEFAULT CLASS VARIABLE */
	/* STYLES */
	* {
		cursor: pointer;
	}
	.switch.switch--default > .switch-toggle {
		height: 32px;
		width: 52px;
		cursor: pointer !important;
		user-select: none !important;
		position: relative !important;
		display: inline-block;
	}
	.switch.switch--default > .switch-toggle.switch-toggle--on::before,
	.switch.switch--default > .switch-toggle.switch-toggle--on::after,
	.switch.switch--default > .switch-toggle.switch-toggle--off::before,
	.switch.switch--default > .switch-toggle.switch-toggle--off::after {
		content: '';
		left: 0;
		position: absolute !important;
	}
	.switch.switch--default > .switch-toggle.switch-toggle--on::before,
	.switch.switch--default > .switch-toggle.switch-toggle--off::before {
		height: inherit;
		width: inherit;
		border-radius: 16px;
		will-change: background;
		transition: background 0.4s 0.3s ease-out;
	}
	.switch.switch--default > .switch-toggle.switch-toggle--on::after,
	.switch.switch--default > .switch-toggle.switch-toggle--off::after {
		top: 2px;
		height: 28px;
		width: 28px;
		border-radius: 14px;
		background: #ffffff !important;
		will-change: transform;
		transition: transform 0.4s ease-out;
	}
	.switch.switch--default > .switch-toggle.switch-toggle--on::before {
		background: #979797 !important;
	}
	.switch.switch--default > .switch-toggle.switch-toggle--on::after {
		transform: translateX(22px);
	}
	.switch.switch--default > .switch-toggle.switch-toggle--off::before {
		background: #cccccc !important;
	}
	.switch.switch--default > .switch-toggle.switch-toggle--off::after {
		transform: translateX(2px);
	}

	.switch.switch--graphite-small > .switch-toggle {
		height: 20px;
		width: 32.5px;
		cursor: pointer !important;
		user-select: none !important;
		position: relative !important;
		display: inline-block;
	}
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before,
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after,
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before,
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
		content: '';
		left: 0;
		position: absolute !important;
	}
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before,
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
		height: inherit;
		width: inherit;
		border-radius: 10px;
		will-change: background;
		transition: background 0.4s 0.3s ease-out;
	}
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after,
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
		top: 2px;
		height: 16px;
		width: 16px;
		border-radius: 8px;
		background: #ffffff !important;
		will-change: transform;
		transition: transform 0.4s ease-out;
	}
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before {
		background: gray !important;
	}
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after {
		transform: translateX(14.5px);
	}
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
		background: #cccccc !important;
	}
	.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
		transform: translateX(2px);
	}
`;
const ThemedToggleSwitch = (props: any) => {
	const enabledFromProps = () => {
		let { enabled } = props;

		// If enabled is a function, invoke the function
		enabled = isFunction(enabled) ? enabled() : enabled;

		// Return enabled if it is a boolean, otherwise false
		return isBoolean(enabled) && enabled;
	};

	const [isEnabled, setEnabled] = useState(enabledFromProps);

	const toggleSwitch = (evt: any) => {
		const { onClick, onStateChanged } = props;
		setEnabled(!isEnabled);
		// Augument the event object with SWITCH_STATE
		const switchEvent = Object.assign(evt, { SWITCH_STATE: !isEnabled });
		// Augument the event object with SWITCH_STATE

		// Execute the callback functions
		isFunction(onClick) && onClick(switchEvent);
		isFunction(onStateChanged) && onStateChanged(!isEnabled);
	};
	// Isolate special props and store the remaining as restProps

	const render = () => {
		const {
			enabled: _enabled,
			theme,
			onClick,
			className,
			onStateChanged,
			customSvg,
			...restProps
		} = props;

		// Use default as a fallback theme if valid theme is not passed
		const switchTheme = theme && isString(theme) ? theme : 'default';

		const switchClasses = `switch switch--${switchTheme}`;
		const togglerClasses = `switch-toggle switch-toggle--${
			isEnabled ? 'on' : 'off'
		}`;
		return (
			<StyledToggleSwitch onClick={toggleSwitch}>
				<div className={switchClasses} {...restProps}>
					<div className={togglerClasses}></div>
				</div>
				{customSvg ? customSvg : null}
			</StyledToggleSwitch>
		);
	};
	return render();
};
export default ThemedToggleSwitch;
