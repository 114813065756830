import React, { FormEvent } from 'react';
import { Form, FormGroup, ModalBody } from 'reactstrap';

import { _logError } from '../../common/log';
import { SubmitButton } from '../../components/forms';
import { useModalCloser } from '../../stores/ModalStack';
import DialogModal from '../../components/modals/dialog-modal.component';

import { NotificationsContext } from '../../notifications';
import themeStore from '../../theme/models/ThemeStore';

import CollectionAssetSelect from './collection-asset-select.component';
import { AssetCollection } from '../../workflows/types/workflow.types';
import { useAxios } from 'hooks';

const assetTerm = themeStore._.asset;
const collectionTerm = themeStore._.assetCollection;

// interface
interface Props {
	selectedCollection?: AssetCollection;
}

// component
const AddAssetsDialog = (props: Props) => {
	const { info } = React.useContext(NotificationsContext);
	const modalStack = useModalCloser();
	const collectionStore = useAxios<AssetCollection>('collections');

	const [editedCollection, setEditedCollection] = React.useState(
		props.selectedCollection
	);

	const onFormSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedCollection = await collectionStore.updateOne(
				editedCollection?._id as string,
				editedCollection as AssetCollection
			);
			setEditedCollection(updatedCollection);
			modalStack.closeModal();

			info(`${collectionTerm} saved!`);
		} catch (error) {
			_logError(error);
			error(
				`An issue occurred while adding ${assetTerm} to ${collectionTerm}. Please try again later.`
			);
		}
	};

	return (
		<DialogModal
			modalSize={'xl'}
			header={`Add ${themeStore._.asset.toLowerCase()}s`}
		>
			<ModalBody>
				<Form>
					<FormGroup>
						<CollectionAssetSelect
							onChange={(updated) => {
								setEditedCollection({
									...editedCollection,
									assets: updated.assets,
								} as AssetCollection);
								return;
							}}
							collection={props?.selectedCollection as AssetCollection}
						/>
					</FormGroup>
					<SubmitButton label="Save" onClick={onFormSubmit} />
				</Form>
			</ModalBody>
		</DialogModal>
	);
};

export default AddAssetsDialog;
