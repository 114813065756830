import styled from 'styled-components';

import { fsSmol } from '../../theme/ui/typography';
import unstyledButton from '../../theme/components/unstyled-button';
import storageModel from 'models/StorageModel';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const ShareAssetButton = styled.button`
	${unstyledButton};
	${fsSmol};
	display: inline-block;
	color: ${defaults?.secondary};

	&:hover {
		text-decoration: underline;
		color: ${defaults?.secondaryHighlighted};
	}
`;
