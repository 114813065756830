import React from 'react';
import { FormGroup } from 'reactstrap';
import { EditPreferencesToggleSwitch } from './EditPreferencesToggleSwitch';
import { HelpBlock } from './HelpBlock.styled';
import { NotificationsContext } from '../../notifications';
import usePushNotifications from 'admin-dashboard/components/notifications/usePushNotification';

type Props = {
	preferencesEnabled: boolean;
	preferenceStore: any;
	setPushEnabled: (enabled: boolean) => void;
	pushEnabled: boolean;
};

export const UpdateOnNewFunctionalityFormGroup = (props: Props) => {
	const { warn } = React.useContext(NotificationsContext);
	const {
		onClickSusbribeToPushNotification,
		onClickUnsubscribeToPushNotification,
	} = usePushNotifications();
	const onToggle = async (enabled: boolean) => {
		if (enabled) {
			try {
				onClickSusbribeToPushNotification();
			} catch {
				warn(
					'Sorry, an issue occurred updating your preference, try again later.'
				);
			}
		} else {
			onClickUnsubscribeToPushNotification();
		}
		props.setPushEnabled(enabled);
		props.preferenceStore.setRecievePushNotifications(enabled);
	};

	return (
		<FormGroup style={{ fontSize: 14 }}>
			<HelpBlock style={{ fontSize: 16 }}>
				Update me when ROME has new functionality
			</HelpBlock>

			<EditPreferencesToggleSwitch
				preferencesEnabled={props.preferencesEnabled}
				enabled={props.pushEnabled}
				onStateChanged={onToggle}
				label="I would like to receive notifications on this device when ROME receives updates"
			/>
		</FormGroup>
	);
};
