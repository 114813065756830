import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { buildClassList } from '../common';
import themeStore from '../theme/models/ThemeStore';

import { boxShadowFocus } from '../theme/ui/ui';

const { colors } = themeStore.selectedTheme;

export const StyledCircleButton = styled(Button)`
	&& {
		border-radius: 100%;
	}
	background-color: ${colors.darkerGrey};
	color: var(--white);
	height: 2.5em;
	margin-left: 0.5em;
	width: 2.5em;

	&:hover {
		background-color: ${colors.darkestGrey};
	}

	&:focus {
		${boxShadowFocus};
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.disabled {
		&& {
			border: 1px solid ${colors.darkerGrey};
			cursor: not-allowed;
		}
		color: ${colors.darkerGrey};
	}

	&.small,
	&.sm {
		height: 2em;
		width: 2em;

		svg {
			top: -0.125em;
			left: -0.25em;
		}
	}

	svg {
		position: relative;
	}
`;

interface CircleButtonProps {
	icon: IconDefinition;
	onClick: () => void;

	className?: string;
	disabled?: boolean;
	tooltip?: string;
	color?: string;

	/** @deprecated */
	id?: string;
}

const CircleButton = ({
	icon,
	onClick,
	className,
	disabled = false,
	tooltip,
	color,
}: CircleButtonProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	return (
		<StyledCircleButton
			color={color}
			innerRef={buttonRef}
			className={buildClassList('circle-button', className)}
			disabled={disabled}
			onClick={onClick}
		>
			<FontAwesomeIcon icon={icon} />
			{tooltip && buttonRef.current ? (
				<UncontrolledTooltip target={buttonRef.current}>
					{tooltip}
				</UncontrolledTooltip>
			) : null}
		</StyledCircleButton>
	);
};

export default CircleButton;
