import { User } from 'auth';
import * as axios from 'axios';
import { isEqual, isObject } from 'lodash';
import React from 'react';
import { ReportBuilderInstance } from 'reports/types';
import { flattenStages } from 'workflows/helpers';
import {
	AssetVersion,
	Flattenable,
	Workflow,
	WorkflowTemplate,
} from 'workflows/types';
import moment from 'moment';
import { humanReadableFileSizeSI } from 'common';
export const useReportBuilder = () => {
	const endpoint = process.env.REACT_APP_ROME_API_ENDPOINT as string;
	const token = localStorage.getItem('rome_auth') as string;
	const config = React.useMemo(
		() =>
			token && JSON.parse(token)?.accessToken
				? {
						headers: {
							Authorization: `Bearer ${JSON.parse(token).accessToken}`,
						},
				  }
				: { headers: {} },
		[token]
	);

	const getHeaders = React.useCallback(() => {
		let authHeaders = { headers: config?.headers };
		if (
			!!authHeaders?.headers?.Authorization?.includes('null') ||
			!authHeaders?.headers?.Authorization
		) {
			authHeaders = {
				headers: {
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem('rome_auth') as string).accessToken
					}`,
				},
			};
		}
		return authHeaders;
	}, [config]);

	const saveReport = async (report: ReportBuilderInstance) => {
		const response = await axios.default.post(
			`${endpoint}/report-builder/create`,
			report,
			getHeaders()
		);
		return response?.data;
	};

	const updateReport = async (
		reportId: string,
		updatedReport: ReportBuilderInstance
	) => {
		const response = await axios.default.patch(
			`${endpoint}/report-builder/${reportId}`,
			updatedReport,
			getHeaders()
		);
		return response?.data;
	};

	const fetchExistingReports = async () => {
		const response = await axios.default.get(
			`${endpoint}/report-builder`,
			getHeaders()
		);
		return response?.data;
	};

	const findById = async (id: string) => {
		const response = await axios.default.get(
			`${endpoint}/report-builder/${id}`,
			getHeaders()
		);
		return response?.data;
	};

	const deleteReport = async (id: string) => {
		const response = await axios.default.delete(
			`${endpoint}/report-builder/${id}`,
			getHeaders()
		);
		return response?.status === 200;
	};

	const filterByKeyAndObject = (object: any, filter: any) => {
		if (filter.key.includes('metadata') || filter.key.includes('Metadata')) {
			const objectKey = filter.key.includes('skuMetdata')
				? 'skuMetadata'
				: 'metadata';
			const metadataKey = filter.key
				.replace('metadata.values.', '')
				.replace('skuMetadata.values.');
			const metadataValueMustEqual = filter.type === 'equals' ? true : false;
			if (metadataValueMustEqual) {
				const isValidObject = isObject(object[objectKey]['values']);
				const hasKey = isValidObject
					? Object.keys(object[objectKey]['values']).some(
							(a) => a === metadataKey
					  )
					: false;
				return hasKey
					? isEqual(object[objectKey]['values'][metadataKey], filter.value)
					: false;
			} else {
				return object[objectKey]['values'][metadataKey]
					?.toString()
					?.includes(filter.value);
			}
		} else if (filter.key.includes('.')) {
			const objectKey = filter.key.split('.')[0];
			console.log(objectKey);
			const objectSubkey = filter.key.split('.')[1];
			if (!object[objectKey]) return false;
			switch (filter.type) {
				case 'equals':
					return isEqual(filter.value, object[objectKey][objectSubkey] ?? '');
				case 'contains':
					return filter.value
						.toLowerCase()
						.includes((object[objectKey][objectSubkey] ?? '').toLowerCase());
			}
		} else {
			switch (filter.type) {
				case 'equals':
					const eqValue =
						filter.key === 'createdBy'
							? object[filter.key].givenName +
							  ' ' +
							  object[filter.key].familyName
							: object[filter.key];
					return isEqual(eqValue, filter.value);
				case 'contains':
					const includesValue =
						filter.key === 'createdBy'
							? object[filter.key].givenName +
							  ' ' +
							  object[filter.key].familyName
							: object[filter.key];
					return includesValue
						?.toString()
						?.toLowerCase()
						.includes(filter.value?.toString()?.toLowerCase());
				case 'greater-than':
					return moment(object['createdAt']).isAfter(moment(filter.value));

				case 'less-than':
					return moment(object['createdAt']).isAfter(moment(filter.value));

				case 'between':
					return (
						moment(object['createdAt']).isBefore(moment(filter.metadata.end)) &&
						moment(object['createdAt']).isAfter(moment(filter.metadata.start))
					);

				default:
					return false;
			}
		}
	};

	const filterReportRows = (report: ReportBuilderInstance, all: any[]) => {
		// const getFilterByKey = (key: string) =>
		// 	report?.filters?.find((a: any) => a.key === key);
		switch (report.selectedPrimaryEntity) {
			case 'assets':
				const assetRows: any[] = [];
				let allAssets = all as AssetVersion[];
				if (report?.filters?.length) {
					allAssets = allAssets?.filter((asset) =>
						report?.filters?.every((filter) =>
							filterByKeyAndObject(asset, filter)
						)
					);
				}
				allAssets?.forEach((asset) => {
					let reportRow: Record<string, string> = {};
					report.columns.forEach((column) => {
						if (column.key.includes('metadata.')) {
							const metadataKey = column.key.replace('metadata.', '');
							reportRow[column.key] =
								asset?.metadata?.values && asset.metadata?.values[metadataKey]
									? Array.isArray(asset.metadata.values[metadataKey])
										? (asset.metadata.values[metadataKey] as string[]).join(',')
										: (asset.metadata.values[metadataKey] as string)
									: '';
						} else if (column.key === 'createdBy' && asset.createdBy) {
							reportRow[column.key] = `${(asset.createdBy as any)?.givenName} ${
								(asset.createdBy as any)?.familyName
							}`;
						} else if (column.key === 'fileSizeBytes') {
							reportRow[column.key] = humanReadableFileSizeSI(
								asset.fileSizeBytes
							);
						} else
							reportRow[column.key as string] = asset[
								column.key as keyof AssetVersion
							] as string;
					});
					assetRows.push(reportRow);
				});
				return { ...report, rows: assetRows };

			case 'templates':
				const templateRows: any[] = [];
				let allTemplates = all as WorkflowTemplate[];
				if (report?.filters?.length) {
					allTemplates = allTemplates?.filter((t) =>
						report?.filters?.every((filter) => filterByKeyAndObject(t, filter))
					);
				}
				allTemplates.forEach((template) => {
					let reportRow: Record<string, string> = {};
					report.columns.forEach((column) => {
						if (column.key.includes('skuMetadata.')) {
							const metadataKey = column.key.replace('metadata.', '');
							reportRow[column.key] =
								template?.skuMetadata?.values &&
								template.skuMetadata?.values[metadataKey]
									? Array.isArray(template.skuMetadata.values[metadataKey])
										? (template.skuMetadata.values[
												metadataKey
										  ] as string[]).join(',')
										: (template.skuMetadata.values[metadataKey] as string)
									: '';
						} else if (column.key.includes('metadata.')) {
							const metadataKey = column.key.replace('metadata.', '');
							reportRow[column.key] =
								template?.metadata?.values &&
								template.metadata?.values[metadataKey]
									? Array.isArray(template.metadata.values[metadataKey])
										? (template.metadata.values[metadataKey] as string[]).join(
												','
										  )
										: (template.metadata.values[metadataKey] as string)
									: '';
						} else if (column.key === 'createdBy' && template.createdBy) {
							reportRow[column.key] = `${
								(template.createdBy as User)?.givenName
							} ${(template.createdBy as User)?.familyName}`;
						} else {
							reportRow[column.key] = template[
								column.key as keyof WorkflowTemplate
							] as string;
						}
					});
					templateRows.push(reportRow);
				});
				return { ...report, rows: templateRows };

			case 'workflows':
				const workflowRows: any[] = [];
				let allWorkflows = all as Workflow[];
				if (report?.filters?.length) {
					allWorkflows = allWorkflows?.filter((wf) =>
						report?.filters?.every((filter) => filterByKeyAndObject(wf, filter))
					);
				}

				allWorkflows?.forEach((wf) => {
					let reportRow: Record<string, string> = {};
					report.columns.forEach((column) => {
						if (column.key.includes('metadata.')) {
							const metadataKey = column.key.replace('metadata.', '');
							reportRow[column.key] =
								wf?.metadata?.values && wf.metadata?.values[metadataKey]
									? Array.isArray(wf.metadata.values[metadataKey])
										? (wf.metadata.values[metadataKey] as string[]).join(',')
										: (wf.metadata.values[metadataKey] as string)
									: '';
						} else if (column.key === 'statusMsg') {
							reportRow[column.key] = wf.statusMsg ?? '';
						} else if (column.key === 'Active Stages') {
							reportRow[column.key] = flattenStages(wf as Flattenable, true)
								?.filter((a: any) => a.status === 'active')
								.map((a) => a.title)
								.join(',');
						} else if (column.key === 'createdBy') {
							const createdBy = wf?.createdBy as User;
							reportRow[
								column.key
							] = `${createdBy.givenName} ${createdBy?.familyName}`;
						} else if (column.key === 'workflowCollection') {
							reportRow[column.key] = `${
								(wf.workflowCollection as any)?.title ?? ''
							}`;
						} else if (column.key === 'skuMetadata') {
							reportRow[column.key] = wf?.skuMetadata?.rows
								? JSON.stringify(wf.skuMetadata.rows)
								: JSON.stringify([]);
						} else {
							reportRow[column.key] = !!wf[column.key as keyof Workflow]
								? (wf[column.key as keyof Workflow] as string)
								: '';
						}
					});
					workflowRows.push(reportRow);
				});
				return { ...report, rows: workflowRows };
			case 'users':
				const userRows: any[] = [];
				let allUsers = all as User[];
				if (report?.filters?.length) {
					allUsers = allUsers.filter((u) =>
						report?.filters?.every((filter) => filterByKeyAndObject(u, filter))
					);
				}
				allUsers.forEach((user) => {
					let reportRow: Record<string, string> = {};
					report.columns.forEach((column) => {
						if (column.key === 'expertise') {
							reportRow[column.key] = user.title ?? user.expertise ?? '';
						} else {
							reportRow[column.key] =
								(user[column.key as keyof User] as string) ?? '';
						}
					});
					userRows.push(reportRow);
				});
				return { ...report, rows: userRows };

			default:
				return [];
		}
	};
	return {
		saveReport,
		fetchExistingReports,
		findById,
		deleteReport,
		filterReportRows,
		updateReport,
	};
};
