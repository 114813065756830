import { ThemeColorsModel, ThemeGreysModel } from './ThemeColorsModel';
import ThemeImagesModel from './ThemeImagesModel';
import ThemeTermsModel from './ThemeTermsModel';
import { Instance, types } from 'mobx-state-tree';
export const SiteDefaultsModel = types.model('SiteDefaults', {
	_id: types.optional(types.identifier, ''),
	desktopLogo: types.optional(types.string, ''),
	mobileLogo: types.optional(types.string, ''),
	loginBackground: types.optional(types.string, ''),

	navbarBackground: types.optional(types.string, ''),
	navbarForeground: types.optional(types.string, ''),

	primary: types.optional(types.string, ''),
	secondary: types.optional(types.string, ''),
	primaryHighlighted: types.optional(types.string, ''),
	secondaryHighlighted: types.optional(types.string, ''),

	pipeline: types.optional(types.string, ''),
	focus: types.optional(types.string, ''),

	active: types.optional(types.string, ''),
	healthy: types.optional(types.string, ''),
	overdue: types.optional(types.string, ''),
	blocked: types.optional(types.string, ''),
});
export const ThemeModel = types.model('Theme', {
	_id: types.identifier,
	title: types.string,
	colors: types.optional(types.compose(ThemeGreysModel, ThemeColorsModel), {}),
	defaults: types.maybeNull(SiteDefaultsModel),
	images: types.optional(ThemeImagesModel, {}),
	terms: types.optional(ThemeTermsModel, {}),
});

export interface ThemeModel extends Infer<typeof ThemeModel> {}
export interface Theme extends Instance<ThemeModel> {}

export default ThemeModel;
