import { Uppy } from '@uppy/core';
import { AuthProvider } from './AuthProvider';

export class MultipleFileUploadProvider {
	private readonly endpoint: string;
	public readonly uppy: Uppy;

	constructor(endpoint: string, private readonly authProvider: AuthProvider) {
		this.endpoint = endpoint + '/uploads';

		this.uppy = new Uppy({
			id: 'rome-assets',
			meta: { type: 'file' },
			restrictions: { maxNumberOfFiles: 10 },
			allowMultipleUploads: true,
			autoProceed: true,
		});

		this.uppy.on('complete', (result) => {});
	}
}
