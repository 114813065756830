import React from 'react';
import { Workflow, Stage } from '../../types/workflow.types';
import { SecondaryButton } from '../../../components/buttons.styled-components';
import { useModalCloser, useModalCreator } from '../../../stores/ModalStack';
import ConfirmationDialog from '../../../components/modals/confirmation-dialog.component';
import { IconWithRightMargin } from '../action-button.styled-components';
import { faStepBackward } from '@fortawesome/free-solid-svg-icons';
import { NotificationsContext } from '../../../notifications';
import LoggingProvider, { LogLevel } from '../../../core/LoggingProvider';
import { useWorkflowContext } from '../../models/useWorkflowStore';
import { EventWithValue } from '../../types';
import { Input } from 'reactstrap';

interface Props {
	stage: Stage;
	workflow: Workflow;
}

export const RevertStageDialog = (props: Props) => {
	const { info, error: showError } = React.useContext(NotificationsContext);
	const logger = new LoggingProvider();
	const modalCloser = useModalCloser();
	const [message, setUserMessage] = React.useState('');
	const { revertToStage } = useWorkflowContext();
	const onRevert = async () => {
		try {
			await revertToStage(props.stage._id, props.workflow._id);
			info('Successfully reverted stage: ' + props.stage.title);
		} catch (err) {
			logger.logToCloudWatch(
				`Error reverting stage! ${err?.stack} ${{ ...err }}`,
				LogLevel.error,
				{ e: err }
			);
			showError('Failed to revert stage. Please try again later');
		}
	};

	return (
		<ConfirmationDialog
			header={`Revert ${props.stage.title}`}
			confirmText="Revert stage"
			onConfirm={onRevert}
			onCancel={() => {
				modalCloser.closeModal();
				return;
			}}
		>
			<p className="py-3">
				Reverting the stage will update the current stages status. Are you sure
				you want to revert the stage?
			</p>
			<Input
				type="textarea"
				label="Message"
				name="message"
				form="form"
				defaultValue={message}
				onChange={(e: EventWithValue<string>) => setUserMessage(e.target.value)}
				className={'form-control'}
			/>
		</ConfirmationDialog>
	);
};

export const RevertStageButton = (props: Props) => {
	const modalStack = useModalCreator();

	const showModal = () =>
		modalStack.addModal(
			<RevertStageDialog workflow={props.workflow} stage={props.stage} />
		);

	return (
		<SecondaryButton onClick={showModal}>
			<IconWithRightMargin icon={faStepBackward} />
			Revert Stage
		</SecondaryButton>
	);
};
