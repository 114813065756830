import { AlgoliaAssetVersion } from 'dam-assets/components/asset-infinite-hits.component';
import { User } from '../auth';
const aa = require('search-insights');
export default class InsightsProvider {
	private readonly provider: any;
	/**
	 * @description
	 * intializes a new instance of the logging service using AWS Secrets provided by environment variables
	 */
	constructor() {
		aa('init', {
			appId: `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
			apiKey: `${process.env.REACT_APP_ALGOLIA_API_KEY}`,
		});
		this.provider = aa;
	}

	public sendInsights(
		event: 'CLICK_HIT' | 'VIEW_HIT' | 'SEARCHED_HIT',
		currentUser: User,
		asset?: AlgoliaAssetVersion,
		searchedValue?: string,
		analyticsType?:
			| 'clickedObjectIDs'
			| 'convertedObjectIDs'
			| 'viewedObjectIDs'
	) {
		const insights = {
			eventName: event,
			index: process.env.REACT_APP_ALGOLIA_DAM_INDEX,
			metadata: asset?.metadata,
			value: searchedValue,
			objectIDs:
				!!asset && !!asset.hasOwnProperty('_id')
					? [`DAMAsset_${asset._id}`]
					: [],
			queryID: asset?.__queryID,
			userToken: currentUser._id,
		};
		this.provider(analyticsType, insights);
		console.dir(`insights sent`);
	}
}
