import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import themeStore from 'theme/models/ThemeStore';
import { SecondaryButton } from 'components/buttons.styled-components';

const { colors } = themeStore.selectedTheme;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.line {
		width: 2px;
		height: 30px;
		background: black;
	}
	.arrow {
		margin-top: -2px;

		&.stage-indicator {
			position: relative;
			left: 0px;
		}
	}

	.add-button {
		border-radius: 50%;
		background: white;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${colors.darkerGrey};
		border-radius: 50%;
		border: none;
		color: var(--white);
		cursor: pointer;
		font-size: 16px;
		height: 26px;
		width: 26px;
		justify-content: center;
		transform: scale(1.1, 1.1) translate3d(0, 0, 0);
		transition-duration: 400ms;
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
		transition: transform ease-out 200ms;

		:hover {
			box-shadow: 2px 4px 24px ${colors.grey};
			transform: scale(1.2, 1.2) translate3d(0, 0, 0);
		}
	}
`;

type Props = {
	isLastStage?: boolean;
	phaseColor?: string;
	className?: string;
	openCreateStageModal: () => void;
	editable?: boolean;
	title?: string;
};

export const AddStageButton = (props: Props) => {
	const { editable } = props;

	return (
		<Wrapper className={props.className}>
			{editable && <div className="line" />}
			{editable && (
				<SecondaryButton onClick={props.openCreateStageModal}>
					{!props.title &&
						(props.isLastStage
							? 'Add Next Stage'
							: !props.title && 'Insert New Stage')}
					{!!props.title && props.title}
				</SecondaryButton>
			)}
			{!props.isLastStage && (
				<>
					<div className="line" />
					<FontAwesomeIcon
						icon={faArrowDown}
						className="arrow stage-indicator"
					/>
				</>
			)}
		</Wrapper>
	);
};
