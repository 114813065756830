import { Form, FormFeedback } from 'reactstrap';
import React, { FormEvent, useCallback, useState } from 'react';

import { _logError } from '../../../common/log';
import { SubmitButton } from '../../../components/forms';
import { useModalCloser } from '../../../stores/ModalStack';
import DownshiftMultiSelect, {
	DownshiftMultiSelectProps,
} from '../../../components/downshift-select/downshift-multi-select.component';

import { ShareDialogComponentProps } from './share-dialog-body.component';
import { useAuthContext, User } from '../../../auth';
import { useAssetHelper } from '../helpers/useAssetHelper';

const SelectUsersForm = (props: ShareDialogComponentProps) => {
	const { link } = props;
	const assetStore = useAssetHelper();
	const modalStack = useModalCloser();
	const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
	const [errorMessage, setErrorMessage] = useState('');
	const { entities: users } = useAuthContext();

	const selectUser = useCallback((user: Nullable<User>) => {
		if (user) {
			setSelectedUsers((selectedUsers) => selectedUsers.concat(user));
		}
	}, []);

	const deselectUser = useCallback((user: User) => {
		setSelectedUsers((selectedUsers) =>
			selectedUsers.filter((u) => u._id !== user._id)
		);
	}, []);

	const notifyUsers = async (event: FormEvent) => {
		event.preventDefault();

		// validation
		if (!selectedUsers.length) {
			setErrorMessage('Please ensure to select one or more users.');
			return;
		}

		// clear error message
		setErrorMessage('');

		// call api
		try {
			selectedUsers.forEach(async (user) => {
				await assetStore.shareAssetToEmail(link, user.email);
			});
			if (props.onSuccess) {
				props.onSuccess();
			}
		} catch (error) {
			_logError(error);

			error(
				`An issue occurred, unable to notify users are this time. Please try again later.`
			);

			modalStack.closeModal();
		}

		return false;
	};

	const doesUserMatch = (term: Nullable<string>, user: User) => {
		return (term?.toLowerCase().includes(user.givenName.toLowerCase()) ||
			term?.toLowerCase().includes(user.familyName.toLowerCase())) as boolean;
	};

	const selectProps: DownshiftMultiSelectProps<User, User> = {
		label: 'Users',
		selectionState: {
			selection: selectedUsers,
			options: users?.filter(
				(user) => !selectedUsers?.some((m) => m._id === user._id)
			),
			searchPredicate: doesUserMatch,
		},
		selectionActions: {
			select: selectUser,
			unselect: deselectUser,
		},
	};

	return (
		<Form>
			<DownshiftMultiSelect {...selectProps} />
			{errorMessage ? (
				<FormFeedback invalid="true" className="d-block">
					{errorMessage}
				</FormFeedback>
			) : null}
			<SubmitButton onClick={notifyUsers} label="Notify users" />
		</Form>
	);
};

export default SelectUsersForm;
