import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Heading } from '../components/ui';
import BackLink from '../components/back-link/back-link.component';
import themeStore from '../theme/models/ThemeStore';

import AddWorkflowCollectionForm from './components/add-workflow-collection-form.component';

const workflowCollectionTerm = themeStore._.workflowCollection;

const WorkflowCollectionCreationView = () => (
	<Container>
		<Row className="justify-content-center">
			<Col lg={8}>
				<BackLink link=".." title={`${workflowCollectionTerm}s`} />
				<div className="d-flex justify-content-between">
					<Heading>Create a {workflowCollectionTerm?.toLowerCase()}</Heading>
				</div>
			</Col>
			<Col lg={8}>
				<AddWorkflowCollectionForm />
			</Col>
		</Row>
	</Container>
);

export default WorkflowCollectionCreationView;
