import { Redirect, Router } from '@reach/router';
import React, { Suspense } from 'react';
import { Col, Container, Nav, Row } from 'reactstrap';
import { FilterNavItem } from '../components/side-bar-filters/side-bar-filters-nav.styled-components';
import { Heading } from '../components/ui';
import { AdminNavLink } from './components/admin.styles';
import { RoleContext } from '../permissions/PermissionsContext';
import { EditTheme } from 'layouts/EditTheme';
import { useAuthContext } from 'auth';
import { AdminLogs } from 'layouts/AdminLogs';
// import UserGroupRolesPanel from 'permissions/components/UserGroupRolesPanel';

const NotificationsPanel = React.lazy(() =>
	import('./components/notifications/NotificationsPanel')
);
const MetadataTemplatesIndexView = React.lazy(() =>
	import('../metadata-templates/metadata-templates-index.view')
);
const PageSubheading = React.lazy(() =>
	import('../components/page-subheading.component')
);
const AccountsPanel = React.lazy(() =>
	import('./components/accounts/AccountsPanel')
);
const UsersPanel = React.lazy(() => import('./components/users/UsersPanel'));

const UserGroupRolesPanel = React.lazy(() =>
	import('permissions/components/UserGroupRolesPanel')
);

const buildNavOptions = (permissions: (prop: string) => boolean) => {
	let navOptions = [];

	if (permissions('canViewAdmin')) {
		const accountsRoute = 'accounts';
		navOptions.push({
			label: 'Accounts',
			value: accountsRoute,
			route: <AccountsPanel path={accountsRoute} key={accountsRoute} />,
		});
	}

	if (permissions('canViewAdmin')) {
		const metadataRoute = 'metadata-templates';
		navOptions.push({
			label: 'Metadata Templates',
			value: metadataRoute,
			route: (
				<MetadataTemplatesIndexView path={metadataRoute} key={metadataRoute} />
			),
		});
	}

	if (permissions('canViewAdmin')) {
		const notificationsRoute = 'push-notifications';
		navOptions.push({
			label: 'Notifications',
			value: notificationsRoute,
			route: (
				<NotificationsPanel
					path={notificationsRoute}
					key={notificationsRoute}
				/>
			),
		});
	}

	if (permissions('canViewAdmin')) {
		const themesRoute = 'themes';
		navOptions.push({
			label: 'Themes',
			value: themesRoute,
			route: <EditTheme key={themesRoute} path={themesRoute} />,
		});
	}

	if (permissions('canViewAdmin')) {
		const usersRoute = 'users';
		navOptions.push({
			label: 'Users',
			value: usersRoute,
			route: <UsersPanel path={usersRoute} key={usersRoute} default />,
		});
	}

	// new combined user roles & groups permissions
	if (permissions('canViewAdmin')) {
		const userGroupRolesRoute = 'usergrouproles';
		navOptions.push({
			label: 'User Permissions',
			value: userGroupRolesRoute,
			route: (
				<UserGroupRolesPanel
					path={userGroupRolesRoute}
					key={userGroupRolesRoute}
				/>
			),
		});
	}

	if (permissions('canViewAdmin')) {
		const logRoute = 'logs';
		navOptions.push({
			label: 'Logs',
			value: logRoute,
			route: <AdminLogs path={logRoute} key={logRoute} />,
		});
	}

	return navOptions;
};

const AdminDashboardView = () => {
	const permissions = React.useContext(RoleContext);
	const isSelected = (link: string) => {
		return window.location.pathname.includes(link) ? 'selected' : '';
	};
	const { currentUser } = useAuthContext();
	const navOptions = buildNavOptions(permissions.canViewByRole);
	if (!navOptions.length && permissions.roles.length && !!currentUser?._id)
		return <Redirect to="/admin" noThrow />;
	return (
		<Suspense fallback={<></>}>
			<Container fluid>
				<Row>
					<Col md={2}>
						<PageSubheading text="Dashboard" />
						<Heading>Admin</Heading>
						<Nav vertical className="mt-3">
							{navOptions.map(({ label, value }) => (
								<FilterNavItem key={value}>
									<AdminNavLink
										to={`./${value}`}
										className={isSelected(`${value}`)}
									>
										{label}
									</AdminNavLink>
								</FilterNavItem>
							))}
						</Nav>
					</Col>

					<Col md={10}>
						<Router primary={false}>
							<UsersPanel path={'registered'} key={'registrations'} />
							{navOptions.map((o) => o.route)}
						</Router>
					</Col>
				</Row>
			</Container>
		</Suspense>
	);
};

export default AdminDashboardView;
