import {
	faBackward,
	faEnvelope,
	faEnvelopeOpen,
	faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from 'auth';
import { generateID } from 'common';
import {
	getItemsOnPage,
	getTotalPages,
	Loading,
	PaginationContainer,
	ScrollCard,
	ScrollTable,
	searchObject,
} from 'components';
import {
	FileShareAuditDto,
	FileShareInstance,
	useFileShare,
} from 'file-sharing/hooks/useFileShare';
import {
	CustomFontAwesomeIcon,
	FileSharingInboxTbody,
} from 'file-sharing/styled';
import { orderBy } from 'lodash';
import windowModel from 'models/WindowModel';
import { NotificationsContext } from 'notifications';
import React from 'react';
import Moment from 'react-moment';
import { Alert, Badge, Input, UncontrolledTooltip } from 'reactstrap';
import './file-sharing.css';

const FileSharingInbox = () => {
	// state
	const [currentPage, setCurrentPage] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState('');
	const token = localStorage.getItem('rome_auth');
	const [recieved, setRecieved] = React.useState<FileShareInstance[]>();
	const [audits, setAudits] = React.useState<FileShareAuditDto[]>();
	const [loading, setLoading] = React.useState(false);
	// hooks
	const { info, error: showError } = React.useContext(NotificationsContext);
	const { currentUser } = useAuthContext();
	const {
		retrieveShareData,
		downloadFileShareFile,
		audit: auditEvent,
	} = useFileShare();

	const hasToken = React.useMemo(() => {
		if (token && JSON.parse(token) !== null) {
			return !!JSON.parse(token).accessToken ? true : false;
		}
	}, [token]);

	React.useEffect(() => {
		if (hasToken && !recieved) {
			setLoading(true);
			retrieveShareData()
				.then((shareData) => {
					setAudits(shareData?.audits);
					setRecieved(shareData?.received);
				})
				.finally(() => setLoading(false));
		}
	}, [hasToken, recieved, retrieveShareData]);

	// helpers
	const getRecipient = (recipients: Array<{ email: string; _id: string }>) => {
		return (
			recipients.find((a) => a.email === currentUser.email) ??
			recipients.find((a) => a._id === currentUser._id)
		);
	};
	const getRecipientLink = (fileShareId: string, recipients: any[]) => {
		const recipient = getRecipient(recipients);
		return `/filesharing/${fileShareId}/${recipient?._id}`;
	};

	const hasViewed = (fileShareId: string) => {
		return audits?.some(
			(audit) => audit.action === 'view' && audit.file_share_id === fileShareId
		);
	};

	const downloadFor = async (data: FileShareInstance) => {
		if (getIsExpired(data) || data.recalled) return;
		try {
			const auditDownloadFor = async (file: {
				preview: string;
				_id: string;
				metadata: any;
			}) => {
				const auditDto: FileShareAuditDto = {
					_id: generateID(),
					action: 'download',
					createdAt: new Date(),
					createdBy: currentUser._id,
					file_share_id: data._id,
					preview: file.preview,
					file_id: file._id,
					metadata: file.metadata,
					recipient_email: getRecipient(data.recipients)?.email as string,
					recipient_id: getRecipient(data.recipients)?._id as string,
				};
				await auditEvent(auditDto);
			};
			data.attachments.forEach(async (attached) => {
				await auditDownloadFor(attached);
				const downloadURL = (await downloadFileShareFile(
					attached._id
				)) as string;
				windowModel.openInNewWindow(downloadURL, attached._id);
			});
			info('Downloaded file share files successfully');
		} catch (err) {
			console.error('FAILED TO DOWNLOAD FILE SHARE FILES ' + err);
			showError(
				'An error occurred while downloading your files. Please try again later.'
			);
		}
	};

	const getIsExpired = (fileShareData: FileShareInstance) => {
		if (fileShareData) {
			const now = new Date();
			return now >= new Date(fileShareData?.validUntil);
		}
	};

	const getRecievedDate = (fileShareData: FileShareInstance) => {
		const received = fileShareData.sent;

		if (received) return <Moment date={received} format="llll" />;
		return '';
	};

	const searchInbox = (searchTerm: string) => {
		return recieved?.filter((inboxItem) => searchObject(inboxItem, searchTerm));
	};

	// markup
	return loading ? (
		<Loading label="Loading..." alignItems="center" justifyContent="center" />
	) : (
		<ScrollCard>
			<ScrollTable style={{ fontSize: 14 }} className="listing-table">
				<thead>
					<tr>
						<th>SUBJECT</th>
						<th>FROM</th>
						<th>RECEIVED</th>
						<th>EXPIRES</th>
						<th>STATUS</th>
						<th>ACTIONS</th>
					</tr>
				</thead>
				<FileSharingInboxTbody>
					<tr>
						<td colSpan={6}>
							<Input
								onChange={(e) => {
									setSearchTerm(e.target.value);
									setCurrentPage(1);
								}}
								type="text"
								className="form-control"
								placeholder="Search inbox..."
							/>
						</td>
					</tr>
					{getItemsOnPage(
						orderBy(searchInbox(searchTerm)!, (r) => r.sent, 'desc'),
						currentPage
					)?.map((data) => (
						<tr key={data._id}>
							<td>
								<a
									href={getRecipientLink(data._id, data.recipients)}
									target={data._id}
									onClick={() =>
										setAudits([
											...(audits || []),
											{
												_id: generateID(),
												action: 'view',
												file_share_id: data._id,
												createdAt: new Date(),
												createdBy: currentUser._id,
												file_id: '',
												preview: '',
												recipient_email: '',
												recipient_id: currentUser?._id,
											},
										])
									}
								>
									{data.subject ? data.subject : 'No Subject'}
								</a>
							</td>
							<td>
								{data.createdBy.givenName} {data.createdBy.familyName}
							</td>
							<td>{getRecievedDate(data)}</td>
							<td>
								<Moment
									format={'llll'}
									className={getIsExpired(data) ? 'inactive' : ''}
								>
									{data?.validUntil}
								</Moment>
							</td>
							<td>
								{data.recalled && (
									<Badge style={{ color: '#fff' }} color={'warning'}>
										RECALLED
										<FontAwesomeIcon
											style={{ color: '#fff' }}
											className="ml-2"
											icon={faBackward}
										/>
									</Badge>
								)}
								{data.recalled === false && (
									<Badge
										style={{ color: `#fff` }}
										color={hasViewed(data._id) ? 'success' : 'warning'}
									>
										{hasViewed(data._id) ? 'READ' : 'UNREAD'}
										<FontAwesomeIcon
											style={{ color: '#fff' }}
											className="ml-2"
											icon={hasViewed(data._id) ? faEnvelopeOpen : faEnvelope}
										/>
									</Badge>
								)}
								{getIsExpired(data) && <Badge color={'danger'}>EXPIRED</Badge>}
							</td>
							<td>
								<div className="d-flex align-items-center">
									<UncontrolledTooltip target={`download${data._id}`}>
										{getIsExpired(data) || data.recalled
											? `Cannot download files that have ${
													data.recalled ? 'been recalled' : 'expired'
											  }.`
											: `Download shared files`}
									</UncontrolledTooltip>
									<span
										id={`download${data._id}`}
										className="action"
										onClick={async () => await downloadFor(data)}
									>
										<CustomFontAwesomeIcon
											className={getIsExpired(data) ? 'inactive' : ''}
											icon={faFileDownload}
										/>
									</span>
								</div>
							</td>
						</tr>
					))}
					{!recieved?.length && (
						<tr>
							<td colSpan={6}>
								<Alert color="warning">No files received to display.</Alert>
							</td>
						</tr>
					)}
				</FileSharingInboxTbody>
			</ScrollTable>
			{!!recieved && (
				<div className="d-flex justify-content-center">
					<PaginationContainer
						totalPages={getTotalPages(searchInbox(searchTerm)!) as number}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</div>
			)}
		</ScrollCard>
	);
};

export default FileSharingInbox;
