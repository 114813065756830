import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';
import { Heading, PageSubheading } from 'components';
import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Card, CardBody, CardHeader } from 'reactstrap';
import './RMCarousel.scss';

type CarouselItem = {
	title: string;
	count: string;
	active: boolean;
};

export const RMCarousel = ({ items }: { items: CarouselItem[] }) => {
	const { defaults } = useThemeContext();

	return (
		<Card className="h-100 rome-card card__resource-expertise">
			<CardHeader>
				<PageSubheading text="Resource Expertise" />
				<FontAwesomeIcon
					className="header-icon"
					style={{ color: defaults?.primary }}
					icon={faLightbulb}
				/>
			</CardHeader>
			<CardBody>
				<Carousel
					enableAutoPlay={false}
					itemsToShow={1}
					disableArrowsOnEnd={true}
					isRTL={false}
					breakPoints={[
						{
							width: 1,
							itemsToShow: 1,
							itemsToScroll: 1,
						},
						{
							width: 480,
							itemsToShow: 2,
						},
						{
							width: 640,
							itemsToShow: 3,
						},
					]}
				>
					{items.map((item) => (
						<div key={item?.title}>
							<div className="rec rec-carousel-item">
								<Heading>{item?.title}</Heading>
								<PageSubheading text={'TOTAL ' + item?.count} />
							</div>
						</div>
					))}
				</Carousel>
			</CardBody>
			{/* <CardFooter></CardFooter> */}
		</Card>
	);
};
