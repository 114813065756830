import {
	faCloudUploadAlt,
	faCopy,
	faDownload,
	faPlusSquare,
	faShare,
	faTimes,
	faTrash,
	faFileImage,
	faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { buildClassList } from '../../common/index';
import { useModalCreator } from '../../stores/ModalStack';
import { useViewer } from '../services/viewer.service';
import CompareAssetsDialog from './compare-assets-dialog.component';

import { NotificationsContext } from '../../notifications';
import ShareMultipleAssetsDialog from './share-dialogs/share-multiple-assets-dialog.component';
import ShareSingleAssetDialog from './share-dialogs/share-single-asset-dialog.component';
import themeStore from '../../theme/models/ThemeStore';
import DropDownToggleMenu from '../../components/forms/dropdown-menu.component';
import AddAssetToCollectionDialog from '../../dam-asset-collections/components/add-asset-collection-dialog.component';
import { AssetVersion } from '../../workflows/types/workflow.types';
import { useAssetHelper } from './helpers/useAssetHelper';
import windowModel from 'models/WindowModel';
import { RomeSwal } from 'components/alert';
import { useRoleContext } from 'permissions/PermissionsContext';
import { useSiteSettings } from 'hooks/useSiteSettings';

const { colors } = themeStore.selectedTheme;
const assetTerm = themeStore._.asset.toLowerCase();
const pluralAssetsTerm = `${assetTerm}s`;

export enum AssetSelectionTool {
	VIEW = 'view',
	COMPARE = 'compare',
	DOWNLOAD = 'download',
	SELECTION = 'selection',
	SHARE = 'share',
	TRASH = 'trash',
}

export const defaultAssetSelectionTools = [
	AssetSelectionTool.VIEW,
	AssetSelectionTool.COMPARE,
	AssetSelectionTool.DOWNLOAD,
	AssetSelectionTool.SHARE,
	AssetSelectionTool.TRASH,
];

// interfaces
export interface AssetSelectionToolbarClickEvent {
	tool: AssetSelectionTool;
	value?: any;
}

interface Props {
	tools: readonly AssetSelectionTool[];
	onDelete?: (assetVersions: AssetVersion[]) => void;
	className?: string;
	onClick?: (event: AssetSelectionToolbarClickEvent) => void;
	selectedAssets: AssetVersion[];
	dispatchCurrentSort: React.Dispatch<{
		type: 'asc' | 'desc';
		payload: 'fileName' | 'createdAt' | 'fileSizeBytes';
	}>;
}

// styled components
export const Toolbar = styled.div`
	display: inline-flex;
`;

// TODO: extract to reusable component (see also IndexViewToggler)
export const ToolbarButton = styled.button`
	background: transparent;
	border-radius: 3px;
	border: none;
	color: var(--darkGrey);
	margin-left: 6px;

	&.active {
		color: ${colors.darkestGrey};
	}

	&:hover {
		color: ${colors.darkestGrey};
	}
`;

// component
const AssetSelectionToolbar = (props: Props) => {
	const { error, info } = React.useContext(NotificationsContext);
	const { downloadAssetFile, deleteOne, zipMultiple } = useAssetHelper();
	const permissions = useRoleContext();
	const {
		tools,
		className,
		onClick,
		selectedAssets,
		onDelete,
		dispatchCurrentSort,
	} = props;
	const [currentSort, setCurrentSort] = useState<{
		fileName: 'A-z' | 'Z-a';
		createdAt: 'A-z' | 'Z-a';
		fileSizeBytes: 'A-z' | 'Z-a';
	}>({ fileName: 'A-z', createdAt: 'A-z', fileSizeBytes: 'A-z' });

	// state actions
	const [isDownloading, setisDownloading] = useState(false);
	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const [isSharing, setIsSharing] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const appSettings = useSiteSettings();
	const { settings: siteSettings } = appSettings;
	const modalCreator = useModalCreator();
	const assetViewer = useViewer();

	// permission methods
	const isAddToCollectionToolAvailable = () => {
		return (
			['damReadOnly', 'damEditable'].some(permissions.canViewByRole) &&
			isSelectingAssets &&
			selectedAssets.length > 0
		);
	};

	const isViewToolAvailable = () => {
		return (
			['damReadOnly', 'damEditable'].some(permissions.canViewByRole) &&
			!!siteSettings['assetSelectionToolbar.view' as keyof Object] &&
			tools.includes(AssetSelectionTool.VIEW) &&
			selectedAssets.length === 1 &&
			isSelectingAssets
		);
	};

	const isCompareToolAvailable = () => {
		return (
			['damReadOnly', 'damEditable'].some(permissions.canViewByRole) &&
			siteSettings['assetSelectionToolbar.compare' as keyof Object] &&
			tools.includes(AssetSelectionTool.COMPARE) &&
			selectedAssets.length === 2 &&
			isSelectingAssets
		);
	};

	const isDownloadToolAvailable = () => {
		// tool not available in props => quick exit
		if (!tools.includes(AssetSelectionTool.DOWNLOAD)) {
			return false;
		}

		if (isSelectingAssets) {
			if (selectedAssets.length === 1) {
				// downloading single asset (prevent falsy)
				return (
					['damReadOnly', 'damEditable'].some(permissions.canViewByRole) &&
					siteSettings['assetSelectionToolbar.downloadSingle' as keyof Object]
				);
			} else if (selectedAssets.length > 1) {
				// downloading multiple assets (prevent falsy)
				return siteSettings[
					'assetSelectionToolbar.downloadMultiple' as keyof Object
				];
			}
			return false;
		} else {
			return false;
		}
	};

	const isShareToolAvailable = () => {
		// tool not available in props => quick exit
		if (!tools.includes(AssetSelectionTool.SHARE)) {
			return false;
		}

		if (isSelectingAssets) {
			if (selectedAssets.length === 1) {
				// sharing single asset (prevent falsy)
				return siteSettings[
					'assetSelectionToolbar.shareSingle' as keyof Object
				];
			} else if (selectedAssets.length > 1) {
				// sharing multiple assets (prevent falsy)
				return siteSettings[
					'assetSelectionToolbar.shareMultiple' as keyof Object
				];
			}
			return false;
		} else {
			return false;
		}
	};

	const isTrashToolAvailable = () => {
		return (
			['damEditable'].some(permissions.canViewByRole) &&
			siteSettings['assetSelectionToolbar.trash' as keyof Object] &&
			tools.includes(AssetSelectionTool.TRASH) &&
			selectedAssets.length &&
			isSelectingAssets
		);
	};

	// callbacks
	const dispatchClick = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (onClick) {
				onClick(event);
			}
		},
		[onClick]
	);

	const toggleSelectingState = useCallback(
		(isSelecting: boolean) => {
			setSelectingAssets(isSelecting);
			dispatchClick({
				tool: AssetSelectionTool.SELECTION,
				value: isSelecting,
			});
		},
		[dispatchClick, setSelectingAssets]
	);

	const dropdownItems = [
		{ label: 'A - Z', value: 'fileName-asc' },
		{ label: 'Z - A', value: 'fileName-desc' },
		{ label: 'Newest - Oldest', value: 'createdAt-desc' },
		{ label: 'Oldest - Newest', value: 'createdAt-asc' },
		{ label: 'Smallest - Largest', value: 'fileSize-asc' },
		{ label: 'Largest - Smallest', value: 'fileSize-desc' },
	];

	const toggleSortAssets = (event: any) => {
		const payload = event.target.attributes.eventkey.value.includes('createdAt')
			? 'createdAt'
			: event.target.attributes.eventkey.value.includes('fileSize')
			? 'fileSizeBytes'
			: event.target.attributes.eventkey.value.includes('fileName')
			? 'fileName'
			: '';
		if (payload === '') return;
		const sortDirection = event.target.attributes.eventkey.value.includes('asc')
			? 'asc'
			: 'desc';
		let updatedSortState = currentSort;
		updatedSortState[payload] = sortDirection === 'asc' ? 'A-z' : 'Z-a';
		setCurrentSort({ ...updatedSortState });
		dispatchCurrentSort({ payload, type: sortDirection });
	};

	const showAddAssetToCollectionModal = useCallback(() => {
		modalCreator.addModal(
			<AddAssetToCollectionDialog
				toggleSelectingState={toggleSelectingState}
				assets={selectedAssets as AssetVersion[]}
			/>
		);
	}, [selectedAssets, modalCreator, toggleSelectingState]);

	const launchAssetViewer = useCallback(async () => {
		await assetViewer.viewSingle(selectedAssets[0]);
		dispatchClick({ tool: AssetSelectionTool.VIEW });
	}, [assetViewer, dispatchClick, selectedAssets]);

	const showCompareModal = useCallback(() => {
		modalCreator.addModal(<CompareAssetsDialog assets={selectedAssets} />);
		dispatchClick({ tool: AssetSelectionTool.COMPARE });
	}, [dispatchClick, modalCreator, selectedAssets]);

	const downloadAssets = useCallback(async () => {
		setisDownloading(true);
		if (selectedAssets.length === 1) {
			// download single asset
			const asset: AssetVersion = selectedAssets[0];
			await downloadAssetFile(asset).catch(() => {
				error(
					`An issue occurred while downloading the ${assetTerm}. Please try again later.`
				);
			});
		} else {
			const response = await zipMultiple(selectedAssets.map((a) => a._id));
			windowModel.openInNewWindow(response.url, 'asset_download');
		}
		setisDownloading(false);
		dispatchClick({ tool: AssetSelectionTool.DOWNLOAD });
		//eslint-disable-next-line
	}, [dispatchClick, selectedAssets, downloadAssetFile, error]);

	const showShareModal = useCallback(async () => {
		setIsSharing(true);
		if (selectedAssets.length === 1) {
			// share single asset
			const asset: AssetVersion = selectedAssets[0];
			modalCreator.addModal(<ShareSingleAssetDialog asset={asset} />);
		} else {
			// share multiple assets
			modalCreator.addModal(
				<ShareMultipleAssetsDialog assets={selectedAssets} />
			);
		}
		dispatchClick({ tool: AssetSelectionTool.SHARE });
		setIsSharing(false);
		//eslint-disable-next-line
	}, [dispatchClick, modalCreator, selectedAssets]);

	const deleteAssets = useCallback(async () => {
		setIsDeleting(true);
		// TODO: trash selected assets (RRR-224)
		const { isConfirmed } = await RomeSwal.fire({
			icon: 'question',
			title: 'Are you sure?',
			text: 'Deleting the assets cannot be reversed.',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel',
			showCancelButton: true,
		});
		if (isConfirmed) {
			selectedAssets.forEach(async (asset) => {
				await deleteOne(asset._id);
			});
			info('Successfully removed ' + selectedAssets.length + ' assets.');
			if (onDelete) onDelete(selectedAssets);
		}
		dispatchClick({ tool: AssetSelectionTool.TRASH });
		setIsDeleting(false);
		//eslint-disable-next-line
	}, [dispatchClick, selectedAssets, error]);

	const onKeyUp = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				toggleSelectingState(false);
			}

			if (event.key === 'Delete') {
				deleteAssets();
			}
		},
		//eslint-disable-next-line
		[toggleSelectingState]
	);

	useEffect(() => {
		// set up
		document.addEventListener('keyup', onKeyUp);

		return () => {
			// clean up
			document.removeEventListener('keyup', onKeyUp);
		};
	}, [onKeyUp]);

	// render methods
	const renderSelectAssetsButton = () => (
		<>
			<ToolbarButton
				id="selectAssetsButton"
				onClick={() => toggleSelectingState(true)}
			>
				<FontAwesomeIcon icon={faPlusSquare} />
			</ToolbarButton>
			<UncontrolledTooltip target="selectAssetsButton">
				Select {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderAddToCollection = () => (
		<>
			<ToolbarButton
				id="addToCollectionButton"
				onClick={showAddAssetToCollectionModal}
			>
				<FontAwesomeIcon icon={faFileImage} />
			</ToolbarButton>
			<UncontrolledTooltip target="addToCollectionButton">
				Add to collection
			</UncontrolledTooltip>
		</>
	);
	const renderDropdownToggle = () => (
		<div
			style={{
				background: 'transparent',
				borderRadius: 3,
				color: '#afafaf',
				marginLeft: 6,
			}}
		>
			<span id="sortAssetsButton">
				<img
					alt="Sort Assets"
					src={'/images/sort.svg'}
					style={{ height: '20px', filter: 'contrast(0.15)' }}
				/>
			</span>
			<UncontrolledTooltip target="sortAssetsButton">
				Sort {pluralAssetsTerm}
			</UncontrolledTooltip>
		</div>
	);
	const renderSortAssetsButton = () => (
		<>
			<DropDownToggleMenu
				items={dropdownItems}
				onClick={(e) => toggleSortAssets(e)}
				label={renderDropdownToggle()}
				direction={'down'}
			/>
		</>
	);

	const renderCancelSelectionButton = () => (
		<>
			<ToolbarButton
				id="cancelStateButton"
				onClick={() => toggleSelectingState(false)}
			>
				<FontAwesomeIcon icon={faTimes} />
			</ToolbarButton>
			<UncontrolledTooltip target="cancelStateButton">
				Cancel selecting {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderViewerButton = () => (
		<>
			<ToolbarButton id="launchViewerButton" onClick={launchAssetViewer}>
				<FontAwesomeIcon icon={faCloudUploadAlt} />
			</ToolbarButton>
			<UncontrolledTooltip target="launchViewerButton">
				Launch {assetTerm} in detailed viewer
			</UncontrolledTooltip>
		</>
	);

	const renderCompareButton = () => (
		<>
			<ToolbarButton id="compareAssetsButton" onClick={showCompareModal}>
				<FontAwesomeIcon icon={faCopy} />
			</ToolbarButton>
			<UncontrolledTooltip target="compareAssetsButton">
				Compare {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderDownloadButton = () => (
		<>
			<ToolbarButton id="downloadAssetsButton" onClick={downloadAssets}>
				<FontAwesomeIcon
					className={isDownloading ? 'fa-spin' : ''}
					icon={isDownloading ? faSpinner : faDownload}
				/>
			</ToolbarButton>
			<UncontrolledTooltip target="downloadAssetsButton">
				Download {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderShareButton = () => (
		<>
			<ToolbarButton id="shareAssetsButton" onClick={showShareModal}>
				<FontAwesomeIcon
					className={isSharing ? 'fa-spin' : ''}
					icon={isSharing ? faSpinner : faShare}
				/>
			</ToolbarButton>
			<UncontrolledTooltip target="shareAssetsButton">
				Share {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const renderTrashButton = () => (
		<>
			<ToolbarButton id="deleteAssetsButton" onClick={deleteAssets}>
				<FontAwesomeIcon
					className={isDeleting ? 'fa-spin' : ''}
					icon={isDeleting ? faSpinner : faTrash}
				/>
			</ToolbarButton>
			<UncontrolledTooltip target="deleteAssetsButton">
				Delete {pluralAssetsTerm}
			</UncontrolledTooltip>
		</>
	);

	const render = () => (
		<Toolbar className={buildClassList('assets-toolbar', className)}>
			{isViewToolAvailable() ? renderViewerButton() : null}
			{isCompareToolAvailable() ? renderCompareButton() : null}
			{isDownloadToolAvailable() ? renderDownloadButton() : null}
			{isShareToolAvailable() ? renderShareButton() : null}
			{isTrashToolAvailable() ? renderTrashButton() : null}
			{!isSelectingAssets ? renderSelectAssetsButton() : null}
			{isAddToCollectionToolAvailable() ? renderAddToCollection() : null}
			{isSelectingAssets ? renderCancelSelectionButton() : null}
			{renderSortAssetsButton()}
		</Toolbar>
	);

	return render();
};

export default AssetSelectionToolbar;
