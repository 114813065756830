import React from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import styled, { StyledComponent } from 'styled-components';

import { formatDuration } from '../../common';
import {
	ListColumnCreationOptions,
	makeColumns,
} from '../../components/list-column.component';
import OwnerAvatarList from '../../components/owner-avatar-list.component';
import StageStatusPill from '../../components/stage-status-pill.component';
import themeStore from '../../theme/models/ThemeStore';
import { Td, Tr, TrSub, TrSubChild } from './stage-table.styled-components';
import { StageType } from '../../workflow-templates/models/StageTypes';
import { StageStatus } from '../../models/StageStatusModel';
import {
	flattenStages,
	getStageDueDate,
} from '../helpers/workflowStage.helpers';
import { Workflow, Stage } from '../types/workflow.types';
import { first, isArray, random } from 'lodash';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
const { selectedTheme } = themeStore;
const StageDueDateColumn = styled.span`
	white-space: nowrap;
	font-size: 14px;
`;
const columnOptions: ListColumnCreationOptions<Stage>[] = [
	{ label: 'Name', prop: 'title' },
	{
		label: 'Status',
		prop: (s: Stage) =>
			s?.substages?.length &&
			s.substages
				?.flatMap((s) => s)
				.every((ss) => ss.status === 'completed') ? (
				<StageStatusPill status={StageStatus.completed} />
			) : (
				<StageStatusPill status={s.status as StageStatus} />
			),
	},
	{
		label: 'Duration',
		prop: (s: Stage) =>
			!s?.expectedDurationHrs
				? 'TBD'
				: formatDuration(s?.expectedDurationHrs as number),
	},
	{
		label: 'Due',
		prop: (s: Stage) => (
			<StageDueDateColumn>
				<small>{getStageDueDate(s)}</small>
			</StageDueDateColumn>
		),
	},
	{
		label: `${themeStore._.owner}(s)`,
		prop: (s: Stage) =>
			(
				<OwnerAvatarList displayNames={false} owners={s.owners} size="md" />
			) as JSX.Element,
	},
];

const stageTableColumns = makeColumns(
	columnOptions.map((c) => {
		c.cellWrapper = Td;
		return c;
	})
);

interface StageRowProps {
	stage?: Stage;
	wrapper?: StyledComponent<any, any>;
	dispatch: React.Dispatch<{ type: 'setStage'; payload: Stage }>;
}
const wrapperForStage = (stage: Stage): React.ComponentType<any> => {
	switch (stage.type) {
		case StageType.parallel:
		case StageType.single:
			return React.memo(Tr);
		case StageType.substage:
		case StageType.sideTask:
			return new Array(stage).shift() === stage
				? React.memo(TrSub)
				: React.memo(TrSubChild);
		default:
			return React.memo(Tr);
	}
};

const StageRow = ({ stage, dispatch }: StageRowProps) => {
	const {
		stage: selectedStage,
		setStage: setSelectedStage,
	} = useWorkflowContext();
	const stageToRender = isArray(stage)
		? (first(stage) as Stage)
		: (stage as Stage);

	const Wrapper = wrapperForStage(stage as Stage);
	return (
		<Wrapper
			id={stageToRender._id}
			onClick={() => {
				setSelectedStage(stageToRender as Stage);
				dispatch({ type: 'setStage', payload: stageToRender });
			}}
			className={`${
				stageToRender?._id === (selectedStage as Stage)?._id ? 'selected' : ''
			} ${
				isArray(stage) && stageToRender === first(stage) ? 'parallel-stage' : ''
			}`}
		>
			{stageTableColumns.map((c) => c.render(stageToRender as Stage))}
		</Wrapper>
	);
};

// component
const StageTable = React.memo(
	({
		dispatch,
	}: {
		dispatch: React.Dispatch<{ type: 'setStage'; payload: Stage }>;
	}) => {
		const { workflow } = useWorkflowContext();
		const rows = (
			<tbody>
				{flattenStages(workflow as Workflow).map((stage: Stage) => (
					<StageRow
						key={`stage_${random(1111)}${stage?._id}`}
						dispatch={dispatch}
						stage={stage}
					/>
				))}
			</tbody>
		);
		const sideTaskRows = workflow?.sideTasks?.map((sideTask) => (
			<tbody
				key={`stage_${random(1111)}${sideTask?._id}`}
				id={sideTask._id}
				className="sidetask-body border-0"
			>
				<StageRow dispatch={dispatch} stage={sideTask} />
			</tbody>
		));

		return (
			<Card className="h-100">
				<CardHeader>
					<h2 className="mb-0">{selectedTheme.terms.stage} Overview</h2>
				</CardHeader>
				<Table className="mb-0">
					<thead>
						<tr>{stageTableColumns.map((c) => c.renderHeader())}</tr>
					</thead>
					{rows}
					{!!sideTaskRows?.length && (
						<thead>
							<tr>
								<th colSpan={5}>Side Tasks</th>
							</tr>
						</thead>
					)}
					{sideTaskRows}
				</Table>
			</Card>
		);
	}
);

export default StageTable;
