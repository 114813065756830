import React, { useState } from 'react';
import { ModalBody } from 'reactstrap';
import { SelectedMetadata } from '../../../dam-assets/asset.contexts';
import { AssetUploadForm } from '../../../dam-assets/components/asset-upload-form.component';
import DialogModal from '../../../components/modals/dialog-modal.component';
import { useModalCloser } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';
import { SelectedInputSlot } from '../../workflow.contexts';
import { InputSlot, Stage, Workflow } from '../../types/workflow.types';
import { emptyMetadataTemplate } from '../../helpers/workflowStage.helpers';
import { clone } from 'lodash';

export interface UploadAssetDialogProps {
	stage: Stage;
	selectedSlot?: InputSlot;
	id?: string;
	workflow: Workflow;
}

const UploadStageAssetDialog = (props: UploadAssetDialogProps) => {
	const { stage, selectedSlot, workflow } = props;
	const modalStack = useModalCloser();

	const [slot, setSlot] = useState<InputSlot>(selectedSlot as InputSlot);
	const emptyMetadata = emptyMetadataTemplate();

	const metadata = clone((workflow as Workflow)?.metadata ?? emptyMetadata);

	return (
		<DialogModal
			header={`Upload an ${themeStore._.asset.toLowerCase()} to "${
				stage.title
			}"`}
			modalSize="lg"
		>
			<SelectedInputSlot.Provider value={[slot, setSlot]}>
				<SelectedMetadata.Provider
					value={SelectedMetadata.asReadonly(metadata)}
				>
					<ModalBody>
						<AssetUploadForm
							workflow={workflow as Workflow}
							stage={stage}
							slot={slot}
							afterSubmit={modalStack.closeModal}
						/>
					</ModalBody>
				</SelectedMetadata.Provider>
			</SelectedInputSlot.Provider>
		</DialogModal>
	);
};

export default UploadStageAssetDialog;
