import React from 'react';
import styled from 'styled-components';
import { UseFormMethods } from 'react-hook-form';
import { ModalProps, Modal } from './Modal';
import {
	DangerButton,
	SecondaryButton as SecondaryButtonBase,
} from '../../buttons.styled-components';

type Props = {
	onSubmit: (data: any) => void;
	onCancel?: () => void;
	form: UseFormMethods;
	submitText?: string;
	cancelText?: string;
} & ModalProps;

const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 15px;
`;

const SecondaryButton = styled<any>(SecondaryButtonBase)`
	margin-right: 8px;
`;

export const FormModal = ({
	isOpen,
	onSubmit,
	onClose,
	form,
	title,
	...rest
}: Props) => {
	const { submitText, cancelText, children, onCancel, ...props } = rest;
	return (
		<Modal isOpen={isOpen} onClose={onClose} title={title} {...props}>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				{children}

				<ActionButtons>
					<SecondaryButton type="submit">
						{submitText ? submitText : 'Submit'}
					</SecondaryButton>
					<DangerButton type="button" onClick={onCancel ?? onClose}>
						{cancelText ? cancelText : 'Cancel'}
					</DangerButton>
				</ActionButtons>
			</form>
		</Modal>
	);
};
