import styled from 'styled-components';

import { fsNormal } from '../../theme/ui/typography';
import themeStore from '../../theme/models/ThemeStore';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const Td = styled.td`
	${fsNormal};
	border-top: none !important;
	border-bottom: 1px solid #dbdbdb;
	height: 60px !important;
	vertical-align: middle !important;
`;

export const Tr = styled.tr`
	&:hover {
		background-color: #ebebeb;
		cursor: pointer;
		transition: background 0.2s linear;
	}

	&.parallel-stage {
		> td {
			&:first-child {
				border-left: 8px solid #afafaf;
			}
		}
	}

	&.selected {
		background-color: #eee;

		&::after {
			position: absolute;
			border-bottom: 20px solid transparent;
			border-right: 26px solid #ffffff;
			border-top: 20px solid transparent;
			clear: both;
			content: '';
			height: 0;
			margin-top: 14px;
			right: -32px;
			width: 0;
		}

		@media (max-width: 992px) {
			&::after {
				border-top: none;
				border-bottom: none;
				border-right: none;
			}
		}
	}
`;

export const TrSub = styled.tr`
	border-bottom: none;
	background-color: #f5f5f5;
	&:hover {
		background-color: #ebebeb;
		cursor: pointer;
		transition: background 0.2s linear;
	}
	&:last-child {
		> td {
			border-bottom: 1px solid #dbdbdb;
		}
	}

	> td {
		border-bottom: none;

		&:first-child {
			border-left: 8px solid #afafaf;
			padding-left: 2.5em;
			position: relative;

			&:before {
				content: '↳ ';
				color: ${defaults?.secondaryHighlighted};
				position: absolute;
				top: 15px;
				left: 15px;
				font-size: 20px;
			}
		}
	}
`;

export const TrSubChild = styled.tr`
	background-color: var(--white);
	&:hover {
		background-color: ${colors.lightGrey};
		cursor: pointer;
		transition: background 0.2s linear;
	}
	> td:first-child {
		padding-left: 4em;

		&:before {
			left: 35px;
		}
	}
`;
