import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { Subheading } from '../../../components/ui';
import { useModalCreator } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';
import { Workflow } from '../../types/workflow.types';
import UploadStageAssetDialog, {
	UploadAssetDialogProps,
} from '../stage-actions/upload-stage-asset-dialog.component';
import {
	Hr,
	InputSlot,
	InputSlotContainer,
} from './workflow-stage-card.styled-components';
interface StageInputSlotsProps {
	id: string;
	workflow: Workflow;
}
const StageInputSlots = (props: StageInputSlotsProps) => {
	const { stage, workflow } = useWorkflowContext();
	const memoStage = React.useMemo(() => {
		return stage;
	}, [stage]);
	const modalStack = useModalCreator();
	const openAssetUploadModal = useCallback(
		({ stage, selectedSlot }: UploadAssetDialogProps) => {
			if (stage?.inputSlots && selectedSlot) {
				modalStack.addModal(
					<UploadStageAssetDialog
						workflow={workflow ?? props.workflow}
						id={props.id}
						stage={stage}
						selectedSlot={selectedSlot}
					/>
				);
			}
		},
		[modalStack, props.id, props.workflow, workflow]
	);

	if (memoStage && memoStage?.inputSlots?.length) {
		return (
			<div className="mt-4">
				<Subheading>Required {themeStore._.asset}s</Subheading>

				<InputSlotContainer>
					{memoStage?.inputSlots.map((slot: any) => (
						<InputSlot
							key={slot._id}
							className={slot.versions.length ? 'fulfilled' : 'missing'}
							onClick={() =>
								openAssetUploadModal({
									stage: memoStage,
									selectedSlot: slot,
									workflow: workflow ?? props.workflow,
								})
							}
						>
							<div>
								<FontAwesomeIcon
									icon={slot.versions.length ? faCheck : faPlus}
								/>
								<p>{slot.label}</p>
							</div>
						</InputSlot>
					))}
				</InputSlotContainer>
				<Hr />
			</div>
		);
	} else return null;
};

export default StageInputSlots;
