import React from 'react';
import { Card, Col } from 'reactstrap';
import OwnerAvatarList from '../../components/owner-avatar-list.component';
import { SmallTextMuted } from '../../components/ui';
import themeStore from '../../theme/models/ThemeStore';
import {
	BaseWorkflowOwner,
	WorkflowCollection,
} from '../../workflows/types/workflow.types';

import {
	CollectionCardBody,
	CollectionCardBodyHeader,
	CollectionTitle,
	Link,
} from './workflow-collection-card.styled-components';

// interface
interface CollectionCardProps {
	collection: WorkflowCollection;
}

// component
const WorkflowCollectionCard = ({ collection }: CollectionCardProps) => {
	const workflowCount = collection.workflows.length;

	return (
		<Col lg={4} sm={6} className="mt-2 mb-3">
			<Link to={`./${collection._id}`}>
				<Card>
					<CollectionCardBody>
						<CollectionCardBodyHeader>
							<CollectionTitle>{collection.title}</CollectionTitle>
							<OwnerAvatarList
								displayNames={true}
								owners={(collection.owners as any) as BaseWorkflowOwner[]}
							/>
						</CollectionCardBodyHeader>
						<SmallTextMuted>
							{workflowCount} {themeStore._.workflow.toLowerCase()}
							{workflowCount === 1 ? '' : 's'}
						</SmallTextMuted>
					</CollectionCardBody>
				</Card>
			</Link>
		</Col>
	);
};

export default WorkflowCollectionCard;
