import React from 'react';
import { BaseWorkflowOwner } from '../../workflows/types/workflow.types';
import { useAuthContext, User } from '../../auth';
import { UserGroup } from '../../accounts/types';
import { useGroupContext } from 'auth';
import DownshiftMultiSelect, {
	DownshiftMultiSelectProps,
} from 'components/downshift-select/downshift-multi-select.component';
import { property } from 'lodash';
import styled from 'styled-components';
const OwnerSelectWrapper = styled.div`
	div[role='combobox'] {
		max-height: 500px;
		overflow-y: auto;
	}
`;
export const BaseOwnerSelect = ({
	owners,
	onChange,
	label,
}: {
	owners?: BaseWorkflowOwner[];
	onChange: React.Dispatch<{ type: 'update'; payload: BaseWorkflowOwner[] }>;
	label?: string;
}) => {
	const { entities: allUsers } = useAuthContext();
	const { entities: allGroups } = useGroupContext();

	const [edited, setEdited] = React.useState(owners);
	const addOwner = React.useCallback(
		(owner: User | UserGroup) => {
			const isGroupOwner = allGroups.some((m) => m._id === owner._id);
			if (isGroupOwner) {
				setEdited((edited) =>
					edited && edited?.length
						? [
								...edited?.filter((a) => a._id !== owner._id),
								{ type: 'AccountGroup', _id: owner._id } as BaseWorkflowOwner,
						  ]
						: [{ type: 'AccountGroup', _id: owner._id } as BaseWorkflowOwner]
				);
				onChange({
					type: 'update',
					payload:
						edited && edited?.length
							? [
									...edited?.filter((a) => a._id !== owner._id),
									{
										type: 'AccountGroup',
										_id: owner._id,
									} as BaseWorkflowOwner,
							  ]
							: [
									{
										type: 'AccountGroup',
										_id: owner._id,
									} as BaseWorkflowOwner,
							  ],
				});
			} else {
				setEdited((edited) =>
					edited && edited?.length
						? [
								...edited?.filter((a) => a._id !== owner._id),
								{ type: 'AccountUser', _id: owner._id } as BaseWorkflowOwner,
						  ]
						: [{ type: 'AccountUser', _id: owner._id } as BaseWorkflowOwner]
				);
				onChange({
					type: 'update',
					payload:
						edited && edited?.length
							? [
									...edited?.filter((a) => a._id !== owner._id),
									{ type: 'AccountUser', _id: owner._id } as BaseWorkflowOwner,
							  ]
							: [{ type: 'AccountUser', _id: owner._id } as BaseWorkflowOwner],
				});
			}
		},
		[edited, onChange, allGroups]
	);

	const removeOwner = React.useCallback(
		(ownerNameOrTitle: string) => {
			const isGroup = allGroups.some((g) => g.title === ownerNameOrTitle);
			if (isGroup) {
				const groupId = allGroups.find((m) => m.title === ownerNameOrTitle)
					?._id;
				if (edited) {
					setEdited([...edited?.filter((owner) => owner._id !== groupId)]);
					onChange({
						type: 'update',
						payload: [...edited?.filter((owner) => owner._id !== groupId)],
					});
				}
			} else {
				const userId = allUsers.find(
					(u) => `${u.givenName} ${u.familyName}` === ownerNameOrTitle
				)?._id;
				if (edited) {
					setEdited([...edited?.filter((e) => e._id !== userId)]);
					onChange({
						type: 'update',
						payload: [...edited?.filter((owner) => owner._id !== userId)],
					});
				}
			}
		},
		[edited, onChange, allGroups, allUsers]
	);

	const findGroupOrUserName = (owner: BaseWorkflowOwner) => {
		if (owner.type === 'AccountGroup') {
			if (allGroups.some((m) => m._id === owner._id))
				return allGroups.find((m) => m._id === owner._id)?.title;
		} else if (owner.type === 'AccountUser') {
			if (allUsers.some((m) => m._id === owner._id)) {
				const user = allUsers.find((m) => m._id === owner._id);
				return `${user?.givenName} ${user?.familyName}`;
			}
		}
	};

	const isNotAlreadyOwner = (existingOwner: User | UserGroup) =>
		!(edited || [])?.some((owner) => owner?._id === existingOwner._id);

	const doesOwnerExist = (owner: BaseWorkflowOwner) => {
		return [...(allGroups || []), ...(allUsers || [])].some(
			(u) => u._id === owner._id
		);
	};
	const options = React.useMemo(() => {
		if (allUsers && allGroups) {
			return [...allGroups, ...allUsers];
		} else return [];
	}, [allUsers, allGroups]);

	const searchPredicate = (val: Nullable<string>, opt: any) => {
		if (!val) return true;
		if (property('members')(opt)) {
			const title = property('title')(opt) as string;
			return title.toLowerCase().indexOf(val?.toLowerCase()) > -1;
		} else if (property('givenName')(opt)) {
			const first = property('givenName')(opt) as string;
			const last = property('familyName')(opt) as string;
			return [first, last].some(
				(value) => value.toLowerCase().indexOf(val.toLowerCase()) > -1
			);
		}
		return false;
	};

	const selectProps: DownshiftMultiSelectProps<any, any> = {
		label: label || 'Stakeholders',
		selectionState: {
			selection: edited
				?.filter(doesOwnerExist)
				.map((owner) => findGroupOrUserName(owner)) as string[],
			options: options?.filter(isNotAlreadyOwner),
			searchPredicate,
		},
		selectionActions: {
			select: addOwner,
			unselect: removeOwner,
		},
	};

	return (
		<>
			<OwnerSelectWrapper>
				<DownshiftMultiSelect {...selectProps} />{' '}
			</OwnerSelectWrapper>
		</>
	);
};

export default BaseOwnerSelect;
