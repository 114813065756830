import { faFireExtinguisher } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import {
	isOverdue,
	isRoadblocked,
} from 'workflows/helpers/workflowDate.helpers';
import { Workflow } from '../../workflows/types/workflow.types';
export const PaddedBadge = styled(Badge)`
	&& {
		cursor: pointer;
		padding: 4px 6px;
		font-size: 11px;
	}
`;

const WorkflowHealthFlag = (props: {
	workflow: Workflow;
	whenInactive?: ReactElement;
}) => {
	const { workflow, whenInactive = null } = props;

	if (workflow.status !== 'active') {
		return whenInactive;
	} else if (isRoadblocked(workflow)) {
		return (
			<>
				{workflow.hot && (
					<PaddedBadge color="danger">
						<FontAwesomeIcon icon={faFireExtinguisher} /> Hot
					</PaddedBadge>
				)}
				<PaddedBadge color="danger">Roadblocked</PaddedBadge>
			</>
		);
	} else if (isOverdue(workflow)) {
		return (
			<>
				{workflow.hot && (
					<PaddedBadge color="danger">
						<FontAwesomeIcon icon={faFireExtinguisher} /> Hot
					</PaddedBadge>
				)}
				<PaddedBadge color="warning">Overdue</PaddedBadge>
			</>
		);
	} else {
		return (
			<>
				{workflow.hot && (
					<PaddedBadge color="danger">
						<FontAwesomeIcon icon={faFireExtinguisher} /> Hot
					</PaddedBadge>
				)}
				<PaddedBadge color="success">Healthy</PaddedBadge>
			</>
		);
	}
};

export default WorkflowHealthFlag;
