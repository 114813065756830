import { orderBy, values } from 'lodash';
import * as R from 'ramda';
import * as T from '../types/workflow.types';
import { StageEvent } from '../types/workflow.types';
import { StatusEnum } from '../types/workflowStatus.types';
import moment from 'moment';
import { User } from '../../auth';
import { UserGroup } from 'accounts/types';
import { isLastStageInList } from 'workflow-templates/components/stage-cards/Stage.helpers';

// this function exists inside of the common file, but using it causes mobx error in teh test file
// thus, I just copied and pasted it here.  mobx sucks.
export function generateID() {
	const hex = (x: number) => (~~x).toString(16);
	const randomByte = () => hex(Math.random() * 16);
	return `${hex(Date.now() / 1000)}${' '.repeat(16).replace(/./g, randomByte)}`;
}

const getRevertedStageIndex = (stageToRevert: T.Stage, stages: T.Stage[]) =>
	stages.reduce(
		(accum, stage, index) =>
			stage.title === stageToRevert.title ? index : accum,
		0
	);

export const isFinalStage = (stage: T.Stage, stages: T.Stage[]) => {
	if (stage.substages) return false;
	if (R.not(isLastStageInList(stage, stages))) return false;
	return true;
};

export const isInitialStage = (stage: T.Stage, stages: T.Stage[]) =>
	R.equals(R.head(stages), stage);

export const recursiveFlattenStages = (stages: T.Stage[]): T.Stage[] => {
	if (R.not(R.any(R.has('substages'), stages))) return stages;

	const flattenedStages = R.flatten(
		stages.map((stage) => {
			if (!stage.substages) return stage;
			return [R.omit(['substages'], stage), ...R.flatten(stage.substages)];
		})
	);

	return recursiveFlattenStages(flattenedStages);
};

/**
 * Returns the next stage or undefined if there is no next stage
 * @param stage previous stage
 * @param stages all stages on template
 */
export const getNextStage = (
	stage: T.Stage | undefined,
	stages: T.Stage[]
): T.Stage | undefined => {
	if (!stage) return undefined;
	// if last stage in stages list, return null
	if (R.equals(stage, R.last(stages))) return undefined;

	if (R.includes(stage, stages)) return stages[R.indexOf(stage, stages) + 1];

	// @ts-ignore test pass. I'm on a deadline.  fix this later. blame management.
	return stages.reduce((nextStage, { substages }) => {
		if (!substages) return undefined;
		if (nextStage) return nextStage;
		return substages.reduce(
			// @ts-ignore test pass. I'm on a deadline.  fix this later. blame management.
			(_, substage) => getNextStage(stage, substage),
			undefined
		);
	}, undefined);
};

export const _getNextStage = (
	stage: T.Stage,
	workflow: T.Workflow
): T.Stage | undefined => {
	if (!workflow) return;
	// const nextStageId = stage.transitions?.find(isForward)?.targetStage;
	const nextStageId = '';
	return workflow?.stages?.find((wfStage) => wfStage._id === nextStageId);
};

export const emptyMetadataTemplate = (): T.EntityMetadataTemplate => {
	return {
		title: '',
		_id: generateID(),
		fields: [''],
		fieldTypes: [{}],
		fieldOptions: {},
		values: {},
		tags: [''],
	} as T.EntityMetadataTemplate;
};

export const flattenStages = (
	object: T.Flattenable,
	includeSideTasks = false
) => {
	const flat = [
		...(object?.stages || [])?.flatMap((stage: T.Stage) => {
			if (stage?.substages?.length) {
				return [stage, ...stage.substages.flatMap((s) => s)];
			}
			return stage;
		}),
		...(!!includeSideTasks ? object?.sideTasks || [] : []),
	];
	return flat;
};

export const getAllStages = (workflows: T.Workflow[]) => {
	const allStages: T.Stage[] = [];

	workflows?.forEach((workflow) => {
		const allWorkflowStages = flattenStages(workflow as T.Flattenable, true);

		allStages.push(...(allWorkflowStages || []));
	});
	return allStages;
};

const getStageLabel = (status: StatusEnum) => {
	return status === StatusEnum.queue ? `Pipeline` : status;
};

const ownerList = (owners: (User | UserGroup)[]) => {
	return owners
		?.map((owner: User | UserGroup) =>
			(owner as UserGroup)?.BrandPermissions
				? owner.title
				: `${(owner as User)?.givenName} ${(owner as User)?.familyName}`
		)
		.join(', ')
		.replace(/, ([^,]*)$/, '');
};

export const getActiveStage = (workflow: T.Workflow) => {
	// if workflow completed, return initial stage
	if (workflow.status === 'completed') return getInitialStage(workflow);

	// return first stage whose status is 'active' or 'roadblocked'
	const activeStage = recursiveFlattenStages(workflow.stages).find(
		({ status }) => status === 'active' || status === 'roadblocked'
	);

	// if no active stage, return first stage
	return activeStage ?? getInitialStage(workflow);
};

export const getInitialStage = (workflow: T.Workflow) => {
	return workflow.stages[0];
	// not sure what this other stuff is
	// const initialStage: Maybe<T.Stage> = workflow?.stages?.find((s) => s.initial);

	// if (initialStage) {
	// 	return initialStage;
	// } else {
	// 	// Uh oh, we should have one... hopefully we can deduce it.
	// 	const candidates = workflow?.stages?.filter(
	// 		(s) => !s.backwardTransitions?.length
	// 	);

	// 	if (candidates?.length === 1) {
	// 		const newInitialStage = candidates[0];
	// 		newInitialStage.initial = true;
	// 		return newInitialStage;
	// 	}
	// }

	// throw new Error('Invalid workflow state');
};

export const getStageDueDate = (stage: T.Stage) => {
	if (!stage.events) return '';
	const statusChangeEvents = stage.events.filter(
		(m: any) => m.type === 'statusChange'
	) as StageEvent[];
	const activeChangeEvents = statusChangeEvents.filter(
		(m) => m.newStatus === 'active'
	);
	if (!activeChangeEvents.length) return '';
	const date = moment(
		orderBy(activeChangeEvents, (a) => a.createdAt, 'desc')[0].createdAt
	)
		.add(stage.expectedDurationHrs, 'hours')
		.toISOString();

	return date.substring(0, 10);
};

export const getStageEventAsString = (
	stageEvent: StageEvent,
	removed: (User | UserGroup)[],
	added: (User | UserGroup)[]
) => {
	let addedMsg = '';
	let removedMsg = '';
	let connector = '';

	if (!stageEvent?.type) return '';

	if (stageEvent.type === 'statusChange')
		return `changed the status from ${getStageLabel(
			stageEvent.oldStatus as StatusEnum
		)} to ${getStageLabel(stageEvent.newStatus as StatusEnum)}.`;

	if ((added || []).length) {
		addedMsg = `assigned stage to ${ownerList(added || [])}`;
	}

	if (values(removed || []).length) {
		removedMsg = `unassigned stage from ${ownerList(removed || [])}`;
	}

	if (values(added || []).length && values(removed || []).length) {
		connector = 'and';
	}

	return `${addedMsg} ${connector} ${removedMsg}`;
};

export const revertStage = (stage: T.Stage) => {
	return { ...stage, status: 'pipeline' };
};

const revertStages = (
	stages: T.Stage[],
	resetStageIndex: number,
	shouldRevert?: boolean
) =>
	stages.map((stage, index) => {
		const isRevertedStage = index <= resetStageIndex || shouldRevert;

		if (!isRevertedStage) return stage;
		if (stage.substages)
			revertStages(
				stage.substages.flatMap((s) => s),
				resetStageIndex,
				isRevertedStage
			);

		return {
			...stage,
			status: 'pipeline',
		};
	});

const isUserOwnerOrFollower = (
	template: T.WorkflowTemplate,
	currentUser: User,
	groupsForCurrentUser: UserGroup[]
) => {
	if (!template) return false;
	const isFollower =
		!!template.followers?.some((follower) => {
			return (
				currentUser?.proxyingFor?._id === follower?._id ||
				follower?._id === currentUser?._id ||
				!!groupsForCurrentUser?.some(
					(group: UserGroup) => group?._id === follower?._id
				)
			);
		}) ||
		!!((template?.createdBy as User)?._id === currentUser?._id) ||
		!!template?.owners?.some(
			(owner) =>
				currentUser?.proxyingFor?._id === owner?._id ||
				owner?._id === currentUser?._id ||
				groupsForCurrentUser?.some((group) => group._id === owner._id)
		);

	const isStakeholder = flattenStages(
		template as T.Flattenable
	)?.some((stage) =>
		stage?.owners?.some(
			(owner) =>
				currentUser?.proxyingFor?._id === owner?._id ||
				owner?._id === currentUser?._id ||
				groupsForCurrentUser?.some((grp) => grp._id === owner._id)
		)
	);
	return isFollower || isStakeholder;
};

export const canView = (
	workflow: T.Workflow,
	currentUser: User,
	groupsForCurrentUser: UserGroup[]
) => {
	const isStakeholder = flattenStages(
		workflow as T.Flattenable
	)?.some((stage) =>
		stage?.owners?.some(
			(owner) =>
				currentUser?.proxyingFor?._id === owner?._id ||
				owner._id === currentUser?._id ||
				groupsForCurrentUser?.some((grp) => grp._id === owner._id)
		)
	);

	const isWorkflowFollower = workflow?.followers?.some(
		(a) =>
			a._id === currentUser?._id ||
			groupsForCurrentUser.some((grp) => grp._id === a._id)
	);
	const isCreator = workflow?.createdBy?._id === currentUser?._id;

	const isFollower = isUserOwnerOrFollower(
		workflow?.templateUsed as T.WorkflowTemplate,
		currentUser,
		groupsForCurrentUser
	);

	return isStakeholder || isCreator || isFollower || isWorkflowFollower;
};

export const revertWorkflow = (stageToRevert: T.Stage, workflow: T.Workflow) =>
	revertStages(
		workflow?.stages!,
		getRevertedStageIndex(stageToRevert, workflow?.stages!)
	);

export const getUsersStages = (
	workflows: T.Workflow[],
	user: User,
	userGroups: any[]
) =>
	getAllStages(
		workflows?.filter(
			(wf) => wf.status !== 'cancelled' && canView(wf, user, userGroups)
		)
	).filter((stage: T.Stage) => userIsOwner(stage, user, userGroups));

export const userIsOwner = (
	stage?: T.Stage,
	user?: User,
	userGroups?: UserGroup[]
) => {
	if (!user || !stage) return false;
	if (userOwnsStage(stage, user)) return true;
	if (!userGroups) return false;
	if (userGroups && stageInUserGroup(stage, userGroups)) return true;
	return false;
};

const stageInUserGroup = ({ owners }: T.Stage, groups: UserGroup[]) =>
	groups
		? groups.some(({ _id }) => owners && owners.some(R.propEq('_id', _id)))
		: false;

const userOwnsStage = ({ owners }: T.Stage, currentUser: User) => {
	return (
		owners?.some(R.propEq('_id', currentUser?.proxyingFor?._id)) ||
		owners?.some(R.propEq('_id', currentUser._id))
	);
};
export const getActiveStageNames = (workflow?: T.Workflow) => {
	if (!workflow || !workflow.stages) return '—';
	return (
		workflow.stages
			.filter(({ status }) => status === 'active')
			.map(R.prop('title'))
			.join(', ') || '—'
	);
};

export const canEditWorkflowCollection = (
	collection: T.WorkflowCollection,
	user: User
) => {
	return true;
	// return (
	// 	[UserRole.RomeDevelopers, UserRole.SuperAdmin].some(
	// 		(role) => role === user.role
	// 	) || collection.owners.some((owner) => owner._id === user._id)
	// );
};

export const listActiveStageNames = (workflow: T.Workflow): string => {
	return workflow?.stages?.filter((stage) => stage.status === 'active').length
		? workflow?.stages
				?.filter((stage) => stage.status === 'active')
				.map((stage) => stage.title)
				.join(', ')
		: '—';
};

export const isActionableStage = (
	stage: T.Stage | undefined,
	currentUser: User,
	groups: UserGroup[]
) =>
	!!currentUser &&
	!!stage &&
	stage.type !== 'parallel' &&
	(stage.status === 'active' || stage.status === 'roadblocked') &&
	stage?.owners.some((m) =>
		m.type === 'AccountGroup'
			? groups.some((g) => g._id === m._id)
			: currentUser?.proxyingFor?._id === m._id || currentUser._id === m._id
	);
// {
// 	if (!currentUser) return false;
// 	if (stage.type === 'parallel') return false;
// 	if (R.not(stage.status === 'active' || stage.status === 'roadblocked'))
// 		return false;
// };
