import React, { useRef, ChangeEvent } from 'react';
import styled from 'styled-components';
// import { Icon } from '../../components/Icons/Icon'
import { useKeybind } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

type Props = {
	onSearch: (input: string) => void;
	placeholder?: string;
	autoFocus?: boolean;
	defaultValue?: string;
};

export const Search = ({ onSearch, ...props }: C<Props>) => {
	const ref = useRef<HTMLInputElement>(null);
	useKeybind('/', () => !!ref?.current && ref?.current?.focus());

	const placeholder = props.placeholder ? props.placeholder : 'Search';

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
		onSearch(e?.target?.value);
	};

	return (
		<div className={props.className}>
			<label
				style={{ display: 'none' }}
				className="search-label"
				htmlFor="search_field"
			>
				Search
			</label>
			<div className="search-field">
				<FontAwesomeIcon icon={faSearch} className="search-icon" />
				<input
					ref={ref}
					id="search_field"
					onChange={handleInput}
					autoFocus={props.autoFocus}
					autoComplete="off"
					defaultValue={props.defaultValue}
					placeholder={`${placeholder}`}
					type="search"
				/>
			</div>
		</div>
	);
};

export default styled(Search)`
	width: 100%;
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
	:active,
	:focus {
		border: none;
		outline: none;
	}

	.search-label {
		display: none;
	}

	.search-icon {
		margin-right: 5px;
	}

	.search-field {
		margin: 10px;
		display: flex;
		justify-content: start;
		align-items: center;

		input {
			padding: 5px;
			border: none;
			width: 100%;

			:active,
			:focus {
				outline: none;
			}
		}
	}
`;
