import React, { FC } from 'react';
import { property } from 'lodash';
import { UserRole } from '../accounts/models/UserRole.model';
import { useAxios } from '../hooks/useAxios';
import { useAuthContext } from '../auth';
export type featureMapKeys =
	| 'canViewReportBuilder'
	| 'canViewAssignments'
	| 'canViewProjects'
	| 'canViewProxy'
	| 'canViewDashboard'
	| 'canViewAdmin'
	| 'canViewReporting'
	| 'canViewWorkflows'
	| 'canViewTemplates'
	| 'canViewDamCollections'
	| 'canViewDamAssets'
	| 'canViewResourceManagement'
	| 'canViewFileSharing';

export interface Role {
	_id: string;
	role: string;
	damReadOnly?: boolean;
	damEditable?: boolean;
	damUploadable?: boolean;
	wfEditable?: boolean;
	wfReadonly?: boolean;
	wfTemplatesReadonly?: boolean;
	wfTemplatesEditable?: boolean;
	// collections are projects.
	wfCollectionsReadonly?: boolean;
	wfCollectionsEditable?: boolean;
	canViewReporting?: boolean;
	canViewAdmin?: boolean;
	canViewDamCollections?: boolean;
	canViewRoadblocks?: boolean;
	canEditRoadblocks?: boolean;
	canViewAssignments?: boolean;
	canViewFileSharing?: boolean;
	// can create proxys
	canViewProxy?: boolean;

	// can view resource management
	canViewResourceManagement?: boolean;

	// licenses <can use>
	featureMap?: Record<featureMapKeys, string>;
}

type RoleContext = {
	showDisabledOverlay: () => boolean;
	canViewByRole: (prop: string) => boolean;
	roles: Role[];
	updateRole: (rolePermissions: Role, role: string) => void;
	createRole: (role: Role) => Promise<Role>;
	deleteRole: (role: Role) => Promise<boolean>;
};

function roleReducer(
	state: Role[],
	action: { type: 'remove' | 'update' | 'set'; payload: Role | Array<Role> }
) {
	switch (action.type) {
		case 'remove':
			return [
				...state.filter((role) => role._id !== (action.payload as Role)._id),
			];
		case 'update':
			return [
				...state.filter((r) => r._id !== (action.payload as Role)._id),
				action.payload as Role,
			] as Role[];
		case 'set':
			return [...(action.payload as Role[])];
		default:
			return [];
	}
}

export const RoleContext = React.createContext<RoleContext>({
	canViewByRole: () => false,
	showDisabledOverlay: () => false,
	roles: [],
	updateRole: () => {
		return;
	},
	createRole: (payload: Role) => Promise.resolve({} as Role),
	deleteRole: () => Promise.resolve(false),
});

export const RoleContextProvider: FC<{}> = ({ children }) => {
	const userStore = useAuthContext();

	const [roles, dispatch] = React.useReducer(roleReducer, []);
	const roleStore = useAxios<Role>('permissions');
	const token = localStorage.getItem('rome_auth');
	const hasToken = React.useMemo(() => {
		if (token && JSON.parse(token) !== null) {
			return !!JSON.parse(token).accessToken ? true : false;
		}
	}, [token]);

	React.useEffect(() => {
		if (!hasToken || roles.length > 0) return;
		const fetchPermissions = () => {
			roleStore
				.findAll()
				.then((fetchedRoles: Role[] | undefined) =>
					dispatch({ type: 'set', payload: fetchedRoles as Role[] })
				);
		};
		if (!!hasToken && !roles.length) {
			fetchPermissions();
		}
		// eslint-disable-next-line
	}, [hasToken]);

	const canViewByRole = (prop: string) => {
		if (!hasToken) return false;
		if (prop === 'isAdmin') return userStore.currentUser?.isAdmin as boolean;
		if (prop === 'isRomeDeveloper')
			return (userStore.currentUser?.role ===
				UserRole.RomeDevelopers) as boolean;
		const rolePermissions = roles.find(
			(r) => r.role === userStore.currentUser?.role
		);
		return (
			!!rolePermissions &&
			!!rolePermissions.hasOwnProperty(prop) &&
			!!property(prop)(rolePermissions)
		);
	};

	const showDisabledOverlay = () => {
		const route = window.location.pathname;
		const superRole = roles.find((a) => a.role === 'SuperAdmin');
		if (route.includes('assignments')) {
			return superRole?.featureMap?.canViewAssignments === 'true';
		} else if (route.includes('workflows') || route.includes('roadblocks')) {
			return superRole?.featureMap?.canViewWorkflows === 'true';
		} else if (route.includes('templates')) {
			return superRole?.featureMap?.canViewTemplates === 'true';
		} else if (route.includes('assets') || route?.includes('saved-queries')) {
			return superRole?.featureMap?.canViewDamAssets === 'true';
		} else if (route.includes('reports')) {
			return superRole?.featureMap?.canViewReporting === 'true';
		} else if (route.includes('report-builder')) {
			return superRole?.featureMap?.canViewReportBuilder === 'true';
		} else if (route.includes('filesharing')) {
			return superRole?.featureMap?.canViewFileSharing === 'true';
		} else if (route.includes('resource-management')) {
			return superRole?.featureMap?.canViewResourceManagement === 'true';
		} else if (route.includes('collections')) {
			return superRole?.featureMap?.canViewDamCollections === 'true';
		} else if (route.includes('projects')) {
			return superRole?.featureMap?.canViewProjects === 'true';
		} else if (route.includes('proxies')) {
			return superRole?.featureMap?.canViewProxy === 'true';
		} else if (route === '/admin') {
			return superRole?.featureMap?.canViewDashboard === 'true';
		} else if (route.includes('/admin/dashboard')) {
			return superRole?.featureMap?.canViewAdmin === 'true';
		} else if (
			['/preferences', '/profile'].some((path) => route.includes(path))
		) {
			// no permissions surrounding profile / preferences views.
			return true;
		}
		return false;
	};

	const updateRole = async (rolePermissions: Role) => {
		const updatedRole = await roleStore.updateOne(
			rolePermissions._id,
			rolePermissions
		);
		dispatch({ type: 'update', payload: updatedRole as Role });
	};

	const createRole = async (role: Role) => {
		const createdRole = await roleStore.createOne(role);
		dispatch({ type: 'update', payload: createdRole });
		return role;
	};

	const deleteRole = async (role: Role) => {
		const result = await roleStore.deleteOne(role._id);
		dispatch({ type: 'remove', payload: role });
		return !!result;
	};
	return (
		<RoleContext.Provider
			value={{
				canViewByRole,
				showDisabledOverlay,
				roles,
				updateRole,
				createRole,
				deleteRole,
			}}
		>
			{children}
		</RoleContext.Provider>
	);
};
export const useRoleContext = () => {
	const context = React.useContext(RoleContext);
	if (context === undefined)
		throw new Error('expected to be in a role context but was not');
	return context;
};
