import { NullableContext } from '../common';
import { InputSlot, Stage, Workflow } from './types/workflow.types';

export const SelectedWorkflowStage = NullableContext.mutable<Stage>(
	'workflow stage'
);
export const SelectedWorkflow = NullableContext.mutable<Workflow>('workflow');
export const SelectedInputSlot = NullableContext.mutable<InputSlot>(
	'input slot'
);
