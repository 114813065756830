import { ModalBody } from 'reactstrap';
import React, { useEffect } from 'react';
import AsyncDispatcher from '../../../components/async-dispatcher.component';
import DialogModal from '../../../components/modals/dialog-modal.component';
import ShareDialogBody from './share-dialog-body.component';
import {
	FileShareAuditDto,
	useFileShare,
} from 'file-sharing/hooks/useFileShare';
import { SharedFile } from 'file-sharing/components/FileSharingFileDialog';
import { generateID } from 'common';
const renderErrorState = () => (
	<>
		<p>
			We're currently unable to obtain a link which can be used to share your
			file.
		</p>
		<p>
			Please try again later, or contact a system administrator if the issue
			persists.
		</p>
	</>
);

const ShareFileDialog = ({
	file,
	onClosed,
	file_share_id,
	recipient_id,
	recipient_email,
	setAllEvents,
	shouldAudit,
}: {
	file: SharedFile;
	onClosed: () => void;
	recipient_id: string;
	file_share_id: string;
	recipient_email: string;
	setAllEvents: any;
	shouldAudit?: boolean;
}) => {
	const { generateFileShareFileShareLink, audit } = useFileShare();
	useEffect(() => {
		if (!!shouldAudit) {
			const auditDto: FileShareAuditDto = {
				_id: generateID(),
				action: 'share',
				createdAt: new Date(),
				createdBy: recipient_id,
				file_share_id,
				file_id: file._id,
				preview: file.preview,
				metadata: file.metadata,
				recipient_email: recipient_email,
				recipient_id: recipient_id,
			};
			audit(auditDto).then((audit) => {
				setAllEvents((events: any) => [...events, audit]);
				console.info(`Generated audit ${audit._id}`);
			});
		}
		//eslint-disable-next-line
	}, []);
	return (
		<DialogModal onClosed={onClosed} header={`Share ${file.metadata.filename}`}>
			<ModalBody>
				<AsyncDispatcher
					loader={() => generateFileShareFileShareLink(file._id)}
				>
					{{
						success: (filePath) => <ShareDialogBody link={filePath} />,
						error: renderErrorState,
					}}
				</AsyncDispatcher>
			</ModalBody>
		</DialogModal>
	);
};

export default ShareFileDialog;
