import React, { useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import {
	SearchButton,
	StyledInput,
} from '../search-bar/search-bar.styled-components';
import windowModel from '../../models/WindowModel';
import { useSearchHistory } from 'search/useSearchHandler';
import { useAuthContext } from 'auth';

const SearchBox = ({
	placeholder,
	currentRefinement,
	refine,
	onChange,
	value,
}: {
	placeholder: string;
	currentRefinement: string;
	refine: (event: any) => void;
	onChange: (event: any) => void;
	value?: string;
}) => {
	const [searchHistory] = useSearchHistory();
	const { currentUser } = useAuthContext();
	useEffect(() => {
		// check if they are loading a saved query
		const queryValue = windowModel.getURLSearchParam('q');
		if (queryValue) {
			refine(queryValue);
			onChange(queryValue);
		} else {
			// check if they want to persist search filters
			if (!!currentUser?.persistSearchFilters && searchHistory.searchTerm) {
				refine(searchHistory.searchTerm);
				onChange(searchHistory.searchTerm);
			}
		}
		//eslint-disable-next-line
	}, []);

	return (
		<InputGroup>
			<InputGroupAddon addonType="append">
				<SearchButton>
					<FontAwesomeIcon icon={faSearch} />
				</SearchButton>
			</InputGroupAddon>
			<StyledInput
				type="search"
				placeholder={placeholder}
				onChange={(event: any) => {
					refine(event.currentTarget.value);
					onChange(event.currentTarget.value);
				}}
			/>
		</InputGroup>
	);
};

const CustomSearchBox = connectSearchBox(SearchBox);
export default CustomSearchBox;
