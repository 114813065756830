import * as R from 'ramda';
import { InputField, Select, Textarea } from 'components';
import { Option } from 'components/forms/Select/Option/Option';
import { FormModal } from 'components/Modal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Flattenable,
	Stage,
	StageTransition,
	TemplatePhase,
	WorkflowTemplate,
} from 'workflows/types';
import { useTemplateContext } from 'workflow-templates/helpers/useTemplateContext';
import { flattenStages } from 'workflows/helpers';
import { generateID } from 'common';
import { isEqual } from 'lodash';
import BaseOwnerSelect from '../owner-select.component';
import { StageType } from 'workflow-templates/models';
import {
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
} from 'reactstrap';

type Props = {
	title: string;
	isOpen: boolean;
	closeModal: () => void;
	stage?: Stage;
	onSubmit: (stage: Stage) => void;
};

export const getStagesBefore = (template: WorkflowTemplate, stage: Stage) => {
	if (
		stage.title === 'Untitled Stage' &&
		flattenStages(template)?.every((s) => s._id !== stage._id)
	) {
		return flattenStages(template);
	}
	let searchStage: Maybe<Stage>;
	if (stage.type === StageType.sideTask) {
		return template?.sideTasks?.filter(
			(sideTask) => sideTask._id !== stage._id
		);
	}
	if (stage.type === StageType.substage) {
		searchStage = template?.stages?.find((stg: any) => {
			if (
				stg.type === StageType.parallel &&
				stg?.substages?.flatMap((substage: any) =>
					substage.some((sub: any) => sub._id === stage._id)
				)
			) {
				return true;
			}
			return false;
		});
	} else {
		if (template.stages[0] === stage) return [];

		searchStage = stage;
	}

	if (searchStage) {
		const stageIdx = template.stages.findIndex(
			(i) => i._id === searchStage?._id
		) as number;

		if (stageIdx > 0) {
			return template.stages.slice(0, stageIdx);
		}
	}
	return [];
};
export const StageForm = ({ isOpen, closeModal, stage, ...props }: Props) => {
	const form = useForm();
	const [stageOwners, setStageOwners] = useState(stage?.owners);
	const { template } = useTemplateContext();
	const [durationType, setDurationType] = useState<'hours' | 'days'>('hours');
	const [isRequired, setIsRequired] = useState(stage?.isRequired);
	const [expectedDurationHrs, setExpectedDurationHrs] = useState(
		stage?.expectedDurationHrs ? stage?.expectedDurationHrs : 0
	);
	const [sideTaskTransitions, setSideTaskTransitions] = useState(
		stage?.transitions?.filter((a) => a.type === 'sideTask')
	);
	type FormData = {
		title: string;
		expectedDurationHrs: string;
		phase: string;
		rejectTransitionStageId: string;
		owners: string;
		instructions: string;
	};

	const getUpdatedXitions = (
		existingTransitions: StageTransition[],
		rejectTransitionStageId: string
	) => {
		let newTransitions: StageTransition[] = existingTransitions.filter(
			(a) => a.type !== 'backward' && a.type !== 'sideTask'
		);
		if (!rejectTransitionStageId) return newTransitions;
		if (rejectTransitionStageId?.includes(',')) {
			newTransitions = [
				...newTransitions,
				...rejectTransitionStageId.split(',').map((targetStage) => ({
					targetStage,
					type: 'backward',
					_id: generateID(),
				})),
			];
		} else if (rejectTransitionStageId) {
			newTransitions = [
				...newTransitions,
				{
					targetStage: rejectTransitionStageId,
					_id: generateID(),
					type: 'backward',
				},
			];
		} else {
			newTransitions = [];
		}

		if (existingTransitions && existingTransitions.length) {
			if (!newTransitions.length) {
				// if they have no reject transition ID, nothing was selected, so filter out the backwards transitions
				return existingTransitions.filter(
					(m) => m.type !== 'backward' && m.type !== 'sideTask'
				);
			} else {
				return [...newTransitions];
			}
		} else if (newTransitions) {
			return newTransitions;
		}
		return [];
	};

	const getUpdatedStage = (data: FormData) => {
		const { instructions, rejectTransitionStageId, title, phase } = data;
		let updatedTransition = sideTaskTransitions
			? [...stage?.transitions, ...sideTaskTransitions]
			: stage?.transitions;

		updatedTransition = [
			...getUpdatedXitions(stage?.transitions || [], rejectTransitionStageId),
			...(sideTaskTransitions || []),
		];

		const durationHrs = expectedDurationHrs;

		return {
			...stage,
			title,
			instructions: instructions,
			expectedDurationHrs: durationHrs
				? durationType === 'hours'
					? durationHrs
					: durationHrs
				: 0,
			phase: phase ? phase : undefined,
			transitions: updatedTransition,
			owners: stageOwners,
			isRequired,
		} as Stage;
	};

	const updateStage = (data: FormData) => {
		if (!template?.stages) return;
		props.onSubmit(getUpdatedStage(data));
	};

	const duration = React.useMemo(
		() =>
			durationType === 'hours'
				? expectedDurationHrs?.toString()
				: expectedDurationHrs
				? Math.round(expectedDurationHrs / 24).toString()
				: 'TBD',
		[durationType, expectedDurationHrs]
	);

	return (
		<FormModal
			onCancel={() => {
				setStageOwners(stage?.owners);
				closeModal();
			}}
			title={props.title}
			form={form}
			onSubmit={updateStage}
			isOpen={isOpen}
			onClose={closeModal}
			type="right-panel"
		>
			{/* Stage Name */}
			<InputField
				form={form as any}
				label="Stage Name"
				defaultValue={stage && stage.title}
				name="title"
				validators={{ required: true }}
			/>

			<div className="d-block">
				<Label>Expected Stage Duration</Label>
				{/* Stage Duration */}
				<div
					style={{
						display: 'flex',
						justifyContent: 'stretch',
						alignItems: 'center',
					}}
				>
					<InputGroup>
						<Input
							className="form-control"
							validators={{ required: true }}
							form={form as any}
							label="Stage Duration"
							value={duration}
							onChange={(e) =>
								setExpectedDurationHrs(
									durationType === 'hours'
										? +e.target.value
										: +e.target.value * 24
								)
							}
							name="expectedDurationHrs"
							type="number"
							style={{ height: 55 }}
						/>
						<InputGroupAddon addonType="append">
							<InputGroupText>
								<select
									name="durationType"
									className="form-control"
									defaultValue={['hours']}
									onChange={(e) =>
										setDurationType(e.target.value as 'days' | 'hours')
									}
								>
									<option value="days">As Days</option>
									<option value="hours">As Hours</option>
								</select>
							</InputGroupText>
						</InputGroupAddon>
					</InputGroup>
				</div>
			</div>
			{stage?.type === 'sideTask' && (
				<FormGroup className="ml-3 my-3">
					<Label style={{ fontSize: 16 }} check>
						<Input
							type="checkbox"
							checked={isRequired}
							onChange={(e: any) => setIsRequired(e.target.checked)}
						></Input>
						Is Required For Completion?
					</Label>
				</FormGroup>
			)}
			<Textarea
				validators={{ required: false }}
				form={form as any}
				label="Instructions"
				name="instructions"
				type="textarea"
				defaultValue={stage?.instructions}
			/>

			{/* Stage Phase */}
			{template && !R.isEmpty(template.phases) && (
				<div style={{ marginTop: '20px' }}>
					<Select
						form={form}
						label="Select Phase"
						name="phase"
						defaultValue={getPhase(stage?.phase)}
					>
						{template &&
							template.phases.map((phase) => (
								<Option key={phase._id} label={phase.title} value={phase._id} />
							))}
					</Select>
				</div>
			)}

			{/* Side Task Stage Activation */}
			{
				<div style={{ marginTop: '20px' }}>
					<Select
						form={form}
						name="sideTrackActivation"
						label="Side task to activate on completion"
						defaultValue={
							sideTaskTransitions &&
							sideTaskTransitions.map(({ targetStage }) => targetStage)
						}
						multiple
						onSelect={(selectedSideTasks) => {
							if (
								Array.isArray(selectedSideTasks) &&
								selectedSideTasks.length
							) {
								const payload = selectedSideTasks.map((selected) => ({
									type: 'sideTask',
									_id: generateID(),
									targetStage: selected.value,
								}));
								setSideTaskTransitions([...payload]);
							} else if (
								selectedSideTasks &&
								!Array.isArray(selectedSideTasks)
							) {
								const payload = {
									type: 'sideTask',
									_id: generateID(),
									targetStage: selectedSideTasks.value,
								};
								setSideTaskTransitions([payload]);
							} else {
								setSideTaskTransitions([]);
							}
						}}
						search
					>
						{template &&
							stage &&
							template?.sideTasks
								?.filter(
									(sideTask) =>
										flattenStages(template as Flattenable).filter(
											(a) =>
												!a.transitions.some(
													(x) => x.targetStage === sideTask._id
												)
										) && sideTask?._id !== stage._id
								)
								.map((stage) => (
									<Option
										key={stage._id}
										label={stage.title}
										value={stage._id}
									/>
								))}
					</Select>
				</div>
			}

			{/* Stage Owners/Followers/Stakeholders/Assignees */}
			{stage?.type !== 'parallel' && (
				<BaseOwnerSelect
					onChange={(updated) => setStageOwners(updated.payload)}
					owners={stageOwners}
					label="Stakeholders"
				/>
			)}

			{/* Rejection stage */}
			{!isEqual(template?.stages[0], stage) && (
				<div style={{ marginTop: '20px' }}>
					<Select
						form={form}
						name="rejectTransitionStageId"
						label="Stage to Return to Upon Rejection"
						defaultValue={
							stage &&
							stage?.transitions &&
							stage.transitions
								.filter((x) =>
									flattenStages(template as Flattenable, true)
										?.flatMap((stage) => stage)
										.some((s) => s._id === x.targetStage)
								)
								?.filter((s) => s.type === 'backward')
								.map(({ targetStage }) => targetStage || '')
						}
						multiple
						search
					>
						{template &&
							stage &&
							getStagesBefore(template, stage)?.map((stage) => (
								<Option key={stage._id} label={stage.title} value={stage._id} />
							))}
					</Select>
				</div>
			)}
		</FormModal>
	);
};

const getPhase = (phase?: TemplatePhase | string) => {
	if (!phase) return;
	if (typeof phase === 'string') return phase;
	return phase._id;
};
