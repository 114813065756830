import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UploadResult, UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { DragDrop } from '@uppy/react';
import { authProvider } from 'core';
import { MultipleFileUploadProvider } from 'core/MultipleFileUploadProvider';
import { isEqual } from 'lodash';

import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import {
	generateID,
	noop,
	requiredValue,
	sanitizeForDOMElementId,
} from '../../common';
import { _logError } from '../../common/log';

import { AssetVersion } from '../../workflows/types/workflow.types';
import AssetCard from './asset-card.component';
import {
	DeleteAssetButton,
	DisabledMask,
	DragDropContainer,
} from './asset-picker-styled.components';
const multipleFileUploadProvider = new MultipleFileUploadProvider(
	requiredValue(process.env.REACT_APP_ROME_API_ENDPOINT),
	authProvider
);
interface AssetPickerProps {
	files: UppyFile[];
	onSelect?: (result: UploadResult) => void;
}

function assetFromUppyFile(file: UppyFile): AssetVersion {
	return {
		_id: '$temp_' + generateID(),

		fileName: file.name,

		type: file.extension,

		fileSizeBytes: file.size,
		path: '',
	} as AssetVersion;
}

const MultipleAssetPicker = ({ onSelect = noop, files }: AssetPickerProps) => {
	const uploadProvider = multipleFileUploadProvider;
	const { uppy } = uploadProvider;
	const [currFiles, setFiles] = React.useState(files);
	const [numFiles, setNumFiles] = useState(files?.length || 0);

	useEffect(() => {
		uppy.on('complete', (file) => {
			onSelect(file);
			const files = file.successful as UppyFile[];
			setFiles(files);
		});

		return () => {
			uppy.off('complete', onSelect);
			uppy.reset();
		};
	}, [uppy, onSelect]);

	/* eslint-disable */

	useEffect(() => {
		if (!isEqual(files, currFiles)) {
			setFiles(files);
		}
	}, [files]);

	// clear only works when given
	// an empty dependency array
	const clearInputSlot = useCallback((uf: UppyFile) => {
		try {
			uppy.removeFile(uf.id);
			const newUploads = uppy.getFiles();
			setFiles(newUploads);
			setNumFiles(newUploads.length);
		} catch (error) {
			_logError(error);
		}
	}, []);
	/* eslint-enable */

	const renderAssetCard = () => {
		const buildButtonId = (fileId: string) => {
			return `deleteAsset${sanitizeForDOMElementId(fileId)}`;
		};

		return (
			<Row>
				{currFiles?.map((uf: UppyFile) => (
					<Col key={uf.id} className="mb-3">
						<DisabledMask>
							<AssetCard assetVersion={assetFromUppyFile(uf)} />
						</DisabledMask>
						<UncontrolledTooltip target={buildButtonId(uf.id)}>
							Clear this input slot
						</UncontrolledTooltip>
						<DeleteAssetButton
							type="button"
							id={buildButtonId(uf.id)}
							onClick={() => clearInputSlot(uf)}
						>
							<FontAwesomeIcon icon={faTimes} size="sm" />
						</DeleteAssetButton>
					</Col>
				))}
			</Row>
		);
	};

	const renderDropArea = () => (
		<DragDropContainer>
			<DragDrop
				uppy={uppy}
				locale={{
					strings: {
						dropHereOr: 'Drop here or %{browse}',
						browse: 'browse',
					},
				}}
			/>
		</DragDropContainer>
	);

	const render = () => {
		// files.length is the source of truth,
		// but its value changing doesnt trigger renders
		if (!!currFiles?.length && currFiles?.length !== numFiles) {
			setNumFiles(currFiles.length);
		}

		return currFiles?.length ? renderAssetCard() : renderDropArea();
	};

	return render();
};

export default MultipleAssetPicker;
