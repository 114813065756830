interface RefinementItem {
	label: string;
	value: string[];
	count: number;
	isRefined: boolean;
}

export interface RefinementSettings {
	attribute: string;
	label: string;
	searchable: boolean;
	limit: number;
	transformItems?: (items: Array<RefinementItem>) => Array<RefinementItem>;
}

// const existingFileTypes = {
// 	'application/pdf': 'PDF (.pdf)',
// 	'application/zip': 'ZIP Archive (.zip)',
// 	'image/jpeg': 'JPEG image (.jpg, .jpeg)',
// 	'application/x-zip-compressed': 'Compressed ZIP (.zip)',
// 	'application/x-font-otf': 'OTF Font (.otf)',
// 	'application/postscript': 'PostScript (.ps)',
// 	'binary/octet-stream': 'Binary file',
// 	'image/vnd.adobe.photoshop': 'Adobe Photoshop (.psd)',
// 	'image/tiff': 'TIFF image (.tiff)',
// 	'application/x-font-ttf': 'TTF Font (.ttf)',
// };

// const isKnownMimeType = (
// 	mimetype: string
// ): mimetype is keyof typeof existingFileTypes => {
// 	return mimetype in existingFileTypes;
// };

// const transformMimeType = (item: string): string => {
// 	if (!isKnownMimeType(item)) {
// 		return item;
// 	} else {
// 		return existingFileTypes[item];
// 	}
// };

// const transformFileType = (
// 	items: Array<RefinementItem>
// ): Array<RefinementItem> => {
// 	return items.map((item) => {
// 		item.label = transformMimeType(item.label);
// 		return item;
// 	});
// };

export const transformArchived = (
	items: Array<RefinementItem>
): Array<RefinementItem> => {
	return items.map((item) => {
		item.label = item.label === 'false' ? 'Active' : 'Archived';
		return item;
	});
};

// Hardcoded for now, todo make configurable.
export const damAssetRefinementSettings: readonly RefinementSettings[] = [
	{
		attribute: 'metadata.Division',
		label: 'Division',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Product Category',
		label: 'Product Category',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Associated Product Names',
		label: 'Associated Product Names',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Labels',
		label: 'Labels',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Dieline',
		label: 'Dieline',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Customers',
		label: 'Customers',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Asset Type',
		label: 'Asset Type',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'type',
		label: 'File Type',
		limit: 24,
	},
	{
		attribute: 'archived',
		label: 'Archived',
		searchable: false,
		transformItems: transformArchived,
	},
].map((settings) => {
	const defaults = {
		searchable: false,
		limit: 24,
	};
	return Object.assign(defaults, settings) as RefinementSettings;
});

// Hardcoded for now, todo make configurable.
export const damAssetFolderRefinementSettings: readonly RefinementSettings[] = [
	{
		attribute: 'metadata.Division',
		label: 'Division',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Associated Product Names',
		label: 'Associated Product Names',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Labels',
		label: 'Labels',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Dieline',
		label: 'Dieline',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Customers',
		label: 'Customers',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'metadata.Asset Type',
		label: 'Asset Type',
		searchable: true,
		limit: 24,
	},
	{
		attribute: 'type',
		label: 'File Type',
		limit: 24,
	},
	{
		attribute: 'archived',
		label: 'Archived',
		searchable: false,
		transformItems: transformArchived,
	},
].map((settings) => {
	const defaults = {
		searchable: false,
		limit: 1000,
	};
	return Object.assign(defaults, settings) as RefinementSettings;
});
