import React from 'react';
import styled from 'styled-components';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import { FormGroup } from 'reactstrap';
import VirtualizedSelect from 'react-virtualized-select';
const SelectWrapper = styled.div`
	.Select--multi .Select-value {
		color: #fff;
		background: #6c757d;
	}

	.Select-input {
		display: flex !important;
		> input {
			width: 100% !important;
		}
	}
`;

type Props = {
	labelText: string;
	options: Array<{ label: string; value: string }>;
	selected: Array<{ label: string; value: string }>;
	onChange: (selections: Array<{ label: string; value: string }>) => void;
	loadOptions: (
		input: string,
		callback: (err: any, result: any) => void
	) => void;
	searchText: string;
};

export const DownshiftMulti = (props: Props) => {
	return (
		<FormGroup>
			<label className="w-100 d-block">{props.labelText}</label>
			<SelectWrapper>
				<Select
					multi
					loadingPlaceholder={'Loading assets...'}
					onBlurResetsInput={false}
					onCloseResetsInput={false}
					onSelectResetsInput={false}
					onInputChange={(e: any) => {
						props.loadOptions(e, (o, r) => {
							console.log(o, r);
						});
						return e;
					}}
					loadOptions={props.loadOptions}
					searchPromptText={'Search assets...'}
					searchable={true}
					clearAllText={'Clear'}
					noResultsText={'No results found'}
					autoload
					autosize
					value={props.selected}
					options={props.options}
					onChange={(update) => props.onChange(update as any)}
				/>
			</SelectWrapper>
		</FormGroup>
	);
};
