// import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
// import storageModel from 'models/StorageModel';
import React, { useState } from 'react';
import LineTo from 'react-lineto';
import WindowSizeListener from 'react-window-size-listener';
import { useTemplateContext } from 'workflow-templates/helpers/useTemplateContext';
import { flattenStages } from 'workflows/helpers';
import { Flattenable } from 'workflows/types';
import './PathIndicators.scss';
// const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) || {}) as SiteDefaults;

/* TODO optimize refs calculation to run once */
/* TODO clear adn render lines on window resize */

/* stage cards are targeted by className: stage-card_[stage card object id] */

// line drawing parameters
const scale = 1.5;
const vScale = 5;
const lineWidth = 2;
const backArrowColor = '#C00';
const sideArrowColor = 'var(--darkGrey)';

// component
const PathIndicators = () => {
	const { template } = useTemplateContext();

	const [, setResizeCount] = useState(0);

	// map out array of stages with array transition objects
	const refs = flattenStages(template as Flattenable, true)?.map((ref) => {
		return {
			stageId: ref._id,
			transitions: ref.transitions.filter(
				(t) => t.type === 'backward' || t.type === 'sideTask'
			),
		};
	});

	// flatten/merge for output loop
	const backrefs: any[] = [];
	refs?.forEach((ref) => {
		ref.transitions.forEach((t) => {
			backrefs.push({
				stageId: ref.stageId,
				targetStage: t.targetStage,
				type: t.type,
			});
		});
	});

	// build lineto output from refs arr
	const output = React.useMemo(() => {
		return (
			<div>
				<WindowSizeListener
					onResize={(windowsize) => {
						console.log('>>> RESIZE ', windowsize);
						// update state to redraw.
						// resize listener throttled at 100ms update cycle
						setResizeCount((resizeCt) => resizeCt + 1);
					}}
				></WindowSizeListener>
				{/* eslint-disable-next-line */}
				{backrefs.map((r, index) => {
					let arrowColor =
						r.type === 'backward' ? backArrowColor : sideArrowColor;
					let verticalOffset = 50 + index * vScale;
					let offset = (index + 1) * scale + 5;
					let sideTaskLine = (
						<React.Fragment key={index}>
							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`center left`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`center right`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								within="stage-editor"
							/>
						</React.Fragment>
					);

					let backwardLine = (
						<React.Fragment key={index}>
							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`0 ${verticalOffset}%`}
								to={`stage-card_${r.stageId}`}
								toAnchor={`-${offset} ${verticalOffset}%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`-${offset} ${verticalOffset}%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`-${offset} ${verticalOffset}%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.targetStage}`}
								fromAnchor={`0 ${verticalOffset}%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`-${offset} ${verticalOffset}%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								within="stage-editor"
								className="end-segment"
							/>
						</React.Fragment>
					);

					if (r.type === 'sideTask') {
						return sideTaskLine;
					} else if (r.type === 'backward') {
						return backwardLine;
					} else {
						return null;
					}
				})}
			</div>
		);
	}, [backrefs]);

	return (
		// Still hard coded line for the moment
		<div>{output}</div>
	);
};

export default PathIndicators;
