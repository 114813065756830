import { Card, CardHeader } from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const theme = themeStore.selectedTheme;

export const PhaseIndicator = styled.div`
	background-color: ${(props: { background: string }) => props.background};
	border: 1px solid ${theme.colors.darkGrey};
	height: 25px;
	width: 25px;
	border-radius: 100%;
	margin-right: 5px;
	margin-bottom: 5px;
	cursor: pointer;
	flex: none;

	&:hover {
		transform: scale(1.2, 1.2);
		transition: transform ease-out 200ms;
	}
`;

export const PhaseKeyCard = styled(Card)`
	max-width: 165px;
	position: sticky !important;
	top: 20px;
	left: 30px !important;
	z-index: 3 !important;
`;

export const PhaseKeyCardHeader = styled(CardHeader)`
	display: flex;
	justify-content: space-between;
	cursor: pointer;
`;
