import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Stage } from 'workflows/types';
import { WorkflowsIndexView } from 'workflows/WorkflowIndexPage';
import { MyAssignmentsTable } from '../components';
import { useAuthContext } from '../../auth';
import { useGroupContext } from '../../auth';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import * as H from '../helpers';
import { getStageDueDate } from '../helpers';
import moment from 'moment';
import { orderBy, uniqBy } from 'lodash';
export const MyAssignments = () => {
	const { entities } = useWorkflowContext();
	const { groupsForCurrentUser } = useGroupContext();
	const { currentUser } = useAuthContext();
	const [sort, setSort] = useState<string>('A - Z');
	const [stages, setStages] = useState<Stage[]>([]);
	const [defaultFilter, setDefaultFilter] = useState<string[]>([]);
	const [isHashed, setIsHashed] = useState(false);

	const getSortProperty = (stage: Stage) => {
		if (sort.includes('ASC') || sort.includes('DESC'))
			return new Date(getStageDueDate(stage));
		return stage.title;
	};

	useEffect(() => {
		if (!entities) return;
		const userStages = H.getUsersStages(
			entities,
			currentUser,
			groupsForCurrentUser
		);
		if (entities?.length && !isHashed && !window.location.hash) {
			setDefaultFilter(['all']);
			setStages(
				orderBy(
					userStages,
					(s) => getSortProperty(s),
					sort.includes('ASC') || sort === 'A - Z' ? 'asc' : 'desc'
				)
			);
		}
		//eslint-disable-next-line
	}, [entities]);

	useEffect(() => {
		if (window.location.hash) {
			setIsHashed(true);
			const filter = window.location.hash.replace('#', '');
			window.location.hash = '';

			handleFilter([filter]);
		}
		//eslint-disable-next-line
	}, [defaultFilter]);

	// handleSorting
	const sortingOptions = [
		'A - Z',
		'Z - A',
		'Due Date (ASC)',
		'Due Date (DESC)',
	].map((val) => ({
		label: val,
		value: val,
	}));
	// Sort Handler
	const handleSort = (sorter: string) => {
		setSort(sorter);
		if (sorter === 'A - Z') setStages(H.alphabetizeBy('title', stages));
		if (sorter === 'Z - A') setStages(H.reverseAlphabetizeBy('title'));
		if (sorter === 'Due Date (ASC)')
			setStages((stages) =>
				orderBy(
					stages,
					(stage) =>
						getStageDueDate(stage) ? new Date(getStageDueDate(stage)) : 0,
					'asc'
				)
			);
		if (sorter === 'Due Date (DESC)')
			setStages((stages) =>
				orderBy(stages, (stage) => new Date(getStageDueDate(stage)), 'desc')
			);
	};

	// filterOptions
	const filterOptions = [
		currentUser?.proxyingFor?._id ? ['Proxied Assignments', 'proxied'] : [],
		['All Assignments', 'all'],
		['Active', 'active'],
		['Pipeline', 'pipeline'],
		['Completed', 'completed'],
		['Overdue', 'overdue'],
		['Roadblocked', 'roadblocked'],
	]
		.filter(([label, value]) => !!label && !!value)
		.map(([label, value]) => ({ label, value }));
	const handleFilter = (filter: string | string[]) => {
		const userStages = H.getUsersStages(
			entities,
			currentUser,
			groupsForCurrentUser
		);

		if (filter) {
			console.log(filter);
			const allFilteredStages: Stage[] = [];
			setDefaultFilter(Array.isArray(filter) ? filter : [filter]);
			if (Array.isArray(filter)) {
				filter.forEach((filters) => {
					if (filters === 'overdue')
						allFilteredStages.push(
							...(userStages.filter(
								(stage: Stage) =>
									!!stage &&
									stage?.status === 'active' &&
									moment(getStageDueDate(stage)).isBefore(new Date())
							) || [])
						);
					if (filters === 'all') allFilteredStages.push(...userStages);
					if (filters === 'active')
						allFilteredStages.push(
							...userStages.filter(({ status }: Stage) => status === 'active')
						);
					if (filters === 'completed')
						allFilteredStages.push(
							...userStages.filter(
								({ status }: Stage) => status === 'completed'
							)
						);
					if (filters === 'pipeline')
						allFilteredStages.push(
							...userStages.filter(({ status }: Stage) => status === 'queue')
						);
					if (filters === 'roadblocked')
						allFilteredStages.push(
							...userStages.filter(
								({ status }: Stage) => status === 'roadblocked'
							)
						);
					if (filters === 'proxied')
						allFilteredStages.push(
							...userStages.filter(({ owners }: Stage) =>
								owners.some(
									(owner) => owner._id === currentUser?.proxyingFor?._id
								)
							)
						);
				});
				setStages(uniqBy(allFilteredStages, (stage) => stage._id));
			} else {
				if (filter === 'overdue')
					allFilteredStages.push(
						...(userStages.filter(
							(stage: Stage) =>
								!!stage &&
								stage?.status === 'active' &&
								moment(getStageDueDate(stage)).isBefore(new Date())
						) || [])
					);
				if (filter === 'all') allFilteredStages.push(...userStages);
				if (filter === 'active')
					allFilteredStages.push(
						...userStages.filter(({ status }: Stage) => status === 'active')
					);
				if (filter === 'completed')
					allFilteredStages.push(
						...userStages.filter(({ status }: Stage) => status === 'completed')
					);
				if (filter === 'pipeline')
					allFilteredStages.push(
						...userStages.filter(({ status }: Stage) => status === 'queue')
					);
				if (filter === 'roadblocked')
					allFilteredStages.push(
						...userStages.filter(
							({ status }: Stage) => status === 'roadblocked'
						)
					);
				if (filter === 'proxied')
					allFilteredStages.push(
						...userStages.filter(({ owners }: Stage) =>
							owners.some(
								(owner) => owner._id === currentUser?.proxyingFor?._id
							)
						)
					);
				setStages(uniqBy(allFilteredStages, (stage) => stage._id));
			}
		} else {
			setStages([]);
			setDefaultFilter([]);
		}
	};

	const views = {
		table: (stages: Stage[]) => <MyAssignmentsTable stages={stages} />,
	};
	const sorting = {
		options: sortingOptions,
		fn: handleSort,
	};
	const filters = {
		options: filterOptions,
		fn: handleFilter,
	};

	return (
		<WorkflowsIndexView
			defaultFilter={defaultFilter}
			defaultSort={sort}
			title="Assignments"
			collection={stages}
			sorting={sorting}
			filters={filters}
			views={views}
		/>
	);
};
