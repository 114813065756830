import { css } from 'styled-components';
import { boxShadowFocus } from '../ui/ui';
import { ffTextBody, fsNormal } from '../ui/typography';
import storageModel from 'models/StorageModel';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

const select = css`
	${ffTextBody};
	${fsNormal};
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	appearance: none;
	background-color: var(--white);
	background-image: url(/images/caret-down.min.svg);
	background-position-x: calc(100% - 12px);
	background-position-y: 50%;
	background-repeat: no-repeat;
	background-size: 8px 4px;
	border-radius: 4px;
	border: 1px solid var(--darkGrey);
	color: #222;
	cursor: pointer;
	line-height: 20px;
	padding: 6px 24px 6px 12px;
	position: relative;
	text-rendering: optimizeLegibility;

	&:focus {
		${boxShadowFocus}
		border: 1px solid ${defaults?.primaryHighlighted} !important;
		color: #222;
	}

	/* fix for firefox removes dotted outline on selection option */
	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #222;
	}
`;
export default select;
