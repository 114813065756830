import {
	Dispatch,
	MouseEvent,
	MouseEventHandler,
	useCallback,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import {
	applyPatch,
	applySnapshot,
	getSnapshot,
	IAnyModelType,
	Instance,
	SnapshotIn,
	SnapshotOut,
} from 'mobx-state-tree';
import { pick } from 'lodash';

export function useSearchQuery<
	T extends { includesMentionOf(s: string): boolean }
>() {
	const [searchQuery, setQuery] = useState('');
	const matcher = useCallback((x: T) => x.includesMentionOf(searchQuery), [
		searchQuery,
	]);
	return [searchQuery, setQuery, matcher] as const;
}

export function useToggler(
	defaultVal = false
): [boolean, { (): void; (explicit: boolean): void }] {
	const [value, setValue] = useState(defaultVal);
	const toggleValue = (explicit?: boolean) =>
		setValue(typeof explicit === 'boolean' ? explicit : !value);
	return [value, toggleValue];
}

function useReverter<IT extends IAnyModelType, T extends Instance<IT>>(
	instance: T
): (s: SnapshotOut<IT>) => SnapshotOut<IT> {
	const lastKnownGood = useRef<SnapshotOut<IT>>(getSnapshot(instance));

	/**
	 * Returns lastKnownGood before update, for any other processing
	 */
	const setLastKnownGood = useCallback((newLastKnownGood: SnapshotOut<IT>) => {
		const oldLastKnownGood = lastKnownGood.current;
		lastKnownGood.current = newLastKnownGood;
		return oldLastKnownGood;
	}, []);

	useEffect(() => applySnapshot(instance, lastKnownGood.current), [instance]);

	return setLastKnownGood;
}

/**
 * usePatchers takes an Instance and returns a function that
 * - takes a property key K and returns a function that
 * - takes a value of the type Instance[K] and applies it to the instance.
 * @param instance
 */
export const usePatchers = <IT extends IAnyModelType>(instance: Instance<IT>) =>
	useCallback(
		<K extends keyof SnapshotIn<IT>>(path: K) => (value: SnapshotIn<IT>[K]) =>
			applyPatch(instance, { op: 'replace', path: `/${path}`, value: value }),
		[instance]
	);

export const useEditingFlow = <IT extends IAnyModelType>(
	instance: Instance<IT>
): [
	<K extends keyof SnapshotIn<IT>>(
		path: K
	) => (value: SnapshotIn<IT>[K]) => void,
	() => SnapshotOut<IT>,
	(s: SnapshotOut<IT>) => SnapshotOut<IT>
] => [
	usePatchers(instance),
	useCallback(() => getSnapshot(instance), [instance]),
	useReverter(instance),
];

export const useStateFromEvent = <S>(
	defaultVal: S | (() => S)
): [S, Dispatch<EventWithValue<S>>] => {
	const [value, setValue] = useState(defaultVal);
	const unwrapped = useCallback(unwrapEvent(setValue), [setValue]);
	return [value, unwrapped];
};

interface WithValue<T> {
	value: T;
}

type TargetForInput<T> = T extends undefined
	? Partial<WithValue<T>>
	: WithValue<T>;

interface EventWithValue<T> {
	target: EventTarget & TargetForInput<T>;
}

export function unwrapEvent<T, U>(
	fn: (x: T) => U
): (event: EventWithValue<T>) => U;
export function unwrapEvent<T extends undefined, U>(
	fn: (x?: T) => U
): (event: EventWithValue<T>) => U;
export function unwrapEvent<T, U>(
	fn: (x: T) => U
): (event: EventWithValue<T>) => U {
	return (event: EventWithValue<T>) => fn(event.target.value as T);
}

const unwrappedMouseEventKeys = [
	'type',
	'altKey',
	'button',
	'buttons',
	'clientX',
	'clientY',
	'ctrlKey',
	'metaKey',
	'movementX',
	'movementY',
	'pageX',
	'pageY',
	'screenX',
	'screenY',
	'shiftKey',
] as const;

export type UnwrappedMouseEvent = Pick<
	MouseEvent,
	ArrayValues<typeof unwrappedMouseEventKeys>
>;

/**
 * Destructure the event to in order to avoid passing the event reference around.
 * https://reactjs.org/docs/events.html#event-pooling
 */
export const unwrapMouseEvent = (e: MouseEvent): UnwrappedMouseEvent =>
	pick(e, unwrappedMouseEventKeys);

export function useEventHandler<T extends unknown>(
	fn: () => T
): MouseEventHandler;
export function useEventHandler<T extends unknown>(
	fn: (e: UnwrappedMouseEvent) => T
): MouseEventHandler;
export function useEventHandler<T extends unknown>(
	fn: ((e: UnwrappedMouseEvent) => T) | (() => T)
): MouseEventHandler {
	return useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			e.preventDefault();
			if (fn.length) {
				return fn(unwrapMouseEvent(e));
			} else {
				return (fn as () => T)();
			}
		},
		[fn]
	);
}

/**
 * Note: make sure fn is a stable reference.
 * @param fn - Function to run on component dismount.
 */
export const useCleanup = <T>(fn: () => void | undefined): void =>
	useEffect(() => fn, [fn]);

function useRenderCheck<A extends unknown[]>(
	fn: (...args: A) => unknown
): (...args: A) => void {
	const isRendered = useRef(true);

	const onDismount = useCallback(() => void (isRendered.current = false), [
		isRendered,
	]);

	useCleanup(onDismount);

	return useCallback(
		(...args: A) => {
			if (isRendered.current) {
				fn(...args);
			}
		},
		[fn]
	);
}

export const useStateSafe = <T>(initial: T) => {
	const [state, setState] = useState<T>(initial);
	return [state, useRenderCheck(setState)] as const;
};

export const useReducerSafe = <T, A>(fn: (t: T, a: A) => T, initial: T) => {
	const [state, dispatch] = useReducer(fn, initial);
	return [state, useRenderCheck(dispatch)] as const;
};
