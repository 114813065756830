import { createContext, useContext } from 'react';
import { _logError } from '../../common/log';
import { SelectionContext } from './downshift.interfaces';
import { noop, stubFalse } from '../../common';

export const DownshiftContext = createContext<
	Nullable<SelectionContext<any, any>>
>(null);

export function useDownshift<
	Item extends Displayable,
	Option extends Displayable
>(): SelectionContext<Item, Option> {
	const downshiftCtx = useContext(DownshiftContext);

	if (downshiftCtx) {
		return downshiftCtx;
	}

	_logError('Expected to be in a Downshift context');
	return {
		downshift: (null as unknown) as any,
		selection: [],
		options: [],
		select: noop,
		unselect: noop,
		searchPredicate: stubFalse,
	};
}
