import {
	Workflow,
	WorkflowTemplate,
	Stage,
	Flattenable,
} from '../types/workflow.types';
import * as R from 'ramda';
import { flattenStages } from './workflowStage.helpers';
import {
	isOverdue,
	isActive,
	isPipeline,
	isHealthy,
	isRoadblocked,
} from './workflowDate.helpers';
import { User } from 'auth';
import { getWorkflowBoolean } from './workflowBoolean.helpers';
import { UserGroup } from 'accounts/types';

// prettier-ignore
// export const WorkflowStatusLabels = [`All ${themeStore._.workflow.toLowerCase()}`, 'Pipeline', 'Active', 'Paused', 'Cancelled', 'Completed', 'Following', 'Collaborating', 'Created by me', 'Roadblocked', 'Overdue', 'Healthy'] as const;
// prettier-ignore
// const WorkflowStatusValues = ['all', 'active', 'cancelled', 'collaborating', 'completed', 'createdBy', 'following', 'owned', 'paused', 'overdue', 'roadblocked', 'healthy', 'pipeline' ] as const;
// export type WorkflowStatusLabel = typeof WorkflowStatusLabels[number];
// export type WorkflowStatusValue = typeof WorkflowStatusValues[number];

export const filterByCreatedBy = (workflows: Workflow[], user: User) =>
	workflows.filter((workflow) => workflow.createdBy._id === user._id);

export const filterByFollowing = (
	workflows: Workflow[],
	currentUser: User,
	userFollowedTemplates?: any[]
) =>
	workflows.filter((workflow) =>
		userFollowedTemplates
			? userIsFollowing(workflow, currentUser, userFollowedTemplates)
			: workflow?.followers?.some((a) => a._id === currentUser._id)
	);

const userIsFollowing = (
	workflow: Workflow,
	currentUser: User,
	userFollowedTemplates: any[]
) =>
	userFollowedTemplates.some((template) => {
		if (typeof workflow.templateUsed === 'string') return false;
		if (!!template._id) return false;
		if (!!workflow.templateUsed._id) return false;
		return template._id === workflow.templateUsed._id;
	}) || workflow?.followers?.some((a) => a._id === currentUser._id);

export const filterByOwned = (
	workflows: Workflow[],
	userGroups: any[],
	currentUser: User
) =>
	workflows.filter((workflow) =>
		workflowIsOwned(workflow, userGroups, currentUser)
	);
const workflowIsOwned = (
	workflow: Workflow,
	userGroups: any[],
	currentUser: User
) =>
	getWorkflowBoolean(
		'isOwnedByCurrentUser',
		workflow,
		userGroups,
		currentUser,
		workflow.templateUsed as WorkflowTemplate
	);

export const filterByOverdue = R.pipe(
	R.filter(isOverdue),
	R.filter(R.propEq('status', 'active'))
);

const isStageOwner = (
	workflow: Workflow,
	currentUser: User,
	userGroups: any[]
) =>
	flattenStages(workflow)?.some((stage: Stage[] | Stage) => {
		return Array.isArray(stage)
			? stage.some((m) =>
					m.substages?.length
						? m?.substages
								?.flatMap((s) => s)
								.some((substage: Stage) =>
									substage?.owners?.some(
										(owner) =>
											owner?._id === currentUser?._id ||
											userGroups.some(R.propEq('_id', owner?._id))
									)
								)
						: m?.owners?.some(
								(owner) =>
									owner?._id === currentUser?._id ||
									userGroups.some(R.propEq('_id', owner._id))
						  )
			  )
			: stage?.owners?.some(
					(m) =>
						m._id === currentUser?._id ||
						userGroups?.some(R.propEq('_id', m._id))
			  );
	});

const isWorkflowOwner = (
	workflow: Workflow,
	currentUser: User,
	userGroups: any[]
) =>
	(currentUser?.proxyingFor &&
		isStageOwner(workflow, currentUser?.proxyingFor, userGroups)) ||
	isStageOwner(workflow, currentUser, userGroups) ||
	workflow.owners?.some(
		({ _id }) =>
			(currentUser?.proxyingFor &&
				R.equals(_id, currentUser?.proxyingFor?._id)) ||
			R.equals(_id, currentUser?._id) ||
			userGroups.some(R.propEq('_id', _id))
	);

const isUserOwnerOrFollower = (
	template: WorkflowTemplate,
	currentUser: User,
	groupsForCurrentUser: UserGroup[]
) => {
	if (!template) return false;
	const isFollower =
		template?.followers?.some((follower) => {
			return (
				// user is follower
				follower?._id === currentUser?._id ||
				// user is proxying for a follower
				currentUser?.proxyingFor?._id === follower?._id ||
				// user is in group that is a follower
				!!groupsForCurrentUser?.some(
					(group: UserGroup) => group._id === follower._id
				)
			);
		}) ||
		// template is created by Current User
		!!((template.createdBy as User)?._id === currentUser?._id) ||
		// template is created by User current user is proxying
		!!((template.createdBy as User)?._id === currentUser?.proxyingFor?._id) ||
		// template is owned by currentUser
		!!template.owners?.some(
			(owner) =>
				owner._id === currentUser?._id ||
				currentUser?.proxyingFor?._id === owner?._id ||
				groupsForCurrentUser.some((group) => group?._id === owner?._id)
		);

	const isStakeholder = flattenStages(template as Flattenable)?.some((stage) =>
		stage?.owners?.some(
			(owner) =>
				currentUser?.proxyingFor?._id === owner?._id ||
				owner?._id === currentUser?._id ||
				groupsForCurrentUser.some((grp) => grp?._id === owner?._id)
		)
	);

	return isFollower || isStakeholder;
};

const canView = (
	workflow: Workflow,
	currentUser: User,
	groupsForCurrentUser: UserGroup[]
) => {
	const isStakeholder = flattenStages(workflow as Flattenable)?.some((stage) =>
		stage?.owners?.some(
			(owner) =>
				owner._id === currentUser?._id ||
				groupsForCurrentUser?.some((grp) => grp._id === owner._id)
		)
	);
	const isWorkflowFollower = workflow?.followers?.some(
		(a) =>
			a._id === currentUser?._id ||
			groupsForCurrentUser.some((grp) => grp._id === a._id)
	);
	const isCreator =
		workflow?.createdBy?._id === currentUser?.proxyingFor?._id ||
		workflow?.createdBy?._id === currentUser?._id;

	const isFollower = isUserOwnerOrFollower(
		workflow?.templateUsed as WorkflowTemplate,
		currentUser,
		groupsForCurrentUser
	);

	return isStakeholder || isCreator || isFollower || isWorkflowFollower;
};

export const filterByCollaborating = (
	workflows: Workflow[],
	currentUser: User,
	userGroups: any[]
) =>
	workflows.filter(
		(workflow) =>
			isWorkflowOwner(workflow, currentUser, userGroups) ||
			flattenStages(workflow)?.some((stage) =>
				!Array.isArray(stage)
					? isWorkflowOwner(workflow, currentUser, userGroups)
					: stage.some((stage) => {
							if (!!stage.substages) return false;
							return stage.substages && R.isEmpty(stage.substages)
								? stage.substages.some((subStage: any) =>
										isWorkflowOwner(subStage, currentUser, userGroups)
								  )
								: isWorkflowOwner(stage, currentUser, userGroups);
					  })
			)
	);

// HANDLE FILTERING
export const filterWorkflows = (
	user: User,
	userFollowedTemplates: WorkflowTemplate[],
	groupsForCurrentUser: UserGroup[],
	entities: Workflow[],
	status: string
) => {
	const filteredEntities = entities?.filter((wf) =>
		canView(wf, user, groupsForCurrentUser)
	);
	// ) => R.filter(R.propEq('status', status), workflows);
	if (status === 'all') return filteredEntities;
	if (status === 'cancelled')
		return R.filter(R.propEq('status', 'cancelled'), filteredEntities);
	if (status === 'paused')
		return R.filter(R.propEq('status', 'paused'), filteredEntities);
	if (status === 'completed')
		return R.filter(R.propEq('status', 'completed'), filteredEntities);
	if (status === 'healthy') return filteredEntities.filter(isHealthy);
	if (status === 'overdue') return filteredEntities.filter(isOverdue);
	if (status === 'roadblocked') return filteredEntities.filter(isRoadblocked);
	if (status === 'pipeline') return filteredEntities.filter(isPipeline);
	if (status === 'active') return filteredEntities.filter(isActive);
	if (status === 'following')
		return filterByFollowing(filteredEntities, user, userFollowedTemplates);
	if (status === 'collaborating')
		return filterByCollaborating(filteredEntities, user, groupsForCurrentUser);
	if (status === 'createdBy') return filterByCreatedBy(filteredEntities, user);
	if (status === 'owned')
		return filterByOwned(filteredEntities, groupsForCurrentUser, user);
	return [];
};
