import { isEqual } from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { UserRole } from '../accounts/models/UserRole.model';
import { useAuthContext, useGroupContext } from '../auth';
import Loading from '../components/loading.component';
import PageSubheading from '../components/page-subheading.component';
import RenderWhen from '../components/render-when.component';
import { Heading } from '../components/ui';
import { useAxios } from '../hooks';
import { compareStageStatus } from '../models/StageStatusModel';
import { RoleContext } from '../permissions/PermissionsContext';
import {
	isActive,
	isHealthy,
	isOverdue,
	isRoadblocked,
} from '../workflows/helpers/workflowDate.helpers';
import {
	flattenStages,
	getUsersStages,
} from '../workflows/helpers/workflowStage.helpers';
import { useWorkflowContext } from '../workflows/models/useWorkflowStore';
import {
	Stage,
	Workflow,
	WorkflowTemplate,
} from '../workflows/types/workflow.types';
// import { RecentUploads } from './components/RecentUploads';
const AssignmentsPanel = React.lazy(() =>
	import('./components/assignments-panel.component')
);
const RecentWorkflowTable = React.lazy(() =>
	import('./components/recent-workflows-table.component')
);
const RoadblocksPanel = React.lazy(() =>
	import('./components/roadblocks-panel.component')
);
const SavedQueriesPanel = React.lazy(() =>
	import('./components/saved-queries-panel.component')
);
const WorkflowDueDatesCalendar = React.lazy(() =>
	import('./components/WorkflowDueDatesCalendar')
);
const WorkflowHealthPanel = React.lazy(() =>
	import('./components/WorkflowHealthPanel')
);
const ProxyPanel = React.lazy(() =>
	import('./components/proxy-panel.component')
);

const numberOfRecentAssetsToGet: number = 12;

function wfHealthReducer(
	state: {
		initialized: boolean;
		overdue: number;
		roadblocked: number;
		active: number;
		healthy: number;
	},
	action: {
		type: 'set' | 'update';
		payload: {
			initialized: boolean;
			overdue: number;
			roadblocked: number;
			active: number;
			healthy: number;
		};
	}
) {
	switch (action.type) {
		case 'set':
			return { ...action.payload, initalized: true };
		default:
			return state;
	}
}

const WorkflowDashboard = () => {
	// state
	const [recentWorkflows, setRecentWorkflows] = React.useState<Workflow[]>();
	// const [recentUploads, setRecentUploads] = React.useState<AssetVersion[]>();
	const [ongoingStages, setOnGoingStages] = React.useState<Stage[]>();
	const [wfs, dispatch] = React.useReducer(wfHealthReducer, {
		initialized: false,
		overdue: 0,
		roadblocked: 0,
		active: 0,
		healthy: 0,
	});
	// state memos
	const isFetching = React.useMemo(() => {
		return !wfs.initialized && !recentWorkflows && !ongoingStages;
	}, [wfs.initialized, recentWorkflows, ongoingStages]);

	// hooks
	const dashboardStore = useAxios<Workflow>('dashboard');
	const { entities } = useWorkflowContext();
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	// const { findRecentUploads } = useAssetHelper();

	//effects
	React.useEffect(() => {
		if (!wfs.initialized && !!entities) {
			const wfs = entities;
			if (wfs) {
				const isStakeholder = (workflow: Workflow) =>
					flattenStages(workflow)?.some((stage) =>
						stage.owners?.some(
							(owner) =>
								owner._id === currentUser._id ||
								groupsForCurrentUser?.some((grp) => grp._id === owner._id)
						)
					);

				const isCreator = (workflow: Workflow) =>
					isEqual(workflow.createdBy._id, currentUser._id);

				const isFollower = (workflow: Workflow) =>
					(workflow.templateUsed as WorkflowTemplate)?.followers?.some(
						(follower) =>
							follower._id === currentUser._id ||
							groupsForCurrentUser?.some((grp) => grp._id === follower._id)
					);

				const isWorkflowFollower = (workflow: Workflow) =>
					workflow?.followers?.some(
						(follower) =>
							follower._id === currentUser._id ||
							groupsForCurrentUser?.some((grp) => grp._id === follower._id)
					);

				const filteredWorkflows = wfs?.filter(
					(wf) =>
						isCreator(wf) ||
						isFollower(wf) ||
						isStakeholder(wf) ||
						isWorkflowFollower(wf)
				);

				const payload = {
					overdue: filteredWorkflows?.filter(isOverdue).length,
					roadblocked: filteredWorkflows?.filter(isRoadblocked).length,
					healthy: filteredWorkflows?.filter(isHealthy).length,
					active: filteredWorkflows?.filter(isActive).length,
					initialized: true,
				};
				dispatch({ type: 'set', payload: payload });
			}
		}
		// eslint-disable-next-line
	}, [entities]);

	React.useEffect(() => {
		if (!recentWorkflows) {
			dashboardStore.findAll().then((recentWorkflows: Workflow[]) => {
				setRecentWorkflows(recentWorkflows);
			});
		}
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		if (recentWorkflows) {
			const userStages = getUsersStages(
				recentWorkflows,
				currentUser,
				groupsForCurrentUser
			);

			if (!userStages.length && entities?.length) {
				const allUserStages = getUsersStages(
					entities,
					currentUser,
					groupsForCurrentUser
				);
				setOnGoingStages(
					allUserStages
						.sort((a: any, b: any) => compareStageStatus(a, b))
						.slice(0, 4)
				);
			} else {
				setOnGoingStages(
					userStages
						.sort((a: any, b: any) => compareStageStatus(a, b))
						.slice(0, 4)
				);
			}
		}
	}, [recentWorkflows, currentUser, groupsForCurrentUser, entities]);

	// React.useEffect(() => {
	// 	if (!recentUploads) {
	// 		findRecentUploads(numberOfRecentAssetsToGet).then(setRecentUploads);
	// 	}
	// 	//eslint-disable-next-line
	// }, []);

	const { canViewByRole } = React.useContext(RoleContext);
	return (
		<Container>
			<Row>
				{/* loading */}
				{isFetching && (
					<Loading
						alignItems="center"
						justifyContent="center"
						label="Loading dashboard..."
					/>
				)}

				{/* header */}
				{!isFetching && currentUser._id && (
					<>
						<Col xs={12}>
							<PageSubheading text="Dashboard" />
							<Heading className="welcome-message">
								Welcome back, {currentUser.givenName}!
							</Heading>
						</Col>
						<RenderWhen when={currentUser.role !== UserRole.DAMOnlyAdmin}>
							{/* top row */}
							<Col lg={8} md={12} className="mt-lg-0 mt-4">
								{['wfReadonly', 'wfEditable'].some(canViewByRole) && (
									<RecentWorkflowTable recentWorkflows={recentWorkflows} />
								)}
							</Col>
							<Col lg={4} md={6} className="mt-lg-0 mt-4">
								{canViewByRole('canViewAssignments') && (
									<AssignmentsPanel
										recentWorkflows={recentWorkflows as Workflow[]}
										ongoingStages={ongoingStages}
										link={'/admin/workflow/assignments'}
									/>
								)}
							</Col>

							{/* 2nd row */}
							<Col lg={4} md={6} className="mt-4">
								{['wfReadonly', 'wfEditable'].some(canViewByRole) && (
									<WorkflowHealthPanel
										overdue={wfs.overdue}
										roadblocked={wfs.roadblocked}
										active={wfs.healthy}
										healthy={wfs.healthy}
									/>
								)}
							</Col>
							<Col lg={4} md={6} className="mt-4">
								{['wfReadonly', 'wfEditable'].some(canViewByRole) && (
									<WorkflowDueDatesCalendar workflows={recentWorkflows} />
								)}
							</Col>
							<Col lg={4} md={6} className="mt-4">
								{['wfReadonly', 'wfEditable'].some(canViewByRole) && (
									<RoadblocksPanel
										roadblocked={entities?.filter(isRoadblocked)}
										link={'/admin/workflow/roadblocks'}
									/>
								)}
							</Col>
						</RenderWhen>
						{/* 3rd row */}
						{['damReadOnly', 'damEditable', 'damUploadable'].some(
							canViewByRole
						) && (
							<Col lg={4} md={6} className="mt-4">
								<SavedQueriesPanel />
							</Col>
						)}
						{canViewByRole('canViewProxy') && (
							<Col lg={4} md={6} className="mt-4">
								<ProxyPanel userId={currentUser._id} />
							</Col>
						)}
						{/* <Col lg={4} md={6} className="mt-4">
							{['damReadOnly', 'damEditable', 'damUploadable'].some(
								canViewByRole
							) && <RecentUploads assets={recentUploads} />}
						</Col> */}
					</>
				)}
			</Row>
		</Container>
	);
};

export default WorkflowDashboard;
