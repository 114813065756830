import React, { useState, useEffect, useContext, FormEvent } from 'react';
import { Observable, from } from 'rxjs';
import { tap, concatMap } from 'rxjs/operators';

import {
	Row,
	Col,
	Container,
	UncontrolledTooltip,
	ModalFooter,
} from 'reactstrap';
import { _logError } from '../../common/log';
import { UploadContext } from '../../components/admin-navigation/file-upload-provider.component';
import { doesNameableMatch, generateID } from '../../common';
import DownshiftSingleSelect from '../../components/downshift-select/downshift-single-select.component';
import { castToSnapshot } from 'mobx-state-tree';
import {
	faCheckCircle,
	faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { SecondaryButton } from '../../components/buttons.styled-components';

import { NotificationsContext } from '../../notifications';
import themeStore from '../../theme/models/ThemeStore';
import RenderWhen from '../../components/render-when.component';
import LoggingProvider, { LogLevel } from '../../core/LoggingProvider';
import { DeleteAssetButton } from './asset-picker-styled.components';
import { pick, uniq } from 'lodash';
import { Link, Subheading } from '../../components/ui';
import {
	LabeledInput,
	LabeledSelect,
	StyledLabel,
	SubmitButton,
} from '../../components/forms';
import { navigate } from '@reach/router';
import { useModalCloser, useModalCreator } from '../../stores/ModalStack';
import DeleteAssetDialog from './delete-asset-dialog.component';
import {
	AssetVersion,
	EntityMetadata,
	EntityMetadataTemplate,
} from '../../workflows/types/workflow.types';
import {
	buildEmptyAssetMetadata,
	useAssetHelper,
} from './helpers/useAssetHelper';
import ExistingMetadataFields from 'metadata/components/existing-metadata-fields.component';
import TagInput from 'components/tag-input/TagInput';
import {
	EntityPropList,
	EntityPropListItem,
} from 'components/entity-details.styled-components';
import { useMetadataContext } from 'workflows/hooks/useMetadataContext';
import { Loading } from 'components';
import DialogModal from 'components/modals/dialog-modal.component';
import MultipleAssetPicker from './multiple-asset-picker.component';
import { UploadResult } from '@uppy/core';
import { toFile } from 'core/FileUploadProvider';
import defaultAsset from '../../assets/images/default-asset.jpg';
import { extractPreviewURL } from './asset-card.component';

export interface AssetUploadDialogProps {
	afterSubmit?: () => void;
}

const AssetsBlock = styled.div`
	cursor: pointer;
	padding: 15px;
	background: #fff;
	font-size: 14px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&.selected {
		border-bottom: 5px solid #597f71;
	}
`;

const DefaultAsset = styled.div`
	background: url(${defaultAsset}) center center / cover no-repeat;
	height: 172px;
	position: relative;
	top: 10px;
	margin-top: -1.25em;
`;

type CustomAssetVersion = AssetVersion & {
	file?: File;
};

type PreviewUploadDialogProps = {
	asset: CustomAssetVersion;
	afterSubmit: () => void;
	onSuccess?: (asset: CustomAssetVersion) => void;
};
export const CustomPreviewUploadDialog = (props: PreviewUploadDialogProps) => {
	const { asset, afterSubmit } = props;
	const { error: showError, info } = React.useContext(NotificationsContext);
	const { uploadCustomPreview } = useAssetHelper();
	const [fileToSend, setFileToSend] = React.useState<Maybe<UploadResult>>();

	useEffect(() => {
		if (fileToSend) {
			onSubmit(fileToSend).then(() => console.log('Sent file'));
		}
		//eslint-disable-next-line
	}, [fileToSend]);

	const onSubmit = async (uppyResult: Maybe<UploadResult>) => {
		const file = uppyResult?.successful[0];

		if (!file) {
			setFileToSend(undefined);
			showError('You must select a file.');
			return;
		}
		try {
			const updatedAsset = (await uploadCustomPreview(
				toFile(file),
				asset
			)) as AssetVersion;
			info('Succesfully uploaded custom preview!');
			if (props.onSuccess) {
				props.onSuccess({ ...updatedAsset, file: asset.file });
			}
			props.afterSubmit();
		} catch (error) {
			_logError(error);
			showError(
				'An issue occurred while attempting to upload your file. Please try again later.'
			);
		}
	};

	return (
		<DialogModal
			modalSize="lg"
			header={`Upload custom preview for ${asset.fileName}`}
			onClosed={afterSubmit}
		>
			<Container>
				<Row>
					<Col xl={12}>
						<StyledLabel>
							<p>Select a file that will be used as this assets preview.</p>
							<p>
								After selecting, the preview will be automatically converted.
							</p>
						</StyledLabel>
					</Col>
					<Col xl={12}>
						<MultipleAssetPicker files={[]} onSelect={setFileToSend} />
					</Col>
				</Row>
				<ModalFooter></ModalFooter>
			</Container>
		</DialogModal>
	);
};
export const AssetUploadMetadata = () => {
	const { info, error } = React.useContext(NotificationsContext);
	const { assetIds, clear, remove: removeAsset } = useContext(UploadContext);
	const [assets, setAssets] = useState<CustomAssetVersion[]>([]);
	const { uploadNonWorkflowFiles, deleteOne } = useAssetHelper();
	const [uploadingPreview, setUploadingPreview] = React.useState(false);

	useEffect(() => {
		if (assets && assets.length && !selectedFile)
			setSelectedFile(assets[0].fileName);

		return () => {
			clear();
		};
		//eslint-disable-next-line
	}, [assets]);

	useEffect(() => {
		if (assetIds && assetIds.length && !assets.length) {
			setAssets(
				(assetIds.map((a) => ({
					file: a,
					previewURL: URL.createObjectURL(a),
					fileName: a.name,
					updatedAt: a.lastModified,
					_id: generateID(),
					fileSizeBytes: a.size,
					metadata: buildEmptyAssetMetadata,
					createdAt: new Date(),
					extractedMetadata: {},
					account: '',
				})) as unknown) as CustomAssetVersion[]
			);
		}
	}, [assetIds]);
	const [savedAssets, setSavedAssets] = useState<CustomAssetVersion[]>([]);
	const [uploadingAsset, setUploadingAsset] = React.useState<
		CustomAssetVersion
	>();
	const afterSubmit = () => {
		setUploadingPreview(false);
		setUploadingAsset(undefined);
	};

	const openDeleteAssetDialog = (
		event: FormEvent,
		asset: CustomAssetVersion
	) => {
		event.preventDefault();
		modalStack.addModal(
			<DeleteAssetDialog asset={asset} onDelete={() => deleteAsset(asset)} />
		);
	};

	/**
	 * @param event Form event
	 * @param asset Asset Version to be updated
	 * @description updates a single given asset version's metadata on click of update button
	 */
	const updateSingleAssetSubmit = async (
		event?: FormEvent,
		asset?: CustomAssetVersion
	) => {
		event?.preventDefault();
		try {
			if (asset) {
				const snapshot = castToSnapshot({ ...asset }) as CustomAssetVersion;
				setSavedAssets([
					...savedAssets.filter(
						(assetVersion) => assetVersion._id !== asset._id
					),
					asset,
				]);
				updateAsset({ ...snapshot, file: asset?.file });
				info(`${asset?.file?.name} saved!`);
			}
		} catch (error) {
			_logError(error);

			error(
				`An issue occurred while updating ${themeStore._.asset.toLowerCase()}. Please try again later.`
			);
		}

		return false;
	};

	/**
	 * @param event Form event
	 * @param asset Asset Version to be updated
	 * @description updates all current assets in state with given asset version's metadata on click of update all button
	 */
	const updateAllAssetsSubmit = async (
		event: FormEvent,
		asset: AssetVersion
	) => {
		event.preventDefault();
		try {
			assets?.forEach(async (version) => {
				const updated = await {
					...version,
					metadata: asset.metadata,
					file: version.file,
				};
				setAssets((assets) => [
					...assets.map((a) => (a._id === updated._id ? updated : a)),
				]);
				setSavedAssets((saved) => [
					...saved.filter((a) => a._id !== updated._id),
					updated,
				]);
				info(`${version.title} saved!`);
			});
		} catch (error) {
			_logError(error);

			error(
				`An issue occurred while updating ${themeStore._.asset.toLowerCase()}. Please try again later.`
			);
		}

		return false;
	};

	const patchTemplateUsed = async (
		template: Maybe<Nullable<EntityMetadataTemplate>>,
		asset: CustomAssetVersion
	) => {
		window.location.hash = `${template?._id},${asset._id}` as string;
		const updatedAsset = {
			...asset,
			metadata: template as EntityMetadata,
			metadataTemplateUsed: template?._id as string,
		};
		updateAsset({ ...updatedAsset, file: asset.file });
		info('Updated asset ' + asset?.file?.name + '.');
	};
	const logger = new LoggingProvider();
	const [selectedFile, setSelectedFile] = useState('');
	const { templates } = useMetadataContext();
	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();
	const navigateToDAM = async () => {
		await uploadNonWorkflowFiles(
			savedAssets.map((a) => a.file!),
			savedAssets.map((a) => ({
				title: a.title,
				message: '',
				metadata: pick(
					a.metadata,
					'fields',
					'fieldTypes',
					'fieldOptions',
					'values',
					'tags'
				),
				templateUsed: a.metadataTemplateUsed
					? a.metadataTemplateUsed
					: a.metadata?._id
					? a.metadata?._id
					: undefined,
			}))
		);
		info('Successfully imported the files the DAM');
		return navigate('/admin/dam/assets');
	};

	const updateAsset = (asset: CustomAssetVersion) => {
		const newAssets: CustomAssetVersion[] = [];
		assets.forEach((a) => {
			if (a._id === asset._id) newAssets.push(asset);
			else newAssets.push(a);
		});
		setAssets(newAssets);
	};
	const deleteAsset = async (asset: CustomAssetVersion) => {
		try {
			const deletedId = asset._id;
			removeAsset(asset?.file as File);
			modalCloser.closeModal();
			setAssets((a) => [...a.filter((m) => m._id !== deletedId)]);
			info(`Successfully deleted ${asset?.file?.name}.`);
		} catch (e) {
			logger.logToCloudWatch(
				`Error occurred deleting asset in asset-upload-metadata.component.tsx, stack: ${e?.stack}`,
				LogLevel.error
			);
			error(`Failed to deleted ${asset?.file?.name}, please try again later.`);
		}
	};
	return (
		<Container>
			{uploadingPreview && uploadingAsset && (
				<CustomPreviewUploadDialog
					asset={uploadingAsset}
					afterSubmit={afterSubmit}
					onSuccess={async (asset: CustomAssetVersion) => {
						await updateSingleAssetSubmit(undefined, asset);
					}}
				/>
			)}
			<Subheading>ASSETS</Subheading>
			<Row>
				<Col md={4}>
					{uniq(assets).map((file) => (
						<Row key={file?.fileName}>
							<AssetsBlock
								className={
									selectedFile === file?.fileName ? 'selected mb-1' : 'mb-1'
								}
								style={{
									textDecoration:
										selectedFile === file?.fileName ? 'underline' : 'none',
								}}
								onClick={() => setSelectedFile(file?.fileName)}
							>
								{file.fileName}
								<FontAwesomeIcon
									className="float-right"
									icon={
										savedAssets.some((a) => a.fileName === file.fileName)
											? faCheckCircle
											: faExclamationCircle
									}
								/>
							</AssetsBlock>
						</Row>
					))}
					<RenderWhen when={!!assets?.length}>
						<Row>
							<Col md={8}>
								<SubmitButton
									id={'importToDam'}
									label="Import to DAM"
									onClick={navigateToDAM}
									disabled={savedAssets.length !== assets?.length}
								>
									<RenderWhen when={assets?.length !== savedAssets.length}>
										<UncontrolledTooltip target="importToDam">
											You must save all assets to import
										</UncontrolledTooltip>
									</RenderWhen>
								</SubmitButton>
							</Col>
						</Row>
					</RenderWhen>
				</Col>

				<Col md={8}>
					{assets.map((file) => (
						<React.Fragment key={file?._id}>
							<div
								style={{
									display: selectedFile === file?.fileName ? 'block' : 'none',
								}}
							>
								<div className={'col-xl-12 d-flex justify-content-center'}>
									{file.previewURL && (
										<img
											style={{ maxWidth: 300 }}
											src={extractPreviewURL(file)}
											alt={file.fileName}
											className={'img-fluid img-thumbnail'}
										/>
									)}
									{!file.previewURL && (
										<>
											<DefaultAsset />
											<Link
												to=""
												style={{ cursor: 'pointer' }}
												className="link"
												onClick={() => {
													setUploadingAsset(file);
													setUploadingPreview(true);
												}}
											>
												Upload preview
											</Link>
										</>
									)}
								</div>
								<DownshiftSingleSelect
									label={`Metadata Template*`}
									placeholder="Search by name..."
									selectionState={{
										selection: (file?.templateUsed as EntityMetadataTemplate)
											?._id,
										options: [...templates],
										searchPredicate: doesNameableMatch,
									}}
									selectionActions={{
										select: (template) =>
											patchTemplateUsed(
												template as EntityMetadataTemplate,
												file
											),
									}}
								/>
								<EntityPropList>
									<EntityPropListItem>
										<LabeledInput
											label="Display name"
											type="text"
											name="title"
											value={file.title}
											onChange={(event) => {
												const updated = { ...file, title: event.target.value };
												updateAsset(updated);
											}}
										/>
									</EntityPropListItem>
									<EntityPropListItem>
										<LabeledSelect
											id="assetStatus"
											label="Asset Status"
											value={file.archived ? 'true' : 'false'}
											name="archived"
											onChange={(event) => {
												const updated = {
													...file,
													archived: event.target.value === 'true',
												};
												updateAsset(updated);
											}}
										>
											{[
												{ option: 'Archived', value: 'true' },
												{ option: 'Active', value: 'false' },
											].map((opt) => (
												<option key={opt.option} value={opt.value}>
													{opt.option}
												</option>
											))}
										</LabeledSelect>
									</EntityPropListItem>
								</EntityPropList>
								<div className="mt-2">
									<Subheading>Metadata</Subheading>
									<hr />
									<ExistingMetadataFields
										showAddField
										assetId={file._id}
										onChange={(e) => {
											updateAsset({ ...file, metadata: e as EntityMetadata });
											return e as EntityMetadata;
										}}
										metadata={file.metadata}
									/>
									<TagInput
										onChange={(e) => updateAsset({ ...file, metadata: e })}
										metadata={file.metadata}
									/>
								</div>
								<Row>
									<Col md={8}>
										<div className="d-flex align-items-center justify-content-between">
											<RenderWhen when={!!assets?.length && assets?.length > 1}>
												<SecondaryButton
													onClick={async (e: FormEvent) => {
														const existingIdx = assets?.indexOf(file) as number;
														const nextIndex =
															(existingIdx + 1) % assets?.length;
														await updateSingleAssetSubmit(e, file);
														setSelectedFile(assets[nextIndex].fileName);
													}}
												>
													Save &amp; Edit Next Asset
												</SecondaryButton>
											</RenderWhen>
											<div className="d-flex justify-content-between mt-2">
												<SecondaryButton
													onClick={(e: FormEvent) =>
														updateAllAssetsSubmit(e, file)
													}
												>
													Update
													<RenderWhen
														when={!!assets?.length && assets?.length > 1}
													>
														&nbsp;All
													</RenderWhen>
												</SecondaryButton>
												<DeleteAssetButton
													className={'position-sticky ml-2'}
													onClick={(e: FormEvent) =>
														openDeleteAssetDialog(e, file)
													}
												>
													Delete
												</DeleteAssetButton>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</React.Fragment>
					))}
				</Col>
			</Row>
		</Container>
	);
};
export default AssetUploadMetadata;
