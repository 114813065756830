import {
	faFileDownload,
	faFileImport,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { buildAssetDetailsUrl, buildVersionDetailsUrl } from 'common/links';
import { InteractiveFontAwesomeIcon, SmallTextMuted } from 'components';
import { RomeSwal } from 'components/alert';
import { SecondaryButton } from 'components/buttons.styled-components';
import { NotificationsContext } from 'notifications';
import React, { useState } from 'react';
import {
	Container,
	ListGroup,
	ListGroupItem,
	ListGroupItemText,
} from 'reactstrap';
import { useModalCloser } from 'stores/ModalStack';
import { AssetVersion } from 'workflows/types';
import { AddAssetVersionDialog } from '../AddAssetVersionDialog';
import AssetCard from '../asset-card.component';
import {
	CardTitleLine,
	FileSizeLabel,
	FileTitle,
} from '../asset-card.styled-components';
import { useAssetHelper } from '../helpers/useAssetHelper';

import { AssetTabsProps } from './asset-details-tab-set.component';

const VersionsPanel = (props: AssetTabsProps) => {
	// render methods
	const [showDialog, setShowDialog] = useState(false);
	const [versions, setVersions] = useState<Array<AssetVersion>>();
	const closer = useModalCloser();
	const { listVersions, deleteOne, downloadAssetFile } = useAssetHelper();
	const { info: showInfo, error: showError } = React.useContext(
		NotificationsContext
	);
	React.useEffect(() => {
		if (props.asset && !props.isAssetVersion) {
			listVersions(props.asset._id).then(setVersions);
		} else if (props.damAsset && !props.isAssetVersion) {
			listVersions(props.damAsset._id).then(setVersions);
		} else if (props.isAssetVersion) {
			setVersions([]);
		}
		//eslint-disable-next-line
	}, [props.asset]);

	const downloadVersion = async (version: AssetVersion) => {
		const success = showInfo('Successfully downloaded ' + version.title);
		const error = showError(
			'An error occurred trying to download ' +
				version.title +
				'. If this continues to occur, please file a support ticket.'
		);
		await downloadAssetFile(version).then(success).catch(error);
	};

	const onClickCallback = async (asset: any) => {
		closer.closeModal();

		return (await asset?._id) === props?.asset?._id
			? navigate(`/${buildAssetDetailsUrl(props.asset)}`)
			: navigate(`/${buildVersionDetailsUrl(asset)}`);
	};

	const promptToDeleteVersion = async (version: AssetVersion) => {
		const prompt = await RomeSwal.fire({
			icon: 'question',
			title: 'Are you sure?',
			text: 'After deleting the asset version, it cannot be restored.',
			confirmButtonText: 'Okay',
			showCancelButton: true,
			cancelButtonText: 'Cancel',
		});
		if (prompt.isConfirmed) {
			const success = await deleteOne(version?.versionId as string);
			if (success) {
				setVersions((versions) =>
					versions?.filter((v) => v?.versionId !== version?.versionId)
				);
				await RomeSwal.fire({
					icon: 'success',
					title: 'Successfully deleted version',
					text:
						(('Successfully removed version ' + version.versionId) as string) +
						' from asset.',
				});
			}
		}
	};
	const render = () => {
		return (
			<>
				{showDialog && (
					<AddAssetVersionDialog
						asset={(props.asset ? props.asset : props.damAsset) as AssetVersion}
						afterSubmit={() => setShowDialog(false)}
					/>
				)}
				<Container className="p-md-4 py-3">
					{!props.isAssetVersion && (
						<SecondaryButton onClick={() => setShowDialog(true)}>
							Add Version <FontAwesomeIcon icon={faFileImport} />{' '}
						</SecondaryButton>
					)}
					{props.isAssetVersion && (
						<SmallTextMuted>
							Cannot add a version of an existing version.{' '}
						</SmallTextMuted>
					)}
				</Container>
				<ListGroup style={{ height: '100%', overflow: 'auto' }}>
					{!props.isAssetVersion && versions && (
						<ListGroupItem style={{ borderBottom: '1px solid #ccc' }}>
							<CardTitleLine>
								<FileTitle>Original Version</FileTitle>
							</CardTitleLine>
							<ListGroupItemText>
								<AssetCard
									type="original"
									disableDetail
									onClick={() => onClickCallback(props.asset)}
									showUploadedDate
									isLinkEnabled
									assetVersion={
										(props.asset ? props.asset : props.damAsset) as AssetVersion
									}
								/>
							</ListGroupItemText>
						</ListGroupItem>
					)}
					{versions &&
						versions.map((version, idx) => (
							<>
								<ListGroupItem
									style={{ borderBottom: '1px solid #ccc' }}
									key={version._id}
								>
									<CardTitleLine>
										<FileTitle>Version #{idx + 2}</FileTitle>
										<FileSizeLabel>
											<div className="d-flex justify-content-around">
												<InteractiveFontAwesomeIcon
													id={`download-${version._id}`}
													tooltip={'Download version ' + version._id}
													color="var(--text-color)"
													onClick={() => downloadVersion(version)}
													icon={faFileDownload}
												/>
												<InteractiveFontAwesomeIcon
													id={`delete-${version._id}`}
													tooltip={'Delete version ' + version._id}
													color="red"
													onClick={() => promptToDeleteVersion(version)}
													icon={faTrashAlt}
												/>
											</div>
										</FileSizeLabel>
									</CardTitleLine>
									<ListGroupItemText>
										<AssetCard
											disableDetail
											onClick={onClickCallback}
											showUploadedDate
											isLinkEnabled
											assetVersion={version}
										/>
									</ListGroupItemText>
								</ListGroupItem>
							</>
						))}
				</ListGroup>
			</>
		);
	};

	return render();
};

export default VersionsPanel;
