import { Card, Col } from 'reactstrap';
import moment from 'moment';
import React, { useCallback } from 'react';

import { Cell } from '../../components/tables.styled-components';
import {
	ListColumn,
	makeColumns,
} from '../../components/list-column.component';
import { ScrollTable } from '../../components/ui';
import ListTable from '../../components/list-table.component';
import themeStore from '../../theme/models/ThemeStore';

import { useModalCreator } from '../../stores/ModalStack';
import { AssetDetailsDialog } from '../asset-details-dialog.component';
import InsightsProvider from '../../core/InsightsProvider';
import { AssetVersion } from '../../workflows/types/workflow.types';
import { AlgoliaAssetVersion } from './asset-infinite-hits.component';
import { useAuthContext } from 'auth';
import { humanReadableFileSizeSI } from 'common';

// constants
const assetTerm = themeStore._.asset.toLowerCase();

// interface
interface Props {
	assets: AlgoliaAssetVersion[];
	isSelectingAssets?: boolean;
	onAssetSelected?: (assets: AlgoliaAssetVersion) => void;
	selectedAssets?: AssetVersion[];
	metadataProperties?: Array<{ label: string; property: string }>;
	onDelete: (asset: AlgoliaAssetVersion) => void;
}

// component
const AssetListTable = (props: Props) => {
	const insightsProvider = React.useMemo(() => new InsightsProvider(), []);
	const userStore = useAuthContext();
	const {
		assets,
		isSelectingAssets,
		onAssetSelected,
		selectedAssets,
		metadataProperties,
		onDelete,
	} = props;
	const modalStack = useModalCreator();
	const openDetailsModal = useCallback(
		(asset: AlgoliaAssetVersion) => {
			insightsProvider.sendInsights(
				'CLICK_HIT',
				userStore.currentUser,
				asset,
				'',
				'convertedObjectIDs'
			);
			modalStack.addModal(
				<AssetDetailsDialog
					onDelete={() => onDelete(asset)}
					shouldFetch={true}
					assetToPreview={asset}
					workflowId={asset?.workflowId as string}
					assetId={asset._id}
				/>
			);
		},
		[modalStack, insightsProvider, userStore.currentUser, onDelete]
	);

	const presetColumns = (metadataProperties || [])?.map((prop) => ({
		prop: (asset: AlgoliaAssetVersion) => (
			<span>
				{asset.metadata.values && <>{asset.metadata.values[prop.property]}</>}
			</span>
		),
		label: prop.label,
	}));

	const assetListColumns: ListColumn<AlgoliaAssetVersion>[] = makeColumns([
		{
			label: 'Name',
			prop: (asset: AlgoliaAssetVersion) => (
				<span
					className="btn btn-link text-dark"
					onClick={() => openDetailsModal(asset)}
				>
					{asset.title}
				</span>
			),
		},
		...presetColumns,
		{
			label: 'Created At',
			prop: (asset) => moment(asset.createdAt).format('YYYY-MM-DD'),
		},
		{
			label: 'File size',
			prop: (asset) => humanReadableFileSizeSI(asset.fileSizeBytes),
		},
		{
			label: 'File type',
			prop: 'type',
		},
	]);

	const checkboxCallBack = useCallback(
		(asset: AlgoliaAssetVersion) => {
			if (onAssetSelected) {
				onAssetSelected(asset);
			}
		},
		[onAssetSelected]
	);

	const renderStaticList = () => (
		<ListTable
			columns={assetListColumns}
			rows={assets}
			noResultsLabel={`No ${assetTerm.toLowerCase()}s yet`}
		/>
	);

	const renderSelectableRows = () =>
		assets.map((asset) => (
			<tr key={asset._id}>
				{assetListColumns.map((column) => column.render(asset))}
				<Cell>
					<input
						type="checkbox"
						value={asset._id}
						checked={selectedAssets?.includes(asset)}
						onChange={() => checkboxCallBack(asset)}
					/>
				</Cell>
			</tr>
		));

	const renderSelectableList = () => (
		<ScrollTable>
			<thead>
				<tr>
					{assetListColumns.map((c) => c.renderHeader())}
					<th>Select</th>
				</tr>
			</thead>
			<tbody>
				{assets.length ? (
					renderSelectableRows()
				) : (
					<tr>
						<Cell className="pt-4">{`No ${assetTerm}s`}</Cell>
					</tr>
				)}
			</tbody>
		</ScrollTable>
	);

	const render = () => (
		<Col md={12}>
			<Card className="mt-3">
				{isSelectingAssets ? renderSelectableList() : renderStaticList()}
			</Card>
		</Col>
	);

	return render();
};

export default AssetListTable;
