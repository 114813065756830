import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RomeSwal } from 'components/alert';
import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import { isObject } from 'lodash';
import React from 'react';
import { Col, FormText } from 'reactstrap';
import { EntityMetadata } from 'workflows/types';
type AddMetadataColumnProps = {
	objectArray?: Array<EntityMetadata>;
	onSelect: (prop: string, allProperties: string[]) => void;
	onClose: () => void;
};

export const AddMetadataColumnDialog = (props: AddMetadataColumnProps) => {
	const { objectArray, onSelect, onClose } = props;
	const [selectedProperty, setSelectedProperty] = React.useState('');
	const isValid = React.useMemo(() => !!selectedProperty, [selectedProperty]);
	const allProperties = React.useMemo(() => {
		const uniqueKeys: string[] = [];
		objectArray?.forEach((obj) => {
			if (obj && isObject(obj)) {
				Object.keys(obj).forEach((key) => {
					if (!uniqueKeys.includes(`metadata.${key}`))
						uniqueKeys.push(`metadata.${key}`);
				});
			}
		});
		return uniqueKeys;
		//eslint-disable-next-line
	}, [props.objectArray]);

	const onConfirm = async () => {
		if (!selectedProperty) {
			await RomeSwal.fire({
				icon: 'error',
				title: 'Missing Selection',
				text:
					'You must select a metadata property in order to add the column to the report.',
			});
			return;
		}
		onSelect(selectedProperty, allProperties);
	};

	return (
		<ConfirmationDialog
			isDisabled={isValid && !isValid}
			confirmText="Add column"
			onConfirm={onConfirm}
			onCancel={onClose}
			header="Add metadata column to the report"
		>
			<Col xs={12}>
				<FormText className="mb-2">
					Select a metadata property to add to the report.
				</FormText>
				<div className="d-flex justify-content-between align-items-center">
					<select
						className="form-control"
						onChange={(e) => setSelectedProperty(e.target.value)}
					>
						<option value="">Please select...</option>
						<option value="all">All available metadata fields</option>
						{allProperties.map((property) => (
							<option key={property} value={property}>
								{property}
							</option>
						))}
					</select>
					{isValid && (
						<FontAwesomeIcon
							style={{ fontSize: 24 }}
							className="pl-1 text-success"
							icon={faCheck}
						/>
					)}
				</div>
			</Col>
		</ConfirmationDialog>
	);
};
