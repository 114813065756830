import {
	faChevronDown,
	faChevronRight,
	faFileCsv,
	faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from '@reach/router';
import {
	Heading,
	Loading,
	ScrollTable,
	SmallTextMuted,
	Subheading,
} from 'components';
import BackLink from 'components/back-link/back-link.component';
import { startCase, toArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Badge, Container } from 'reactstrap';
import { useReportBuilder } from 'reports/hooks/useReportBuilder';
import { ReportBuilderInstance as Instance } from 'reports/types';

import { useCsvBuilder } from 'reports/hooks/useCsvBuilder';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from 'components/entity-details.styled-components';
import { AnchorSpan } from 'dashboard/components/proxy-panel.styled.component';
import { StyledLink } from 'dam-assets/components/asset-card.styled-components';
import {
	buildTemplateUrl,
	buildUserProfileUrl,
	buildVersionUrl,
	buildWorkflowUrl,
} from 'common/links';
import { useAuthContext, User } from 'auth';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { Workflow } from 'workflows/types';
import { Td, TrSub } from 'workflows/components/stage-table.styled-components';
export const ReportBuilderInstance = () => {
	const { reportId } = useParams();
	const { findById } = useReportBuilder();
	const [instance, setInstance] = useState<Instance>();
	const getBadgeColor = () => {
		switch (instance?.selectedPrimaryEntity) {
			case 'templates':
				return 'danger';
			case 'workflows':
				return 'warning';
			case 'assets':
				return 'success';
			case 'users':
				return 'info';
		}
	};

	const { entities: users } = useAuthContext();
	const { entities: workflows } = useWorkflowContext();
	useEffect(() => {
		findById(reportId).then(setInstance);
		//eslint-disable-next-line
	}, []);

	const renderKey = (key: string, row: Record<string, any>) => {
		return key === 'metadata' || key === 'skuMetdata'
			? row[key]['values']
			: row[key];
	};

	const { exportToCsv } = useCsvBuilder();

	const renderPdf = () => {
		// need to add functionality here that will handle exporting to PDF>
		const input = document.getElementById('divToPrint');
		if (input) {
		}
	};

	const renderCsv = () => {
		if (instance && instance.rows) {
			exportToCsv(
				instance?.rows,
				instance?.columns?.map((a) => a.header),
				`${instance?.title}.csv`
			);
		}
	};

	const renderOption = (exportOption: { type: string }) => {
		if (exportOption.type.toUpperCase() === 'CSV') {
			return (
				<AnchorSpan
					className="px-2 py-2"
					key={exportOption.type}
					onClick={renderCsv}
				>
					Export to CSV <FontAwesomeIcon icon={faFileCsv} />
				</AnchorSpan>
			);
		}

		if (exportOption.type.toUpperCase() === 'PDF') {
			return (
				<AnchorSpan
					className="px-2 py-2"
					key={exportOption.type}
					onClick={renderPdf}
				>
					Export to PDF <FontAwesomeIcon icon={faFilePdf} />
				</AnchorSpan>
			);
		}
	};

	const renderKeysRecursive = (object: any): JSX.Element[] => {
		return Object.keys(object)
			.filter((key) => key !== 'createdBy')
			.map((key) => {
				return typeof object[key] === 'object' ? (
					renderKeysRecursive(object[key])
				) : (
					<EntityPropListItem key={key}>
						<EntityPropLabel>{key}</EntityPropLabel>
						<p>{object[key]}</p>
					</EntityPropListItem>
				);
			}) as JSX.Element[];
	};

	const [visible, setVisible] = useState<Array<any>>([]);
	const renderSkuMetadata = (skuMetadata: string) => {
		const meta = JSON.parse(skuMetadata) as Array<{
			fields: string[];
			values: Record<string, string | string[]>;
		}>;
		const output =
			meta && meta.length && meta[0].fields ? (
				<>
					<p
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setVisible((visible) => [
								...visible.filter((a) => !a[skuMetadata]),
								{
									[skuMetadata]: !visible.some(
										(a) => !!a && a[skuMetadata] && a[skuMetadata] === true
									)
										? true
										: false,
								},
							]);
						}}
					>
						{!visible.some(
							(a) => !!a && a[skuMetadata] && a[skuMetadata] === true
						)
							? 'Hide SKU Metdata'
							: 'Toggle SKU Metdata'}
						&nbsp;
						<FontAwesomeIcon
							icon={
								!visible.some(
									(a) => !!a && a[skuMetadata] && a[skuMetadata] === true
								) === true
									? faChevronDown
									: faChevronRight
							}
						/>
					</p>
					{!visible.some(
						(a) => !!a && a[skuMetadata] && a[skuMetadata] === true
					) && (
						<ScrollTable style={{ border: 'none' }}>
							<tbody style={{ border: 'none' }}>
								{meta.map((item, index) => (
									<TrSub key={index}>
										<Td>
											<SmallTextMuted>Row {index + 1}</SmallTextMuted>
										</Td>
										{item &&
											item.values &&
											Object.keys(item.values).map((value, index) => (
												<td key={value} style={{ border: 'none' }}>
													<EntityPropLabel className="d-block">
														{value}
													</EntityPropLabel>
													<p>
														{Array.isArray(item.values[value])
															? toArray(item.values[value]).join(',')
															: item.values[value]}
													</p>
												</td>
											))}
									</TrSub>
								))}
							</tbody>
						</ScrollTable>
					)}
				</>
			) : (
				<SmallTextMuted>No SKU Metadata to display</SmallTextMuted>
			);
		return output;
	};

	const isValidDate = (date: Date) => {
		return new Date(date).getFullYear() >= 2015;
	};

	const dateFns = React.useMemo(() => {
		return (date: Date) => {
			if (instance?.formatters?.some((a) => a.type === 'date')) {
				const format = instance?.formatters?.find((a) => a.type === 'date')
					?.value;
				return new Date(date)[format as 'toISOString']();
			} else return new Date(date).toISOString();
		};
	}, [instance]);

	const findUser = (key: string, row: any) =>
		users?.find(
			(u) =>
				`${u.givenName} ${u.familyName}` === renderKey(key, row)?.toString()
		) as User;
	const findWorkflow = (key: string, row: any) =>
		workflows?.find(
			(w) => `${w._id}` === renderKey(key, row)?.toString()
		) as Workflow;

	const isEmpty = (columnValue: any) => columnValue === '' || !columnValue;
	const isWorkflowLink = (key: string, row: any) =>
		(key === 'workflowId' && typeof renderKey(key, row) === 'string') ||
		(instance?.selectedPrimaryEntity === 'workflows' && key === '_id');
	const isWorkflowTemplateLink = (key: string, row: any) =>
		(key === 'templateUsed' && typeof renderKey(key, row) === 'string') ||
		(key === '_id' && instance?.selectedPrimaryEntity === 'templates');
	const isAssetLink = (key: string, row: any) =>
		key === '_id' && instance?.selectedPrimaryEntity === 'assets';
	const isUserLink = (key: string, row: any) =>
		(key === '_id' && instance?.selectedPrimaryEntity === 'users') ||
		key === 'createdBy';

	const shouldRenderLink = (key: string, row: any) => {
		return (
			isWorkflowLink(key, row) ||
			isWorkflowTemplateLink(key, row) ||
			isAssetLink(key, row) ||
			isUserLink(key, row)
		);
	};
	return (
		<Container style={{ overflowX: 'scroll' }} fluid>
			{instance === undefined && <Loading />}
			{!!instance && (
				<>
					<div className="my-3 d-flex justify-content-between">
						<div>
							<BackLink link="/admin/report-builder" title="report builder" />
							<Heading className="m-0 p-0">
								<div className="w-100">{instance?.title}</div>
							</Heading>
							<Subheading>
								Report Type{' '}
								<Badge color={getBadgeColor()}>
									{startCase(instance?.selectedPrimaryEntity)}
								</Badge>
							</Subheading>
						</div>
					</div>

					{!!instance.exportOptions && !!instance.exportOptions.length && (
						<div className={'d-flex justify-content-end'}>
							{instance.exportOptions.map(renderOption)}
						</div>
					)}

					<ScrollTable style={{ overflowX: 'scroll' }} id={'divToPrint'}>
						<thead>
							<tr>
								{instance?.columns.map((column) => (
									<th key={column.header}>{column.header}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{instance?.rows?.map((row, idx) => (
								<tr key={idx}>
									{Object.keys(row).map((key) => (
										<td key={key}>
											{shouldRenderLink(key, row) && (
												<>
													{isWorkflowTemplateLink(key, row) && (
														<StyledLink
															to={buildTemplateUrl(
																renderKey(key, row)?.toString()
															)}
														>
															{renderKey(key, row)?.toString()}
														</StyledLink>
													)}
													{isWorkflowLink(key, row) && (
														<StyledLink
															to={buildWorkflowUrl(findWorkflow(key, row))}
														>
															{renderKey(key, row)?.toString()}
														</StyledLink>
													)}
													{isUserLink(key, row) && (
														<StyledLink
															to={buildUserProfileUrl(findUser(key, row))}
														>
															{renderKey(key, row)?.toString()}
														</StyledLink>
													)}
													{isAssetLink(key, row) && (
														<StyledLink
															to={buildVersionUrl(
																renderKey(key, row)?.toString()
															)}
														>
															{renderKey(key, row)?.toString()}
														</StyledLink>
													)}
												</>
											)}
											{shouldRenderLink(key, row) === false && (
												<>
													{isEmpty(renderKey(key, row)) && <>&nbsp;</>}
													{key === 'skuMetadata' &&
														renderSkuMetadata(row['skuMetadata'])}

													{key !== 'skuMetadata' &&
														renderKey(key, row)?.toString() ===
															'[object Object]' && (
															<EntityPropList
																style={{ maxHeight: 150, overflowY: 'auto' }}
															>
																{renderKeysRecursive(renderKey(key, row))}
															</EntityPropList>
														)}
													{isValidDate(renderKey(key, row)) &&
														dateFns(renderKey(key, row))}
													{key !== 'skuMetadata' &&
														!isValidDate(renderKey(key, row)) &&
														['string', 'boolean', 'number'].some(
															(type) => typeof renderKey(key, row) === type
														) && <>{renderKey(key, row)?.toString()}</>}
												</>
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</ScrollTable>
				</>
			)}
		</Container>
	);
};
