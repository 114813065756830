import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
	faBatteryEmpty,
	faChevronRight,
	faEllipsisV,
	faEquals,
	faFilter,
	faGreaterThan,
	faInfo,
	faInfoCircle,
	faLayerGroup,
	faLessThan,
	faPlusCircle,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';
import { User } from 'auth';
import {
	Divider,
	PageSubheading,
	ScrollTable,
	SmallTextMuted,
} from 'components';
import { DropDownMenuItem } from 'components/drop-down-menu.component';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from 'components/entity-details.styled-components';
import { AnchorSpan } from 'dashboard/components/proxy-panel.styled.component';
import { PaddedBadge } from 'dashboard/components/workflow-health-flag.component';
import { keysIn, range, toArray, uniqBy } from 'lodash';
import { prop } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import {
	CardBody,
	Col,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	FormText,
	Label,
	Nav,
	Row,
	UncontrolledDropdown,
	UncontrolledTooltip,
} from 'reactstrap';
import { useModalCloser, useModalCreator } from 'stores/ModalStack';
import styled from 'styled-components';
import { TrSub } from 'workflows/components/stage-table.styled-components';
import { StyledAlert } from 'workflows/components/workflow-stage-details/workflow-disabled-mask.component';
import { listActiveStageNames } from 'workflows/helpers';
import {
	AssetVersion,
	EntityMetadata,
	Workflow,
	WorkflowTemplate,
} from 'workflows/types';
import { SecondaryButton } from '../components/buttons.styled-components';
import { AddFilterDialog } from './dialogs/AddFilterDialog';
import { AddMetadataColumnDialog } from './dialogs/AddMetadataColumnDialog';
import { ReportBuilderAddColumnDialog } from './dialogs/ReportBuilderAddColumnDialog';
import { SelectObjectPropertyDialog } from './dialogs/SelectObjectPropertyDialog';
import { FilterCheckboxSelections } from './FilterCheckboxSelections';
import { useReportBuilder } from './hooks/useReportBuilder';
import { ReportBuilderStepOne } from './ReportBuilderStepOne';
import { ReportBuilderInstance } from './types';

type StepThreeProps = {
	instance?: ReportBuilderInstance;
	setSelectedPrimaryEntity: (primary: string) => void;
	selectedPrimaryEntity: string;
	selectedFilters: any[];
	currentUser: User;
	templates: WorkflowTemplate[];
	workflows: Workflow[];
	assets: AssetVersion[];
	users: User[];
	onAdvance: (
		columns: Array<{ header: string; key: string; index: number }>,
		addedFilters: Array<{
			key: string;
			type: 'between' | 'greater-than' | 'less-than' | 'equals' | 'contains';
			metadata?: {
				start: string;
				end: string;
				connector: 'or' | 'and';
			};
			value: string;
		}>,
		dateFormat?: string
	) => void;
	onChange: (
		columns: Array<{ header: string; key: string; index: number }>
	) => void;
};

type ReportKeys = Array<{ type: string; keys: string[] }>;

type Th = {
	dragOver: boolean;
};

const StyledTh = styled.th<Th>`
	white-space: nowrap;
	color: #716f88;
	letter-spacing: 1.5px;
	text-align: left;
	text-transform: capitalize;
	vertical-align: middle;
	border-bottom: 2px solid #eef0f5;
	text-transform: uppercase;

	cursor: grab;

	border-left: ${(props) => props.dragOver && `5px solid red`};
`;

export const ReportBuilderStepThree = (props: StepThreeProps) => {
	const { defaults } = useThemeContext();
	const Key = styled.p`
		padding: 9px 14px;
		border-radius: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: grab;
		border: 1px dashed ${defaults?.primary};
	`;

	const AddKey = styled.span`
		position: absolute;
		right: 25px;
	`;

	const {
		selectedPrimaryEntity,
		currentUser,
		templates,
		workflows,
		assets,
		users,
		onAdvance,
	} = props;

	// hooks

	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();

	const { filterReportRows } = useReportBuilder();

	// state && lifecycles
	const [columns, setColumns] = useState<
		Array<{ key: string; header: string; index: number }>
	>([]);
	const [previewRows, setPreviewRows] = useState<number>(5);
	const [reportingDateFormat, setReportingDateFormat] = useState('');
	const [addedFilters, setAddedFilters] = useState<
		{
			key: string;
			type: 'greater-than' | 'less-than' | 'equals' | 'contains' | 'between';
			value: string;
			metadata?: { start: string; end: string; connector: 'and' | 'or' };
		}[]
	>([]);

	const keyArray = React.useMemo(
		() => [
			{
				type: 'users',
				keys: keysIn(currentUser).filter(
					(a: any) => !Array.isArray(prop(a)(currentUser))
				),
			},
			{
				type: 'templates',
				keys:
					templates && templates[0]
						? [
								...keysIn(templates[0]!).filter(
									(a: any) => !Array.isArray(prop(a)(templates[0]))
								),
						  ]
						: [],
			},
			{
				type: 'assets',
				keys:
					assets && assets[0]
						? keysIn(assets[0]!).filter(
								(a: any) => !Array.isArray(prop(a)(assets[0]))
						  )
						: [],
			},
			{
				type: 'workflows',
				keys:
					workflows && workflows[0]
						? [
								...keysIn(workflows[0]!).filter(
									(a: any) => !Array.isArray(prop(a)(workflows[0]))
								),
								'Active Stages',
						  ]
						: [],
			},
		],
		[currentUser, workflows, templates, assets]
	);

	const { instance } = props;
	useEffect(() => {
		if (instance) {
			props.setSelectedPrimaryEntity(instance.selectedPrimaryEntity);
			setColumns(instance.columns);
			if (instance.filters) setAddedFilters(instance.filters);
			if (instance.formatters && instance.formatters.length)
				setReportingDateFormat(instance.formatters[0].value);
		}
		//eslint-disable-next-line
	}, [instance]);

	// helper functions
	const showAddModal = (reportKey: string) => {
		modalStack.addModal(
			<ReportBuilderAddColumnDialog
				onCancel={() => {
					modalCloser.closeModal();
				}}
				reportKey={reportKey}
				onUpdate={(header) => {
					setColumns(
						columns.map((a) => (a.key === reportKey ? { ...a, header } : a))
					);
					modalCloser.closeModal();
				}}
			/>
		);
	};

	const getReportArrayForType = (type: string) => {
		if (type === 'workflows') return workflows;
		if (type === 'templates') return templates;
		if (type === 'assets') return assets;
		if (type === 'users') return users;
		return [];
	};

	const getMetadataOptionsForType = (type: string, isSku = false) => {
		if (type === 'workflows')
			return workflows
				?.filter((a) => (isSku ? !!a.skuMetadata : !!a.metadata))
				.map((a) => a.metadata?.values) as EntityMetadata[];
		if (type === 'templates')
			return templates
				?.filter((a) => (isSku ? !!a.skuMetadata : !!a.metadata))
				.map((a) => a.metadata?.values) as EntityMetadata[];
		if (type === 'assets')
			return assets
				?.filter((a) => (isSku ? false : !!a.metadata))
				.map((a) => a.metadata?.values) as EntityMetadata[];
		if (type === 'users') return [] as EntityMetadata[];
	};

	const sampleRow = (key: any, index: number) => {
		const report = {
			selectedPrimaryEntity,
			columns: columns,
			filters: addedFilters,
		} as ReportBuilderInstance;
		const data = filterReportRows(
			report,
			getReportArrayForType(selectedPrimaryEntity)
		) as any;
		if (data && data.rows) {
			if (data.rows[index]) {
				try {
					return data.rows[index][key] ? data.rows[index][key] : '';
				} catch {
					return '';
				}
			}
		}
		return '';
	};
	const totalRows = () => {
		const report = {
			selectedPrimaryEntity,
			columns: columns,
			filters: addedFilters,
		} as ReportBuilderInstance;
		const data = filterReportRows(
			report,
			getReportArrayForType(selectedPrimaryEntity)
		) as any;
		if (data && data.rows) {
			return data.rows.length;
		}
		return '';
	};

	const populateOptions = (key: string) => {
		if (key === 'createdBy') {
			return [...users?.map((a) => a.givenName + ' ' + a.familyName)];
		}

		switch (selectedPrimaryEntity) {
			case 'users':
				return uniqBy(users, (a) => a[key as keyof User]).map(
					(a) => a[key as keyof User]
				);

			case 'templates':
				if (['metadata', 'skuMetadata'].some((a) => a === key)) {
					return uniqBy(templates, (a) => a.metadata.values)
						.filter((a) => !!a.metadata.values)
						.map((a) => a.metadata.values);
				}

				return uniqBy(templates, (a) => a[key as keyof WorkflowTemplate]).map(
					(a) => a[key as keyof WorkflowTemplate]
				);

			case 'workflows':
				if (['metadata', 'skuMetadata'].some((a) => a === key)) {
					return uniqBy(templates, (a) => a.metadata.values)
						.filter((a) => !!a.metadata.values)
						.map((a) => a.metadata.values);
				}
				if (key === 'Active Stages') {
					return uniqBy(
						workflows.map((w) => listActiveStageNames(w)),
						(a) => a
					);
				}
				return uniqBy(workflows, (a) => a[key as keyof Workflow]).map(
					(a) => a[key as keyof Workflow]
				);

			case 'assets':
				return uniqBy(assets, (a) => a[key as keyof AssetVersion]).map(
					(a) => a[key as keyof AssetVersion]
				);

			default:
				return [];
		}
	};

	const getFilterForKey = (key: string) =>
		addedFilters.find((filter: any) => filter.key === key);

	const filterTypeIconMap = {
		'greater-than': faGreaterThan,
		'less-than': faLessThan,
		between: faCalendar,
		equals: faEquals,
		contains: faLayerGroup,
	};

	const getFilterTypeByKey = (key: string) => getFilterForKey(key)?.type;

	const getSubFiltersForKey = (key: string) =>
		addedFilters.filter(
			(filter: any) =>
				key !== 'title' && filter.key.includes(key) && filter.key.includes('.')
		);

	const [dragOver, setDragOver] = useState('');

	const handleDragStart = (e: any) => {
		const { id } = e.target;
		const idx = columns.findIndex((a) => a.key === id);
		if (idx > -1) {
			e.dataTransfer.setData('colIdx', idx);
		} else {
			e.dataTransfer.setData('colKey', id);
		}
	};

	const handleDragOver = (e: any) => e.preventDefault();
	const handleDragEnter = (e: any) => {
		const { id } = e.target;
		if (columns.some((a) => a.key === id)) {
			setDragOver(id);
		}
	};

	const insert = (arr: any, index: any, newItem: any) =>
		[
			// part of the array before the specified index
			...arr.slice(0, index),
			// inserted item
			newItem,
			// part of the array after the specified index
			...arr.slice(index),
		].map((a, idx) => ({ ...a, index: idx }));

	const handleOnDrop = (e: any) => {
		const { id } = e.target;
		const actualKey = e.dataTransfer.getData('colKey');

		const droppedColIdx = columns.findIndex((a) => a.key === id);
		if (actualKey === 'remove' && droppedColIdx >= 0 && columns) {
			setColumns((previousColumns) => [
				...previousColumns
					.filter((a) => a.key !== columns![droppedColIdx].key)
					.map((column, index) => ({ ...column, index })),
			]);
			return;
		}

		if (droppedColIdx <= -1 || actualKey) {
			let newColumns: any[] = [];
			if (actualKey) {
				const newIdx = droppedColIdx > -1 ? droppedColIdx : columns.length;
				if (droppedColIdx >= -1) {
					newColumns = insert(columns, newIdx, {
						key: actualKey,
						header: actualKey,
						index: newIdx,
					});
				} else {
					newColumns = [
						...(columns || []),
						{ key: actualKey, header: actualKey, index: newIdx },
					];
				}
			} else if (droppedColIdx > -1) {
				const newIdx = droppedColIdx > -1 ? droppedColIdx : columns.length;
				newColumns = insert(columns, newIdx, {
					key: actualKey,
					header: actualKey,
					index: newIdx,
				});
			}
			console.log(newColumns);
			setColumns(newColumns);
		} else {
			const draggedColIdx = e.dataTransfer.getData('colIdx');
			const tempCols = [...columns];

			tempCols[draggedColIdx] = columns[droppedColIdx];
			tempCols[droppedColIdx] = columns[draggedColIdx];
			setColumns(tempCols);
		}
		setDragOver('');
	};

	const [view, setView] = React.useState<'actions' | 'filters' | 'formatting'>(
		'actions'
	);

	// const onPreviewChange = (val: number) => setPreviewRows(val);
	const date = new Date();
	const dateFormats = [
		{ value: date.toDateString(), formatter: 'toDateString' },
		{ value: date.toISOString(), formatter: 'toISOString' },
		{ value: date.toLocaleDateString(), formatter: 'toLocaleDateString' },
		{ value: date.toLocaleString(), formatter: 'toLocaleString' },
	];

	useEffect(() => console.log(addedFilters), [addedFilters]);
	const addFilterDialog = (
		key: string,
		type: 'equals' | 'greater-than' | 'less-than' | 'contains' | 'between'
	) => {
		if (populateOptions(key) && typeof populateOptions(key)[0] === 'object')
			modalStack.addModal(
				<SelectObjectPropertyDialog
					type={type as any}
					objectArray={populateOptions(key) as Object[]}
					objectTitle={key}
					onClose={modalCloser.closeModal}
					onSelect={(selectedProperty, selectedValue) => {
						setAddedFilters([
							...addedFilters,
							{
								type,
								key: ['metadata', 'skuMetadata'].some((k) => k === key)
									? `${key}.values.${selectedProperty}`
									: `${key}.${selectedProperty}`,
								value: selectedValue,
							},
						]);
					}}
				/>
			);
		else
			modalStack.addModal(
				<AddFilterDialog
					options={populateOptions(key) as string[]}
					onCancel={modalCloser.closeModal}
					type={type}
					onUpdate={(filter) =>
						setAddedFilters([...addedFilters, { ...filter }])
					}
					reportKey={key}
				/>
			);
	};

	const FilterAlert = (props: {
		color: string;
		icon: IconProp;
		text?: string;
		badgeText?: boolean;
	}) => (
		<StyledAlert color={props.color}>
			<p>
				<FontAwesomeIcon icon={props.icon} className="mr-2" />
				{props.badgeText && (
					<div className="d-flex">
						<PaddedBadge
							color={'danger'}
						>{`${totalRows()} Total Rows`}</PaddedBadge>
					</div>
				)}
				{props.text
					? props.text
					: `${addedFilters.length} Filters Currently Applied`}
			</p>
		</StyledAlert>
	);

	const renderFilterSelectedValue = (filter: any, col: any) => {
		const filterValue = addedFilters.find((f) => filter.key === f.key)?.value;
		if (filterValue === 'metadata') {
			return `Range: ${
				addedFilters.find((f) => f.key === filter.key)?.metadata?.start
			} - 
		${addedFilters.find((f) => f.key === filter.key)?.metadata?.end}`;
		}
		return filterValue;
	};

	const renderReportActions = () => (
		<>
			<Row>
				<Nav tabs>
					<li onClick={() => setView('actions')} className={'nav-item'}>
						<span className={`nav-link ${view === 'actions' ? 'active' : ''}`}>
							Actions
						</span>
					</li>
					<li onClick={() => setView('formatting')} className={'nav-item'}>
						<span
							className={`nav-link ${view === 'formatting' ? 'active' : ''}`}
						>
							Formatting
						</span>
					</li>
					<li onClick={() => setView('filters')} className={'nav-item'}>
						<span className={`nav-link ${view === 'filters' ? 'active' : ''}`}>
							Filters
						</span>
					</li>
				</Nav>
			</Row>
			<Row>
				<CardBody>
					{view === 'actions' && (
						<Col xl={12}>
							<FormGroup style={{ maxWidth: 150 }}>
								<Label className="p-0 m-0">Remove columns</Label>
								<Key
									className="text-danger"
									id={'remove'}
									draggable
									onDragStart={handleDragStart}
									onDragOver={handleDragOver}
									onDragEnter={handleDragEnter}
								>
									Delete &nbsp;
									<UncontrolledTooltip trigger={'hover'} target={'remove'}>
										Drag on top of a column to remove from the report
									</UncontrolledTooltip>
									<FontAwesomeIcon icon={faTimesCircle} />
								</Key>
							</FormGroup>
						</Col>
					)}

					{view === 'formatting' && (
						<Col xl={12}>
							<FormGroup>
								<Label className="m-0 p-0">Dates</Label>
								<select
									className="form-control"
									onChange={(e) => setReportingDateFormat(e.target.value)}
								>
									<option value={''}>Choose a date format</option>
									{dateFormats.map((format) => (
										<option key={format.formatter} value={format.formatter}>
											{format.value}
										</option>
									))}
								</select>
							</FormGroup>
						</Col>
					)}
					{view === 'filters' && (
						<Col xl={12} style={{ maxHeight: 350, overflow: 'auto' }}>
							{addedFilters.length === 0 && (
								<FilterAlert color={'warning'} icon={faBatteryEmpty} />
							)}
							{addedFilters.length > 0 && (
								<FilterAlert color={'success'} icon={faFilter} />
							)}

							<FormGroup>
								<Label className="m-0 p-0">
									{addedFilters.length === 0
										? 'Add report filters'
										: 'Update report filters'}
								</Label>
								<ScrollTable>
									<tbody>
										{columns
											.filter((col) => !col.key.includes('metadata.'))
											.map((col, index) => (
												<>
													<tr key={col.key}>
														<td style={{ verticalAlign: 'middle' }}>
															{getFilterForKey(col.key) && index !== 0 && (
																<FormText
																	style={{ display: 'block' }}
																></FormText>
															)}
															<p className="d-flex align-items-center">
																{camelToTitle(col.key)}
															</p>
														</td>
														{!getFilterForKey(col.key) && (
															<td>
																<UncontrolledDropdown>
																	<DropdownToggle color="white">
																		<FontAwesomeIcon icon={faEllipsisV} />
																	</DropdownToggle>
																	<DropdownMenu>
																		<DropDownMenuItem
																			onClick={() =>
																				addFilterDialog(col.key, 'equals')
																			}
																		>
																			= equals
																		</DropDownMenuItem>

																		<DropDownMenuItem
																			onClick={() =>
																				addFilterDialog(col.key, 'contains')
																			}
																		>
																			~ contains
																		</DropDownMenuItem>
																		{col.key === 'createdAt' && (
																			<React.Fragment>
																				<DropDownMenuItem
																					onClick={() =>
																						addFilterDialog(
																							col.key,
																							'greater-than'
																						)
																					}
																				>
																					{'> greater than'}
																				</DropDownMenuItem>

																				<DropDownMenuItem
																					onClick={() =>
																						addFilterDialog(
																							col.key,
																							'less-than'
																						)
																					}
																				>
																					{'less than < '}
																				</DropDownMenuItem>

																				<DropDownMenuItem
																					onClick={() =>
																						addFilterDialog(col.key, 'between')
																					}
																				>
																					{'start and end (date-range)'}
																				</DropDownMenuItem>
																			</React.Fragment>
																		)}
																	</DropdownMenu>
																</UncontrolledDropdown>
															</td>
														)}
														{!!getFilterForKey(col.key) && (
															<td>
																<p>
																	<FormText className="d-block">
																		{getFilterTypeByKey(col.key)?.toUpperCase()}
																		<FontAwesomeIcon
																			style={{
																				color: defaults?.primary,
																				fontSize: 28,
																			}}
																			icon={
																				filterTypeIconMap[
																					getFilterTypeByKey(col.key)!
																				]
																			}
																		/>
																	</FormText>
																</p>
															</td>
														)}
														<td>
															<select className="form-control">
																<option>None Selected</option>
																{!!getFilterForKey(col.key) && (
																	<option
																		selected={
																			!!addedFilters.find(
																				(filter) => filter.key === col.key
																			)
																		}
																	>
																		{renderFilterSelectedValue(
																			getFilterForKey(col.key),
																			col
																		)}
																	</option>
																)}
															</select>
														</td>
														{!!getFilterForKey(col.key) && (
															<td>
																<FontAwesomeIcon
																	icon={faTimesCircle}
																	className="text-danger"
																	style={{ fontSize: 28 }}
																	onClick={() =>
																		setAddedFilters((addedFilters) => [
																			...addedFilters.filter(
																				(filter) => filter.key !== col.key
																			),
																		])
																	}
																/>
															</td>
														)}
													</tr>
													{getSubFiltersForKey(col.key) &&
														getSubFiltersForKey(col.key).map((filter) => (
															<TrSub>
																<td style={{ verticalAlign: 'middle' }}>
																	<p className="d-flex align-items-center">
																		{camelToTitle(filter.key)}
																	</p>
																</td>
																<td>
																	<p>
																		<FormText className="d-block">
																			{filter.type?.toUpperCase()}
																			<FontAwesomeIcon
																				style={{
																					color: defaults?.primary,
																					fontSize: 28,
																				}}
																				icon={filterTypeIconMap[filter.type]}
																			/>
																		</FormText>
																	</p>
																</td>
																<td>
																	<select className="form-control">
																		<option>None Selected</option>
																		{!!getFilterForKey(filter.key) && (
																			<option selected>{filter.value}</option>
																		)}
																	</select>
																</td>
																<td>
																	<FontAwesomeIcon
																		icon={faTimesCircle}
																		className="text-danger"
																		style={{ fontSize: 28 }}
																		onClick={() =>
																			setAddedFilters((addedFilters) => [
																				...addedFilters.filter(
																					(c) => filter.key !== c.key
																				),
																			])
																		}
																	/>
																</td>
															</TrSub>
														))}
												</>
											))}
									</tbody>
								</ScrollTable>
							</FormGroup>
						</Col>
					)}
				</CardBody>
			</Row>
		</>
	);

	const showAddMetadataModal = () => {
		modalStack.addModal(
			<AddMetadataColumnDialog
				objectArray={getMetadataOptionsForType(selectedPrimaryEntity)}
				onClose={modalCloser.closeModal}
				onSelect={(selectedColumn: string, allProperties: string[]) => {
					const all = allProperties
						.filter((key) => !columns.some((col) => col.key === key))
						.map((property, idx) => ({
							header: property.replace('metadata.', ''),
							index: columns.length + idx,
							key: property,
						}));
					if (selectedColumn !== 'all')
						setColumns((columns) => [
							...columns,
							{
								header: selectedColumn,
								index: columns.length,
								key: selectedColumn,
							},
						]);
					else setColumns([...columns, ...all]);
				}}
			/>
		);
	};

	const renderSkuMetadata = (skuMetadata: string) => {
		if (JSON.parse(skuMetadata)) {
			const meta = JSON.parse(skuMetadata) as Array<{
				fields: string[];
				values: Record<string, string | string[]>;
			}>;
			const output =
				meta && meta.length && meta[0].fields ? (
					<ScrollTable style={{ border: 'none' }}>
						<tbody style={{ border: 'none' }}>
							{meta.map((item, index) => (
								<TrSub key={index}>
									{Object.keys(item.values).map((value, index) => (
										<td style={{ border: 'none' }}>
											<EntityPropLabel className="d-block">
												{value}
											</EntityPropLabel>
											<p>
												{Array.isArray(item.values[value])
													? toArray(item.values[value]).join(',')
													: item.values[value]}
											</p>
										</td>
									))}
								</TrSub>
							))}
						</tbody>
					</ScrollTable>
				) : (
					<SmallTextMuted>No SKU Metadata to display</SmallTextMuted>
				);
			return output;
		} else {
			return <SmallTextMuted>No SKU Metadata to display</SmallTextMuted>;
		}
	};

	const renderKeysRecursive = (object: any): JSX.Element[] => {
		return Object.keys(object)
			.filter((key) => key !== 'createdBy')
			.map((key) => {
				return typeof object[key] === 'object' ? (
					renderKeysRecursive(object[key])
				) : (
					<EntityPropListItem key={key}>
						<EntityPropLabel>{key}</EntityPropLabel>
						<p>{object[key]}</p>
					</EntityPropListItem>
				);
			}) as JSX.Element[];
	};

	const dateFns = React.useMemo(() => {
		return (date: Date) => {
			if (reportingDateFormat) {
				return new Date(date)[reportingDateFormat as 'toISOString']();
			} else return new Date(date).toISOString();
		};
	}, [reportingDateFormat]);

	const isValidDate = (date: Date) => {
		return new Date(date).getFullYear() >= 2015;
	};

	/**
	 * Convert camel case to title case
	 * @param val: String
	 * @returns String
	 */
	const camelToTitle = (val: String) => {
		let result = val.replace(/([A-Z])/g, ' $1');
		return result.charAt(0).toUpperCase() + result.slice(1);
	};

	// component
	return (
		<>
			<Col xl={12} className="pl-3">
				<ReportBuilderStepOne
					selectedPrimaryEntity={selectedPrimaryEntity}
					setSelectedPrimaryEntity={(newSelection) => {
						props.setSelectedPrimaryEntity(newSelection);
						setAddedFilters([]);
						setColumns([]);
					}}
				/>
			</Col>
			<Divider />
			{keyArray
				.filter((a) => a.type === selectedPrimaryEntity)
				.map((key) => (
					<Col key={key.type} xl={12}>
						<Row>
							<Col xl={4}>
								<p className="d-flex justify-content-between">
									Drag available {selectedPrimaryEntity} columns to populate
									your report.
								</p>
							</Col>
							{!!getMetadataOptionsForType(selectedPrimaryEntity) &&
								getMetadataOptionsForType(selectedPrimaryEntity)?.length! >
									0 && (
									<Col xl={2}>
										<div className="d-flex justify-content-stretch flex-row">
											<AnchorSpan onClick={showAddMetadataModal}>
												Add a metadata column{' '}
												<FontAwesomeIcon icon={faPlusCircle} />
											</AnchorSpan>
										</div>
									</Col>
								)}
						</Row>
						<Row>
							<Col style={{ borderRight: '1px solid #ccc' }} xl={6}>
								<Row>
									{key.keys
										.filter((a) => !columns.some((col) => col?.key === a))
										.map((reportKey) => (
											<Col key={reportKey} xl={3}>
												<Key
													title={camelToTitle(reportKey)}
													id={reportKey}
													draggable
													onDragStart={handleDragStart}
													onDragOver={handleDragOver}
													onDragEnter={handleDragEnter}
												>
													<span
														style={{
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															whiteSpace: 'nowrap',
														}}
													>
														{camelToTitle(reportKey)}
														<AddKey
															onClick={() => {
																showAddModal(reportKey);
															}}
														></AddKey>
													</span>
												</Key>
											</Col>
										))}
								</Row>
							</Col>
							<Col xl={5} style={{ marginLeft: '.5rem' }}>
								{columns.length === 0 && (
									<FilterAlert
										text="Add columns to utilize report actions"
										color={'warning'}
										icon={faInfo}
									/>
								)}
								{!!columns?.length && renderReportActions()}
							</Col>
						</Row>
					</Col>
				))}

			<Col xl={12} style={{ overflowX: 'scroll' }}>
				<div className={'d-block'}>
					<PageSubheading text="Report Preview" />
					{addedFilters.length > 0 && (
						<FilterAlert
							text={
								'Added filters are automatically applied during report creation.'
							}
							badgeText
							color={'info'}
							icon={faInfoCircle}
						/>
					)}
					{addedFilters.length === 0 && columns.length > 0 && (
						<PaddedBadge color={'danger'}>{totalRows()} Total Rows</PaddedBadge>
					)}
					<FormGroup>
						<Label>Amount of rows to preview</Label>

						<FilterCheckboxSelections
							selected={[{ title: previewRows.toString() }]}
							options={[5, 10, 15].map((a) => ({ title: a.toString() }))}
							multiple={false}
							defaults={defaults}
							onChange={(selected) => {
								if (selected.length) setPreviewRows(+selected[0].title);
								else setPreviewRows(5);
							}}
						/>
					</FormGroup>
				</div>
				<ScrollTable>
					<thead>
						<tr>
							{columns.length === 0 && (
								<StyledTh
									id={'initial'}
									key={'initial'}
									draggable
									dragOver={dragOver === 'initial'}
									onDragStart={handleDragStart}
									onDragOver={handleDragOver}
									onDrop={handleOnDrop}
									onDragEnter={handleDragEnter}
								>
									Drag a column here to add to report
								</StyledTh>
							)}
							{columns.length > 0 &&
								columns.map((a) => (
									<StyledTh
										id={a.key}
										key={a.key}
										draggable
										onClick={() => {
											showAddModal(a.key);
										}}
										onDragStart={handleDragStart}
										onDragOver={handleDragOver}
										onDrop={handleOnDrop}
										onDragEnter={handleDragEnter}
										dragOver={a.key === dragOver}
									>
										{camelToTitle(a.header)}
										{a.index === 0 && columns.length > 1 && (
											<>
												<UncontrolledTooltip target={'infoTooltip'}>
													Drag and drop table headers to order columns.
												</UncontrolledTooltip>
												<FontAwesomeIcon
													className="ml-1"
													icon={faInfoCircle}
													id={'infoTooltip'}
												/>
											</>
										)}
									</StyledTh>
								))}
						</tr>
					</thead>
					<tbody>
						{range(previewRows).map((row, index) => (
							<tr key={index}>
								{columns.map((a) => (
									<td key={a.index}>
										{a.key !== 'skuMetadata' &&
											typeof sampleRow(a.key, index) === 'object' && (
												<EntityPropList
													style={{ maxHeight: 150, overflowY: 'auto' }}
												>
													{renderKeysRecursive(sampleRow(a.key, index))}
												</EntityPropList>
											)}
										{a.key === 'skuMetadata' &&
											renderSkuMetadata(sampleRow(a.key, index))}

										{isValidDate(new Date(sampleRow(a.key, index) as Date)) && (
											<>{dateFns(sampleRow(a.key, index) as Date)}</>
										)}

										{a.key !== 'skuMetadata' &&
											!isValidDate(new Date(sampleRow(a.key, index) as Date)) &&
											['string', 'boolean', 'number'].some(
												(key) => typeof sampleRow(a.key, index) === key
											) &&
											sampleRow(a.key, index)?.toString()}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</ScrollTable>
			</Col>
			{columns.length > 0 && (
				<Col xl={12}>
					<SecondaryButton
						onClick={() =>
							onAdvance(columns, addedFilters, reportingDateFormat)
						}
					>
						{' '}
						<FontAwesomeIcon icon={faChevronRight} /> Advance to next step
					</SecondaryButton>
				</Col>
			)}
		</>
	);
};
