import { UploadResult, Uppy, UppyFile } from '@uppy/core';
import { AuthProvider } from './AuthProvider';

export class FileUploadProvider {
	private readonly endpoint: string;
	public readonly uppy: Uppy;

	constructor(endpoint: string, private readonly authProvider: AuthProvider) {
		this.endpoint = endpoint + '/uploads';

		this.uppy = new Uppy({
			id: 'rome-assets',
			meta: { type: 'file' },
			restrictions: { maxNumberOfFiles: 1 },
			autoProceed: true,
		});

		// TODO if we ever use tus
		// observe(
		// 	this.authProvider,
		// 	'idToken',
		// 	({ newValue }) => {
		// 		const plugin = this.uppy.getPlugin('Tus');
		// 		this.uppy.removePlugin(plugin);
		// 		this.uppy.use(Tus, {
		// 			endpoint: this.endpoint,
		// 			headers: {
		// 				'Authorization': `Bearer: ${ newValue }`,
		// 			},
		// 			resume: true,
		// 			autoRetry: true,
		// 			retryDelays: [0, 1000, 3000, 5000],
		// 		});
		// 	},
		// );
		//
		// this.uppy.use(Tus, {
		// 	endpoint: this.endpoint,
		// 	resume: true,
		// 	headers: {
		// 		'Authorization': `Bearer ${ this.authProvider.idToken }`,
		// 	},
		// 	autoRetry: true,
		// 	retryDelays: [0, 1000, 3000, 5000],
		// });

		this.uppy.on('complete', (result) => {});
	}
}

export function toFile(uf: UppyFile): File {
	if (uf.data instanceof File) {
		return uf.data;
	}
	return new File([uf.data], uf.name, { type: uf.type });
}

export function resultToFile(ur?: UploadResult): Maybe<File> {
	if (ur) {
		const [uploadedFile] = ur.successful;
		if (uploadedFile) {
			return toFile(uploadedFile);
		}
	}
	return undefined;
}
