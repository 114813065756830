import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { isIdentifiableLoaded } from '../common';

import BackLink from '../components/back-link/back-link.component';
import { LoadingRow } from '../components/loading.component';
import { Heading } from '../components/ui';
import { useAxios } from '../hooks';
import themeStore from '../theme/models/ThemeStore';
import { WorkflowCollection } from '../workflows/types/workflow.types';
import EditWorkflowCollectionForm from './components/edit-workflow-collection-form.component';

// constants
const collectionTerm = themeStore._.workflowCollection;

// interface
interface EditWorkflowCollectionProps {
	projectId: string;
}

// component
const WorkflowCollectionEditView = (props: EditWorkflowCollectionProps) => {
	const { projectId } = props;
	const workflowCollectionStore = useAxios<WorkflowCollection>('projects');
	const [fetching, setFetching] = useState(false);
	const [editedCollection, setEditedCollection] = useState<
		WorkflowCollection
	>();
	React.useEffect(() => {
		if (fetching) return;
		if (!editedCollection) {
			setFetching(true);
			workflowCollectionStore
				.findOne(projectId)
				.then(setEditedCollection)
				.finally(() => setFetching(false));
		}
	}, [fetching, editedCollection, workflowCollectionStore, projectId]);
	const render = () => {
		return (
			<Container>
				{isIdentifiableLoaded(editedCollection) ? (
					<Row className="justify-content-center">
						<Col lg={8}>
							<BackLink link=".." title={`${collectionTerm} Details`} />
							<div className="d-flex justify-content-between">
								<Heading>Edit "{editedCollection.title}"</Heading>
							</div>
						</Col>
						<Col lg={8}>
							<EditWorkflowCollectionForm collection={editedCollection} />
						</Col>
					</Row>
				) : (
					<Container>
						<LoadingRow label="Loading..." />
					</Container>
				)}
			</Container>
		);
	};

	return render();
};

export default WorkflowCollectionEditView;
