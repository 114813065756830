import { UploadResult } from '@uppy/core';
import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import React, { useState } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { AssetVersion } from 'workflows/types';
import AssetPicker from './asset-picker.component';
type Props = {
	asset: AssetVersion;
	onFormSubmit: (file: UploadResult) => void;
	onCancel: () => void;
};

export const ReplaceAssetDialog = (props: Props) => {
	const [result, setResult] = useState<Maybe<UploadResult>>();
	const submit = () => {
		if (result?.successful[0]) {
			const file = result;
			props.onFormSubmit(file);
		}
	};
	return (
		<ConfirmationDialog
			confirmText={'Upload'}
			header={'Replace ' + props.asset.fileName}
			onConfirm={submit}
		>
			<Form>
				<Row form>
					<Col md={12} className="p-2">
						<AssetPicker onSelect={setResult} />
					</Col>
				</Row>
			</Form>
		</ConfirmationDialog>
	);
};
