import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import { NotificationsContext } from '../notifications';

import { SecondaryButton } from './buttons.styled-components';
import { connectStateResults } from 'react-instantsearch-dom';
import { SearchState } from 'react-instantsearch-core';
import { useQueryStoreContext } from 'queries/hooks/useQueryStore';

type Props = {
	searchState: SearchState;
	className: string;
	type: string;
};

const _SaveAlgoliaQueryButton = ({ searchState, type, className }: Props) => {
	const { info } = React.useContext(NotificationsContext);
	const { addNew: addNewQuery } = useQueryStoreContext();

	const saveQuery = () => {
		if (!searchState.query) return;
		addNewQuery(searchState.query, type, true);
		info(`Saved query "${searchState.query}".`);
	};

	return (
		<SecondaryButton
			className={`save-query-button ${className}`}
			onClick={saveQuery}
			disabled={!searchState.query}
		>
			<FontAwesomeIcon icon={faSave} className="mr-2" />
			<span className="button-label">Save query</span>
		</SecondaryButton>
	);
};

const SaveAlgoliaQueryButton = connectStateResults(_SaveAlgoliaQueryButton);
export default SaveAlgoliaQueryButton;
