import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Stage } from 'workflows/types';
import { WorkflowsIndexView } from 'workflows/WorkflowIndexPage';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import * as H from '../helpers';
import RoadblocksTable from 'workflows/components/tables/RoadblockedTable';

export const Roadblocks = () => {
	const { entities: workflows } = useWorkflowContext();

	const [stages, setStages] = useState<Stage[]>([]);

	useEffect(() => {
		if (!workflows) return;
		const activeEntities = workflows.filter(R.propEq('status', 'active'));
		setStages(
			H.getAllStages(activeEntities).filter(
				({ status }: Stage) => status === 'roadblocked'
			)
		);
	}, [workflows]);

	// handleSorting
	const sortingOptions = ['A - Z', 'Z - A'].map((val) => ({
		label: val,
		value: val,
	}));
	// Sort Handler
	const handleSort = (sorter: string) => {
		if (sorter === 'A - Z') setStages(H.alphabetizeBy('title', stages));
		if (sorter === 'Z - A') setStages(H.reverseAlphabetizeBy('title'));
	};

	const views = {
		table: (stages: Stage[]) => (
			<RoadblocksTable stages={stages} workflows={workflows} />
		),
	};
	const sorting = {
		options: sortingOptions,
		fn: handleSort,
	};

	return (
		<WorkflowsIndexView
			title="Roadblocks"
			collection={stages}
			views={views}
			sorting={sorting}
		/>
	);
};
