import { navigate } from '@reach/router';
import React, { FormEvent, useCallback } from 'react';
import WorkflowCollectionForm from './workflow-collection-form.component';
import {
	Workflow,
	WorkflowCollection,
} from '../../workflows/types/workflow.types';
import { generateID } from '../../common';
import { useAuthContext } from '../../auth';
import { useAxios } from '../../hooks';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { buildEmptyAssetMetadata } from 'dam-assets/components/helpers/useAssetHelper';
import { useWorkflowCollectionContext } from 'workflows/hooks/useWorkflowCollectionStore';

const AddWorkflowCollectionForm = () => {
	const collectionStore = useAxios<WorkflowCollection>('projects');
	const { updateContext } = useWorkflowCollectionContext();
	const { currentUser } = useAuthContext();
	const { entities: workflows } = useWorkflowContext();
	const [collectionRef, setCollectionRef] = React.useState<WorkflowCollection>({
		_id: generateID(),
		createdBy: currentUser,
		metadata: buildEmptyAssetMetadata,
	} as WorkflowCollection);
	const workflowStore = useAxios<Workflow>('workflows');

	const addCollection = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const createdCollection = (await collectionStore.createOne(
				collectionRef
			)) as WorkflowCollection;

			createdCollection.workflows.forEach(async (wf: string | Workflow) => {
				const flow = workflows.find((m) => m._id === (wf as string));
				const edited = {
					...flow,
					workflowCollection: createdCollection,
				} as Workflow;
				await workflowStore.updateOne(edited?._id, edited);
			});
			updateContext(createdCollection);
			return navigate(`/admin/workflow/projects/${createdCollection._id}`);
		},
		//eslint-disable-next-line
		[collectionStore, workflowStore, workflows, collectionRef]
	);

	return (
		<WorkflowCollectionForm
			onChange={(updated) => setCollectionRef(updated)}
			selectedCollection={collectionRef}
			onSubmit={addCollection}
		/>
	);
};
export default AddWorkflowCollectionForm;
