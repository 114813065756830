import React from 'react';
import { buildStageUrl } from '../../../common/links';
import {
	EntityPropLabel,
	EntityPropListItem,
} from '../../../components/entity-details.styled-components';
import themeStore from '../../../theme/models/ThemeStore';
import {
	Workflow,
	Stage,
	AssetVersion,
} from '../../../workflows/types/workflow.types';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { findVersionInWorkflow } from './info-panel.component';
import { navigate } from '@reach/router';
import { useModalCloser } from 'stores/ModalStack';
import { Button } from 'reactstrap';
import styled from 'styled-components';
const stageTerm = themeStore._.stage;

export const ButtonLink = styled(Button)`
	background: transparent !important;
	color: #222 !important;
	border: transparent !important;
	font-size: 14px !important;
	padding: 0 !important;
	text-align: left !important;
`;

const AssetWorkflowProps = (props: {
	asset: AssetVersion;
	workflow?: Workflow;
}) => {
	const { asset } = props;
	const modalCloser = useModalCloser();
	let { workflow } = useWorkflowContext();
	let stage: Stage;

	// get workflow
	try {
		if (!workflow) workflow = props.workflow;
	} catch (error) {
		return null;
	}

	// get stage
	try {
		stage = findVersionInWorkflow(asset._id, workflow as Workflow) as Stage;
	} catch (error) {
		return null;
	}

	const navigateToStageURL = () => {
		modalCloser.closeModal();
		return navigate(buildStageUrl(workflow, stage));
	};

	// render list item
	return workflow && stage ? (
		<EntityPropListItem>
			<EntityPropLabel>Added in {stageTerm.toLowerCase()}</EntityPropLabel>
			<ButtonLink onClick={navigateToStageURL}>
				{workflow.title} ({stage.title})
			</ButtonLink>
		</EntityPropListItem>
	) : null;
};

export default AssetWorkflowProps;
