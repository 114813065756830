import { useAuthContext, useGroupContext } from 'auth';
import { useAxios } from 'hooks';
import { useFetching } from 'hooks/useFetching';
import React, { useCallback } from 'react';
import { canView } from 'workflows/helpers';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { doesNameableMatch } from '../../common';
import DownshiftMultiSelect, {
	DownshiftMultiSelectProps,
} from '../../components/downshift-select/downshift-multi-select.component';
import themeStore from '../../theme/models/ThemeStore';
import {
	Workflow,
	WorkflowCollection,
} from '../../workflows/types/workflow.types';

const CollectionWorkflowSelect = ({
	collection,
	onChange,
}: {
	collection: WorkflowCollection;
	onChange: (wc: WorkflowCollection) => void;
}) => {
	const { entities } = useWorkflowContext();
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	const workflowCollectionStore = useAxios<WorkflowCollection>('projects');
	const [collections, setCollections] = React.useState<WorkflowCollection[]>();
	const { beginFetching, finishFetching, isFetching } = useFetching();
	React.useEffect(() => {
		if (isFetching) return;
		if (!collections) {
			beginFetching();
			workflowCollectionStore
				.findAll()
				.then(setCollections)
				.finally(finishFetching);
		}
	}, [
		beginFetching,
		isFetching,
		finishFetching,
		collections,
		workflowCollectionStore,
	]);
	const workflows = entities;
	const [edited, setEdited] = React.useState(collection);
	const addWorkflow = useCallback(
		(workflow: Nullable<Workflow>) => {
			if (workflow) {
				const updatedCollection = {
					...edited,
					workflows: edited?.workflows?.length
						? [
								...edited.workflows.filter((m) => m !== workflow._id),
								workflow._id,
						  ]
						: [workflow._id],
				};
				setEdited(updatedCollection);
				onChange(updatedCollection);
			}
		},
		[edited, onChange]
	);

	const removeWorkflow = useCallback(
		(workflowTitle: string) => {
			const workflowId = workflows?.find(
				(workflow) => workflow.title === workflowTitle
			)?._id;
			const updatedCollection = {
				...edited,
				workflows: [...edited.workflows.filter((wfId) => wfId !== workflowId)],
			};
			setEdited(updatedCollection);
			onChange(updatedCollection);
		},
		[edited, onChange, workflows]
	);

	const canBeAdded = (workflow: Workflow) =>
		!!canView(workflow, currentUser, groupsForCurrentUser) &&
		!edited?.workflows?.some((wf: string) => workflow._id === (wf as string)) &&
		!collections?.some((wfCollection) => {
			if (wfCollection.workflows && wfCollection.workflows.length) {
				return wfCollection.workflows?.some((wfId) => wfId === workflow._id);
			}
		});

	const selectProps: DownshiftMultiSelectProps<any, any> = {
		label: `${themeStore._.workflow}s`,
		selectionState: {
			selection: edited?.workflows?.map(
				(wfId) => entities?.find((workflow) => workflow._id === wfId)?.title
			),
			options: workflows?.filter(canBeAdded) as readonly Workflow[],
			searchPredicate: doesNameableMatch,
		},
		selectionActions: {
			select: addWorkflow,
			unselect: removeWorkflow,
		},
	};

	return <DownshiftMultiSelect {...selectProps} />;
};

export default CollectionWorkflowSelect;
