import React, { ReactChild } from 'react';
import styled from 'styled-components';

import {
	extMatcher,
	typeMatcher,
	typePrefixMatcher,
} from './asset-type-matchers';

import AssetViewerWarning from './asset-viewer-warning.component';
import ImageViewerWithZoom from './image-viewer-with-zoom.component';
import { FallbackViewer } from './fallback-viewer.component';
import { stubTrue } from '../../../common/fn.utils';
import {
	AssetVersion,
	HasAssetVersion,
} from '../../../workflows/types/workflow.types';
import { useExtractPreview } from '../asset-card.component';

// styled components
const PreviewContainer = styled.div`
	margin-top: 2em;
`;

export type Previewable = AssetVersion;

interface AssetPreviewProps {
	asset: HasAssetVersion;
}

interface AssetRenderer {
	render: (props: HasAssetVersion) => ReactChild;
	canRender: (a: AssetVersion) => boolean;
}

export const invalidFileRenderer = {
	render: () => (
		<AssetViewerWarning
			text="Unable to preview asset: File type is likely invalid."
			color="danger"
		/>
	),
	canRender: () => true,
};

// component
const AssetPreview = (props: AssetPreviewProps) => {
	const { asset } = props;
	const { extractPreviewURL } = useExtractPreview();
	const renderers: ReadonlyArray<AssetRenderer> = [
		{
			render: ImageViewerWithZoom,
			canRender: (a: AssetVersion) => !!extractPreviewURL(a),
		},
		{ render: ImageViewerWithZoom, canRender: typePrefixMatcher('image') },
		{ render: ImageViewerWithZoom, canRender: extMatcher('ai') },
		{
			render: ImageViewerWithZoom,
			canRender: typeMatcher('application/postscript'),
		},
		{ render: ImageViewerWithZoom, canRender: typeMatcher('application/eps') },
		{ render: ImageViewerWithZoom, canRender: typeMatcher('application/pdf') },
		{ render: FallbackViewer, canRender: typeMatcher('application/zip') },
		{ render: FallbackViewer, canRender: stubTrue },
		{
			render: ImageViewerWithZoom,
			canRender: typeMatcher('image/vnd.adobe.photoshop'),
		},
	];

	const findRendererFor = (a: AssetVersion) => {
		return renderers.find((r) => r.canRender(a)) ?? invalidFileRenderer;
	};
	return (
		<PreviewContainer className="asset-preview">
			{findRendererFor(asset.asset).render(props.asset)}
		</PreviewContainer>
	);
};

export default AssetPreview;
