import {
	faCircleNotch,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { ButtonProps } from 'reactstrap';
import { useStateSafe } from '../../common/hooks';
import ManagedButton from './managed-button.component';
import { RotatingIcon } from './submit-button.styled-components';

type SubmitButtonState = 'idle' | 'processing';

export const PromiseSubmitButton = (
	props: ButtonProps & {
		label: string;
		canClick?: boolean;
		progressMsg?: string;
		icon?: IconDefinition;
		promise?: Promise<unknown>;
	}
) => {
	const {
		label,
		canClick = true,
		progressMsg = 'Processing...',
		icon = null,
		promise,
		...buttonProps
	} = props;

	const [buttonState, setButtonState] = useStateSafe<SubmitButtonState>(
		promise ? 'processing' : 'idle'
	);

	const isButtonDisabled = buttonState === 'processing' || !canClick;

	useEffect(() => {
		if (promise) {
			setButtonState('processing');
			promise.finally(() => setButtonState('idle'));
		}
	}, [promise, setButtonState]);

	const buttonIcon =
		buttonState === 'processing' ? (
			<RotatingIcon icon={faCircleNotch} className="mr-2" />
		) : icon ? (
			<FontAwesomeIcon icon={icon} className="mr-2" />
		) : null;

	const buttonLabel = buttonState === 'processing' ? progressMsg : label;

	return (
		<ManagedButton
			{...buttonProps}
			label={buttonLabel}
			buttonIcon={buttonIcon}
			disabled={isButtonDisabled}
		/>
	);
};

export default PromiseSubmitButton;
