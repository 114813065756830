import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { UserAvatar } from '../admin-dashboard/components/user-details.styled-components';
import EditUserForm from '../admin-dashboard/components/users/EditUserForm';
import { camelCaseToSentenceCase } from '../common';
import { _logError } from '../common/log';
import { Heading, Subheading } from '../components/ui';

import { NotificationsContext } from '../notifications';
import { getUserRoleName } from '../accounts/models/UserRole.model';
import { useAuthContext, User } from '../auth';
import { useAxios } from '../hooks';

const EditProfile = () => {
	const { currentUser, refreshEntity, refreshCurrentUser } = useAuthContext();
	const { updateOne } = useAxios<User>('users');
	const { info, warn } = React.useContext(NotificationsContext);
	const onSubmit = async (updatedUser: User) => {
		try {
			const updated = await updateOne(updatedUser._id, updatedUser);
			if (updated) {
				refreshEntity(updated);
				if (updated._id === currentUser?._id) {
					refreshCurrentUser(updated);
				}
			}

			info('Successfully saved user profile.');
		} catch (error) {
			_logError(error);

			warn(
				`An issue occurred while updating your profile. Please try again later.`
			);
		}
	};

	return (
		<Container>
			<Row>
				<Col md={2}>
					<Subheading>Edit</Subheading>
					<Heading>My profile</Heading>
				</Col>
				<Col md={10}>
					<Card>
						<CardBody>
							<Row>
								<Col
									lg={3}
									className="d-flex justify-content-center align-items-center flex-column"
								>
									<UserAvatar user={currentUser} />
									<h2>{currentUser.name}</h2>
									<p>
										{camelCaseToSentenceCase(getUserRoleName(currentUser.role))}
									</p>
								</Col>
								<Col lg={9} className="p-4">
									{!!currentUser && !!currentUser._id && (
										<EditUserForm
											showChangePassword={true}
											showRole={false}
											selectedUser={currentUser}
											onSubmit={onSubmit}
										/>
									)}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default EditProfile;
