import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { InfiniteHitsProvided } from 'react-instantsearch-core';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { CustomClearRefinements } from '../../components/algolia/clear-refinements';
import SubmitButton from '../../components/forms/submit-button.component';
import Loading from '../../components/loading.component';
import RenderWhen from '../../components/render-when.component';
import { damAssetRefinementSettings } from '../dam-asset-refinements';
import AssetCardGrid from './asset-card-grid.component';
import { isEqual, keysIn } from 'lodash';
import AssetListTable from './asset-list-table.component';
import AssetSelectionToolbar, {
	AssetSelectionTool,
	AssetSelectionToolbarClickEvent,
	defaultAssetSelectionTools,
} from './asset-selection-toolbar.component';
import { orderBy } from 'lodash';
import { SortOrder } from '../../dam-asset-collections/components/facet-tree-custom-hits.component';
import { AssetVersion } from '../../workflows/types/workflow.types';
import { ToggleCollectionView } from 'components/ToggleCollectionView/ToggleCollectionView';
import { useAccounts } from 'admin-dashboard/components/accounts/useAccounts';
const CustomRefinementList = React.lazy(() =>
	import('../../components/algolia/refinement-list.component')
);

interface AlgoliaDAMAsset {
	objectID: string;
	fileName: string;
	type: string;
	metadata?: any;
	createdAt: Date;
	__queryID?: string;
}

export interface PermissionsObject {
	brand: string[];
	country: string[];
	size: string[];
	category: string[];
	die: string[];
	printer: string[];
	packageType: string[];
	variety: string[];
	filetype: string[];
	archived: string[];
}
interface AssetHits extends InfiniteHitsProvided<AlgoliaAssetVersion> {
	isRefining: boolean;
	isRefiningCallback: (isRefining: boolean) => void;
	availableRefinements: string[];
	permissionsObject?: PermissionsObject;
}

export interface AlgoliaAssetVersion extends AssetVersion {
	__queryID?: string;
	objectID: string;
	metadata: any;
	tags: any;
}

const Tools = styled(Col)`
	display: flex;
	justify-content: flex-end;
`;

const NoAssetResults = () => (
	<Col lg={12}>
		<p>No results</p>
	</Col>
);

const AssetInfiniteHits = (props: AssetHits) => {
	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const [selectedAssets, setSelectedAssets] = useState<
		Array<AlgoliaAssetVersion>
	>([]);

	const { defaultAct } = useAccounts();

	const toolbarCallBack = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (event.tool === AssetSelectionTool.SELECTION) {
				const isSelecting: boolean = event.value;
				setSelectingAssets(isSelecting);
				if (!isSelecting) {
					// empty selected assets if selecting is turned off
					setSelectedAssets([]);
				}
			}
		},
		[setSelectingAssets]
	);

	const assetSelectionCallBack = useCallback(
		(asset: AlgoliaAssetVersion) => {
			const index = selectedAssets.indexOf(asset);
			if (index > -1) {
				// remove from selection list
				setSelectedAssets((selectedAssets) =>
					selectedAssets.filter((a) => a._id !== asset._id)
				);
			} else {
				// add to selection list
				setSelectedAssets((selectedAssets) => selectedAssets.concat(asset));
			}
		},
		[selectedAssets]
	);

	// render methods
	const renderAssetCardGrid = (assets: AlgoliaAssetVersion[]) => (
		<AssetCardGrid
			onDelete={(asset) =>
				setAssetList({
					type: 'SET',
					payload: assetList.assets.filter((a) => a._id !== asset._id),
				})
			}
			assets={assets}
			areLinks={!isSelectingAssets}
			isSelectionAvailable={true}
			onAssetSelected={assetSelectionCallBack}
			selectedAssets={selectedAssets}
		/>
	);

	const renderAssetList = (assets: AlgoliaAssetVersion[]) => (
		<AssetListTable
			onDelete={(a) =>
				setAssetList({
					type: 'SET',
					payload: assetList.assets.filter((asset) => a._id !== asset._id),
				})
			}
			assets={assets}
			isSelectingAssets={isSelectingAssets}
			onAssetSelected={assetSelectionCallBack}
			selectedAssets={selectedAssets}
			metadataProperties={[{ label: 'Asset Type', property: 'Asset Type' }]}
		/>
	);

	/**
	 * This method renders the assets in a list or grid based on the users current view selection
	 * @param assets the assets to be rendered
	 * @param viewType the users current view type
	 */
	const renderAssets = (assets: AlgoliaAssetVersion[], viewType = 'cards') => {
		if (!assets?.length) {
			return !isLoading ? <NoAssetResults /> : null;
		}

		return viewType === 'cards'
			? renderAssetCardGrid(assets)
			: renderAssetList(assets);
	};

	/**
	 * this reducer handles sorting of the assets
	 */
	const [currentSort, dispatchCurrentSort] = React.useReducer(function (
		currentSortOrder: SortOrder,
		action: { type: 'asc' | 'desc'; payload?: string }
	) {
		switch (action.type) {
			case 'asc':
				return { sort: action.type, field: action.payload } as SortOrder;
			case 'desc':
				return { sort: action.type, field: action.payload } as SortOrder;
		}
	},
	{} as SortOrder);

	/**
	 * This method takes the users available permissions and passes them into the refinement list component
	 * @param attribute facet to be filtered on
	 */

	const getPermissionsForAttribute = (attribute: string): string[] => {
		switch (attribute) {
			case 'metadata.Division':
				return props.permissionsObject?.brand as string[];
			case 'metadata.Country':
				return props.permissionsObject?.country as string[];
			case 'metadata.Size':
				return props.permissionsObject?.size as string[];
			case 'metadata.Category':
				return props.permissionsObject?.category as string[];
			case 'metadata.Printer':
				return props.permissionsObject?.printer as string[];
			case 'type':
				return props.permissionsObject?.filetype as string[];
			case 'metadata.Variety':
				return props.permissionsObject?.variety as string[];
			case 'metadata.Die':
				return props.permissionsObject?.die as string[];
			case 'metadata.Package Type':
			case 'metadata.Asset Type':
			case 'metadata.Win The Day Materials':
				return props.permissionsObject?.packageType as string[];
			case 'archived':
				return props.permissionsObject?.archived as string[];
			default:
				return [];
		}
	};

	function assetListReducer(
		state: { assets: AlgoliaAssetVersion[] },
		action: { type: 'SET' | 'CLEAR'; payload?: AlgoliaAssetVersion[] }
	) {
		switch (action.type) {
			case 'SET':
				return {
					...state,
					assets: props.isRefining
						? (action.payload as AlgoliaAssetVersion[])
						: [],
				};
			case 'CLEAR':
				return { ...state, assets: [] };
			default:
				return state;
		}
	}

	const doesExist = (item: any) =>
		item && Array.isArray(item)
			? !!item.length && item.some((a) => !!a)
			: !!item;

	const includesValue = (array: string[], value: string) => {
		return array.some(
			(a) => a.toLowerCase().trim() === value.toLowerCase().trim()
		);
	};

	/**
	 * This method filters the hits from algolia mapping them to DAM Assets and also filtering out
	 * any unavailable refinements due to permissions
	 * @param obj The current hits coming back from algolia
	 */
	const filterAssetsByPermission = (obj: AlgoliaAssetVersion[]) => {
		setIsLoading(true);
		const filterOut = (metadata: Record<string, string>) => {
			if (!metadata) return true;
			if (props.permissionsObject?.brand.length && !!metadata.Brand) {
				const brand = Array.isArray(metadata.Brand)
					? metadata.Brand[0].toLowerCase()
					: metadata.Brand.toLowerCase();
				if (
					!props.permissionsObject.brand.some(
						(e) => e.toLowerCase().trim() === brand.trim()
					)
				) {
					return false;
				}
			} else return !doesExist(metadata.Brand);

			if (props.permissionsObject.archived.length && !!metadata.Archived) {
				const archived = Array.isArray(metadata.Brand)
					? metadata.Archived[0]
					: metadata.Archived;
				if (!includesValue(props.permissionsObject?.archived, archived))
					return false;
			} else return !doesExist(metadata.Archived);

			if (props.permissionsObject?.category.length && !!metadata.Category) {
				const category = Array.isArray(metadata.Category)
					? metadata.Category[0]
					: metadata.Category;

				if (!includesValue(props.permissionsObject?.category, category))
					return false;
			} else return !doesExist(metadata.Category) ? true : false;

			if (props.permissionsObject?.size.length && !!metadata.Size) {
				const size = Array.isArray(metadata.Size)
					? metadata.Size[0]
					: metadata.Size;

				if (!includesValue(props.permissionsObject?.size, size)) return false;
			} else return !doesExist(metadata.Size) ? true : false;

			if (props.permissionsObject?.variety.length && !!metadata.Variety) {
				const variety = Array.isArray(metadata.Variety)
					? metadata.Variety[0]
					: metadata.Variety;

				if (!includesValue(props.permissionsObject?.variety, variety))
					return false;
			} else {
				return !doesExist(metadata.Variety) ? true : false;
			}

			if (props.permissionsObject?.filetype.length && !!metadata.type) {
				const type = Array.isArray(metadata.type)
					? metadata.type[0]
					: metadata.type;

				if (!includesValue(props.permissionsObject?.filetype, type))
					return false;
			} else {
				return !doesExist(metadata.type) ? true : false;
			}

			if (props.permissionsObject?.die.length && !!metadata.Die) {
				const die = Array.isArray(metadata.Die)
					? metadata.Die[0]
					: metadata.Die;

				if (!includesValue(props.permissionsObject?.die, die)) return false;
			} else return !doesExist(metadata.Die) ? true : false;

			if (props.permissionsObject?.printer.length && !!metadata.Printer) {
				const printer = Array.isArray(metadata.Printer)
					? metadata.Printer[0]
					: metadata.Printer;
				if (!includesValue(props.permissionsObject?.printer, printer))
					return false;
			} else return !doesExist(metadata.Printer) ? true : false;

			return true;
		};

		const assets = obj.map((asset) => {
			const found = asset;
			return {
				...(found as AssetVersion),
				__queryID: asset.__queryID,
				preview: asset?.preview,
				previewURL: asset.previewURL
					? asset.previewURL
					: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/${
							asset.workflowId ? 'w' : 'd'
					  }/${asset.account}/${asset?.preview
							?.replace('_preview/', '')
							.replace('.jpg', '')
							.replace('.jpeg', '')}`,
				metadata: {
					fields: keysIn(found.metadata),
					values: found.metadata,
					fieldOptions: {},
					fieldTypes: [],
					tags: found.tags,
				},
				objectID: asset.objectID,
				createdAt: new Date((asset.createdAt as number) * 1000),
				_id: asset.objectID.replace('DAMAsset_', ''),
			};
		});

		const assetList = assets.filter((x) =>
			!!defaultAct?.useDAMUserGroupPermissions
				? !!filterOut(x.metadata?.values as Record<string, string>)
				: true
		);
		setIsLoading(false);
		return assetList;
	};
	//eslint-disable-next-line
	const [assetList, setAssetList] = React.useReducer(assetListReducer, {
		assets: Array<AlgoliaAssetVersion>(),
	});

	const [isLoading, setIsLoading] = useState(false);

	React.useEffect(() => {
		if (!props.isRefining) {
			setAssetList({ type: 'CLEAR' });
		}
	}, [props.isRefining]);

	React.useEffect(() => {
		const assets = filterAssetsByPermission(props.hits);
		const filtered = assets;
		if (currentSort && currentSort.sort && currentSort.field)
			setAssetList({
				type: 'SET',
				payload: [
					...orderBy(
						filtered as AlgoliaAssetVersion[],
						(i) => i[currentSort.field] ?? i.title,
						currentSort.sort
					),
				],
			});
		else
			setAssetList({
				type: 'SET',
				payload: [...(filtered as AlgoliaAssetVersion[])],
			});

		// eslint-disable-next-line
	}, [props.hits, currentSort]);

	useEffect(() => {
		const prop = currentSort.field === 'fileName' ? 'title' : currentSort.field;
		if (!assetList.assets) return;
		if (
			isEqual(
				assetList.assets,
				orderBy(assetList.assets, (a) => a[prop], currentSort.sort)
			)
		)
			return;
		if (prop && currentSort.sort) {
			setAssetList({
				type: 'SET',
				payload: orderBy(assetList.assets, (a) => a[prop], currentSort.sort),
			});
		}
	}, [currentSort, assetList.assets]);

	const [viewPref, setViewPref] = useState<'cards' | 'list'>('cards');

	const render = () => {
		return (
			<Suspense fallback={<Loading />}>
				<Row className="align-items-center">
					<Col sm={9} xs={8} className="my-3">
						<Row>
							{damAssetRefinementSettings.map((refinementSettings) =>
								refinementSettings.attribute !== 'archived' ? (
									<CustomRefinementList
										showMoreLimit={500}
										showMore
										availableRefinements={getPermissionsForAttribute(
											refinementSettings.attribute
										)}
										isRefining={props.isRefining}
										isRefiningCallback={props.isRefiningCallback}
										key={refinementSettings.attribute}
										label={refinementSettings.label}
										attribute={refinementSettings.attribute}
										searchable={refinementSettings.searchable}
									/>
								) : (
									<CustomRefinementList
										defaultRefinement={['false']}
										showMoreLimit={500}
										showMore
										availableRefinements={getPermissionsForAttribute(
											refinementSettings.attribute
										)}
										isRefining={props.isRefining}
										isRefiningCallback={props.isRefiningCallback}
										key={refinementSettings.attribute}
										label={refinementSettings.label}
										attribute={refinementSettings.attribute}
										searchable={refinementSettings.searchable}
									/>
								)
							)}

							<CustomClearRefinements
								clearRefiningCallback={props.isRefiningCallback}
							/>
						</Row>
					</Col>
					<Tools sm={3} className="p-0" xs={4}>
						<AssetSelectionToolbar
							onDelete={(deletedAssets: AssetVersion[]) => {
								setAssetList({
									type: 'SET',
									payload: assetList.assets.filter(
										(a) => !deletedAssets.some((b) => b._id === a._id)
									),
								});
								setSelectedAssets([]);
							}}
							tools={defaultAssetSelectionTools}
							className="asset-index-toolbar"
							onClick={toolbarCallBack}
							selectedAssets={selectedAssets}
							dispatchCurrentSort={dispatchCurrentSort}
						/>
						<ToggleCollectionView
							toggle={() => {
								setViewPref((v) => (v === 'cards' ? 'list' : 'cards'));
							}}
							cards={true}
							currentView={viewPref}
						/>
					</Tools>

					{renderAssets(
						assetList.assets,
						viewPref === 'cards' ? 'cards' : 'list'
					)}
					{isLoading && <Loading label={'Loading assets..'} />}
					<RenderWhen when={props.hasMore && props.isRefining}>
						<Col xs={12} className="text-center">
							<SubmitButton label="Show more" onClick={props.refineNext} />
						</Col>
					</RenderWhen>
				</Row>
			</Suspense>
		);
	};
	return render();
};

const CustomAssetHits = connectInfiniteHits(AssetInfiniteHits);
export default CustomAssetHits;
