import { Nav, TabContent, TabPane } from 'reactstrap';
import React, { useCallback, useState } from 'react';

import { useModalCloser } from '../../../stores/ModalStack';
import { NotificationsContext } from '../../../notifications';

import { TabButton } from './share-dialogs.styled-components';
import EmailForm from './email-form.component';
import SelectUsersForm from './select-users-form.component';
import ShareLink from './share-link.component';
import { RequiresFeatureFlag } from '../../../core/FeatureFlags';
import { AssetVersion } from 'workflows/types';

// interfaces
interface Props {
	link: string;
	asset?: AssetVersion;
	assets?: AssetVersion[];
}

export interface ShareDialogComponentProps {
	link: string;
	onSuccess?: () => void;
	onError?: () => void;
	asset?: AssetVersion;
	assets?: AssetVersion[];
}

// component
const ShareDialogBody = (props: Props) => {
	const { info } = React.useContext(NotificationsContext);
	const modalStack = useModalCloser();
	const [activeTab, setActiveTab] = useState('1');

	const shareCallBack = useCallback(
		(action: string) => {
			let text = '';
			switch (action) {
				case 'copy':
					text = 'Link copied to clipboard!';
					break;
				case 'notification':
					text = 'Users notified!';
					break;
				case 'email':
					text = 'Email sent!';
					break;
			}
			info(text);
			modalStack.closeModal();
		},
		[modalStack, info]
	);

	// tab methods
	const buildTabClassList = (tabId: string) => {
		let classList = 'share-tab-button';
		if (isActiveTab(tabId)) {
			classList = `${classList} selected`;
		}
		return classList;
	};

	const isActiveTab = (tabId: string) => {
		return tabId === activeTab;
	};

	const render = () => (
		<>
			<Nav tabs role="tablist" aria-label="Share Assets Tabs">
				<TabButton
					role="tab"
					className={buildTabClassList('1')}
					aria-selected={isActiveTab('1')}
					aria-controls="linkPanel"
					id="linkTab"
					onClick={() => setActiveTab('1')}
				>
					Link
				</TabButton>
				<RequiresFeatureFlag flag="assetSharing.shareToUser">
					<TabButton
						role="tab"
						className={buildTabClassList('2')}
						aria-selected={isActiveTab('2')}
						aria-controls="usersPanel"
						id="usersTab"
						onClick={() => setActiveTab('2')}
					>
						Users
					</TabButton>
				</RequiresFeatureFlag>
				<RequiresFeatureFlag flag="assetSharing.shareToEmail">
					<TabButton
						role="tab"
						className={buildTabClassList('3')}
						aria-selected={isActiveTab('3')}
						aria-controls="usersPanel"
						id="usersTab"
						onClick={() => setActiveTab('3')}
					>
						Email
					</TabButton>
				</RequiresFeatureFlag>
			</Nav>

			<TabContent className="pt-4" activeTab={activeTab}>
				<TabPane tabId="1" role="tabpanel">
					<ShareLink
						link={props.link}
						onSuccess={() => shareCallBack('copy')}
					/>
				</TabPane>
				<TabPane tabId="2" role="tabpanel">
					<SelectUsersForm
						assets={props.assets as AssetVersion[]}
						asset={props.asset}
						link={props.link}
						onSuccess={() => shareCallBack('notification')}
					/>
				</TabPane>
				<TabPane tabId="3" role="tabpanel">
					<EmailForm
						asset={props.asset}
						assets={props.assets as AssetVersion[]}
						link={props.link}
						onSuccess={() => shareCallBack('email')}
					/>
				</TabPane>
			</TabContent>
		</>
	);

	return render();
};

export default ShareDialogBody;
