import { Card, CardBody, Col } from 'reactstrap';
import React from 'react';
import defaultProduct from '../../assets/images/default-product.jpg';
import themeStore from '../../theme/models/ThemeStore';
import WorkflowHealthFlag from '../../dashboard/components/workflow-health-flag.component';
import WorkflowStatusPill from '../../components/workflow-status-pill.component';

import { Stage, Workflow, WorkflowCollection } from '../types/workflow.types';
import {
	CardBodyItem,
	CardFlag,
	Link,
	WorkflowCardHeader,
	WorkflowTitle,
} from './workflow-card.styled-components';
import { WorkflowDueDate } from './WorkflowDueDate';
import { StageStatus } from '../../models/StageStatusModel';
import {
	isCompleted,
	isOverdue,
	isPipeline,
	isRoadblocked,
} from 'workflows/helpers/workflowDate.helpers';
import { useWorkflowCollectionContext } from 'workflows/hooks/useWorkflowCollectionStore';
import { flattenStages, listActiveStageNames } from 'workflows/helpers';
import OwnerAvatarList from 'components/owner-avatar-list.component';
import { uniqBy } from 'lodash';
import { SmallTextMuted } from 'components';

interface WorkflowCardProps {
	workflow: Workflow;
}

export const WorkflowCard = ({ workflow }: WorkflowCardProps) => {
	const getOwnersFromWorkflow = (w: Workflow) => {
		const flat = flattenStages(w, true);
		return uniqBy(
			flat.flatMap((stage) => stage?.owners),
			(a) => a?._id
		);
	};
	const { entities: collections } = useWorkflowCollectionContext();
	return (
		<Col lg={4} sm={6} className="my-3">
			<Link to={`/admin/workflow/workflows/${workflow._id}/stages`}>
				<Card className="h-100">
					<WorkflowCardHeader image={defaultProduct}>
						<CardFlag>
							<WorkflowHealthFlag workflow={workflow} />
						</CardFlag>
					</WorkflowCardHeader>

					<CardBody className="bg-white">
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<WorkflowTitle className="mb-3">{workflow.title}</WorkflowTitle>

								{workflow?.workflowCollection ? (
									<CardBodyItem>
										<em>
											In {themeStore._.workflowCollection.toLowerCase()}:{' '}
										</em>
										{
											collections?.find(
												(col: WorkflowCollection) =>
													col._id === (workflow?.workflowCollection as string)
											)?.title
										}
									</CardBodyItem>
								) : null}

								{workflow?.stages?.filter(
									(stage: Stage) =>
										stage.status === 'active' || stage.status === 'roadblocked'
								)?.length ? (
									<CardBodyItem>
										<em>Active {themeStore._.stage.toLowerCase()}(s): </em>
										{listActiveStageNames(workflow)}
									</CardBodyItem>
								) : null}

								{
									// @ts-ignore
									workflow?.stages[0]?.status === StageStatus.active && (
										<CardBodyItem>
											<em>Due date: </em>
											<WorkflowDueDate workflow={workflow} />
										</CardBodyItem>
									)
								}

								{workflow?.stages[0]?.status !== StageStatus.active && (
									<CardBodyItem>
										<em>Due date: </em>
										<WorkflowDueDate workflow={workflow} />
									</CardBodyItem>
								)}

								<CardBodyItem>
									Status:
									<WorkflowStatusPill
										className={`ml-2`}
										status={workflow.status}
									/>
								</CardBodyItem>
							</div>
							<div>
								<SmallTextMuted>Stakeholder(s)</SmallTextMuted>
								<OwnerAvatarList
									title="Stakeholder(s)"
									owners={getOwnersFromWorkflow(workflow)}
								/>
							</div>
						</div>
					</CardBody>
				</Card>
			</Link>
		</Col>
	);
};

export const getWorkflowStatusBadgeClass = (workflow: Workflow) => {
	if (isRoadblocked(workflow)) return `badge-blocked`;
	if (isOverdue(workflow)) return `badge-overdue`;
	if (isPipeline(workflow)) return `badge-pipeline`;
	if (isCompleted(workflow)) return `badge-completed`;
	return `badge-active`;
};
export default WorkflowCard;
