import React from 'react';

import { LoadingRow } from '../../components/loading.component';
import themeStore from '../../theme/models/ThemeStore';

const LoadingAssetState = () => (
	<LoadingRow label={`Loading ${themeStore._.asset.toLowerCase()}...`} />
);

export default LoadingAssetState;
