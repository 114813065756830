import { useGroupContext, User } from 'auth';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { useAuthContext } from '../../../auth';
import OwnerAvatarList from '../../../components/owner-avatar-list.component';
import { Subheading } from '../../../components/ui';
import { canSetStageOwners } from '../../helpers/workflowBoolean.helpers';
import { BaseWorkflowOwner, Stage, Workflow } from '../../types/workflow.types';
import {
	AssignStageOwnerButton,
	RoadblockStageButton,
	UploadStageAssetButton,
} from '../stage-actions';
import {
	DisabledOwnerButton,
	FlexCardHeader,
} from './workflow-stage-card.styled-components';

type Props = {
	stage: Stage;
	workflow: Workflow;
};

export const StageDetailCardHeader = ({ stage, workflow }: Props) => {
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();

	if (!stage) return null;
	if (R.isEmpty(stage.owners))
		return (
			<FlexCardHeader>
				<div className={'d-flex justify-content-between align-items-center'}>
					<AssignStageOwnerButton />
					<div>
						{(stage.status === 'roadblocked' || stage.status === 'active') && (
							<RoadblockStageButton workflow={workflow} stage={stage} />
						)}
					</div>
				</div>
			</FlexCardHeader>
		);

	if (canSetStageOwners(stage, currentUser, groupsForCurrentUser))
		return (
			<FlexCardHeader>
				<div>
					<AssignStageOwnerButton>
						<OwnersLabel stage={stage} />
					</AssignStageOwnerButton>
				</div>
				<div className="d-flex align-items-center">
					{!R.isEmpty(stage.inputSlots) && stage && (
						<UploadStageAssetButton workflow={workflow} stage={stage} />
					)}

					{(stage.status === 'roadblocked' || stage.status === 'active') && (
						<RoadblockStageButton workflow={workflow} stage={stage} />
					)}
				</div>
			</FlexCardHeader>
		);

	return (
		<FlexCardHeader>
			<DisabledOwnerButton>
				<OwnerAvatarList
					displayNames={false}
					owners={stage.owners}
					className="align-self-center"
				/>
				<OwnersLabel stage={stage} />
			</DisabledOwnerButton>
		</FlexCardHeader>
	);
};

const OwnersLabel = (props: { stage: Stage }) => {
	const { owners } = props.stage;
	const { entities: groups } = useGroupContext();
	const { entities: users } = useAuthContext();
	if (!groups || !users) return null;
	if (!owners) return <>Unassigned</>;

	const getUserDisplayName = (users: User[], userId: string) => {
		const user = users?.find((m) => m._id === userId);

		const userToDisplay =
			user?.proxyRequest?.action === 'accepted' &&
			moment(new Date()).isAfter(user.proxyRequest.proxyStartDate) &&
			moment(new Date()).isBefore(user.proxyRequest.proxyEndDate)
				? user.proxyRequest.proxyingUser
				: user;

		const defaultLabel = `${user?.givenName} ${user?.familyName}`;
		const proxiedByLabel = `(proxied by: ${userToDisplay?.givenName} ${userToDisplay?.familyName})`;

		return user?.proxyRequest?.proxyingUser
			? `${defaultLabel} ${proxiedByLabel}`
			: `${defaultLabel}`;
	};

	const listOwnerNames = (owners: BaseWorkflowOwner[]) =>
		owners.map((owner, idx) => {
			if (owner.type === 'AccountUser') {
				return (
					<span key={owner._id} className="d-flex">
						{getUserDisplayName(users, owner._id)}
						{idx === owners.length - 1 ? ' ' : ',  '}
					</span>
				);
			} else if (owner.type === 'AccountGroup') {
				return (
					<span
						key={groups?.find((grp) => grp._id === owner._id)?._id}
						className="d-flex"
					>
						{`${groups?.find((m) => m._id === owner._id)?.title}`}
						{idx === owners.length - 1 ? '' : ',  '}
					</span>
				);
			} else return null;
		});

	return (
		<div className="align-self-center text-left mx-3 mt-1">
			<Subheading className="mb-0">Assigned to</Subheading>
			<p className="d-flex flex-row flex-wrap mb-0">{listOwnerNames(owners)}</p>
		</div>
	);
};
