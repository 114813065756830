import { APIClient, authProvider } from '../../core/index';
import windowModel from '../../models/WindowModel';
import { _logError } from '../../common/log';
import { AssetVersion } from '../../workflows/types/workflow.types';
import LoggingProvider from 'core/LoggingProvider';
const apiClient = new APIClient(authProvider, new LoggingProvider());
class ViewerService {
	private static readonly viewers = new WeakMap<APIClient, ViewerService>();

	public static getOrCreate(client: APIClient) {
		const cached = ViewerService.viewers.get(client);
		if (cached) {
			return cached;
		}
		const viewerService = new ViewerService(client);
		ViewerService.viewers.set(client, viewerService);
		return viewerService;
	}

	private constructor(private readonly client: APIClient) {}

	viewSingle = async (asset: AssetVersion) =>
		this.openViewerUrl(`assets/cf/view/${asset._id}`);

	compareTwo = async (assetA: AssetVersion, assetB: AssetVersion) =>
		this.openViewerUrl(`assets/cf/compare/${assetA._id}/${assetB._id}`);

	private openViewerUrl = async (url: string): Promise<void> => {
		try {
			windowModel.openInNewWindow(await this.client.get(url));
		} catch (err) {
			_logError(err);
			// TODO:  add notification.  currently it is impossible, since this function is not a hook.
		}
	};
}

export const useViewer = () => ViewerService.getOrCreate(apiClient);
