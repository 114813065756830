import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { capitalizeFirstLetter } from '../../common';
import {
	EntityPropLabel,
	EntityPropListItem,
	TagBadge,
} from '../../components/entity-details.styled-components';
import { EntityMetadata } from '../../workflows/types/workflow.types';

const EntityMetadataFields = ({ metadata }: { metadata: EntityMetadata }) => {
	const renderTags = () => (
		<EntityPropListItem>
			<EntityPropLabel>
				<FontAwesomeIcon icon={faTag} /> Tags
			</EntityPropLabel>
			{(metadata?.tags || [])?.map((tag) => (
				<TagBadge key={tag}>{tag}</TagBadge>
			))}
		</EntityPropListItem>
	);

	const renderMetaValues = () => {
		return Object.keys(metadata?.values || {}).map((key, value) => (
			<EntityPropListItem key={key}>
				<EntityPropLabel>{capitalizeFirstLetter(key)}</EntityPropLabel>
				<p>
					{metadata.values && metadata.values[key]
						? Array.isArray(metadata.values[key])
							? (metadata.values[key] as Array<string>).join(', ')
							: metadata.values[key]
						: ''}
				</p>
			</EntityPropListItem>
		));
	};

	return (
		<>
			{renderMetaValues()}
			{renderTags()}
		</>
	);
};

export default EntityMetadataFields;
