import { useAxios } from 'hooks';
import React, { useEffect } from 'react';
import { Account } from 'workflows/types';

type AccountsContextType = {
	accounts: Array<Account>;
	defaultAct: Account;
	patchAccount: (ac: Account) => Promise<Account>;
};

const AccountsContext = React.createContext<AccountsContextType>({
	accounts: [],
	defaultAct: {} as Account,
	patchAccount: () => Promise.resolve<Account>({} as Account),
});

export const AccountsContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const api = useAxios<Account>('accounts');
	const [accounts, setAccounts] = React.useState<Account[]>();
	const [defaultAct, setDefaultAct] = React.useState<Account>();
	const token = localStorage.getItem('rome_auth');
	const hasToken = React.useMemo(() => {
		if (token && JSON.parse(token) !== null) {
			return !!JSON.parse(token).accessToken ? true : false;
		}
	}, [token]);

	useEffect(() => {
		if (hasToken) {
			api.findAll().then(setAccounts);
		}
		//eslint-disable-next-line
	}, [hasToken]);

	useEffect(() => {
		if (accounts) {
			setDefaultAct(accounts[0]);
		}
		//eslint-disable-next-line
	}, [accounts]);

	const patchAccount = async (account: Account) => {
		const response = (await api.updateOne(account._id, account)) as Account;
		setAccounts([...accounts?.filter((m) => m._id !== account._id), response]);
		return response;
	};

	return (
		<AccountsContext.Provider
			value={{
				accounts: accounts as Account[],
				defaultAct: defaultAct as Account,
				patchAccount,
			}}
		>
			{children}
		</AccountsContext.Provider>
	);
};

export const useAccounts = () => {
	const context = React.useContext(AccountsContext);
	if (context === undefined)
		throw new Error('Expected to be in accounts context but wasnt');
	return context;
};
