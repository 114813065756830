import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import {
	faClock,
	faFileDownload,
	faInfoCircle,
	faShare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from '@reach/router';
import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';
import { UserAvatar } from 'admin-dashboard/components/user-details.styled-components';
import { useAuthContext, User } from 'auth';
import { generateID, humanReadableFileSizeSI } from 'common';
import { PrimaryButton } from 'components/buttons.styled-components';
import { SmallText, Subheading } from 'components/ui';
import {
	FileShareAuditDto,
	FileShareInstance,
	useFileShare,
} from 'file-sharing/hooks/useFileShare';
import { orderBy, uniqBy } from 'lodash';
import { Creatable, getCreationDateStr } from 'models/CreatableEntityModel';
import windowModel from 'models/WindowModel';
import { NotificationsContext } from 'notifications';
import React, { useEffect, useMemo, useState } from 'react';
import {
	Alert,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	ListGroupItemText,
	Nav,
	NavItem,
	Row,
} from 'reactstrap';
import styled from 'styled-components';
import './file-sharing.css';
import { FileSharingCard } from './FileSharingCard';

////////////////////////////////
// URL FORMAT: http://localhost:3000/admin/filesharing/[fileShareId]/[recipient_id]/[recipient_email]
// example: http://localhost:3000/admin/filesharing/12345-ABC/AAAABBBBCCCC1234/test@mail.com
////////////////////////////////

const DisabledMask = styled.div`
	background: var(--background-color);
	opacity: 0.5;
	z-index: 3;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 500px;
`;
const DisabledText = styled.p`
	margin-bottom: 0;
`;

const StyledAlert = styled(Alert)`
	z-index: 4;
`;
const FileShareAlert = (props: {
	isExpired: boolean;
	fileShare: FileShareInstance;
	color: string;
	icon: IconProp;
	isRecall: boolean;
}) => {
	const buildMessage = () => {
		let msg = 'Whoops! It looks like the shared files ';
		if (props.isExpired) msg += ' have expired';
		if (props.isRecall && props.isExpired) msg += ' and have been recalled';
		if (props.isRecall && !props.isExpired) msg += ' have been recalled';
		msg += '.';
		return msg;
	};
	return (
		<StyledAlert color={props.color}>
			<DisabledText>
				<FontAwesomeIcon icon={props.icon} className="mr-2" />
				{buildMessage()}
			</DisabledText>
		</StyledAlert>
	);
};
const FileSharingPoint = () => {
	// state
	const [view, setView] = useState<'access-logs' | 'files' | 'recipients'>(
		'files'
	);
	const [fileShareData, setFileShareData] = useState<FileShareInstance>();
	const [allEvents, setAllEvents] = useState<FileShareAuditDto[]>();
	const shouldAudit = !windowModel.getURLSearchParam('doNotAudit');
	// hooks
	const { fileShareId, recipient_id } = useParams();
	const { entities } = useAuthContext();
	const { defaults } = useThemeContext();
	const { info, error: showError } = React.useContext(NotificationsContext);
	const {
		getFileShareInstance,
		downloadFileShareFile,
		killExpiredShare,
		getStats,
		audit: auditEvent,
	} = useFileShare();

	useEffect(() => {
		if (fileShareId) {
			getFileShareInstance(fileShareId).then(setFileShareData);
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (fileShareData) {
			fileShareData.attachments.forEach(async (file) => {
				await getStats(file._id).then((stats) =>
					setAllEvents((events) => [
						...(events ?? []),
						...stats.downloads,
						...stats.views,
						...stats.shares,
					])
				);
			});
		}
		//eslint-disable-next-line
	}, [fileShareData]);

	const isExpired = useMemo(() => {
		if (fileShareData) {
			const now = new Date();
			return now > new Date(fileShareData?.validUntil?.toString());
		}
	}, [fileShareData]);

	const recipient_email = useMemo(() => {
		if (fileShareData && recipient_id) {
			return fileShareData.recipients.find((a) => a._id === recipient_id)
				?.email as string;
		}
	}, [fileShareData, recipient_id]);

	const killExpired = async (id: string) => {
		if (fileShareData?.attachments.every((a) => a.path !== '')) {
			const updatedFileShareData = await killExpiredShare(id);
			setFileShareData(updatedFileShareData);
		}
	};

	useEffect(() => {
		if (shouldAudit && fileShareData) {
			//audit the view event without file_id, preview, and metadata populated
			//meaning the viewed the entire share, not a specific file
			const auditDto: FileShareAuditDto = {
				_id: generateID(),
				action: 'view',
				createdAt: new Date(),
				createdBy: recipient_id,
				file_share_id: fileShareId,
				preview: '',
				file_id: '',
				metadata: undefined,
				recipient_email: recipient_email as string,
				recipient_id: recipient_id,
			};
			auditEvent(auditDto).then((audit: FileShareAuditDto) => {
				setAllEvents((events) => (events ? [...events, audit] : [audit]));
				console.info(`Generated audit ${audit._id}`);
			});
		}
		//eslint-disable-next-line
	}, [fileShareData]);

	useEffect(() => {
		if (isExpired) {
			killExpired(fileShareId).then(() => {
				console.info(`killed expired fileshare.`);
			});
		}
		//eslint-disable-next-line
	}, [isExpired]);

	// helpers
	const isValidFileShare =
		fileShareId && recipient_id && recipient_email ? 'YES' : 'NO';

	const findUser = (userId: string) => entities?.find((a) => a._id === userId);
	const findUserByEmail = (userEmail: string) =>
		entities?.find((a) => a.email === userEmail);

	const downloadAll = async () => {
		try {
			fileShareData?.attachments.forEach(async (file) => {
				const result = await downloadFileShareFile(file._id);
				if (shouldAudit) {
					//audit the view event without file_id, preview, and metadata populated
					//meaning the viewed the entire share, not a specific file
					const auditDto: FileShareAuditDto = {
						_id: generateID(),
						action: 'download',
						createdAt: new Date(),
						createdBy: recipient_id,
						file_share_id: fileShareId,
						preview: file.preview,
						file_id: file._id,
						metadata: file.metadata,
						recipient_email: recipient_email as string,
						recipient_id: recipient_id,
					};
					const audit = await auditEvent(auditDto);
					setAllEvents((events) => [...events, audit]);
				}
				window.open(result as string, file._id);
			});
			info('Succesfully downloaded all shared files.');
		} catch (err) {
			console.error('Error occurred downloading shared files', err);
			showError('Failed to download shared files. Please try again later.');
		}
	};

	// markup

	if (!isValidFileShare) {
		return (
			<Container>
				<h1 className="danger">Beep Beep Boop! Invalid File Share.</h1>
			</Container>
		);
	}

	const renderEventAsString = (audit: FileShareAuditDto) => {
		const fileDisplay = audit.file_id
			? ` ${
					fileShareData?.attachments.find((a) => a._id === audit.file_id)
						?.metadata.filename
			  } (${humanReadableFileSizeSI(
					fileShareData?.attachments.find((a) => a._id === audit.file_id)
						?.metadata.fileSizeBytes
			  )})`
			: ` the file share URL ${window.location.href}`;
		if (audit.action === 'download') return `downloaded ${fileDisplay}`;
		if (audit.action === 'share') return `shared ${fileDisplay}`;
		if (audit.action === 'view') return `viewed ${fileDisplay}`;
	};

	const renderMask = () => {
		if (!isExpired && !fileShareData?.recalled) return null;
		return (
			<>
				<DisabledMask />
				<>
					<FileShareAlert
						isRecall={fileShareData?.recalled as boolean}
						isExpired={isExpired as boolean}
						fileShare={fileShareData as FileShareInstance}
						color="danger"
						icon={faClock}
					/>
				</>
			</>
		);
	};
	const getIconForAction = (action: 'share' | 'download' | 'view') => {
		if (action === 'share') return faShare;
		if (action === 'download') return faFileDownload;
		if (action === 'view') return faEye;
		return faInfoCircle;
	};
	const defaultUserSRC = `https://lh6.googleusercontent.com/-2AA6LyuzpZI/AAAAAAAAAAI/AAAAAAAAAGE/AMZuuclxEqw2wOgKvIM8phyh3RL9kxQC-Q/photo.jpg`;
	return (
		<Container>
			<Card className="my-5 ">
				<CardHeader>
					<Row className="my-3">
						<Col xl={12}>
							<h1>Download Your Files</h1>
						</Col>
					</Row>
				</CardHeader>
				<CardBody>
					<Row>
						<Col xl={3}>
							<p className="px-2">
								<Subheading>File Share ID </Subheading>
								{fileShareId}
								<br />
								<Subheading>Recipient ID </Subheading>
								{recipient_id}
								<Subheading>Recipient Email </Subheading>
								{recipient_email}
							</p>
						</Col>
						<Col xl={9}>
							<p>
								<Subheading>Subject </Subheading> {fileShareData?.subject}
							</p>
							<p>
								<Subheading>Message </Subheading> {fileShareData?.message}
							</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col xl={12}>
							<Nav tabs>
								<NavItem tabIndex={0}>
									<span
										className={`nav-link ${view === 'files' ? 'active' : ''}`}
										onClick={() => setView('files')}
									>
										Shared Files
									</span>
								</NavItem>
								<NavItem tabIndex={1}>
									<span
										className={`nav-link ${
											view === 'access-logs' ? 'active' : ''
										}`}
										onClick={() => setView('access-logs')}
									>
										Access Logs
									</span>
								</NavItem>
								{fileShareData?.useCC && (
									<NavItem tabIndex={2}>
										<span
											className={`nav-link ${
												view === 'recipients' ? 'active' : ''
											}`}
											onClick={() => setView('recipients')}
										>
											Recipients
										</span>
									</NavItem>
								)}
							</Nav>
							<hr />
						</Col>

						{view === 'files' && (
							<>
								<Col xl={12}>{renderMask()}</Col>
								{fileShareData?.attachments?.map((file) => (
									<Col key={file._id} xl={3}>
										<FileSharingCard
											setAllEvents={setAllEvents}
											isExpired={isExpired}
											shouldAudit={shouldAudit}
											fileShareId={fileShareId}
											recipient_email={recipient_email as string}
											recipient_id={recipient_id}
											file={file}
										/>
									</Col>
								))}
							</>
						)}

						{view === 'recipients' && fileShareData?.useCC && (
							<Col xl={12}>
								<ListGroup>
									{fileShareData.recipients.map((recipient) => (
										<ListGroupItem key={recipient._id}>
											<div className="d-flex justify-between">
												{!!findUserByEmail(recipient.email)?.email && (
													<UserAvatar
														size="lg"
														user={findUser(recipient?._id) as User}
													/>
												)}
												{!findUserByEmail(recipient.email) && (
													<UserAvatar
														size="lg"
														user={{ picture: defaultUserSRC } as User}
													/>
												)}
												<ListGroupItemText style={{ marginLeft: 15 }}>
													{findUserByEmail(recipient.email)?.givenName}{' '}
													{findUser(recipient._id)?.familyName}
													<SmallText>{recipient.email}</SmallText>
													{!findUserByEmail(recipient.email) && recipient.email}
												</ListGroupItemText>
											</div>
										</ListGroupItem>
									))}
								</ListGroup>
							</Col>
						)}

						{view === 'access-logs' && (
							<Col xl={12}>
								<ListGroup style={{ maxHeight: 500, overflow: 'auto' }}>
									{orderBy(
										uniqBy(allEvents, (a) => a._id),
										(a) => new Date(a.createdAt),
										'desc'
									)?.map((evt) => (
										<ListGroupItem key={evt._id}>
											<ListGroupItemText
												style={{ color: defaults?.primary, fontSize: 12 }}
											>
												<FontAwesomeIcon
													icon={getIconForAction(evt.action) as IconDefinition}
												/>{' '}
												&nbsp;
												{findUser(evt.createdBy)?.givenName}{' '}
												{findUser(evt.createdBy)?.familyName}{' '}
												{renderEventAsString(evt)}
												<SmallText className="d-flex">
													{getCreationDateStr((evt as any) as Creatable)}
												</SmallText>
											</ListGroupItemText>
										</ListGroupItem>
									))}
								</ListGroup>
							</Col>
						)}
					</Row>
				</CardBody>
				<CardFooter>
					<PrimaryButton
						style={{ backgroundColor: defaults?.primary, color: `#fff` }}
						onClick={downloadAll}
					>
						<FontAwesomeIcon icon={faFileDownload} />
						&nbsp;Download All Files
					</PrimaryButton>
				</CardFooter>
			</Card>
		</Container>
	);
};

export default FileSharingPoint;
