import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';
import { useAuthContext, User } from 'auth';
import { PageSubheading } from 'components';
import React from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	FormText,
	ListGroup,
	ListGroupItem,
	ListGroupItemText,
} from 'reactstrap';
import styled from 'styled-components';
import './RMResourcesCard.scss';

const Avatar = styled.img`
	border-radius: 50%;
	transition: all 0.2s ease-in-out;
	&:hover {
		border: 2px solid #eee;
		transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
`;

export const RMUserList = () => {
	const { entities } = useAuthContext();
	const { defaults } = useThemeContext();
	const defaultUserSRC = `https://lh6.googleusercontent.com/-2AA6LyuzpZI/AAAAAAAAAAI/AAAAAAAAAGE/AMZuuclxEqw2wOgKvIM8phyh3RL9kxQC-Q/photo.jpg`;
	const isNotDisabled = (user: User) => user.status !== 'disabled';
	return (
		<Card className="rome-card">
			<CardHeader>
				<PageSubheading text={'Most Utilized Resources'} />
				<FontAwesomeIcon
					className="header-icon"
					style={{ color: defaults?.primary }}
					icon={faCubes}
				/>
			</CardHeader>
			<CardBody>
				<ListGroup>
					{entities
						?.filter(isNotDisabled)
						.slice(0, 10)
						.map((user) => (
							<ListGroupItem key={user._id}>
								<div className="d-flex justify-between">
									{user.picture && (
										<Avatar
											alt={`${user.givenName} ${user.familyName}`}
											src={user?.picture}
											style={{ height: 60 }}
											className="img-thumbnail img-fluid"
										/>
									)}
									{!user.picture && (
										<Avatar
											src={defaultUserSRC}
											alt={`${user.givenName} ${user.familyName}`}
											style={{ height: 60 }}
											className="img-thumbnail img-fluid"
										/>
									)}
									<div
										style={{
											paddingLeft: 10,
											marginLeft: 5,
											color: '#222',
											overflow: 'hidden',
											maxWidth: 300,
										}}
									>
										<p
											style={{
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
											}}
										>
											{user.email}
										</p>
										<ListGroupItemText
											style={{ color: defaults?.primary, fontSize: 12 }}
										>
											{user.givenName} {user.familyName}
											<FormText className="d-flex">
												{(user?.title || user?.expertise) && (
													<>({user?.title ?? user?.expertise})</>
												)}
											</FormText>
										</ListGroupItemText>
									</div>
								</div>
							</ListGroupItem>
						))}
				</ListGroup>
			</CardBody>
		</Card>
	);
};
