import { Container } from 'reactstrap';
import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

import { AuthContext } from '../core';
import { focus } from '../theme/ui/ui';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

// styled components
const FooterContainer = styled.footer`
	display: flex;
	position: absolute;
	align-items: center;
	background-color: var(--white);
	// set border color to theme
	border-top: 1px solid #ccc;
	bottom: 0;
	height: 5em;
	margin-top: 5em;
	width: 100%;
`;

const StyledLink = styled(Link)`
	color: ${defaults?.secondary};

	&:hover {
		color: ${defaults?.secondaryHighlighted};
	}

	&:focus {
		${focus};
	}
`;

// component
const Footer = () => {
	const auth = React.useContext(AuthContext);

	const renderTermsLink = () => (
		<p className="text-muted float-right">
			<StyledLink to="/admin/terms">Terms &amp; Conditions</StyledLink>
		</p>
	);

	const render = () => (
		<FooterContainer>
			<Container fluid className="clearfix">
				<p className="text-muted float-left">
					© {new Date().getFullYear()} We Are Alexander
				</p>
				{auth.isAuthenticated ? renderTermsLink() : null}
			</Container>
		</FooterContainer>
	);

	return render();
};

export default Footer;
