import React, { useCallback } from 'react';
import {
	BaseWorkflowOwner,
	Flattenable,
	Stage,
	Workflow,
	WorkflowCollection,
	WorkflowTemplate,
} from '../types/workflow.types';
import * as axios from 'axios';
import { StatusEnum } from '../types/workflowStatus.types';
import { useAxios } from 'hooks';
import { useGroupContext, useAuthContext, User, useHeaders } from 'auth';
import { flattenStages } from 'workflows/helpers/workflowStage.helpers';
import { _logError } from 'common/log';
import { UserGroup } from 'accounts/types';
import { isArray } from 'lodash';
import { EventType } from 'models/StageEventTypeModel';
import { ReportData } from 'reports/status-report-table.component';
type ReportColumn = {
	title: string;
	key: string;
	label: string;
};
export type ReportTemplate = {
	_id: string;
	title: string;
	filenameTemplate: string;
	columns: ReportColumn[];
};

export type ReportStats = Array<{
	views: number;
	reportId: string;
	timestamp: Date;
}>;

type StoreProps = {
	updateFollowers: (
		workflowId: string,
		newFollowers: BaseWorkflowOwner[]
	) => Promise<boolean>;
	deleteComment: (
		commentId: string,
		workflowId: string,
		stageId: string
	) => Promise<boolean>;
	updateComment: (
		commentId: string,
		workflowId: string,
		stageId: string,
		commentText: string
	) => Promise<boolean>;
	updateTemplateContext: (template: WorkflowTemplate) => void;
	addToContext: (workflow: Workflow) => void;
	entities: Workflow[];
	updateOne: (id: string, workflow: Workflow) => Promise<Maybe<Workflow>>;
	findOne: (id: string) => Promise<Maybe<Workflow>>;
	fetchAllWorkflows: () => Promise<Workflow[]>;
	workflow?: Workflow;
	stage?: Stage;
	userFollowedTemplates?: WorkflowTemplate[];
	setStage: (s: Stage) => void;
	setOwner: (
		type: string,
		newOwners: BaseWorkflowOwner[],
		stage: Stage,
		workflow: Workflow
	) => Promise<Maybe<Workflow>>;
	setWorkflow: (wf: Workflow) => void;
	addComment: (comment: string) => Promise<Stage | undefined>;
	updateCollection: (
		workflow: Workflow,
		workflowCollection: string
	) => Promise<Maybe<Workflow>>;
	updateStatus: (
		newStatus: StatusEnum,
		wf: Workflow,
		message: string,
		stage?: Stage
	) => Promise<Maybe<Workflow>>;
	canOwnerActOnWorkflow: (
		currentUser: User,
		groupsForCurrentUser?: UserGroup[]
	) => boolean;
	removeFromCollection: (workflowId: string) => Promise<Maybe<Workflow>>;
	allTemplates: WorkflowTemplate[];
	getReportData: (reportId: string) => Promise<Maybe<ReportData>>;
	getReportStats: () => Promise<Maybe<ReportStats>>;
	fetchAvailableReports: () => Promise<
		Maybe<{ reports: ReportTemplate[]; stats: ReportStats }>
	>;
	revertToStage: (stageId: string, workflowId: string) => Promise<Workflow>;
	rejectStage: (
		transitionId: string,
		message: string,
		workflow: Workflow,
		stage: Stage
	) => Promise<Maybe<Workflow>>;
};

type WorkflowStoreContext = {
	state: StoreProps;
};

export const WorkflowStoreContext = React.createContext<WorkflowStoreContext>({
	state: {
		updateTemplateContext: () => {
			return;
		},
		updateFollowers: () => Promise.resolve(false),
		deleteComment: () => Promise.resolve(false),
		updateComment: () => Promise.resolve(false),
		addToContext: (workflow: Workflow) => {
			return;
		},
		updateOne: () => new Promise((res) => res({} as Workflow)),
		findOne: () => new Promise((res) => res({} as Workflow)),
		fetchAllWorkflows: () => new Promise((res) => res([] as Workflow[])),
		updateCollection: () => new Promise((res) => res({} as Workflow)),
		updateStatus: () => new Promise((res) => res({} as Workflow)),
		removeFromCollection: () => new Promise((res) => res({} as Workflow)),
		addComment: () => new Promise((res) => res({} as Stage)),
		userFollowedTemplates: [] as WorkflowTemplate[],
		stage: {} as Stage,
		setStage: (stage: Stage) => {
			return;
		},
		setOwner: () => new Promise((res) => res({} as Workflow)),
		entities: Array<Workflow>(),
		workflow: {} as Workflow,
		canOwnerActOnWorkflow: () => true,
		setWorkflow: (wf) => {
			return;
		},
		allTemplates: [],
		getReportData: () => Promise.resolve({} as ReportData),
		getReportStats: () => Promise.resolve([] as ReportStats),
		revertToStage: () => new Promise((res) => res({} as Workflow)),
		rejectStage: () => Promise.resolve<Workflow>({} as Workflow),
		fetchAvailableReports: () =>
			Promise.resolve({ reports: [] as ReportTemplate[], stats: [] }),
	},
});

function stageReducer(
	state: { selectedStage: Maybe<Stage> },
	action: {
		type: 'setStage';
		payload: Stage;
	}
) {
	switch (action.type) {
		case 'setStage':
			return { ...state, selectedStage: action.payload as Stage };
		default:
			return state;
	}
}

function workflowReducer(
	state: { workflow: Maybe<Workflow> },
	action: {
		type: 'setWorkflow';
		payload: Workflow;
	}
) {
	switch (action.type) {
		case 'setWorkflow':
			return { ...state, workflow: action.payload as Workflow };
		default:
			return state;
	}
}

export function fetchingReducer(
	state: { isFetching: boolean },
	action: { type: 'set'; payload: boolean }
) {
	switch (action.type) {
		case 'set':
			return { ...state, isFetching: action.payload };
		default:
			return state;
	}
}
export const WorkflowStoreContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const [state, dispatch] = React.useReducer(fetchingReducer, {
		isFetching: false,
	});
	const { getHeaders } = useHeaders();

	const [entities, setEntities] = React.useState<Workflow[]>();
	const [workflow, setWorkflow] = React.useReducer(workflowReducer, {
		workflow: undefined,
	});
	const [stage, setStage] = React.useReducer(stageReducer, {
		selectedStage: undefined,
	});
	const [userFollowedTemplates, setUserFollowedTemplates] = React.useState<
		WorkflowTemplate[]
	>();
	const [allTemplates, setAllTemplates] = React.useState<WorkflowTemplate[]>();

	const refreshEntity = (entity: Workflow) => {
		if (entities?.some((wf) => wf._id === entity._id)) {
			setEntities([...entities.filter((e) => e._id !== entity._id), entity]);
		}
	};

	const updateComment = async (
		commentId: string,
		workflowId: string,
		stageId: string,
		commentText: string
	) => {
		const response = await axios.default.patch<Workflow>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/stages/${stageId}/events/${commentId}/${commentText}`,
			{},
			getHeaders()
		);
		const workflowAfterCommentDelete = response.data;
		if (workflowAfterCommentDelete) {
			refreshEntity(workflowAfterCommentDelete);
			setWorkflow({ type: 'setWorkflow', payload: workflowAfterCommentDelete });
			const updatedStage = flattenStages(
				workflowAfterCommentDelete as Workflow
			)?.find((stage) => stage._id === stageId);
			setStage({ type: 'setStage', payload: updatedStage as Stage });
		}
		return !!workflowAfterCommentDelete;
	};

	const deleteComment = async (
		commentId: string,
		workflowId: string,
		stageId: string
	) => {
		const response = await axios.default.delete<Workflow>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/stages/${stageId}/events/${commentId}`,
			getHeaders()
		);
		const workflowAfterCommentDelete = response.data;
		if (workflowAfterCommentDelete) {
			refreshEntity(workflowAfterCommentDelete);
			setWorkflow({ type: 'setWorkflow', payload: workflowAfterCommentDelete });
			const updatedStage = flattenStages(
				workflowAfterCommentDelete as Workflow
			)?.find((stage) => stage._id === stageId);
			setStage({ type: 'setStage', payload: updatedStage as Stage });
		}
		return !!workflowAfterCommentDelete;
	};

	const rejectStage = async (
		transitionId: string,
		message: string,
		workflow: Workflow,
		stage: Stage
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		//if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.put<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflow._id}/stages/${stage._id}`,
				{ type: EventType.backwardTransition, transitionId, message },
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data as Workflow });
		return response.data as Workflow;
	};

	const getReportData = async (id: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		const response = await axios.default.get<ReportData>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflow-reports/${id}`,
			getHeaders()
		);
		return response.data;
	};

	const getReportStats = async () => {
		if (!getHeaders()?.headers?.Authorization) return;
		const response = await axios.default.get<ReportStats>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflow-reports/stats`,
			getHeaders()
		);

		return response.data;
	};

	const fetchAvailableReports = async () => {
		if (!getHeaders()?.headers?.Authorization) return;
		const response = await axios.default.get<{
			reports: ReportTemplate[];
			stats: ReportStats;
		}>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflow-reports`,
			getHeaders()
		);

		return response && response.data
			? response.data
			: { reports: [] as ReportTemplate[], stats: {} as ReportStats };
	};

	const revertToStage = async (stageId: string, workflowId: string) => {
		const response = await axios.default.post<Workflow>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/revert/${stageId}`,

			{},
			getHeaders()
		);
		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data });
		return response.data;
	};

	const fetchAllWorkflows = async () => {
		dispatch({ type: 'set', payload: true });
		const res = await axios.default
			.get<Workflow[]>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows`,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		setEntities(res.data);
		return res.data;
	};

	React.useEffect(() => {
		if (entities) return;
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		axios.default
			.get<Workflow[]>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows`,
				getHeaders()
			)
			.then((response) => {
				if (response?.data) {
					setEntities(response.data);
				}
			})
			.finally(() => dispatch({ type: 'set', payload: false }));
	}, [getHeaders, state.isFetching, entities]);

	const isUserOwnerOrFollower = (template: WorkflowTemplate) => {
		const isFollower =
			!!template.followers?.some((follower) => {
				return (
					follower._id === currentUser._id ||
					!!groupsForCurrentUser.some(
						(group: UserGroup) => group._id === follower._id
					)
				);
			}) ||
			!!((template.createdBy as User)._id === currentUser._id) ||
			!!template.owners?.some(
				(owner) =>
					owner._id === currentUser._id ||
					groupsForCurrentUser.some((group) => group._id === owner._id)
			);

		const isStakeholder = flattenStages(
			template as Flattenable
		)?.some((stage) =>
			stage?.owners?.some(
				(owner) =>
					owner._id === currentUser._id ||
					groupsForCurrentUser.some((grp) => grp._id === owner._id)
			)
		);
		return isFollower || isStakeholder;
	};

	React.useEffect(() => {
		if (allTemplates?.length && allTemplates.length > 0) return;
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		templateStore
			.findAll()
			.then((templates) => {
				setAllTemplates(templates);
				setUserFollowedTemplates(templates.filter(isUserOwnerOrFollower) || []);
			})
			.catch((e) => {
				_logError(e);
				dispatch({ type: 'set', payload: false });
			})
			.finally(() => dispatch({ type: 'set', payload: false }));
		// eslint-disable-next-line
	}, [
		getHeaders,
		userFollowedTemplates,
		groupsForCurrentUser,
		currentUser._id,
		state.isFetching,
		allTemplates,
		templateStore,
	]);

	const addToContext = (workflow: Workflow) => {
		setEntities([...(entities || []), workflow]);
	};

	const addComment = async (comment: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const updatedWorkflow = await axios.default
			.put<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflow?.workflow?._id}/stages/${stage.selectedStage?._id}`,
				{ message: comment, type: 'comment' },
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		refreshEntity(updatedWorkflow.data);
		setWorkflow({
			type: 'setWorkflow',
			payload: updatedWorkflow.data as Workflow,
		});
		setStage({
			type: 'setStage',
			payload: flattenStages(updatedWorkflow.data)?.find(
				(updated) => updated._id === stage.selectedStage?._id
			) as Stage,
		});
		return flattenStages(updatedWorkflow.data)?.find(
			(updated) => updated._id === stage.selectedStage?._id
		) as Stage;
	};

	const setStageCb = useCallback((stage: Stage) => {
		setStage({ type: 'setStage', payload: stage });
	}, []);

	const updateOne = async (id: string, updatedWf: Workflow) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.patch<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${id}`,
				{ ...updatedWf, updatedBy: currentUser._id },
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		if (response.data) {
			refreshEntity(response.data);
			setWorkflow({ type: 'setWorkflow', payload: response.data });
		}
		return response.data as Workflow;
	};

	const updateCollection = async (wf: Workflow, workflowCollection: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.put<{ workflow: Workflow; collections: WorkflowCollection[] }>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${wf._id}/collection/${workflowCollection}`,
				wf,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity({
			...response.data.workflow,
			workflowCollection: response.data.collections[0],
		});
		setWorkflow({
			type: 'setWorkflow',
			payload: {
				...(response.data.workflow as Workflow),
				workflowCollection: response.data.collections[0],
			},
		});

		return {
			...response.data.workflow,
			workflowCollection: response.data.collections[0],
		} as Workflow;
	};

	const findOne = async (id: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.get<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${id}`,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		return response.data as Workflow;
	};

	const setOwner = async (
		type: string = 'setOwner',
		newOwners: BaseWorkflowOwner[],
		stage: Stage,
		workflow: Workflow
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		//if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const response = await axios.default
			.put<Workflow>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflow._id}/stages/${stage._id}`,
				{ type, newOwners },
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(response.data);
		setWorkflow({ type: 'setWorkflow', payload: response.data as Workflow });
		return response.data as Workflow;
	};

	const updateStatus = async (
		newStatus: StatusEnum,
		wf: Workflow,
		message: string,
		stage?: Stage
	) => {
		if (!getHeaders()?.headers?.Authorization) return;
		//if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		let endpoint: string = `${process.env.REACT_APP_ROME_API_ENDPOINT}`;
		if (stage) {
			endpoint += `/workflows/${wf._id}/stages/${stage._id}`;
		} else {
			endpoint += `/workflows/${wf._id}/status`;
		}

		let payload;
		if (!!stage)
			payload = { type: 'statusChange', newStatus, statusMsg: message };
		else payload = { status: newStatus, statusMsg: message };

		const workflowAfterAction = await axios.default
			.put<Workflow>(endpoint, payload, getHeaders())
			.finally(() => dispatch({ type: 'set', payload: false }));

		refreshEntity(workflowAfterAction.data);
		setWorkflow({
			type: 'setWorkflow',
			payload: workflowAfterAction.data as Workflow,
		});

		if (stage) {
			setStage({
				type: 'setStage',
				payload: workflowAfterAction.data.stages?.find(
					(stg) => stg._id === stage?._id
				) as Stage,
			});
		}

		return workflowAfterAction.data as Workflow;
	};

	const setWorkflowCb = useCallback((workflow: Workflow) => {
		setWorkflow({ type: 'setWorkflow', payload: workflow });
	}, []);

	const removeFromCollection = async (workflowId: string) => {
		if (!getHeaders()?.headers?.Authorization) return;
		if (state.isFetching) return;
		dispatch({ type: 'set', payload: true });
		const updated = await axios.default
			.delete<{ workflow: Workflow; collections: WorkflowCollection[] }>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/collection`,
				getHeaders()
			)
			.finally(() => dispatch({ type: 'set', payload: false }));
		if (updated.data) {
			refreshEntity(updated.data.workflow);
			setWorkflow({
				type: 'setWorkflow',
				payload: updated.data.workflow as Workflow,
			});
		}
		return updated.data.workflow as Workflow;
	};

	const canOwnerActOnWorkflow = (
		currentUser: User,
		groupsForCurrentUser?: UserGroup[]
	) => {
		if (!workflow.workflow) return false;
		if (!!flattenStages(workflow.workflow)) {
			return ((
				flattenStages(workflow.workflow) || []
			)?.some((stage: Stage[] | Stage) =>
				isArray(stage)
					? stage?.some((owner) =>
							owner?.owners?.some(({ _id }) => _id === currentUser._id)
					  )
					: stage?.owners?.some(({ _id }) => _id === currentUser._id) ||
					  (groupsForCurrentUser || [])?.some(({ members }) =>
							members?.some((u) => u._id === currentUser._id)
					  )
			) ||
				workflow.workflow?.owners.some((m) => m._id === currentUser._id) ||
				workflow.workflow?.createdBy._id === currentUser._id ||
				currentUser.isAdmin) as boolean;
		} else return false;
	};

	const updateTemplateContext = (template: WorkflowTemplate) => {
		setAllTemplates([
			...(allTemplates || [])?.filter((a) => a._id !== template._id),
			template,
		]);
	};

	const updateFollowers = async (
		workflowId: string,
		newFollowers: BaseWorkflowOwner[]
	) => {
		const response = await axios.default.patch<Workflow>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/workflows/${workflowId}/followers`,
			{ followers: newFollowers },
			getHeaders()
		);
		if (response && response.data) {
			refreshEntity(response.data);
			setWorkflow({
				type: 'setWorkflow',
				payload: response.data as Workflow,
			});
		}
		return !!response && !!response.data;
	};

	return (
		<WorkflowStoreContext.Provider
			value={{
				state: {
					updateFollowers,
					deleteComment,
					updateComment,
					updateTemplateContext,
					addToContext,
					addComment,
					fetchAllWorkflows,
					findOne,
					setStage: setStageCb,
					updateOne,
					entities: entities as Workflow[],
					userFollowedTemplates,
					updateCollection,
					stage: stage.selectedStage,
					workflow: workflow.workflow as Workflow,
					setWorkflow: setWorkflowCb,
					updateStatus,
					removeFromCollection,
					setOwner,
					canOwnerActOnWorkflow,
					allTemplates: allTemplates as WorkflowTemplate[],
					getReportData,
					revertToStage,
					rejectStage,
					fetchAvailableReports,
					getReportStats,
				},
			}}
		>
			{children}
		</WorkflowStoreContext.Provider>
	);
};

export const useWorkflowContext = () => {
	const Context = React.useContext(WorkflowStoreContext);
	if (!Context)
		throw new Error('Expected to be in a workflow context, but was not');
	return { ...Context.state };
};
