import { types } from 'mobx-state-tree';
import { enumValues } from '../../common/enum.utils';

export enum UserRole {
	'RomeDevelopers' = 'RomeDevelopers',
	'SuperAdmin' = 'SuperAdmin',
	'ProjectManager' = 'ProjectManager',
	'User' = 'User',
	'Observer' = 'Observer',
	'DAMOnlyAdmin' = 'DAMOnlyAdmin',
	'DAMOnlyNonAdmin' = 'DAMOnlyNonAdmin',
}

export const getUserRoleName = (userRole: UserRole | string) => {
	if (!userRole) return '';
	switch (userRole) {
		case UserRole.User:
			return 'Reviewer / Operator';
		case UserRole.SuperAdmin:
			return 'SiteAdmin';
		default:
			return userRole.toString();
	}
};

// Roles with most privileges have higher index.

const userRoles = enumValues(UserRole);

export const userRolesModel = types.enumeration(userRoles);

const sortedRoles = [
	UserRole.DAMOnlyAdmin,
	UserRole.DAMOnlyNonAdmin,
	UserRole.User,
	UserRole.Observer,
	UserRole.ProjectManager,
	UserRole.SuperAdmin,

	UserRole.RomeDevelopers,
];

export const isRoleAtLeast = (role: UserRole, required: UserRole) =>
	sortedRoles.indexOf(role) >= sortedRoles.indexOf(required);
