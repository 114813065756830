import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { EditPreferencesToggleSwitch } from 'views/EditPreferences/EditPreferencesToggleSwitch';
export const ResourceSettingsDialog = ({
	initial,
	setInclude,
	setShowSettings,
}: {
	initial: boolean;
	setInclude: (bool: boolean) => void;
	setShowSettings: (show: boolean) => void;
}) => {
	const [localState, setLocalState] = React.useState(initial);
	const onToggle = (params: any) => {
		setLocalState(!localState);
	};

	const render = () => (
		<ConfirmationDialog
			confirmText={'Update settings'}
			onCancel={() => setShowSettings(false)}
			onConfirm={() => setInclude(localState)}
			header="Resource Settings"
		>
			<Container>
				<Row>
					<Col xl={12}>
						<EditPreferencesToggleSwitch
							label="Include User Groups"
							onStateChanged={onToggle}
							preferencesEnabled={initial}
							enabled={initial}
						/>
					</Col>
				</Row>
			</Container>
		</ConfirmationDialog>
	);
	return render();
};
