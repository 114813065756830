import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';
import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const ViewButtonWrapper = styled.div`
	display: inline-flex;
`;

export const ViewButton = styled.button`
	border: none;
	background: transparent;
	margin-left: 6px;
	border-radius: 3px;
	color: var(--darkGrey);

	&.active {
		color: ${defaults?.secondary};
	}

	&:hover {
		color: ${colors.darkestGrey};
	}
`;
