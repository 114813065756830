import { useParams } from '@reach/router';
import { Heading, PageSubheading } from 'components';
import React from 'react';
import { Col, Container, Nav, Row } from 'reactstrap';
import FileSharingHistory from './FileSharingHistory';
import FileSharingInbox from './FileSharingInbox';
import FileSharingSend from './FileSharingSend';

const FileSharing = () => {
	const { view: selectedView, recipient_email } = useParams();
	const [view, setView] = React.useState<'inbox' | 'send' | 'history'>(
		selectedView ?? 'inbox'
	);
	const activeViewClass = (type: 'inbox' | 'send' | 'history') => {
		if (view === type) return 'text-underlined';

		return '';
	};
	return (
		<Container>
			<div>
				<PageSubheading text="ROME" />
				<Heading>File Sharing</Heading>
			</div>
			<hr />
			<Nav className="mt-3" tabs>
				<li
					onClick={() => setView('inbox')}
					className={activeViewClass('inbox')}
				>
					<span className={`nav-link ${view === 'inbox' ? 'active' : ''}`}>
						Inbox
					</span>
				</li>
				<li onClick={() => setView('send')} className={activeViewClass('send')}>
					<span className={`nav-link ${view === 'send' ? 'active' : ''}`}>
						Send
					</span>
				</li>
				<li
					onClick={() => setView('history')}
					className={activeViewClass('history')}
				>
					<span className={`nav-link ${view === 'history' ? 'active' : ''}`}>
						History
					</span>
				</li>
			</Nav>
			<Row>
				<Col xl={12}>
					{view === 'inbox' && <FileSharingInbox />}
					{view === 'send' && (
						<FileSharingSend recipient_email={recipient_email} />
					)}
					{view === 'history' && <FileSharingHistory />}
				</Col>
			</Row>
		</Container>
	);
};
export default FileSharing;
