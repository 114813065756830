import React, { useCallback } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { buildClassList } from '../../../common';
import { StageStatus } from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';

import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from '../action-button.styled-components';

import { StageActionButtonProps } from '.';
import ApproveStageDialog from './approve-stage-dialog.component';
import { Stage, Workflow } from '../../types/workflow.types';
import { useWorkflowContext } from 'workflows/models/useWorkflowStore';
import { isFulfilled } from 'workflow-templates/components/stage-cards/Stage.helpers';

const buildButtonClassList = (isFulfilled: boolean) => {
	let classList = 'action-button approve-stage-button';
	if (isFulfilled) {
		classList = `${classList} success`;
	} else {
		classList = `${classList} disabled`;
	}
	return classList;
};

export const ApproveStageButton = (props: StageActionButtonProps) => {
	const { className } = props;
	const modalStack = useModalCreator();
	const { workflow, stage } = useWorkflowContext();

	const fulfilled = isFulfilled(stage as Stage);
	const buttonId = `approveStage${stage?._id}`;
	const isButtonDisabled =
		!fulfilled || stage?.status === StageStatus.roadblocked;

	const openApprovalModal = useCallback(() => {
		modalStack.addModal(
			<ApproveStageDialog
				workflow={workflow as Workflow}
				stage={stage as Stage}
			/>
		);
	}, [stage, modalStack, workflow]);

	return (
		<ActionButtonContainer
			className={buildClassList('stage-action approve-stage', className)}
		>
			<StyledTooltip target={buttonId}>
				{fulfilled ? 'Approve stage' : 'Stage is missing a required asset'}
			</StyledTooltip>
			<ActionButton
				id={buttonId}
				color=""
				size="sm"
				onClick={openApprovalModal}
				className={buildButtonClassList(fulfilled)}
				disabled={isButtonDisabled}
			>
				<IconWithRightMargin icon={faCheck} />
				Complete
			</ActionButton>
		</ActionButtonContainer>
	);
};
