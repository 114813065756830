import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';
import { useQueryStoreContext } from 'queries/hooks/useQueryStore';
import React from 'react';
import { Button } from 'reactstrap';
import { NotificationsContext } from '../notifications';
import { SecondaryButton } from './buttons.styled-components';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
	children: React.ReactChild[] | React.ReactNode[] | any;
}
export const NewSecondaryButton: React.FC<
	ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }: any) => {
	const { defaults } = useThemeContext();
	return (
		<Button {...props} style={{ background: defaults?.secondary }}>
			{children}
		</Button>
	);
};

interface Props {
	className?: string;
	searchTerm: string;
	type: string;
}

export const SaveQueryButton = ({ searchTerm, type, className }: Props) => {
	const { info } = React.useContext(NotificationsContext);
	const queryStore = useQueryStoreContext();

	const saveQuery = () => {
		queryStore.addNew(searchTerm, type, true);
		info(`Saved query "${searchTerm}".`);
	};

	return (
		<SecondaryButton
			className={`save-query-button ${className}`}
			onClick={saveQuery}
			disabled={searchTerm === ''}
		>
			<FontAwesomeIcon icon={faSave} className="mr-2" />
			<span className="button-label">Save query</span>
		</SecondaryButton>
	);
};

export default SaveQueryButton;
