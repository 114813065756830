import ThemeModel from '../models/ThemeModel';
import { toRGBA } from '../../common/color.utils';

const primary = '#42210b';
const primaryHighlighted = '#70543d';

const secondary = '#004c45';
const secondaryHighlighted = '#2f7366';

const navbarBackground = '#ffffff';

// status colors
const pipeline = primary;

// a11y
const focus = toRGBA(primary, 0.5);

export const acreageTheme = ThemeModel.create({
	_id: 'acreage',
	title: 'Acreage Holdings',
	images: {
		desktopLogo: '/themes/acreage/logo-desktop.png',
		mobileLogo: '/themes/acreage/logo-mobile.png',
		loginBackground: '/themes/acreage/login-bg.jpg',
	},
	colors: {
		primary,
		primaryHighlighted,
		secondary,
		secondaryHighlighted,
		pipeline,
		focus,
		navbarBackground,
	},
});
