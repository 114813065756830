import {
	faCheckCircle,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useActiveUsersContext } from 'auth/ActiveUserContext';
import { User } from 'auth/AuthContext';
import React from 'react';
import styled from 'styled-components';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export interface AvatarProps {
	user: User;
	size?: AvatarSize | number;
	className?: string;
	hideOnlineStatus?: boolean;
}

export const ProvidedAvatar = styled.div<AvatarProps>`
	display: flex;
	min-width: ${(props) => (props.size ? props.size : 2.5)}em;
	height: ${(props) => (props.size ? props.size : 2.5)}em;
	border-radius: 50%;
	font-weight: 700;
	color: white;
	align-items: center;
	font-family: 'Roboto Slab', serif;
	justify-content: center;
	border: 1px solid #fff;
	background-color: white;
	background-image: url('${(props) => props.user?.picture}');
	background-size: cover;
	background-position: center center;
	margin-right: -0.5em
  `;

export interface GeneratedAvatarProps {
	backgroundColor: string;
	size?: AvatarSize | number;
}
export const GeneratedAvatar = styled.div<GeneratedAvatarProps>`
	display: flex;
	min-width: ${(props) => (props.size ? props.size : 2.5)}em;
	height: ${(props) => (props.size ? props.size : 2.5)}em;
	border-radius: 50%;
	font-weight: 700;
	background-color: ${(props) => props.backgroundColor};
	color: white;
	align-items: center;
	font-family: 'Roboto Slab', serif;
	justify-content: center;
	border: 1px solid #fff;
	margin-right: -0.5em;
`;

export const Avatar = (props: AvatarProps) => {
	const { user, size } = props;
	const { activeUsers } = useActiveUsersContext();
	if (!user?._id) return null;
	const isActive = activeUsers.some((a: any) => a?._id === user?._id);
	const getSizeToUse = () => {
		if (!size) return 2.5;
		if (size === 'xs') return 1;
		if (size === 'sm') return 1.25;
		if (size === 'md') return 2.5;
		if (size === 'lg') return 2.5;
		if (size === 'xl') return 3.5;
	};

	const VALID_URL_REGEX = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	);

	if (
		(user && user?.picture?.search('gravatar') !== -1) ||
		!VALID_URL_REGEX.test(user?.picture)
	) {
		let id: string | number = user?._id?.charAt(user._id.length - 1);

		id = isNaN(+id) ? id.charCodeAt(0) - 97 : id;
		let backgroud: string;
		switch (true) {
			case id > 5:
				backgroud = '#0F2347';
				break;
			case id > 10:
				backgroud = '#1C3F6E';
				break;
			case id > 15:
				backgroud = '#A23541';
				break;
			case id > 20:
				backgroud = '#5AACCF';
				break;
			default:
				backgroud = '#80C271';
				break;
		}
		return (
			<div
				style={{ height: 'min-content', position: 'relative' }}
				title={user && user?.givenName + ' ' + user?.familyName}
			>
				<GeneratedAvatar size={getSizeToUse()} backgroundColor={backgroud}>
					{user?.givenName?.charAt(0)}
					{user?.familyName?.charAt(0)}
				</GeneratedAvatar>
			</div>
		);
	}

	return (
		<div
			style={{ height: 'min-content', position: 'relative' }}
			title={user && user.givenName + ' ' + user.familyName}
		>
			<ProvidedAvatar size={getSizeToUse()} user={user} />
		</div>
	);
};
