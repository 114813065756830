import React, { useCallback, useState } from 'react';
import { faPlus, faShare, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate, RouteComponentProps } from '@reach/router';
import Moment from 'react-moment';
import { Col, Container, Row, ButtonGroup } from 'reactstrap';
import BackLink from '../components/back-link/back-link.component';
import CircleButton from '../components/circle-button.component';
import CollectionActionDropdown from '../components/collection-action-dropdown';
import DownloaderButton from '../components/downloader-button.component';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../components/entity-details.styled-components';
import ConfirmationDialog from '../components/modals/confirmation-dialog.component';
import { Divider, Heading } from '../components/ui';
import EntityMetadataFields from '../metadata/components/entity-metadata-fields.component';
import { NotificationsContext } from '../notifications';
import { useModalCreator } from '../stores/ModalStack';
import themeStore from '../theme/models/ThemeStore';
import AddAssetsDialog from './components/add-assets-dialog.component';
import { ShareAssetButton } from '../dam-assets/components/asset-details.styled-components';
import ShareSingleAssetDialog from '../dam-assets/components/share-dialogs/share-single-asset-dialog.component';
import ShareMultipleAssetsDialog from '../dam-assets/components/share-dialogs/share-multiple-assets-dialog.component';
import Loading from '../components/loading.component';
import RenderWhen from '../components/render-when.component';
import {
	AssetCollection,
	AssetVersion,
	EntityMetadata,
} from '../workflows/types/workflow.types';
import { useAxios } from '../hooks';
import { useAssetHelper } from '../dam-assets/components/helpers/useAssetHelper';
import { useAuthContext, User } from '../auth';
import { _logError } from 'common/log';
import { RequiresFeatureFlag } from 'core/FeatureFlags';
import AssetSelectionToolbar, {
	AssetSelectionTool,
	AssetSelectionToolbarClickEvent,
} from 'dam-assets/components/asset-selection-toolbar.component';
import { orderBy } from 'lodash';
import AssetCardGrid from 'dam-assets/components/asset-card-grid.component';
import { AlgoliaAssetVersion } from 'dam-assets/components/asset-infinite-hits.component';
const tools = [
	AssetSelectionTool.VIEW,
	AssetSelectionTool.COMPARE,
	AssetSelectionTool.DOWNLOAD,
	AssetSelectionTool.SHARE,
];
// constants
const assetTerm = themeStore._.asset.toLowerCase();
const assetCollectionTerm = themeStore._.assetCollection;

// component
const AssetCollectionDetailsView = (
	props: RouteComponentProps<{ collectionId: string }>
) => {
	const { info, error } = React.useContext(NotificationsContext);
	const collectionStore = useAxios<AssetCollection>('collections');
	const assetHelper = useAxios<AssetVersion>('assets');
	const { entities: users, currentUser } = useAuthContext();
	const { canEditAssetCollection } = useAssetHelper();
	const [fetching, setFetching] = useState(false);
	const [collection, setCollection] = useState<AssetCollection>();
	const [assets, setAssets] = useState<AssetVersion[]>([]);
	const assetStore = useAssetHelper();
	const [sortProperty, setSortProperty] = useState('');
	const [sortDirection, setSortDirection] = useState('');
	const [selectingAssets, setSelectingAssets] = useState(false);
	const [selectedAssets, setSelectedAssets] = useState<AssetVersion[]>([]);

	const assetSelectionCallBack = useCallback(
		(asset: AlgoliaAssetVersion) => {
			const index = selectedAssets.indexOf(asset);
			if (index > -1) {
				// remove from selection list
				setSelectedAssets((selectedAssets) =>
					selectedAssets.filter((a) => a._id !== asset._id)
				);
			} else {
				// add to selection list
				setSelectedAssets((selectedAssets) => selectedAssets.concat(asset));
			}
		},
		[selectedAssets]
	);

	React.useEffect(() => {
		if (!!collection?.assets?.length && assets.length === 0) {
			collection.assets.forEach(async (asset) => {
				const found = (await assetStore.findOne(asset)) as AssetVersion;
				setAssets((assets) => [...assets, found]);
			});
		}
		// eslint-disable-next-line
	}, [collection]);
	React.useEffect(() => {
		if (fetching) return;
		if (!collection) {
			setFetching(true);
			collectionStore
				.findOne(props.collectionId as string)
				.then(setCollection)
				.finally(() => setFetching(false));
		}
	}, [
		fetching,
		collection,
		collectionStore,
		props.collectionId,
		assets,
		assetHelper,
	]);

	const [owner, setOwner] = useState<User>();

	React.useEffect(() => {
		if (owner) return;
		if (collection && !!collection.createdBy) {
			if (typeof collection.createdBy === 'object')
				setOwner(collection.createdBy as User);
			if (typeof collection.createdBy === 'string') {
				const owner = users?.find(
					(m) => m._id === (collection?.createdBy as string)
				);
				setOwner(owner);
			}
		}
	}, [collection, users, owner]);

	const modalStack = useModalCreator();
	const [isLoading] = useState(false);
	// callbacks
	const deleteCollection = useCallback(
		async (collection: AssetCollection) => {
			try {
				await collectionStore.deleteOne(collection._id);
				info(`${assetCollectionTerm} deleted!`);
				return navigate(`/admin/dam/collections`);
			} catch (e) {
				_logError(e);
				error('Failed to delete collection. Please try again later.');
			}
		},
		[collectionStore, error, info]
	);

	const showAddAssetsModal = useCallback(
		(collection: AssetCollection) => {
			modalStack.addModal(<AddAssetsDialog selectedCollection={collection} />);
		},
		[modalStack]
	);

	const showDeleteCollectionModal = useCallback(
		(collection: AssetCollection) => {
			modalStack.addModal(
				<ConfirmationDialog
					header={`Deleting "${collection.title}"`}
					onConfirm={() => deleteCollection(collection)}
				>
					<p>
						Are you sure you want to delete this{' '}
						{assetCollectionTerm.toLowerCase()}? This action cannot be undone.
					</p>
				</ConfirmationDialog>
			);
		},
		[modalStack, deleteCollection]
	);

	// render methods
	const renderActionDropdown = (collection?: AssetCollection) => {
		if (!collection) return null;
		if (!canEditAssetCollection(collection, currentUser)) {
			return null;
		}

		return (
			<CollectionActionDropdown
				collection={collection as AssetCollection}
				collectionItems={assets}
				collectionTerm={assetCollectionTerm.toLowerCase()}
				editLink={`/admin/dam/collections/${collection?._id}/edit`}
				showDeleteModal={showDeleteCollectionModal}
				extraClasses="d-inline ml-2"
			/>
		);
	};

	const modalCreator = useModalCreator();
	const showShareModal = useCallback(
		(collection?: AssetCollection) => {
			if (collection?.assets.length === 1) {
				// share single asset
				assetHelper
					.findOne(collection.assets[0] as string)
					.then((assetVersion: AssetVersion) =>
						modalCreator.addModal(
							<ShareSingleAssetDialog asset={assetVersion} />
						)
					);
			} else {
				modalCreator.addModal(<ShareMultipleAssetsDialog assets={assets} />);
			}
		},
		//eslint-disable-next-line
		[modalCreator, assetStore]
	);

	const toolbarCallback = (event: AssetSelectionToolbarClickEvent) => {
		if (event.tool === AssetSelectionTool.SELECTION) {
			const isSelecting: boolean = event.value;
			setSelectingAssets(isSelecting);
			if (!isSelecting) {
				// empty selected assets if selecting is turned off
				setSelectedAssets([]);
			}
		}
	};

	return (
		<Container>
			<RenderWhen when={!!fetching}>
				<Loading label={'Loading asset collection..'} />
			</RenderWhen>
			<RenderWhen when={!!isLoading}>
				<Loading label={'Generating Sharable Link'} />
			</RenderWhen>
			<RenderWhen when={!isLoading}>
				<Row>
					<Col
						xs={12}
						className="d-flex justify-content-between align-items-center"
					>
						<div>
							<BackLink link=".." title={`${assetCollectionTerm}s`} />
							<Heading>{collection?.title}</Heading>
						</div>
						<ButtonGroup>
							<ShareAssetButton
								className="pr-2"
								onClick={() => showShareModal(collection)}
							>
								<FontAwesomeIcon className="mr-2" icon={faShare} />
								Share Collection
							</ShareAssetButton>
							<DownloaderButton
								url="assets/zip"
								data={assets
									?.filter((a) => !!a && !!a._id)
									.map(({ _id }) => _id)}
							>
								<FontAwesomeIcon className="mr-2" icon={faDownload} />
								Download zip
							</DownloaderButton>

							{renderActionDropdown(collection)}
						</ButtonGroup>
					</Col>

					<Col xs={12}>
						<Divider />
					</Col>

					<Col md={3}>
						<EntityPropList className="mt-4">
							<EntityPropListItem>
								<EntityPropLabel>Created on</EntityPropLabel>
								<p>
									<Moment format="MMM DD, YYYY" date={collection?.createdAt} />
								</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>Created by</EntityPropLabel>
								<p>{owner?.givenName + ' ' + owner?.familyName || 'N/A'}</p>
							</EntityPropListItem>
							<EntityMetadataFields
								metadata={collection?.metadata as EntityMetadata}
							/>
						</EntityPropList>
					</Col>

					<Col md={9}>
						<Row>
							<AssetSelectionToolbar
								dispatchCurrentSort={(sort) => {
									setSortDirection(sort.type);
									setSortProperty(sort.payload);
								}}
								selectedAssets={selectedAssets}
								onClick={toolbarCallback}
								tools={tools}
							/>
						</Row>
						<Row className="mt-4">
							{sortProperty && sortDirection ? (
								<AssetCardGrid
									onDelete={(asset) =>
										setAssets(assets.filter((a) => a._id !== asset._id))
									}
									assets={
										orderBy(
											assets.filter((a) => !!a && !!a?._id),
											(a) => a[sortProperty as keyof AssetVersion],
											sortDirection as 'asc' | 'desc'
										) as AlgoliaAssetVersion[]
									}
									areLinks={!selectingAssets}
									isSelectionAvailable={true}
									onAssetSelected={assetSelectionCallBack}
									selectedAssets={selectedAssets as AlgoliaAssetVersion[]}
								/>
							) : (
								<AssetCardGrid
									onDelete={(asset) =>
										setAssets(assets.filter((a) => a._id !== asset._id))
									}
									assets={
										assets.filter(
											(a) => !!a && !!a?._id
										) as AlgoliaAssetVersion[]
									}
									areLinks={!selectingAssets}
									isSelectionAvailable={true}
									onAssetSelected={assetSelectionCallBack}
									selectedAssets={selectedAssets as AlgoliaAssetVersion[]}
								/>
							)}
							<RequiresFeatureFlag
								flag="addAssetCollectionButton"
								defaultValue={true}
							>
								<Col
									lg={4}
									sm={6}
									className="mt-md-4 mt-5 d-flex justify-content-center align-items-center"
								>
									<CircleButton
										className="sm"
										icon={faPlus}
										id="addAssetCollectionButton"
										onClick={() =>
											showAddAssetsModal(collection as AssetCollection)
										}
										tooltip={`Add ${assetTerm}`}
									/>
								</Col>
							</RequiresFeatureFlag>
						</Row>
					</Col>
				</Row>
			</RenderWhen>
		</Container>
	);
};

export default AssetCollectionDetailsView;
