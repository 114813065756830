import React, { FormEvent } from 'react';
import { ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';

import { useModalCloser } from '../../stores/ModalStack';
import DialogModal from '../../components/modals/dialog-modal.component';
import { ModalTitle } from '../../components/modals/dialog-modal.styled-components';
import { SubmitButton } from '../../components/forms';
import { AssetVersion } from '../../workflows/types/workflow.types';
interface DeleteAssetDialogProps {
	asset: AssetVersion;
	onDelete: (event: FormEvent) => void;
}
const DeleteAssetDialog = (props: DeleteAssetDialogProps) => {
	const modalCloser = useModalCloser();
	return (
		<DialogModal header={`Delete ${props.asset.fileName}`}>
			<ModalBody>
				<ModalTitle>
					Are you sure you want to delete this asset? <br />
					It cannot be undone.
				</ModalTitle>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<SubmitButton label="Delete" onClick={props.onDelete}>
						Delete
					</SubmitButton>
					<SubmitButton
						size={'sm'}
						className="ml-2"
						label="Cancel"
						onClick={modalCloser.closeModal}
					>
						Cancel
					</SubmitButton>
				</ButtonGroup>
			</ModalFooter>
		</DialogModal>
	);
};

export default DeleteAssetDialog;
