import React from 'react';
import { _logError } from '../../../common/log';
import { NotificationsContext } from '../../../notifications';
import StageDialog from './stage-dialog.component';
import { useWorkflowContext } from '../../models/useWorkflowStore';
import { StatusEnum } from '../../types/workflowStatus.types';
import { Stage, Workflow } from '../../types/workflow.types';

interface Props {
	stage: Stage;
	statusChange: StatusEnum;
}

const ChangeStageStatusDialog = ({ statusChange, stage }: Props) => {
	const { updateStatus, workflow } = useWorkflowContext();
	const { info, error } = React.useContext(NotificationsContext);
	const onSubmit = async (message: string) => {
		try {
			await updateStatus(statusChange, workflow as Workflow, message, stage);
			info('Updated status successfully.');
		} catch (e) {
			error('Failed to update status, please try again later');
			_logError(e);
			error(
				'Unable to change the status, an issue occurred while saving. Please try again later.'
			);
		}
	};
	return (
		<StageDialog
			header={`Changing status to ${statusChange}`}
			context="Please provide some context about the status change."
			successMessage="Status updated!"
			onClick={onSubmit}
		/>
	);
};

export default ChangeStageStatusDialog;
