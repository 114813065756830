import { orderBy } from 'lodash';
import React, { FormEvent } from 'react';
import { Col, Form, FormFeedback, Row } from 'reactstrap';
import { useAuthContext } from '../../auth';
import { doesNameableMatch } from '../../common';
import DownshiftSingleSelect from '../../components/downshift-select/downshift-single-select.component';
import { SelectionActions } from '../../components/downshift-select/downshift.interfaces';
import { LabeledInput, SubmitButton } from '../../components/forms';
import { buildEmptyAssetMetadata } from '../../dam-assets/components/helpers/useAssetHelper';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import themeStore from '../../theme/models/ThemeStore';
import {
	BaseWorkflowOwner,
	EntityMetadata,
	EntityMetadataTemplate,
	Workflow,
	WorkflowTemplate,
} from '../types/workflow.types';

const workflowTerm = themeStore._.workflow;

interface EditWorkflowFormProps {
	workflow: Workflow;
	onSubmit: (event: FormEvent & { data: Workflow }) => Promise<unknown>;
	templateUsed: string;
	setWf: React.Dispatch<{ type: 'set' | 'update'; payload: any[] | Workflow }>;
	templates?: WorkflowTemplate[];
	onChange: (workflow: Workflow) => void;
}

function templateReducer(
	state: { templates: WorkflowTemplate[] },
	action: { type: 'set'; payload: WorkflowTemplate[] }
) {
	switch (action.type) {
		case 'set':
			return { ...state, templates: action.payload };
		default:
			return state;
	}
}

const EditWorkflowForm = (props: EditWorkflowFormProps) => {
	const { workflow, templates, onSubmit, setWf, onChange } = props;
	const { currentUser } = useAuthContext();

	const [editedWorkflow, setEditedWorkflow] = React.useState<Workflow>({
		...workflow,
		metadata:
			(workflow?.metadata as EntityMetadata) ??
			(buildEmptyAssetMetadata as EntityMetadata),
		owners: [
			{
				type: 'AccountUser',
				_id: currentUser?._id as string,
			} as BaseWorkflowOwner,
		],
	});

	const [editedMetadata, setEditedMetadata] = React.useState(
		editedWorkflow?.metadata ?? buildEmptyAssetMetadata
	);
	const [usedTemplate, setUsedTemplate] = React.useState<WorkflowTemplate>();
	const [state, dispatch] = React.useReducer(templateReducer, {
		templates: templates as WorkflowTemplate[],
	});

	React.useEffect(() => {
		if (!state.templates && !!templates) {
			dispatch({ type: 'set', payload: templates });
		}
	}, [state.templates, templates]);

	const patchTemplateUsed = (template?: WorkflowTemplate) => {
		if (template) {
			setUsedTemplate(state.templates.find((m) => m._id === template._id));
			window.location.hash = `${template._id}`;
			const editedWf = {
				...editedWorkflow,
				stages: template.stages,
				phases: template.phases,
				owners: [...editedWorkflow.owners, ...template.owners],
				metadata: template?.metadata
					? {
							fields: template?.metadata?.fields,
							values: template?.metadata?.values
								? Object.keys(template?.metadata?.values).map((key) => ({
										[key]: '',
								  }))
								: {},
							fieldTypes: template.metadata.fieldTypes,
							fieldOptions: template.metadata.fieldOptions || {},
							tags: template.metadata.tags,
					  }
					: buildEmptyAssetMetadata,
				templateUsed: template._id,
			};
			setEditedMetadata(template?.metadata);
			setEditedWorkflow(editedWf);
			onChange(editedWf);
		} else {
			// todo there is probably a cleaner way to achieve this.
			onChange({
				...editedWorkflow,
				metadata: buildEmptyAssetMetadata,
				templateUsed: '' as string,
			} as Workflow);
		}
		return {
			...editedMetadata,
		};
	};

	const valid = React.useMemo(() => {
		return (!(editedWorkflow?.templateUsed as WorkflowTemplate)
			?._id).toString();
	}, [editedWorkflow]);

	const patchMetaTemplate = (template?: EntityMetadataTemplate) => {
		const updated = {
			...editedWorkflow,
			metadata: {
				...template,
				values: template?.values
					? Object.keys(template?.values).map((key) => ({ [key]: '' }))
					: {},
			},
			metadataTemplateUsed: template?._id,
		} as Workflow;
		setEditedMetadata({ ...template });
		setEditedWorkflow(updated);
		onChange(updated);
		return editedMetadata;
	};

	return (
		<Form>
			<Row form>
				<Col md={12}>
					<LabeledInput
						label="Title*"
						type="text"
						name="title"
						id="title"
						value={editedWorkflow.title}
						onChange={(e) => {
							setEditedWorkflow({ ...editedWorkflow, title: e.target.value });
							setWf({
								type: 'update',
								payload: { ...editedWorkflow, title: e.target.value },
							});
						}}
						required
						inputValid={editedWorkflow.title !== ''}
						errorMsg="Title is required"
					/>
				</Col>
				<Col md={12}>
					<DownshiftSingleSelect
						label={`${workflowTerm} Template*`}
						placeholder="Search by name..."
						selectionState={{
							selection: !usedTemplate
								? undefined
								: (state?.templates?.find(
										(m) => m._id === usedTemplate?._id
								  ) as Displayable),
							options:
								state.templates && state.templates.length
									? orderBy(
											[
												...state.templates?.filter(
													(template) => template.editingState === 'final'
												),
											] as Array<Titled & Identifiable>,
											(i) => i.title,
											'asc'
									  )
									: Array<Titled & Identifiable>(),
							searchPredicate: doesNameableMatch,
						}}
						selectionActions={
							{
								select: patchTemplateUsed,
								unselect: () => {
									setEditedMetadata(buildEmptyAssetMetadata);
									setUsedTemplate(undefined);
									setWf({
										type: 'set',
										payload: {
											...editedWorkflow,
											metadata: buildEmptyAssetMetadata,
										},
									});
									setEditedWorkflow({
										...editedWorkflow,
										metadata: buildEmptyAssetMetadata,
									});
								},
							} as SelectionActions<Displayable, Titled & Identifiable>
						}
					/>
					{}
					<FormFeedback
						invalid={valid}
						style={{ display: editedWorkflow.templateUsed ? 'none' : 'block' }}
					>
						{workflowTerm} template is required
					</FormFeedback>
				</Col>
			</Row>
			<Row className="py-2">
				<Col>
					<EntityMetadataForm
						update={(metadata) => {
							setEditedMetadata({ ...metadata.payload });
							setWf({
								type: 'update',
								payload: {
									...workflow,
									metadata: { ...metadata.payload },
									templateUsed: usedTemplate as WorkflowTemplate,
								} as Workflow,
							});
						}}
						onChange={patchMetaTemplate}
						metadata={editedWorkflow.metadata as EntityMetadata}
					/>
				</Col>
			</Row>
			<SubmitButton
				onClick={(event: FormEvent) =>
					onSubmit({
						...event,
						data: { ...editedWorkflow, metadata: editedMetadata } as Workflow,
					})
				}
				label={`Save ${workflowTerm.toLowerCase()}`}
				disabled={!editedWorkflow.title || !editedWorkflow.templateUsed}
			/>
		</Form>
	);
};

export default EditWorkflowForm;
