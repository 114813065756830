import { orderBy } from 'lodash';
import * as R from 'ramda';
import { Workflow } from 'workflows/types';
import { getDueDate } from './workflowDate.helpers';

export const alphabetizeBy = (property: string, workflows: any[]) =>
	orderBy(workflows, (a) => a[property], 'asc');
export const reverseAlphabetizeBy = (property: string) => <T>(list: T[]) =>
	R.reverse(alphabetizeBy(property, list));

export const sortByDueDateAsc = (a: Workflow, b: Workflow) =>
	new Date(getDueDate(a)).getTime() - new Date(getDueDate(b)).getTime();
export const sortByDueDateDesc = (a: Workflow, b: Workflow) =>
	new Date(getDueDate(b)).getTime() - new Date(getDueDate(a)).getTime();
