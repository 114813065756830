import { navigate } from '@reach/router';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BackLink from '../components/back-link/back-link.component';
import { Heading } from '../components/ui';
import { NotificationsContext } from '../notifications';
import { EntityMetadataTemplate } from '../workflows/types/workflow.types';
import EntityMetadataTemplateEditForm from './components/entity-metadata-template-edit-form.component';
import { useMetadataContext } from 'workflows/hooks/useMetadataContext';

interface MetadataTemplateDetailViewProps {
	metadataTemplateId: string;
}
function metadataTemplateDetailViewReducer(
	state: { metadataTemplate: EntityMetadataTemplate | null },
	action: { type: 'set'; payload: EntityMetadataTemplate }
) {
	switch (action.type) {
		case 'set':
			return { ...state, metadataTemplate: action.payload };
		default:
			return state;
	}
}
const MetadataTemplateDetailsView = (
	props: MetadataTemplateDetailViewProps
) => {
	const { info } = React.useContext(NotificationsContext);
	const { templates, updateTemplate } = useMetadataContext();
	const [state, dispatch] = React.useReducer(
		metadataTemplateDetailViewReducer,
		{ metadataTemplate: null }
	);
	React.useEffect(() => {
		if (!state.metadataTemplate && templates) {
			const template = templates.find(
				(m) => m._id === props.metadataTemplateId
			);
			if (template) {
				dispatch({ type: 'set', payload: template });
			}
		}
	}, [templates, state.metadataTemplate, props.metadataTemplateId]);

	const render = () => {
		if (!state.metadataTemplate) {
			return null;
		}

		const updateMetadataTemplate = async (event: EntityMetadataTemplate) => {
			await updateTemplate(state?.metadataTemplate?._id as string, event);

			info(`${event.title} saved!`);

			return navigate(``);
		};

		return (
			<Container>
				<Row className="justify-content-center">
					<Col lg={8}>
						<BackLink link=".." title="Metadata Templates" />
						<div className="d-flex justify-content-between">
							<Heading>{state.metadataTemplate.title}</Heading>
						</div>
					</Col>
					<Col lg={8}>
						<EntityMetadataTemplateEditForm
							selectedMetadataTemplate={state.metadataTemplate}
							onSubmit={(event) => updateMetadataTemplate(event)}
							displayTemplateField={false}
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	return render();
};

export default MetadataTemplateDetailsView;
