import React, { PropsWithChildren, useCallback } from 'react';
import { _logError } from '../common/log';
import { useAssetHelper } from '../dam-assets/components/helpers/useAssetHelper';
import { SecondaryButton } from './buttons.styled-components';

const DownloaderButton = ({
	data = {},
	children,
}: PropsWithChildren<{
	url: string;
	data?: object;
}>) => {
	const assetStore = useAssetHelper();

	const triggerDownload = useCallback(() => {
		assetStore
			.zipMultiple(data)
			.then((response: { url: string }) => window.open(response.url, '_blank'))
			.catch(_logError);
	}, [assetStore, data]);

	return (
		<form className="d-inline-block" method="POST" target="_blank">
			<input type="hidden" name="data" value={JSON.stringify(data)} />
			<SecondaryButton type="button" onClick={triggerDownload}>
				{children}
			</SecondaryButton>
		</form>
	);
};

export default DownloaderButton;
