import OwnerAvatarList from 'components/owner-avatar-list.component';
import { uniqBy } from 'lodash';
import React from 'react';
import { Card } from 'reactstrap';
import { flattenStages, listActiveStageNames } from 'workflows/helpers';
import { useWorkflowCollectionContext } from 'workflows/hooks/useWorkflowCollectionStore';
import { useAuthContext, useGroupContext } from '../../auth';
import {
	ListColumn,
	makeColumns,
} from '../../components/list-column.component';
import ListTable from '../../components/list-table.component';
import { CellLink } from '../../components/tables.styled-components';
import WorkflowStatusPill from '../../components/workflow-status-pill.component';
import WorkflowHealthFlag from '../../dashboard/components/workflow-health-flag.component';
import themeStore from '../../theme/models/ThemeStore';
import { getWorkflowBoolean } from '../helpers/workflowBoolean.helpers';
import { Workflow, WorkflowTemplate } from '../types/workflow.types';
import { WorkflowDueDate } from './WorkflowDueDate';

export const WorkflowListTable = (props: { workflows?: Workflow[] }) => {
	const { workflows } = props;
	const { entities: collections } = useWorkflowCollectionContext();
	const getOwnersFromWorkflow = (w: Workflow) => {
		const flat = flattenStages(w, true);
		return uniqBy(
			flat.flatMap((stage) => stage.owners),
			(a) => a._id
		);
	};

	const workflowListColumns: ListColumn<Workflow>[] = makeColumns([
		{
			label: 'Name',
			prop: (w: Workflow) => (
				<React.Fragment>
					<CellLink to={`/admin/workflow/workflows/${w._id}/stages`}>
						{w.title}
					</CellLink>
				</React.Fragment>
			),
		},
		{
			label: `In ${themeStore._.workflowCollection}`,
			prop: (w: Workflow) => {
				return !!w.workflowCollection ? (
					<p>
						{collections &&
							collections.find(
								(collection) =>
									collection._id === (w.workflowCollection as string)
							)?.title}
					</p>
				) : (
					<p>—</p>
				);
			},
		},
		{
			label: 'Template Used',
			prop: (w: Workflow) => (
				<p>{(w.templateUsed as WorkflowTemplate)?.title ?? '—'}</p>
			),
		},
		{
			label: 'Stakeholder(s)',
			prop: (w: Workflow) => (
				<OwnerAvatarList owners={getOwnersFromWorkflow(w)} />
			),
		},
		{
			label: `Active ${themeStore._.stage.toLowerCase()}(s)`,
			prop: (w: Workflow) => <p>{listActiveStageNames(w)}</p>,
		},
		{
			label: 'Due date',
			prop: (w: Workflow) => <WorkflowDueDate workflow={w} />,
		},
		{
			label: 'Status',
			prop: (w: Workflow) => (
				<WorkflowStatusPill className={``} status={w.status} />
			),
		},
		{
			label: 'Health',
			prop: (w: Workflow) =>
				w.status === 'active' ? <WorkflowHealthFlag workflow={w} /> : <p>—</p>,
		},
	]);

	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	const canView = (workflow: Workflow): boolean =>
		workflow?.followers?.some(
			(a) =>
				a._id === currentUser?._id ||
				groupsForCurrentUser.some((grp) => grp._id === a._id)
		) ||
		getWorkflowBoolean(
			'isCurrentUserInvolved',
			workflow,
			groupsForCurrentUser,
			currentUser,
			workflow.templateUsed as WorkflowTemplate
		) ||
		getWorkflowBoolean(
			'isCurrentUserFollower',
			workflow,
			groupsForCurrentUser,
			currentUser,
			workflow.templateUsed as WorkflowTemplate
		) ||
		getWorkflowBoolean(
			'isOwnedByCurrentUser',
			workflow,
			groupsForCurrentUser,
			currentUser,
			workflow.templateUsed as WorkflowTemplate
		);

	return (
		<Card className="mt-3">
			<ListTable
				columns={workflowListColumns}
				rows={workflows?.filter(canView) || []}
				noResultsLabel={`No ${themeStore._.workflow.toLowerCase()}s yet`}
			/>
		</Card>
	);
};

export default WorkflowListTable;
