import {
	faColumns,
	faEquals,
	faGreaterThan,
	faLessThan,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'admin-dashboard/components/theme/useThemeContext';
import { Heading } from 'components';
import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import { startCase } from 'lodash';
import React, { useState } from 'react';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';

type FilterItem = {
	key: string;
	value: string;
	metadata?: {
		start: string;
		end: string;
		connector: 'and' | 'or';
	};
	type: 'equals' | 'contains' | 'greater-than' | 'less-than' | 'between';
};

type AddColumnProps = {
	reportKey: string;
	onUpdate: (filter: FilterItem) => void;
	onCancel: () => void;
	type: 'equals' | 'contains' | 'greater-than' | 'less-than' | 'between';
	options: string[];
};

export const AddFilterDialog = (props: AddColumnProps) => {
	const { reportKey, type, options, onUpdate, onCancel } = props;
	const [filter, setFilter] = useState<FilterItem>({
		key: reportKey,
		value: '',
		metadata: {
			start: '',
			end: '',
			connector: 'or',
		},
		type: props.type,
	});
	const { defaults } = useThemeContext();
	const onConfirm = () => {
		onUpdate(filter);
	};

	return (
		<ConfirmationDialog
			header={`Add ${startCase(reportKey)} "${type}" filter to report`}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={'Add column'}
		>
			<Row>
				<Col xl={12}>
					<Heading className="mx-2">{startCase(reportKey)}</Heading>
					<Row>
						<Col xl={12}>
							<div className="d-flex justify-content-between">
								{type === 'contains' && (
									<p>
										<FormText className="d-block">
											CONTAINS{' '}
											<FontAwesomeIcon
												style={{ color: defaults?.primary, fontSize: 28 }}
												icon={faColumns}
											/>
										</FormText>
									</p>
								)}
								{type === 'equals' && (
									<p>
										<FormText className="d-block">
											EQUALS{' '}
											<FontAwesomeIcon
												style={{ color: defaults?.primary, fontSize: 28 }}
												icon={faEquals}
											/>
										</FormText>
									</p>
								)}
								{type === 'greater-than' && (
									<p>
										<FormText className="d-block">
											GREATER THAN{' '}
											<FontAwesomeIcon
												style={{ color: defaults?.primary, fontSize: 28 }}
												icon={faGreaterThan}
											/>
										</FormText>
									</p>
								)}
								{type === 'less-than' && (
									<p>
										<FormText className="d-block">
											LESS THAN{' '}
											<FontAwesomeIcon
												style={{ color: defaults?.primary, fontSize: 28 }}
												icon={faLessThan}
											/>
										</FormText>
									</p>
								)}

								{type === 'between' && (
									<FormGroup>
										<Label>Begin Date</Label>
										<Input
											type={'date'}
											placeholder={`${reportKey} must be after this date`}
											onChange={(e) =>
												setFilter({
													...filter,
													value: 'metadata',
													metadata: {
														...(filter.metadata || {
															end: '',
															connector: 'or',
														}),
														start: e.target.value,
													},
												})
											}
										/>

										<Label>End Date</Label>
										<Input
											type={'date'}
											placeholder={`${reportKey} must be before this date`}
											onChange={(e) =>
												setFilter({
													...filter,
													value: 'metadata',
													metadata: {
														...(filter.metadata || {
															start: '',
															connector: 'or',
														}),
														end: e.target.value,
													},
												})
											}
										/>
									</FormGroup>
								)}

								{options.length > 0 && type === 'equals' && (
									<select
										onChange={(e) =>
											setFilter({ ...filter, value: e.target.value })
										}
										className="form-control"
									>
										<option>Please select...</option>
										{options.map((option, i) => (
											<option key={i} value={option}>
												{option}
											</option>
										))}
									</select>
								)}

								{(options.length === 0 || type === 'contains') &&
									['greater-than', 'less-than'].some((a) => a !== type) && (
										<Input
											type={'text'}
											onChange={(e) =>
												setFilter({ ...filter, value: e.target.value })
											}
										/>
									)}

								{['greater-than', 'less-than'].some((a) => a === type) && (
									<Input
										type={'date'}
										placeholder={`${reportKey} must be less than this date`}
										onChange={(e) =>
											setFilter({ ...filter, value: e.target.value })
										}
									/>
								)}
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</ConfirmationDialog>
	);
};
