import { Link } from '@reach/router';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';
import storageModel from 'models/StorageModel';
import { NavItem } from 'reactstrap';
import styled, { css } from 'styled-components';
import { secondaryButton } from '../../../theme/components/secondary-button';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
const link = css`
	color: ${defaults?.secondary};
	text-decoration: none;

	&:hover {
		color: ${defaults?.secondaryHighlighted};
		text-decoration: none;
	}
`;

export const TabNavItem = styled(NavItem)`
	width: 50%;
`;

export const StyledLink = styled(Link)`
	${link};
`;

export const StyledSummary = styled.summary`
	${link};
`;

export const FullWidthLink = styled(Link)`
	${secondaryButton};
	width: 100%;
`;

export const FullWidthButton = styled.button`
	${secondaryButton};
	width: 100%;
`;

export const FullWidthLinkButton = styled(Link)`
	${secondaryButton};
	width: 100%;
`;
