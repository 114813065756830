import { HelpBlock } from 'admin-dashboard/components/admin.styles';
import usePushNotifications from 'admin-dashboard/components/notifications/usePushNotification';
import storageModel from 'models/StorageModel';
import { RoleContext } from 'permissions/PermissionsContext';
import { default as React, useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	Col,
	Container,
	Form,
	FormGroup,
	Row,
} from 'reactstrap';
import { UserAssetViewType } from '../accounts/models/UserStatus.model';
import { UserAvatar } from '../admin-dashboard/components/user-details.styled-components';
import { useAuthContext, User } from '../auth';
import { camelCaseToSentenceCase } from '../common';
import { _logError } from '../common/log';
import { SubmitButton } from '../components/forms';
import { Heading, Subheading } from '../components/ui';
import { useAxios } from '../hooks';
import { NotificationsContext } from '../notifications';
import { useModalCloser, useModalCreator } from '../stores/ModalStack';
import {
	AssetViewPreferences,
	EditPreferencesToggleSwitch,
	ProxyModal,
	RomeDeveloperPreferences,
	UpdateOnNewFunctionalityFormGroup,
} from './EditPreferences/index';

const EditPreferences = () => {
	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();
	const { currentUser, loggedInUser, refreshCurrentUser } = useAuthContext();
	const permissions = React.useContext(RoleContext);

	useEffect(() => {
		window.addEventListener('NOTIFY', (evt) => {
			console.log(evt);
		});
		return () => {
			window.removeEventListener('NOTIFY', (evt) => {
				console.log(evt);
			});
		};
	}, []);
	const userStore = useAxios<User>('users');
	const preferenceStore = {
		preferences: (JSON.parse(
			localStorage.getItem('UserPreferences') as string
		) ?? { recievePushNotifications: false, viewPreference: 'searchView' }) as {
			recievePushNotifications: boolean;
			viewPreference: string;
		},
		setAssetViewPreference: (preference: string) => {
			const updated = {
				...preferenceStore.preferences,
				viewPreference: preference,
			};
			storageModel.set('UserPreferences', JSON.stringify(updated));
		},
		setRecievePushNotifications: (newValue: boolean) => {
			const updated = {
				...preferenceStore.preferences,
				recievePushNotifications: newValue,
			};
			storageModel.set('UserPreferences', JSON.stringify(updated));
		},
	};
	const { info } = React.useContext(NotificationsContext);

	const editingInstance = React.useMemo(() => currentUser, [currentUser]);
	const [edited, setEdited] = React.useState(editingInstance);
	React.useEffect(() => {
		if (currentUser && currentUser._id) {
			setEdited(currentUser);
		}
	}, [currentUser]);

	const {
		viewPreference,
		recievePushNotifications,
	} = preferenceStore.preferences;

	// prettier-ignore
	const [enabled, setEnabled] = useState(viewPreference === UserAssetViewType.searchView);
	// prettier-ignore
	const [, setPushEnabled] = useState(recievePushNotifications === true);

	const onSubmit = async (updatedSnapshot: any) => {
		try {
			const updatedUser = (await userStore.updateOne(
				edited._id,
				edited
			)) as User;
			refreshCurrentUser(updatedUser);
			info('Preferences saved!');
		} catch (error) {
			_logError(error);

			error(
				`An issue occurred while updating your preferences. Please try again later.`
			);
		}
	};

	const showProxyModal = () => {
		modalStack.addModal(<ProxyModal afterSubmit={modalCloser.closeModal} />);
	};

	const renderProxyAssignment = () => {
		if (!permissions.canViewByRole('canViewProxy')) return null;
		return <SubmitButton onClick={() => showProxyModal()} label={'Proxy'} />;
	};

	const { userConsent, pushServerSubscriptionId } = usePushNotifications();
	const isConsentGranted = userConsent === 'granted';

	return (
		<Container>
			<Row>
				<Col md={2}>
					<Subheading>Edit</Subheading>
					<Heading>My preferences</Heading>
				</Col>
				<Col md={10}>
					<Form>
						<Card>
							<CardBody>
								<Row>
									<Col
										lg={3}
										className="d-flex justify-content-center align-items-center flex-column"
									>
										<UserAvatar user={currentUser} />
										<h2>{currentUser.name}</h2>
										<p>{camelCaseToSentenceCase(currentUser.role)}</p>
									</Col>
									<Col lg={9} className="p-4">
										<Row>
											{edited && edited._id && (
												<Col lg={12}>
													<div className="d-inline">
														<AssetViewPreferences
															setEnabled={setEnabled}
															preferenceStore={preferenceStore}
															viewPreference={viewPreference}
															preferencesEnabled={enabled}
														/>
														<FormGroup style={{ fontSize: 14 }}>
															<EditPreferencesToggleSwitch
																preferencesEnabled={true}
																enabled={edited.persistSearchFilters}
																onStateChanged={(e: boolean) => {
																	setEdited({
																		...edited,
																		persistSearchFilters: e,
																	});
																}}
																label="Persist DAM Search Filters"
															/>
														</FormGroup>
													</div>
													<FormGroup style={{ fontSize: 14 }}>
														<HelpBlock style={{ fontSize: 16 }}>
															Email Notification Preferences
														</HelpBlock>

														<EditPreferencesToggleSwitch
															preferencesEnabled={true}
															enabled={edited.notifyWhenAssignedStakeHolder}
															onStateChanged={(e: boolean) => {
																setEdited({
																	...edited,
																	notifyWhenAssignedStakeHolder: e,
																});
															}}
															label="I would like to receive an email notification when I am assigned as a stakeholder to a stage"
														/>

														<EditPreferencesToggleSwitch
															preferencesEnabled={true}
															enabled={
																edited.notifyWhenAssignmentRequiresAttention
															}
															onStateChanged={(e: boolean) => {
																setEdited({
																	...edited,
																	notifyWhenAssignmentRequiresAttention: e,
																});
															}}
															label="I would like to receive an email notification when an assignment requires my attention"
														/>

														<EditPreferencesToggleSwitch
															preferencesEnabled={true}
															enabled={edited.notifyWhenWorkflowRoadblocked}
															onStateChanged={(e: boolean) => {
																setEdited({
																	...edited,
																	notifyWhenWorkflowRoadblocked: e,
																});
															}}
															label="I would like to receive an email notification when a workflow that I am the owner of is issued a roadblock"
														/>

														<EditPreferencesToggleSwitch
															preferencesEnabled={true}
															enabled={
																edited.notifyWhenWorkflowRoadblockResolved
															}
															onStateChanged={(e: boolean) => {
																setEdited({
																	...edited,
																	notifyWhenWorkflowRoadblockResolved: e,
																});
															}}
															label="I would like to receive an email notification when a workflow that I am the owner of has a roadblock resolved"
														/>

														<EditPreferencesToggleSwitch
															preferencesEnabled={true}
															enabled={edited.notifyWhenWorkflowOverdue}
															onStateChanged={(e: boolean) => {
																setEdited({
																	...edited,
																	notifyWhenWorkflowOverdue: e,
																});
															}}
															label="I would like to receive an email notification when a workflow assignment assigned to me is overdue "
														/>
													</FormGroup>

													<UpdateOnNewFunctionalityFormGroup
														preferencesEnabled={enabled}
														preferenceStore={preferenceStore}
														setPushEnabled={setPushEnabled}
														pushEnabled={
															isConsentGranted && !!pushServerSubscriptionId
														}
													/>
												</Col>
											)}
										</Row>

										{['SuperAdmin', 'RomeDevelopers'].some(
											(a) => loggedInUser.role === a
										) && (
											<Row>
												<Col lg={12}>
													<RomeDeveloperPreferences
														preferencesEnabled={enabled}
														user={loggedInUser}
													/>
												</Col>
											</Row>
										)}
										{renderProxyAssignment()}
										<SubmitButton
											onClick={() => onSubmit(edited)}
											label={'Update Preferences'}
											className="float-right"
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default EditPreferences;
