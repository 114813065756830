import styled, { css } from 'styled-components';
import { Input } from './Input';

import themeStore from '../../../theme/models/ThemeStore';

import { boxShadowFocus } from '../../../theme/ui/ui';
import { ffTextBody, fsNormal } from '../../../theme/ui/typography';
import storageModel from 'models/StorageModel';
import { SiteDefaults } from 'admin-dashboard/components/theme/useThemeContext';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const labelStyles = css`
	margin: 0;
	position: relative;
	left: -10px;
	z-index: 1;
	margin-top: -13px;
	padding: 0px 5px;
	margin-left: 7px;
	border-radius: 5px;
`;

export const inputStyles = css`
	${ffTextBody};
	${fsNormal};
	background-color: var(--white);
	border-radius: 4px;
	border: 1px solid ${colors.darkGrey} !important;
	line-height: 20px;
	padding: 8px 12px;
	position: relative;
	z-index: 0;
	width: 100%;

	&:focus {
		${boxShadowFocus};
		border: 1px solid ${defaults?.primaryHighlighted} !important;
	}

	&:disabled {
		background-color: ${colors.lightGrey} !important;
		cursor: not-allowed;
	}

	&:read-only {
		cursor: not-allowed;
	}
`;

const baseStyles = css`
	margin-top: 30px;
	margin-bottom: 5px;
	display: flex;
	flex-direction: column;
	position: relative;

	.required-icon {
		color: red;
		margin-right: 3px;
	}

	.error,
	p {
		color: red;
		margin: 0px;
		margin-left: 3px;
		padding: 0px;
		padding-top: 2px;
	}
`;

export const InputField = styled(Input)`
	${baseStyles};

	label {
		${labelStyles};
		margin-bottom: 2px;
	}

	.input {
		${inputStyles};
	}
`;

export const Textarea = styled(Input)`
	${baseStyles};

	label {
		${labelStyles}
	}

	textarea {
		${inputStyles};
		min-height: 200px;
	}
`;
