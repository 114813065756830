import React, { useCallback } from 'react';

import { LabeledSelect } from '../../../components/forms/labeled-select.component';
import { unwrapEvent } from '../../../common/hooks';
import themeStore from '../../../theme/models/ThemeStore';
import { SelectedInputSlot } from '../../workflow.contexts';
import { InputSlot, Stage } from '../../types/workflow.types';

const assetTerm = themeStore._.asset.toLowerCase();

const WorkflowStageInputSlotSelect = (props: { stage: Stage }) => {
	const stage = props.stage;
	const slots = stage.inputSlots;
	const [slot] = SelectedInputSlot.useMaybe();

	const updateInputSlot = useCallback(
		unwrapEvent((slotId: string) => {
			SelectedInputSlot.setter(
				slots?.find((m) => m._id === slotId) as InputSlot
			);
		}),
		[props.stage]
	);

	const slotOptions = slots.map((slot: InputSlot) => (
		<option key={slot._id} value={slot._id}>
			{slot.label}
		</option>
	));

	return (
		<LabeledSelect
			className="mb-4"
			id="slotSelect"
			name="select"
			label={`Which ${assetTerm} are you uploading?`}
			onChange={updateInputSlot}
			defaultValue={slot?._id}
		>
			{slotOptions}
		</LabeledSelect>
	);
};

export default WorkflowStageInputSlotSelect;
