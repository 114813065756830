import { LabeledInput } from '../../components/forms/labeled-input.component';
import React, { ChangeEvent, useCallback } from 'react';
import { SelectedMetadata } from '../asset.contexts';
import { EntityMetadata } from '../../workflows/types/workflow.types';
interface AssetMetadataProps {
	readonly?: boolean;
}
const AssetMetadataForm = (props: AssetMetadataProps) => {
	const [metadata, setMetadata] = SelectedMetadata.useMaybe();

	const updateMetadataValues = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			const map: { [key: string]: string | string[] } = {};
			if (metadata?.values)
				Object.keys(metadata.values).forEach((key, idx) => {
					if (metadata && metadata.values) map[key] = metadata.values[key];
				});

			map[name] = value;
			setMetadata({ ...(metadata as EntityMetadata), values: map });
		},
		[metadata, setMetadata]
	);

	const getValue = (field: string) => {
		if (metadata?.values && metadata?.values[field])
			return metadata.values[field];

		return '';
	};

	const inputs =
		metadata?.fields &&
		metadata?.fields?.map((field: string) => (
			<LabeledInput
				readOnly={props.readonly}
				label={field}
				type="text"
				name={field}
				id={field}
				key={field}
				value={getValue(field) as string}
				onChange={updateMetadataValues}
			/>
		));

	return <>{inputs}</>;
};

export default AssetMetadataForm;
