import styled from 'styled-components';
import { MutedButton } from '../../components/buttons.styled-components';

export const DeleteAssetButton = styled(MutedButton)`
	position: absolute;
	top: 16px;
	right: 22px;
	z-index: 1;
	padding: 6px 12px;
`;

export const DisabledMask = styled.div`
	pointer-events: none !important;
`;

export const DragDropContainer = styled.div`
	height: 100%;

	> div {
		height: 100%;
	}
`;
