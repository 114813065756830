import { Stage } from '../workflows/types/workflow.types';
import { StatusEnum } from '../workflows/types/workflowStatus.types';

export enum StageStatus {
	queue = 'queue',
	active = 'active',
	roadblocked = 'roadblocked',
	completed = 'completed',
}

const workflowStageOrdering: Record<StageStatus, number> = [
	StageStatus.active,
	StageStatus.roadblocked,
	StageStatus.queue,
	StageStatus.completed,
].reduce((acc, status, idx) => {
	acc[status] = idx;
	return acc;
}, {} as Record<StageStatus, number>);

export const compareStageStatus = (a?: Stage, b?: Stage): number => {
	return (
		workflowStageOrdering[a?.status as StageStatus] -
		workflowStageOrdering[b?.status as StageStatus]
	);
};
export const orderStagesByStatus = (stages: Stage[]): Stage[] =>
	stages.sort(compareStageStatus);

export const stageBadgeColorMap: Record<StageStatus, string> = {
	[StageStatus.queue]: 'dark',
	[StageStatus.active]: 'info',
	[StageStatus.roadblocked]: 'danger',
	[StageStatus.completed]: 'success',
};

export function isActionableStatus(status: StageStatus): boolean {
	return status === StageStatus.active || status === StageStatus.roadblocked;
}

type StatusMap = Readonly<Record<string, StatusEnum>>;

export const statusForRoadblockToggle: StatusMap = {
	[StatusEnum.roadblocked]: StatusEnum.active,
	[StatusEnum.active]: StatusEnum.roadblocked,
	[StatusEnum.pipeline]: StatusEnum.pipeline,
	[StatusEnum.paused]: StatusEnum.paused,
	[StatusEnum.cancelled]: StatusEnum.cancelled,
	[StatusEnum.queue]: StatusEnum.queue,
	[StatusEnum.completed]: StatusEnum.completed,
};
