import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { useModalCreator } from '../stores/ModalStack';
import {
	AssetCollection,
	EntityMetadataTemplate,
	WorkflowCollection,
} from '../workflows/types/workflow.types';
import { RemoveButton } from './buttons.styled-components';
import ConfirmationDialog from './modals/confirmation-dialog.component';

interface DeleteEntityButtonProps {
	entity: WorkflowCollection | AssetCollection | EntityMetadataTemplate;
	deleteOne: (
		entity: WorkflowCollection | AssetCollection | EntityMetadataTemplate
	) => void | Promise<void>;
	entityName: string;
}

const DeleteEntityButton = (props: DeleteEntityButtonProps) => {
	const modalStack = useModalCreator();
	const { entity, deleteOne, entityName } = props;

	const deleteEntity = useCallback(
		async (entity) => {
			try {
				await deleteOne(entity);
			} catch {
				deleteOne(entity);
			}
		},
		[deleteOne]
	);

	const showModal = useCallback(
		() =>
			modalStack.addModal(
				<ConfirmationDialog
					header={`Delete "${entity.title}"`}
					onConfirm={() => deleteEntity(entity)}
				>
					<p>
						Are you sure you want to delete this {entityName.toLowerCase()}?
						This action cannot be undone.
					</p>
				</ConfirmationDialog>
			),
		[entity, modalStack, deleteEntity, entityName]
	);

	const render = () => (
		<RemoveButton id={`entityDelete${entity._id}`} onClick={showModal}>
			<UncontrolledTooltip target={`entityDelete${entity._id}`}>
				Delete {entityName}
			</UncontrolledTooltip>
			<FontAwesomeIcon icon={faTimes} />
		</RemoveButton>
	);

	return render();
};

export default DeleteEntityButton;
