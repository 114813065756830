import React, { useCallback, useState } from 'react';
import {
	AssetCollection,
	AssetVersion,
} from '../../workflows/types/workflow.types';
import { useAxios } from 'hooks';
import { Loading } from 'components';
import { DownshiftMulti } from 'components/new-downshift/DownshiftSelect';
import { useHeaders } from 'auth';
import axios from 'axios';
import { uniqBy } from 'lodash';

const CollectionAssetSelect = ({
	collection,
	onChange,
}: {
	collection: AssetCollection;
	onChange: (ac: AssetCollection) => void;
}) => {
	const assetStore = useAxios<AssetVersion>('assets');
	const [searchedAssets, setSearchedAssets] = useState<AssetVersion[]>();
	const [allAssets, setAllAssets] = useState<AssetVersion[]>();
	React.useEffect(() => {
		assetStore.paginate(1, 100, 'title').then(setAllAssets);
		// eslint-disable-next-line
	}, []);
	const [editedCollection, setEditedCollection] = useState(collection.assets);

	const doesNotAlreadyContain = useCallback(
		(asset: AssetVersion) => editedCollection.indexOf(asset._id) === -1,
		[editedCollection]
	);

	const onSelectChange = (
		updatedSelections: Array<{ label: string; value: string }>
	) => {
		setEditedCollection([...updatedSelections.map((o) => o.value)]);
		onChange({
			...collection,
			assets: [...updatedSelections.map((o) => o.value)],
		});
	};
	const [currentPage, setCurrentPage] = useState(1);
	const [maxPages, setMaxPages] = useState<number | undefined>();
	const [isLoading, setIsLoading] = useState(false);
	const handleScroll = (event: any) => {
		if (searchText) return;

		const e = event.nativeEvent;
		if (
			e.target.scrollTop + 10 >=
			e.target.scrollHeight - e.target.clientHeight
		) {
			const newPage = Math.floor(currentPage + 1);

			if (maxPages) {
				if (newPage >= maxPages) return;
			}

			setCurrentPage(newPage);

			assetStore.paginate(newPage, 25, 'title').then((moreData) => {
				if (moreData.length > 0) {
					setAllAssets((allAssets) =>
						uniqBy(allAssets?.concat(moreData), (a) => a._id)
					);
				} else {
					setMaxPages(newPage);
				}
			});
		}
	};

	const { getHeaders } = useHeaders();
	const [searchText, setSearchText] = React.useState('');
	const loadOptForSearch = (input: string) => {
		setSearchText(input);
		if (input) {
			axios
				.get<AssetVersion[]>(
					`${process.env.REACT_APP_ROME_API_ENDPOINT}/assets/search/for/asset/${input}`,
					getHeaders()
				)
				.then((result) => setSearchedAssets(result.data));
		} else {
			setSearchedAssets(undefined);
		}
	};

	const compareLowerCase = (a: any, b: any, key: string) => {
		return a[key]?.toString()?.toLocaleLowerCase() >
			b[key]?.toString()?.toLocaleLowerCase()
			? 1
			: b[key]?.toString()?.toLocaleLowerCase() >
			  a[key]?.toString()?.toLocaleLowerCase()
			? -1
			: 0;
	};

	const handler = (input: string) => loadOptForSearch(input);

	const allAssetsForOptions = React.useMemo(() => {
		const assets =
			searchText && searchedAssets?.length ? searchedAssets : allAssets;
		return assets
			?.filter(doesNotAlreadyContain)
			?.sort((a, b) => compareLowerCase(a, b, 'title'))
			.map((asset) => ({ value: asset._id, label: asset.fileName }));
	}, [allAssets, searchedAssets, searchText]);

	return !allAssetsForOptions ? (
		<Loading
			label="Loading assets.."
			alignItems="center"
			justifyContent="center"
		/>
	) : (
		<div onScroll={handleScroll}>
			<DownshiftMulti
				searchText={searchText}
				loadOptions={handler}
				selected={
					editedCollection?.map((a) => ({
						value: a,
						label: [
							...(searchedAssets ? searchedAssets : []),
							...(allAssets ? allAssets : []),
						]?.find((m) => m._id === a)?.fileName,
					})) as Array<{ value: string; label: string }>
				}
				options={
					isLoading
						? [
								...allAssetsForOptions,
								{ label: 'Loading more assets...', value: '' },
						  ]
						: allAssetsForOptions
				}
				labelText="Assets"
				onChange={onSelectChange}
			/>
		</div>
	);
};

export default CollectionAssetSelect;
