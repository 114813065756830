import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import {
	Col,
	Container,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	ModalBody,
	Row,
} from 'reactstrap';
import CircleButton from '../../components/circle-button.component';
import DialogModal from '../../components/modals/dialog-modal.component';
import { Subheading } from '../../components/ui';
import { EntityMetadata } from '../../workflows/types/workflow.types';
import { CircleButtonContainer } from './entity-metadata-form.styled-components';

interface EntityMetadataFieldTypeDialogProps {
	metadata: EntityMetadata;
	fieldType: string;
	header: string;
	fieldKey: string;
	onChange: (e: EntityMetadata) => void;
}

export interface FieldTypeOptions {
	field: string;
	fieldOptions: string[];
	fieldType: string;
}

export const EntityMetadataFieldTypeDialog = (
	props: EntityMetadataFieldTypeDialogProps
) => {
	const [newFieldValue, setNewFieldValue] = React.useState('');
	const { metadata } = props;
	const [isAlphabatized, setIsAlphabatized] = React.useState(false);
	const [editedMetadata, setEditedMetadata] = React.useState(metadata);
	const handleRemoveField = (field: string) => {
		const map: Record<string, { field: string; options: string[] }> = {};
		if (editedMetadata?.fieldOptions) {
			Object.keys(editedMetadata?.fieldOptions).forEach((key) => {
				if (
					editedMetadata?.fieldOptions &&
					editedMetadata?.fieldOptions[key] &&
					key !== field
				) {
					map[key] = editedMetadata?.fieldOptions[key];
					map[key].options = map[key].options.filter(
						(option) => option !== field
					);
				}
			});
		}

		setEditedMetadata({
			...editedMetadata,
			fieldOptions: map,
		});
		props.onChange({
			...editedMetadata,
			fieldOptions: map,
		});
	};
	const handleRemoveFieldTypeOption = (fieldKey: string) => {
		handleRemoveField(fieldKey);
	};
	const setFieldOptions = (fieldOptions: {
		field: string;
		options: string[];
	}) => {
		const map: Record<string, { field: string; options: string[] }> = {};
		if (editedMetadata?.fieldOptions) {
			Object.keys(editedMetadata?.fieldOptions).forEach((key) => {
				if (editedMetadata?.fieldOptions && editedMetadata?.fieldOptions[key])
					map[key] = editedMetadata?.fieldOptions[key];
			});
		}

		map[fieldOptions.field] = {
			field: props.fieldKey,
			options: fieldOptions.options,
		};
		const updated = {
			...editedMetadata,
			fieldOptions: map,
		};

		setEditedMetadata(updated);
		props.onChange(updated);
	};
	const handleAddFieldTypeOption = (fieldKey: string, value: string) => {
		if (
			editedMetadata?.fieldOptions &&
			!!(editedMetadata?.fieldOptions as Record<
				string,
				{ field: string; options: string[] }
			>)[fieldKey]
		) {
			const options = [
				...editedMetadata.fieldOptions[fieldKey].options.filter(
					(v) => v !== value
				),
				value,
			];
			const newFieldOpts = {
				field: fieldKey,
				options: options,
			};
			setFieldOptions(newFieldOpts);
			let updated = editedMetadata;
			if (!!updated.fieldOptions && !!updated.fieldOptions[fieldKey])
				updated.fieldOptions[fieldKey] = { field: fieldKey, options };
			setEditedMetadata({
				...editedMetadata,
				fieldOptions: updated.fieldOptions,
			});
			props.onChange({ ...editedMetadata, fieldOptions: updated.fieldOptions });
		} else {
			let updated: Record<string, { field: string; options: string[] }> = {};
			updated[fieldKey] = { field: fieldKey, options: [value] };

			setEditedMetadata({
				...editedMetadata,
				fieldOptions: updated as Record<
					string,
					{ field: string; options: string[] }
				>,
			});
			props.onChange({
				...editedMetadata,
				fieldOptions: updated as Record<
					string,
					{ field: string; options: string[] }
				>,
			});
			// updated.forEach(item =>
			// 	isArray(item) ? newArr.push(...item) : newArr.push(item)
			// );
			setFieldOptions({ field: fieldKey, options: [value] });
		}
	};
	const { fieldKey } = props;
	const { onChange } = props;
	const alphabatizeOptions = () => {
		const sortedOptions = orderBy(
			editedMetadata?.fieldOptions
				? editedMetadata?.fieldOptions[fieldKey]?.options
				: [],
			(a) => a,
			'asc'
		);
		const updated = { ...editedMetadata?.fieldOptions } as Record<
			string,
			{ field: string; options: string[] }
		>;
		updated[fieldKey] = {
			field: fieldKey,
			options: sortedOptions,
		};
		setEditedMetadata({ ...editedMetadata, fieldOptions: updated });
		onChange({
			...editedMetadata,
			fieldOptions: updated,
		});
	};

	const [isValid, setIsValid] = useState(true);

	const fields =
		editedMetadata?.fieldOptions &&
		editedMetadata?.fieldOptions[props.fieldKey];
	return (
		<DialogModal modalSize="lg" header={props.header}>
			<ModalBody className="w-100">
				<Container>
					<Subheading>List Values</Subheading>
					<div
						style={{
							height: 400,
							overflowY: 'scroll',
							overflowX: 'hidden',
						}}
					>
						{fields?.options.map((ftv: string) => (
							<Row>
								<Col xl={9} sm={10}>
									<Input
										key={ftv}
										readOnly
										style={{ cursor: 'pointer' }}
										value={ftv}
									/>
								</Col>
								<Col xl={3} sm={2}>
									<CircleButtonContainer className="m-0">
										<CircleButton
											onClick={() => handleRemoveFieldTypeOption(ftv)}
											id="addMetadataItem"
											className="sm"
											icon={faMinus}
											tooltip="Remove list option"
										/>
									</CircleButtonContainer>
								</Col>
							</Row>
						))}
					</div>
					<FormGroup>
						<Label check>
							<Input
								type={'checkbox'}
								checked={isAlphabatized}
								onChange={(e) => {
									setIsAlphabatized(e.target.checked);
									if (e.target.checked) {
										alphabatizeOptions();
									}
								}}
							/>
							Alphabatize list
						</Label>
					</FormGroup>
					<Row className="d-flex align-items-center">
						<Col xl={9} sm={10} className="mt-2">
							<Input
								onChange={(e) => {
									setNewFieldValue(e.target.value);
								}}
								value={newFieldValue}
							/>

							<FormFeedback invalid className={isValid ? 'd-none' : 'd-block'}>
								Input a value to add new field.
							</FormFeedback>
						</Col>
						<Col xl={3} sm={2}>
							<CircleButtonContainer style={{ margin: 0 }}>
								<CircleButton
									onClick={() => {
										if (newFieldValue) {
											setIsValid(true);
											handleAddFieldTypeOption(props.fieldKey, newFieldValue);
											setNewFieldValue('');
										} else {
											setIsValid(false);
										}
									}}
									id="addMetadataItem"
									className="sm"
									icon={faPlus}
									tooltip="Add list option"
								/>
							</CircleButtonContainer>
						</Col>
					</Row>
				</Container>
			</ModalBody>
		</DialogModal>
	);
};
