import { faCopy, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import React from 'react';

import { StyledLabel } from '../../../components/forms';

import {
	Container,
	CopyButton,
	CopyLabel,
	Instructions,
	LinkIcon,
	LinkInput,
} from './share-dialogs.styled-components';
import { ShareDialogComponentProps } from './share-dialog-body.component';

// todo randomize ID
const ShareLink = (props: ShareDialogComponentProps) => {
	const copyLinkToClipboard = () => {
		const link = document.getElementById('linkInput') as HTMLInputElement;

		if (!link) {
			return;
		}

		// select text
		link.select();
		link.setSelectionRange(0, 99999); // for mobile devices

		// copy text
		document.execCommand('copy');

		if (props.onSuccess) {
			props.onSuccess();
		}
	};

	const render = () => (
		<>
			<StyledLabel for="linkInput">Link</StyledLabel>
			<Container>
				<LinkIcon icon={faLink} size="xs" />
				<LinkInput id="linkInput" value={props.link} readOnly={true} />
				<CopyButton id="copyLinkButton" onClick={copyLinkToClipboard}>
					<FontAwesomeIcon icon={faCopy} size="xs" />
					<CopyLabel>Copy</CopyLabel>
				</CopyButton>
				<UncontrolledTooltip target="copyLinkButton">
					Copy link to clipboard
				</UncontrolledTooltip>
			</Container>
			<Instructions>
				<p>This link is valid for 24 hours.</p>
			</Instructions>
		</>
	);

	return render();
};

export default ShareLink;
