import React, { ReactElement, useContext, useState } from 'react';
import styled from 'styled-components';

import AssetViewerWarning from './asset-viewer-warning.component';
import DetailedViewButton from './detail-view-button.component';
import SubmitButton from '../../../components/forms/submit-button.component';
import themeStore from '../../../theme/models/ThemeStore';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'reactstrap';
import ZoomComponent from './Zoom.component';
import RenderWhen from '../../../components/render-when.component';
import { RoleContext } from '../../../permissions/PermissionsContext';
import { HasAssetVersion } from '../../../workflows/types/workflow.types';
import { useAssetHelper } from '../helpers/useAssetHelper';
import { extractPreviewURL } from '../asset-card.component';
import { SupportedMimetypes } from '../asset-card-thumbnail.component';
import { NotificationsContext } from 'notifications';

// styled components
const Container = styled.div`
	display: inline-block;
`;

const ImageViewerWithZoom = ({ asset }: HasAssetVersion) => {
	const [isValid, setValid] = useState(true);
	const { warn } = useContext(NotificationsContext);
	const apiClient = useAssetHelper();
	const [previewUrl, setPreviewUrl] = useState(extractPreviewURL(asset));
	const refreshPreview = async () => {
		if (
			SupportedMimetypes.some((type) => asset.type === type) ||
			asset.customPreviewURL
		) {
			const prefix = asset?.workflowId ? 'w' : 'd';
			const updatedURL = await apiClient.refreshPreview(asset, prefix);
			setPreviewUrl(updatedURL);
			setValid(!!updatedURL);
		} else {
			warn(
				'The asset you are trying to refresh is not previewable, or has no custom preview associated with it.'
			);
		}
	};

	React.useEffect(() => {
		if (
			!asset.previewURL &&
			SupportedMimetypes.some((type) => type === asset.type)
		) {
			const prefix = asset?.workflowId ? 'w' : 'd';
			apiClient.refreshPreview(asset, prefix).then((updatedURL) => {
				setPreviewUrl(updatedURL);
				setValid(!!updatedURL);
			});
		}
		// eslint-disable-next-line
	}, []);

	let previewImage: ReactElement;

	if (!isValid || !previewUrl) {
		previewImage = (
			<AssetViewerWarning
				text={`Unable to preview your file: An error may have occurred while fetching it.`}
				color="danger"
			/>
		);
	} else {
		previewImage = (
			<ZoomComponent alt={asset?.fileName} src={`${previewUrl}`} />
		);
	}
	const { canViewByRole } = React.useContext(RoleContext);
	return (
		<Container>
			<Row className="d-flex align-items-baseline m-0 p-0">
				<Col>
					<DetailedViewButton asset={asset} />
				</Col>
				<Col>
					<RenderWhen
						when={['damReadOnly', 'damEditable', 'damUploadable'].some(
							canViewByRole
						)}
					>
						<SubmitButton
							label={`Refresh ${themeStore._.asset.toLowerCase()} preview`}
							icon={faSync}
							onClick={refreshPreview}
							className="mx-4 my-2"
						/>
					</RenderWhen>
				</Col>
			</Row>
			{previewImage}
		</Container>
	);
};

export default ImageViewerWithZoom;
