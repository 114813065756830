import React, { useCallback } from 'react';
import { Form, ModalBody } from 'reactstrap';
import { useStateFromEvent } from '../../../common/hooks';
import { StyledInput } from '../../../components/forms/forms.styled-components';
import { SubmitButton } from '../../../components/forms/submit-button.component';
import DialogModal from '../../../components/modals/dialog-modal.component';
import { NotificationsContext } from '../../../notifications';
import { useModalCloser } from '../../../stores/ModalStack';

type Props = {
	header: string;
	context: string;
	onClick: (message: string) => Promise<unknown>;
	successMessage?: string;
	messageRequired?: boolean;
	warning?: string;
};

const StageDialog = (props: Props) => {
	const { info } = React.useContext(NotificationsContext);
	const { onClick, messageRequired = false, successMessage } = props;

	const modalStack = useModalCloser();
	const [message, setMessage] = useStateFromEvent('');
	const isDisabled = messageRequired && !message;

	const submitDialog = useCallback(async () => {
		await onClick(message);
		modalStack.closeModal();

		if (successMessage) {
			info(successMessage);
		}
	}, [onClick, modalStack, successMessage, info, message]);

	return (
		<DialogModal header={props.header}>
			<ModalBody>
				<Form>
					<p className="mb-4">{props.context}</p>
					<StyledInput
						type="textarea"
						name="message"
						value={message}
						placeholder="Write a message..."
						onChange={setMessage}
						required={messageRequired}
					/>
					<div className="d-flex mt-4">
						<SubmitButton
							label="Submit"
							onClick={submitDialog}
							disabled={isDisabled}
							className="mt-0 align-self-center"
						/>

						{props.warning ? (
							<p className="ml-4 align-self-center">
								{props.warning}
								<em>
									<br />
									Note: This action cannot be undone.
								</em>
							</p>
						) : null}
					</div>
				</Form>
			</ModalBody>
		</DialogModal>
	);
};

export default StageDialog;
