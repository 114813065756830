import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import React from 'react';
import { Container, Row, Col, Input, Label } from 'reactstrap';
import { WorkflowHealthSettings } from 'resource-management/components/RMWorkflowHealthBarChart';
import { property, startCase } from 'lodash';

export const WorkflowHealthSettingsDialog = ({
	settings,
	setSettings,
	onClosed,
}: {
	settings: WorkflowHealthSettings;
	setSettings: (settings: WorkflowHealthSettings) => void;
	onClosed: () => void;
}) => {
	const [edited, setEdited] = React.useState(settings);

	return (
		<ConfirmationDialog
			confirmText={'Update'}
			header="Workflow Health Widget Settings"
			onConfirm={() => {
				setSettings(edited);
				onClosed();
			}}
			onCancel={onClosed}
		>
			<Container>
				<Row>
					{Object.keys(settings).map((setting) => (
						<Col md={4} key={setting}>
							<Label check>
								<Input
									type={'checkbox'}
									checked={!!property(setting)(edited)}
									onChange={(e) =>
										setEdited({ ...edited, [setting]: e.target.checked })
									}
								/>
								{startCase(setting)}
							</Label>
						</Col>
					))}
				</Row>
			</Container>
		</ConfirmationDialog>
	);
};
